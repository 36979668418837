import gsap, { ScrollTrigger } from 'gsap/all';
import React, { StyleHTMLAttributes } from 'react';
import styled from 'styled-components';
import {
	AnimationElementPosition,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from './AnimationCommon';

export const AnimationElementDuck = (props: { position: AnimationElementPosition }) => {
	const duckRef = React.useRef<HTMLImageElement>(null);

	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1676885931-duck.png', {
		width: 114,
	});

	const containerTimeline = useAnimationContainerTimeline();

	// React.useEffect(() => {
	// 	const timeline = gsap.timeline().timeScale(0.5);
	// 	const rotationAmount = 3;
	// 	const heightAmount = 10;

	// 	timeline.fromTo(
	// 		duckRef.current,
	// 		{
	// 			y: props.position.y || 0,
	// 		},
	// 		{
	// 			keyframes: {
	// 				y: [props.position.y, (props.position.y || 0) - heightAmount, props.position.y],
	// 				easeEach: 'sine.inOut',
	// 				ease: 'none',
	// 			},
	// 			duration: 4,
	// 			repeat: -1,
	// 		},
	// 		0
	// 	);
	// 	timeline.fromTo(
	// 		duckRef.current,
	// 		{
	// 			rotate: -rotationAmount,
	// 		},
	// 		{
	// 			delay: -3,
	// 			keyframes: {
	// 				rotate: [rotationAmount, -rotationAmount, rotationAmount],
	// 				ease: 'none',
	// 				easeEach: 'sine.inOut',
	// 			},
	// 			duration: 4,
	// 			repeat: -1,
	// 		},
	// 		0
	// 	);

	// 	return () => {
	// 		timeline.kill();
	// 	};
	// }, []);

	React.useEffect(() => {
		const enterTween = gsap.fromTo(
			duckRef.current,
			{
				scale: 0.8,
				x: '40%',
				rotate: 20,
			},
			{
				scale: 1,
				x: '0%',
				rotate: 0,
				duration: 0.4,
				ease: 'back(1.3).inOut',
			}
		);

		containerTimeline.add(enterTween, 0);

		return () => {
			enterTween.kill();
		};
	}, []);

	return (
		<AnimationPositionElement position={{ ...props.position, origin: '50% 90%' }}>
			<AnimationImageElement ref={duckRef} image={image} />
		</AnimationPositionElement>
	);
};
