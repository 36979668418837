import { CommonSubTitle } from '@client/common/CommonText';
import { Item } from '@client/core/utils/SpotifyLoader';
import { CommonInput } from '@client/style/Shared';
import { Colors, Fonts } from '@client/style/Variables';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InputSuggestions from './InputSuggestions';

const StyledForm = styled.div`
	margin-bottom: 50px;
	height: fit-content;
	width: 100%;
`;

const Title = styled(CommonSubTitle).attrs({ type: 'label' })`
	color: ${Colors.darkText};
	font-family: ${Fonts.GeomanistBold};
	margin-bottom: 30px;
`;

const StyledInput = styled(CommonInput)``;

const StyledInputSuggestions = styled(InputSuggestions)`
	margin-top: -2px;
`;

interface IInputForm {
	label: string;
	placeholder: string;
	suggestion?: Item[];
	inputHandler: (name: string) => void;
	onSelect?: (item: Item) => void;
	disabled?: boolean;
}

export default function InputForm({
	label,
	placeholder,
	suggestion = [],
	inputHandler,
	onSelect,
	disabled = false,
}: IInputForm) {
	const [shouldSuggest, setShouldSuggest] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement | null>(null);

	// TODO: Add keyboard navigation
	// useEffect(() => {
	// 	const handleNavigation = e => {
	// 		if (e.key === 'ArrowDown') {
	// 			console.log('ArrowDown pressed');
	// 		} else if (e.key === 'ArrowUp') {
	// 			console.log('ArrowUp pressed');
	// 		} else if (e.key === 'Enter') {
	// 			console.log('Enter pressed');
	// 		}
	// 		console.log(e);
	// 	};
	//
	// 	if (inputRef.current) {
	// 		inputRef.current.addEventListener('keydown', handleNavigation);
	// 	}
	//
	// 	return () => {
	// 		if (inputRef.current) {
	// 			inputRef.current.removeEventListener('keydown', handleNavigation);
	// 		}
	// 	};
	// }, []);

	useEffect(() => {
		let timeout: number | undefined;
		const onInput = e => {
			clearTimeout(timeout);

			const value = e.target.value;
			if (value.length < 2) {
				setShouldSuggest(false);
				return;
			}

			timeout = window.setTimeout(() => {
				inputHandler(value);
				setShouldSuggest(true);
			}, 1000);
		};

		if (inputRef.current) {
			inputRef.current.addEventListener('input', onInput);
		}

		return () => {
			clearTimeout(timeout);
			if (inputRef.current) {
				inputRef.current.removeEventListener('input', onInput);
			}
		};
	}, [inputHandler]);

	const select = (item: Item) => {
		onSelect && onSelect(item);
		setShouldSuggest(false);
	};

	return (
		<StyledForm>
			<Title>{label}</Title>
			<StyledInput ref={inputRef} placeholder={placeholder} disabled={disabled}></StyledInput>
			<StyledInputSuggestions
				suggestions={suggestion}
				onSelect={select}
				showSuggestions={shouldSuggest}
			></StyledInputSuggestions>
		</StyledForm>
	);
}
