import { CommonTitle } from '@client/common/CommonText';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import DownloadEntry, { IDownloadEntries } from './DownloadEntry';

const Container = styled.div<{ bgColor?: string }>`
	background-color: ${props => props.bgColor || 'white'};
	width: 100%;
	display: flex;
	flex-direction: column;

	align-items: center;

	padding: 40px var(--gridMargin);

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 80px var(--gridMargin);
	}
`;

const Title = styled(CommonTitle)<{ color: string }>`
	font-size: 44px;
	color: ${props => props.color};

	margin-bottom: 40px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 86px;
	}
`;

const EntryContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;

	${Functions.breakpoint(Breakpoints.tablet)} {
		width: ${Functions.col(6)};
		min-width: 500px;
	}
`;

interface IDownloadModuleProps {
	data: {
		id: string;
		backgroundColorLink: any;
		entries: IDownloadEntries[];
	};
}

export default function DownloadModule(props: IDownloadModuleProps) {
	return (
		<Container
			bgColor={props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<Title
				color={props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor)}
			>
				Download
			</Title>
			<EntryContainer>
				{props.data.entries.map((entry, index) => {
					return (
						<DownloadEntry
							key={props.data.id + '_downloadentry_' + index}
							entry={entry}
							textColor={
								props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor)
							}
						></DownloadEntry>
					);
				})}
			</EntryContainer>
		</Container>
	);
}
