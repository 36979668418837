import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import LinkoutStore from './LinkoutStore';
import gsap from 'gsap';
import { Index } from '@client/style/Variables';
import { PageContent } from '@client/style/Shared';
import { CommonBoldText } from '@client/common/CommonText';
import Site from '@client/store/Site';
import { DatoCMSUtils } from '@client/core/utils/datoCMS/DatoCMSUtils';
import TextSlideInAnimation from '../shared/TextSlideInAnimation';
import { IMadPapir } from './LinkoutPaper';
import AnimationSceneComponent from '@client/animationv2/AnimationSceneComponent'; //@ts-ignore
import WaltherLogo from '@client/assets/svgs/waltherlogo.svg';

const Container = styled.div<{ backgroundColor?: string }>`
	width: 100%;
	height: 100vh;
	background-color: ${props => (props.backgroundColor ? props.backgroundColor : '')};
	position: fixed;
	z-index: ${Index.siteLoader};
	pointer-events: none;

	display: flex;
	align-items: center;
	padding: 0 var(--gridMargin);

	opacity: 0;
`;

const StyledCommonTitle = styled(CommonBoldText)<{ color?: string }>`
	margin-top: 25px;
	font-size: 24px;
	pointer-events: none;
	color: ${props => props.color};
`;

const StyledAnimation = styled.div`
	position: relative;
	width: 100px;
	height: 100px;
	fill: white;
`;

export default function Linkout() {
	const url: string | undefined = LinkoutStore.use(state => state.url);
	const madPapir: IMadPapir | undefined = LinkoutStore.use(state => state.madPapir);

	const containerRef = useRef<HTMLDivElement | null>(null);

	const delayBeforeEnteringSite = 2;

	useEffect(() => {
		if (!containerRef.current) return;

		if (url) {
			const tl = gsap.timeline();
			containerRef.current.style.pointerEvents = 'none';

			tl.to(containerRef.current, { duration: 0.5, opacity: 1 });
			tl.to(containerRef.current, {
				delay: delayBeforeEnteringSite,
				onComplete: () => {
					if (url) {
						window.open(url, '_blank');
					}
					LinkoutStore.goto(undefined);
					LinkoutStore.setMadPapir(undefined);
				},
			});
		} else {
			gsap.to(containerRef.current, { delay: 0.5, duration: 0.5, opacity: 0 });
		}
	}, [url]);

	return (
		<>
			{madPapir && (
				<Container
					ref={containerRef}
					backgroundColor={DatoCMSUtils.getInstance().toRGBString(madPapir.backgroundColorLink?.color)}
				>
					<PageContent>
						<StyledAnimation>
							<WaltherLogo />
						</StyledAnimation>
						<StyledCommonTitle
							color={DatoCMSUtils.getInstance().toRGBString(madPapir.backgroundColorLink?.contrastColor)}
						>
							{madPapir.infoText && (
								<TextSlideInAnimation
									duration={0.2}
									delayBetweenLetters={0.02}
									// onCompleteExecution={animationDone}
									breakAtWord={false}
									text={madPapir.infoText}
								/>
							)}
						</StyledCommonTitle>
					</PageContent>
				</Container>
			)}
		</>
	);
}
