import { CommonTitle } from '@client/common/CommonText';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import SquaredLinkButton from '../shared/SquaredLinkButton';
import MapStore from './MapStore';

const imgSrc = '/assets/images/sover-walter.png';

const Container = styled.div<{ bgColor?: string }>`
	background-color: ${props => props.bgColor || 'white'};

	width: 100%;
`;

const Content = styled.div`
	pointer-events: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	z-index: 1;
	padding: 0 var(--gridMargin);
`;

const StyledCommonTitle = styled(CommonTitle)<{ textColor?: string }>`
	color: ${props => props.textColor};
	font-size: 44px;
	width: 100%;
	position: relative;
	z-index: 1;
	pointer-events: auto;
	margin-top: 20px;
	hyphens: auto;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 86px;
		width: ${Functions.col(14)};
		margin-top: 40px;
	}

	${Functions.breakpoint(Breakpoints.tablet)} {
		width: ${Functions.col(12)};
	}
`;
const StyledLinkButton = styled(SquaredLinkButton)`
	margin-top: 20px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 40px;
	}
`;

const StyledImg = styled.img`
	position: relative;
	transform: translate(-100%, 0);
	left: 80%;
	width: 110%;
	margin-top: -50px;
	margin-bottom: -250px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: -50px;
		left: 55%;
		width: 40%;
		margin-bottom: 0px;
	}
	pointer-events: none;
`;

export default function OpenMapModule(props: any) {
	console.log(props);
	const ctaButtonData = props.data.link[0];

	const onClick = () => {
		MapStore.setMapFocus(props.data.locationId);
	};

	return (
		<Container
			bgColor={props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<Content>
				<StyledCommonTitle
					textColor={
						props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor)
					}
				>
					{props.data.title}
				</StyledCommonTitle>
				<StyledLinkButton
					backgroundColor={Functions.hexFromRGB(ctaButtonData.buttonColorLink?.color)}
					textColor={Functions.hexFromRGB(ctaButtonData.textColorLink?.color)}
					data={ctaButtonData.link[0]}
					title={ctaButtonData.title}
					customOnClick={onClick}
				/>
			</Content>
			<StyledImg src={imgSrc}></StyledImg>
		</Container>
	);
}
