import React, { forwardRef, useImperativeHandle } from 'react';
import styled, { keyframes } from 'styled-components';
import gsap from 'gsap';

const Slider = styled.span`
	overflow: hidden;
	display: block;
	font-size: inherit;
	letter-spacing: inherit;
`;

const enter = keyframes`
	from {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
`;

const Letter = styled.span<{ $index: number }>`
	position: relative;
	display: inline-block;
	white-space: pre-wrap;
	transform-origin: bottom center;
	animation-delay: ${props => props.$index * 0.025}s;

	// Use CSS animation when server side rendered
	.ssr & {
		animation-name: ${enter};
		animation-duration: 0.3s;
		animation-timing-function: cubic-bezier(0.31, 1.425, 0.65, 1);
		animation-fill-mode: both;
	}
`;

interface SliderProps {
	text: string;
	breakAtWord?: boolean;
	onCompleteExecution?: VoidFunction;
	direction?: 'fromBelow' | 'fromLeft';
	duration?: number;
	delay?: number;
	delayBetweenLetters?: number;
}

export interface ImperativeTextSlideInAnimation {
	triggerAnimation: () => void;
}

function TextSlideInAnimation(
	{
		text,
		breakAtWord = false,
		onCompleteExecution,
		direction = 'fromBelow',
		duration = 0.4,
		delay = 0,
		delayBetweenLetters = 0.025,
	}: SliderProps,
	ref: React.ForwardedRef<ImperativeTextSlideInAnimation>
) {
	let chars;
	if (breakAtWord) {
		chars = text.split(/(\s+)/);
	} else {
		chars = text.split('');
	}
	const containerRef = React.useRef<HTMLElement | null>(null);

	const tweenRef = React.useRef<gsap.core.Timeline>();

	useImperativeHandle(ref, () => ({
		triggerAnimation() {
			tweenRef.current?.play(0);
		},
	}));

	React.useEffect(() => {
		if (!containerRef.current) return;
		const letters = containerRef.current.querySelectorAll('span');
		tweenRef.current = gsap.timeline({ paused: true });
		tweenRef.current.fromTo(
			letters,
			{
				y: direction === 'fromBelow' ? '100%' : 0,
				x: direction === 'fromLeft' ? '-100%' : 0,
				opacity: 0,
			},
			{
				y: '0%',
				x: '0%',
				opacity: 1,
				duration: duration,
				delay: delay,
				ease: 'elastic(1.1, 1).out',
				stagger: {
					each: delayBetweenLetters * -1,
				},
				onComplete: () => {
					if (onCompleteExecution !== undefined) onCompleteExecution();
				},
			}
		);
		tweenRef.current.progress(1);
	}, []);

	return (
		<Slider ref={containerRef} aria-label={text}>
			{chars.map((letter, i) => (
				<Letter key={i} $index={chars.length - i} aria-hidden='true'>
					{letter}
				</Letter>
			))}
		</Slider>
	);
}

export default forwardRef(TextSlideInAnimation);
