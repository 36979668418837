import { CommonTitle } from '@client/common/CommonText';
import BrowserDetect from '@client/core/utils/BrowserDetect';
import TextSlideInAnimation from '@client/modules/shared/TextSlideInAnimation';
import Site from '@client/store/Site';
import Functions from '@client/style/Functions';
import { Breakpoints, Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	width: 100%;
	height: 100%;
	z-index: 99999;
	position: fixed;
	top: 0px;
	left: 0px;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${Colors.preloaderBackground};
`;

const StyledCommonTitle = styled(CommonTitle)`
	color: ${Colors.lightText};
	pointer-events: none;
	font-size: 24px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 48px;
	}
`;

export const OverlayFlipMobile = () => {
	const [shouldShow, setShouldShow] = React.useState<boolean>(false);

	React.useEffect(() => {
		const getShouldShow = (): boolean => {
			const ratio: number = (1 / window.innerWidth) * window.innerHeight;

			return ratio < 0.5 && BrowserDetect.isMobile();
		};

		const onResize = (): void => {
			setShouldShow(getShouldShow());
		};

		window.addEventListener('resize', onResize);
		onResize();

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	const render = (): JSX.Element | null => {
		const global = Site.use(state => state.global);

		if (!shouldShow) return null;

		return (
			<Container>
				<StyledCommonTitle>
					<TextSlideInAnimation breakAtWord={false} text={global.orientationMessage} />
				</StyledCommonTitle>
			</Container>
		);
	};

	return render();
};
