import MagicModuleSwitcher from '@client/modules/MagicModuleSwitcher';
import Menu from '@client/modules/Menu/Menu';
import { Page, PageContent } from '@client/style/Shared';
import { Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';

const ContentWrapper = styled(PageContent)`
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	background-color: ${Colors.ErrorPageBackground};
`;

export default function StepByStepPage(props: { content?: any }) {
	return (
		<Page>
			<ContentWrapper>
				<Menu currentSlug={props.content?._prefix + props.content?.slug}></Menu>
				<MagicModuleSwitcher modules={props.content ? props.content.modules : []} />
			</ContentWrapper>
		</Page>
	);
}
