import Functions from '@client/style/Functions';
import { Breakpoints, Fonts, Colors } from '@client/style/Variables';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Image, { ImageData } from './Image';
import HTML from '@client/core/utils/HTML';

const Container = styled.div<{ bgColor?: string; color?: string }>`
	position: relative;
	width: 100%;
	padding: 0 var(--gridMargin);
	margin: auto;
	padding-top: 40px;
	padding-bottom: 20px;
	background-color: ${props => props.bgColor};
	color: ${props => props.color};
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TextContainer = styled.div`
	padding-top: 10px;
	font-size: 14px;
	font-family: ${Fonts.Geomanist};
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 40px;
		width: ${Functions.col(8)};

		position: relative;
		margin: auto;
	}
`;

const ImagesWrapper = styled.div`
	width: ${Functions.colMobile(4)};

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(12)};
	}
`;

const StyledImage = styled(Image)`
	width: 100%;
	aspect-ratio: 4/5;
	${Functions.breakpoint(Breakpoints.mobile)} {
		aspect-ratio: 16/9;
	}
`;

interface ImageAndText {
	id: string;
	image: ImageData;
	text: string;
	modelId: string;
	type: string;
	backgroundColor: any;
}

export default function ImageAndText({ data }: { data: ImageAndText }) {
	return (
		<Container
			className='colorWrapperAnimatedModule'
			bgColor={Functions.hexFromRGB(data.backgroundColor?.color)}
			color={Functions.hexFromRGB(data.backgroundColor?.contrastColor)}
		>
			<ImagesWrapper>
				<StyledImage data={data.image} />
			</ImagesWrapper>
			<TextContainer dangerouslySetInnerHTML={{ __html: HTML.clean(data.text) }} />
		</Container>
	);
}
