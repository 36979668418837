import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';
import { AnimationElementTreetop } from '../AnimationElementTreetop';
import { AnimationElementLamps } from '../AnimationElementLamps';
import { Breakpoints } from '@client/style/Variables';
import Functions from '@client/style/Functions';
import { Sine, gsap } from 'gsap';
import { Power1 } from 'gsap';

const StyledAnimationContainer = styled(AnimationContainer)`
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: -3%;
	}
`;

const RainbowContainer = styled.div`
	width: 420vw;

	svg {
		width: 100%;
		height: auto;
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 188vw;
	}
`;

const MobileContainer = styled.span`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;
const DesktopContainer = styled.span`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;

const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const Flower = () => {
	const ref = React.useRef<HTMLImageElement>(null);

	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1687182001-kapselblomst.png', {});
	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const delay = 0;
		const mainDuration = 0.5;
		const prepDuration = 0.1;

		const skewTl = gsap.timeline({ repeat: -1, repeatDelay: delay });
		skewTl.fromTo(ref.current, { skewX: 1 }, { skewX: 5, duration: mainDuration, ease: 'elastic.out(1.5, 0.4)' });
		skewTl.to(ref.current, { skewX: 1, duration: prepDuration, ease: 'none' });
		skewTl.fromTo(
			ref.current,
			{ skewX: 1 },
			{ skewX: -2.5, duration: mainDuration, ease: 'elastic.out(1.5, 0.4)', delay: delay }
		);
		skewTl.to(ref.current, { skewX: 1, duration: prepDuration, ease: 'none' });

		const timeline = gsap.timeline({ repeat: -1, repeatDelay: delay });
		timeline.set(ref.current, { transformOrigin: 'bottom center' });
		timeline.fromTo(
			ref.current,
			{
				scaleY: 1,
			},
			{ scaleY: 1.05, duration: mainDuration, ease: 'elastic.out(1.5, 0.4)' },
			0
		);
		timeline.to(ref.current, { scale: 1, duration: prepDuration, ease: 'none' });
		containerTimeline.add(skewTl, 0);
		containerTimeline.add(timeline, 0);

		return () => {
			timeline.kill();
			skewTl.kill();
		};
	}, []);

	return (
		<AnimationPositionElement
			position={{
				width: '71vw',
				anchor: 'right',
				x: '11%',
				y: '18%',
				breakpoints: { mobile: { width: '24vw', anchor: 'center', x: '-57.5%', y: '30%' } },
			}}
		>
			<AnimationImageElement ref={ref} image={image} />
		</AnimationPositionElement>
	);
};

const FaceFlower = () => {
	const ref = React.useRef<HTMLImageElement>(null);

	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1687182913-party-blomst.png', {});

	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const duration = 0.3;
		const timeline = gsap.timeline({ repeat: -1, repeatDelay: 0 });
		timeline.set(ref.current, { transformOrigin: 'center center -50px' });

		timeline.fromTo(
			ref.current,
			{
				rotateX: 0,
				rotateZ: 0,
				skewY: 1,
			},
			{ rotateX: -10, rotateZ: -5, skewY: '7%', x: '-=4%', y: '+=2%', duration: duration, ease: Power1.easeInOut }
		);
		timeline.to(ref.current, {
			rotateX: 0,
			rotateZ: 0,
			skewY: 1,
			x: '+=4%',
			y: '-=2%',
			duration: 0.3,
			ease: Power1.easeInOut,
		});

		containerTimeline.add(timeline, 0);

		return () => {
			timeline.kill();
		};
	}, []);
	return (
		<AnimationPositionElement
			position={{
				width: '57vw',
				anchor: 'right',
				x: '25%',
				y: '156%',
				breakpoints: { mobile: { width: '25vw', x: '-2%', y: '150%' }, ultra: { y: '143%' } },
			}}
		>
			<AnimationImageElement ref={ref} image={image} />
		</AnimationPositionElement>
	);
};

const longRainbowSvg = (
	<svg width='1562' height='401' viewBox='0 0 1562 401' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1240.53 59.2C1148.83 49.9 1054.22 84.65 989.19 149.74C952.76 185.27 926.58 234.37 893.84 272.26C831.35 344.47 768.5 328.24 717.18 254.13C696.48 222.64 682.17 187.68 665.34 154.07C639.03 98.81 600.24 44.16 543.2 17.02C468.89 -20.35 390.58 6.28001 344.19 73.17C312 116.01 297.25 171.62 272.3 217.77C226.31 310.16 101.27 312.21 55.52 218.57L36.71 227.33C80.21 321.5 209.06 337.65 272.45 255.28C300.24 219.77 314.08 173.17 333.66 132.02C357.76 80.62 398.65 28.5 458.47 21.62C520.81 14.37 578.32 55.72 612.82 104.62C647.81 154.28 665.59 211.68 697.89 262.56C758.85 354.29 841.67 368.9 914.22 280.41C951.26 235.01 981.07 178.13 1029.03 142.18C1094.58 90.01 1182.89 67.5 1265.27 83.76C1308.11 91.95 1350.93 110.51 1385.73 136.78C1442.74 178.65 1484.78 247.46 1556.17 265.06L1561.34 244.97C1514.63 233.62 1482.72 197 1448.43 164.48C1390.93 105.45 1323.45 67.34 1240.53 59.25V59.22V59.2Z'
			fill='#00708A'
		/>
		<path
			d='M1238.93 77.15C1166.78 70.47 1092.81 92.4 1034.98 135.89C1000.08 161.36 972.03 197.93 948.62 232.65C911.38 289.61 851.22 363.55 775.65 331.95C740.6 317.04 714.19 288.09 694.53 256.09C644.32 170.76 622.2 66.9 518.26 27.43C463.99 6.57 406.11 26.31 369.86 70.54C331.96 112.55 314.81 176.69 287.38 226.28C234.84 329.09 92.1801 329.98 43.3801 223.98L24.3701 232.29C70.8601 339.28 218.46 356.74 288.03 262.95C325.13 215.18 339.92 143.9 375.83 96.34C402.51 57.67 448.6 30.54 496.19 42.55C539.87 52.93 575.57 84.31 599.98 120.95C642.63 184.52 659.61 262.46 715.81 316.11C777.27 376.03 850.5 373.9 910.97 313.52C959.4 265.73 986.04 199.38 1041.21 157.32C1099.91 110.27 1178.39 88.16 1252.95 99.71C1292.85 105.46 1332.99 120.83 1366.51 143.19C1429.92 185.24 1476.15 261.43 1554.34 277.6L1558.5 257.27C1446.42 230.47 1421.53 95.61 1238.94 77.11V77.14L1238.93 77.15Z'
			fill='#82ADFF'
		/>
		<path
			d='M1237.33 94.93C1175.05 89.79 1111.27 107.05 1059.55 142.07C1045.59 151.35 1031.81 162.69 1019.9 174.36C963.73 229.17 934.56 315.12 859.36 349C786.17 383.5 713.53 328.21 677.47 266.49C649.74 220.91 632.26 168.78 602.97 123.34C575.34 80.57 532.55 43.59 480.79 38.44C431.59 33.36 389.7 67.74 364.83 107.88C332.44 157.67 318.93 221.05 280.18 266.47C209.9 350.16 74.0399 330.66 31.3099 229.27L12.1799 237.31C59.1099 349.27 207.96 374.88 290.15 286.45C316.96 257.86 334.09 220.2 349.76 183.83C371.72 132.88 401.45 67.92 462.47 59.32C505.89 55.22 543.55 81.96 570.87 114.32C623.73 181.56 638.47 270.97 701.9 330.53C770.99 397.99 856.91 395.94 924.94 327.83C973.55 280.55 999.68 214.03 1053.47 172.26C1106.55 129.3 1177.17 108.19 1245.11 116.53C1288.5 121.54 1331.27 137.82 1366.83 163.21C1426.54 206.52 1475.66 276.64 1552.24 289.98L1555.93 269.56C1437.69 244.21 1412.68 110.58 1237.33 94.87V94.93Z'
			fill='#FFB324'
		/>
		<path
			d='M1235.7 112.79C1161.63 107.36 1086.08 136 1033.53 188.36C998.01 223.54 974.74 269.91 941.73 308.82C906.02 351.62 852.97 386.74 795.57 378.66C734.03 370.4 685.5 321.4 656.09 269.73C626.03 218.19 609.09 160.91 571.85 113.7C530.79 62.39 460.83 32.51 407.65 84.52C350.51 138.9 341.84 227.73 287.24 283.89C207.19 368.52 63.42 343.62 19.24 234.6L0 242.37C39.9 342.32 162.15 385.21 255.99 333.38C290.73 315.01 315.35 287.34 335.21 253.77C370.46 198.55 390.08 91.35 464.19 78.26C483.16 76.05 502.93 83.32 518.69 93.41C565.93 122.81 588.57 179.87 611.52 228.14C633.29 275.32 660.36 324.09 701.99 357.6C786.75 428.44 885.97 406.44 954.69 325.58C991.33 283.92 1015.02 233.04 1055.3 196.28C1101.16 154.44 1163.51 130.69 1225.7 133.07C1269.35 134.61 1311.61 147.58 1348.67 170.72C1414.94 214.31 1467.24 290.08 1549.88 302.45L1553.62 282.04C1430.25 258.64 1403.73 125.63 1235.73 112.74L1235.7 112.78V112.79Z'
			fill='#FF3F7F'
		/>
	</svg>
);

const Rainbow = () => {
	return <RainbowContainer>{longRainbowSvg}</RainbowContainer>;
};

export default function IllustrationKabselblomst() {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (moduleRef.current) {
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor == null && nextColor == null) {
				setBackgroundColors([undefined, undefined]);
			} else if (prevColor == null) {
				setBackgroundColors([undefined, nextColor]);
			} else if (nextColor == null) {
				setBackgroundColors([prevColor, undefined]);
			} else {
				setBackgroundColors([prevColor, nextColor]);
			}
		}
	}, [moduleRef]);
	return (
		<StyledAnimationContainer
			height={1280}
			width={690}
			breakpoints={{ mobile: { width: 2600, height: 1850 }, ultra: { width: 4000, height: 2750 } }}
			ref={moduleRef}
		>
			<ColorWrapper>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>
			<MobileContainer>
				<AnimationElementTreetop
					variant={8}
					position={{ flip: true, x: '-10%', y: '48%', width: '60vw', breakpoints: { tiny: { width: '84vw' } } }}
				></AnimationElementTreetop>
			</MobileContainer>
			<DesktopContainer>
				<AnimationElementTreetop
					variant={7}
					position={{
						x: '1%',
						y: '48%',
						anchor: 'right',
						breakpoints: {
							tiny: { width: '42vw' },
							mobile: { width: '42vw' },
							tablet: { width: '42vw' },
							laptop: { width: '42vw' },
							desktop: { width: '42vw' },
							largeDesktop: { width: '42vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={6}
					position={{
						flip: true,
						x: '-59%',
						y: '0%',
						breakpoints: {
							tiny: { width: '40vw' },
							mobile: { width: '40vw' },
							tablet: { width: '40vw' },
							laptop: { width: '40vw' },
							desktop: { width: '40vw' },
							largeDesktop: { width: '40vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={7}
					position={{
						flip: true,
						x: '-31%',
						y: '37%',
						breakpoints: {
							tiny: { width: '113vw' },
							mobile: { width: '45vw', x: '-1%' },
							tablet: { width: '45vw' },
							laptop: { width: '45vw' },
							desktop: { width: '45vw' },
							largeDesktop: { width: '45vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={6}
					position={{
						x: '50.5%',
						y: '8%',
						anchor: 'right',
						breakpoints: {
							tiny: { width: '40vw' },
							mobile: { width: '40vw' },
							tablet: { width: '40vw' },
							laptop: { width: '40vw' },
							desktop: { width: '40vw' },
							largeDesktop: { width: '40vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<AnimationPositionElement
				position={{ y: '45%', angle: -7, x: '-31%', breakpoints: { mobile: { x: '-26%', angle: -7, y: '21%' } } }}
			>
				<Rainbow />
			</AnimationPositionElement>
			<DesktopContainer>
				<AnimationElementTreetop
					variant={6}
					position={{
						x: '64%',
						y: '74%',
						breakpoints: {
							tiny: { width: '40vw' },
							mobile: { width: '40vw' },
							tablet: { width: '40vw' },
							laptop: { width: '40vw' },
							desktop: { width: '40vw' },
							largeDesktop: { width: '40vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={3}
					position={{
						x: '-50%',
						y: '74%',
						anchor: 'right',
						breakpoints: {
							tiny: { width: '113vw' },
							mobile: { width: '38vw' },
							tablet: { width: '38vw' },
							laptop: { width: '38vw' },
							desktop: { width: '38vw' },
							largeDesktop: { width: '38vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<Flower />

			<MobileContainer>
				<AnimationElementTreetop
					variant={3}
					position={{ y: '75%', x: '40%', anchor: 'right', breakpoints: { tiny: { width: '113vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={7}
					position={{
						flip: true,
						x: '-31%',
						y: '103%',
						breakpoints: {
							tiny: { width: '113vw' },
							mobile: { width: '45vw', x: '-1%' },
							tablet: { width: '45vw' },
							laptop: { width: '45vw' },
							desktop: { width: '45vw' },
							largeDesktop: { width: '45vw' },
						},
					}}
				></AnimationElementTreetop>
			</MobileContainer>
			<AnimationElementLamps
				variant={6}
				position={{
					scale: 0.55,
					x: '-23%',
					y: '100%',
					breakpoints: { mobile: { scale: 0.4, y: '47%', x: '-32%' }, ultra: { y: '75%' } },
				}}
			></AnimationElementLamps>
			<AnimationElementLamps
				variant={7}
				position={{
					scale: 0.9,
					x: '-10%',
					y: '175%',
					angle: 0,
					breakpoints: { mobile: { scale: 0.65, y: '95%', x: '-21%' }, ultra: { y: '142%' } },
				}}
			></AnimationElementLamps>
			<DesktopContainer>
				<AnimationElementTreetop
					variant={3}
					position={{
						y: '112%',
						x: '42.5%',
						breakpoints: {
							tiny: { width: '38vw' },
							mobile: { width: '38vw' },
							tablet: { width: '38vw' },
							laptop: { width: '38vw' },
							desktop: { width: '38vw' },
							largeDesktop: { width: '38vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={8}
					position={{
						flip: true,
						x: '-1%',
						y: '32%',
						breakpoints: {
							tiny: { width: '113vw' },
							mobile: { width: '45vw', x: '-1%' },
							tablet: { width: '45vw' },
							laptop: { width: '45vw' },
							desktop: { width: '45vw' },
							largeDesktop: { width: '45vw' },
							ultra: { width: '45vw', y: '28%' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={8}
					position={{
						x: '6%',
						y: '42%',
						anchor: 'right',
						breakpoints: {
							tiny: { width: '113vw' },
							mobile: { width: '45vw' },
							tablet: { width: '45vw' },
							laptop: { width: '45vw' },
							desktop: { width: '45vw' },
							largeDesktop: { width: '45vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={8}
					position={{
						x: '1%',
						y: '50%',
						anchor: 'right',
						breakpoints: {
							tiny: { width: '113vw' },
							mobile: { width: '31vw' },
							tablet: { width: '31vw' },
							laptop: { width: '31vw' },
							desktop: { width: '31vw' },
							largeDesktop: { width: '31vw', y: '55%' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>

			<MobileContainer>
				<AnimationElementTreetop
					variant={6}
					position={{ flip: true, x: '-57%', y: '125%', breakpoints: { tiny: { width: '100vw' } } }}
				></AnimationElementTreetop>
			</MobileContainer>
			<FaceFlower />
		</StyledAnimationContainer>
	);
}
