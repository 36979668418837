import Functions from '@client/style/Functions';
import { Breakpoints, Colors, Fonts } from '@client/style/Variables';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div<{ bgColor?: string; color?: string }>`
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: ${props => props.bgColor || 'transparent'};
	color: ${props => props.color || 'inherit'};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 80px;
		/* padding-bottom: 80px; */
	}
`;

const QuoteSign = styled.div`
	width: 40px;
	height: 32px;
`;

const QuoteContainer = styled.div`
	font-family: ${Fonts.GeomanistBold};
	font-size: 28px;
	margin: 0 var(--gridMargin);
	${Functions.breakpoint(Breakpoints.mobile)} {
		max-width: 940px;
		font-size: 44px;
	}
	text-align: center;
	margin-bottom: 20px;
	margin-top: 20px;
`;

const AuthorContainer = styled.div`
	font-size: 18px;
	text-align: center;
	font-family: ${Fonts.Geomanist};
`;

interface QuoteProps {
	data: {
		quoteText: string;
		quoteAuthor: string;
		backgroundColor: any;
	};
}

export default function QuoteModule(props: QuoteProps) {
	return (
		<Container
			className='colorWrapperAnimatedModule'
			bgColor={Functions.hexFromRGB(props.data.backgroundColor?.color)}
			color={Functions.hexFromRGB(props.data.backgroundColor?.contrastColor)}
		>
			<QuoteSign>
				<svg viewBox='0 0 62 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M28.0715 11.2108L18.7856 0.867188C7.78559 8.48127 0.5 21.8418 0.5 36.3517C0.5 46.5517 7.21422 51.8672 15.0714 51.8672C22.9285 51.8672 29.2143 45.4024 29.2143 37.501C29.2143 31.4672 25.3571 25.2897 17.9285 23.5658C18.7857 18.3939 24.2143 13.3658 28.0715 11.2108ZM60.3572 11.2108L51.0715 0.867188C40.0715 8.48127 32.7857 21.8418 32.7857 36.3517C32.7857 46.5517 39.5001 51.8672 47.3573 51.8672C55.2144 51.8672 61.5 45.4024 61.5 37.501C61.5 31.4672 57.643 25.2897 50.2144 23.5658C51.0716 18.3939 56.5 13.3658 60.3572 11.2108Z'
						fill={Functions.hexFromRGB(props.data.backgroundColor?.contrastColor) || '#262C2E'}
					/>
				</svg>
			</QuoteSign>
			<QuoteContainer>{props.data.quoteText}</QuoteContainer>
			<AuthorContainer>{props.data.quoteAuthor}</AuthorContainer>
		</Container>
	);
}
