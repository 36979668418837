import { IDatoCMSColor } from './IDatoCMSColor';

export class DatoCMSUtils {
	private static _instance: DatoCMSUtils | undefined = undefined;

	public static getInstance(): DatoCMSUtils {
		if (this._instance === undefined) {
			this._instance = new DatoCMSUtils();
		}

		return this._instance;
	}

	public toRGBString(color: IDatoCMSColor): string {
		if (color === undefined || color === null) return 'rgba(0, 0, 0, 0)';

		return `rgba(${color.red}, ${color.green}, ${color.blue}, ${color.alpha})`;
	}

	public toHexColor(color: IDatoCMSColor): string {
		let hex: string = '#' + ((1 << 24) + (color.red << 16) + (color.green << 8) + color.blue).toString(16).slice(1);

		if (color.alpha !== undefined) {
			const alpha = Math.round(0o1 * 255);
			const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
			hex += hexAlpha;
		}

		return hex;
	}
}
