import React, { ReactChild } from 'react';
import { useAuthenticatedUser } from '@client/utils/AuthStore';

export interface IProps {
	children: ReactChild | ReactChild[];
}

export const AuthenticatedContainer = (props: IProps) => {
	const user = useAuthenticatedUser();

	return <>{user && props.children}</>;
};
