import React from 'react';
import { SnailLog } from './SnailLog';

export interface moduleProps {
	modelId: string;
	component: (props: any) => JSX.Element;
	include?: any;
}

export function Module(props: moduleProps) {
	return <></>;
}

export default function ModuleSwitcherCore(props: { children: any; modules: Array<any> }) {
	if (!Array.isArray(props.modules)) return <></>;

	const children = !Array.isArray(props.children) ? [props.children] : props.children;
	const modulesArr: any[] = [];

	for (let i = 0; i < props.modules.length; i++) {
		let moduleData = props.modules[i];
		const found = children.find(c => c.props.modelId === moduleData.modelId && c.props.component) as {
			props: moduleProps;
		};
		if (!found) {
			SnailLog.error(`Module not found : ${moduleData.modelId}`);
		} else {
			if (found.props.include) {
				moduleData = {
					...found.props.include,
					...moduleData,
				};
			}

			const ofType = props.modules.filter(m => m.modelId === moduleData.modelId);

			const modelContext = {
				amount: props.modules.length,
				index: i,
				amountOfType: ofType.length,
				indexOfType: ofType.findIndex(m => m.id === moduleData.id),
				list: props.modules.map(m => {
					return {
						modelId: m.modelId,
						id: m.id,
						type: m.type,
					};
				}),
			};

			modulesArr.push(
				<found.props.component
					data={moduleData}
					context={modelContext}
					key={moduleData && moduleData.id ? moduleData.id : i}
				/>
			);
		}
	}

	return <>{modulesArr}</>;
}
