import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import styled from 'styled-components';
import { Index, Fonts, Colors, Breakpoints } from '../../style/Variables';
import ReactPlayer from 'react-player';
import { getWord } from '../../core/utils/Language';
import CommonWords from '../../types/CommonWords';
import RoundButtonWithAnimation from '../shared/RoundButtonWithAnimation';
import Functions from '@client/style/Functions';

export enum VideoProvider {
	YOUTUBE = 'youtube',
	VIMEO = 'vimeo',
	FACEBOOK = 'facebook',
}

export interface ImperativeVideo {
	play: () => void;
	pause: () => void;
	isPlaying: () => boolean;
	mutePlayer: () => void;
	unmutePlayer: () => void;
	getElement: () => null | Element;
}

export interface VideoData {
	height: number;
	width: number;
	url: string;
	provider: VideoProvider;
	thumbnailUrl: string;
	providerUid: string;
	title: string;
}

export interface VideoProps {
	data: VideoData;
	autoplay?: boolean;
	controls?: boolean;
	muted?: boolean;
	loop?: boolean;
	className?: string;
	ratio?: number;
	closeModal?: () => void;
	updateContainerOnPlay?: (isPlaying: boolean) => void;
	whenReady?: () => void;
	vpOpen?: boolean;
	customClass?: string;
}

export const VideoWrapper = styled.div<{ visibile?: boolean }>``;

const CloseButton = styled(RoundButtonWithAnimation)`
	position: absolute;
	top: 20px;
	left: calc(100vw - 72px);
	margin-right: 20px;
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: ${Index.FullScreenVideoPlayer};
`;

export default forwardRef(function VideoComponent(
	{
		data,
		autoplay = false,
		muted = false,
		loop = false,
		controls = true,
		className = undefined,
		ratio = undefined,
		closeModal,
		whenReady,
		updateContainerOnPlay = undefined,
		vpOpen = false,
		customClass = 'react-player',
	}: VideoProps,
	ref
) {
	const [isPlaying, setIsPlaying] = useState(autoplay);
	const [isMuted, setIsMuted] = useState(muted);
	const [volume, setVolume] = useState(0.5);

	const wrapperRef = useRef<HTMLDivElement | null>(null);

	const localCloseModal = () => {
		closeModal ? closeModal() : null;
	};

	useImperativeHandle(ref, () => ({
		play() {
			setIsPlaying(true);
		},
		pause() {
			setIsPlaying(false);
		},
		isPlaying() {
			return isPlaying;
		},
		mutePlayer() {
			setIsMuted(true);
			setVolume(0);
		},
		unmutePlayer() {
			setIsMuted(false);
			setVolume(0.5);
		},
		getElement() {
			if (!wrapperRef.current) return null;
			return wrapperRef.current.querySelector('.' + customClass) ?? null;
		},
	}));

	if (
		data.provider !== VideoProvider.YOUTUBE &&
		data.provider !== VideoProvider.VIMEO &&
		data.provider !== VideoProvider.FACEBOOK
	) {
		return <></>;
	}

	return (
		<VideoWrapper visibile={vpOpen} ref={wrapperRef} className={className}>
			{vpOpen && (
				<CloseButton size={40} interaction={localCloseModal}>
					<svg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M0.656854 12.6569L6.31371 7M11.9706 1.34315L6.31371 7M11.9706 12.6569L6.31371 7M6.31371 7L0.656854 1.34315'
							stroke='#262C2E'
							strokeWidth='1.5'
						/>
					</svg>
				</CloseButton>
			)}
			<ReactPlayer
				className={customClass}
				playing={isPlaying}
				autoPlay={autoplay}
				muted={isMuted}
				loop={loop}
				pip={true}
				volume={volume}
				onReady={whenReady}
				onError={err => {
					console.log(err);
				}}
				onEnded={() => {
					setIsPlaying(false);
					if (updateContainerOnPlay) {
						updateContainerOnPlay(false);
					}
				}}
				onPlay={() => {
					if (updateContainerOnPlay) {
						updateContainerOnPlay(true);
					}
				}}
				onPause={() => {
					if (updateContainerOnPlay) {
						updateContainerOnPlay(false);
					}
					setIsPlaying(false);
				}}
				controls={controls}
				config={{
					youtube: {
						playerVars: {
							modestBranding: 1,
							rel: 0,
						},
					},
					vimeo: {
						playerOptions: {
							byline: false,
							dnt: true,
							title: false,
							pip: true,
						},
					},
				}}
				url={data.url}
			/>
		</VideoWrapper>
	);
});
