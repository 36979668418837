import React from 'react';
import styled from 'styled-components';
import Functions from '../../../style/Functions';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';

const Container = styled.div`
	height: fit-content;
	width: auto;
	position: relative;
	/* overflow-x: hidden; */
	${Functions.breakpoint('tablet')} {
		/* overflow-x: visible; */
	}
`;
const Inner = styled.div<{ gap: GapType }>`
	position: relative;
	width: fit-content;
	/* white-space: nowrap; */
	left: 0;
	display: flex;
	align-items: end;
	height: 100%;
	gap: var(--gridGutter) ${props => (props.gap === 'followGrid' ? 'var(--gridGutter)' : props.gap + 'px')};
`;

export interface IDraggableOptions {
	gap: GapType;
	snap: boolean;
}

function closestNumber(numbers: number[], target: number): number {
	let closestLow: number | null = null;
	let closestLowDistance: number = Infinity;
	let closestLowIndex: number = 0;
	let closestHigh: number | null = null;
	let closestHighDistance: number = Infinity;
	let closestHighIndex: number = 0;

	for (let i = 0; i < numbers.length; i++) {
		const distance = Math.abs(numbers[i] - target);

		if (numbers[i] < target && distance < closestLowDistance) {
			closestLowDistance = distance;
			closestLow = numbers[i];
			closestLowIndex = i;
		} else if (numbers[i] > target && distance < closestHighDistance) {
			closestHighDistance = distance;
			closestHigh = numbers[i];
			closestHighIndex = i;
		}
	}

	if (closestLow === null) {
		return closestHighIndex ? closestHighIndex : 0;
	} else if (closestHigh === null) {
		return closestLowIndex ? closestLowIndex : 0;
	} else {
		// the magic number is, when target is withing 100 pixels of the next section, then it is the next index
		if (target < closestLow + 100) {
			return closestLowIndex;
		} else {
			return closestHighIndex;
		}
	}
}

function oldClosestNumber(numbers: number[], target: number): number {
	let lowestDifference: number = 1000000000;
	let lowestDifIndex: number = 0;

	for (let index = 0; index < numbers.length; index++) {
		const snapPoint = numbers[index];

		const difference = Math.abs(Math.abs(target) - Math.abs(snapPoint));

		if (difference < lowestDifference) {
			lowestDifference = difference;
			lowestDifIndex = index;
		}
	}
	return lowestDifIndex;
}

type GapType = 'followGrid' | number;
type BoundariesAndSnapType = {
	snapPoints: number[];
	bounds: { minX: number; maxX: number; minY: number; maxY: number };
};

function DraggableCarousel({
	children,
	className,
	activeIndex,
	dragToNewIndex,
	options = { gap: 'followGrid', snap: true },
}: React.PropsWithChildren<{
	className?: string;
	activeIndex: number;
	dragToNewIndex: (index: number) => void;
	options?: IDraggableOptions;
}>) {
	const activeIndexRef = React.useRef(activeIndex);
	const isDragginRef = React.useRef(false);
	const boundariesRef = React.useRef<BoundariesAndSnapType>();
	const carouselContainerRef = React.useRef<HTMLDivElement>(null);
	const parallaxChildrenPositionRef = React.useRef<any[]>([]);

	function updateParallaxPosition(x: number) {
		parallaxChildrenPositionRef.current.forEach(set => {
			set(x);
		});
	}

	// Init draggable and event handlers on first render
	React.useEffect(() => {
		gsap.registerPlugin(Draggable, InertiaPlugin);

		boundariesRef.current = calcBoundariesAndSnapPoints();

		const draggable = Draggable.create(carouselContainerRef.current, {
			bounds: boundariesRef.current.bounds,
			type: 'x',
			// intertia: true,
			throwProps: true,
			throwResistance: 1,
			maxDuration: 0.25,
			edgeResistance: 0.65,
			zIndexBoost: false,
			snap: options.snap ? { x: boundariesRef.current.snapPoints } : undefined,
			onDrag(e) {
				updateParallaxPosition(this.x);
				updateIndex(calcNewIndex(this.x));
			},
			onDragStart() {
				isDragginRef.current = true;
			},
			onDragEnd() {
				isDragginRef.current = false;
			},
			onClick(e) {
				if (carouselContainerRef.current === e.target || e.target.getAttribute('data-carousel-ignore')) return;

				const index = calcNewIndex(-e.target.offsetLeft);
				updateIndex(index);
			},
			onThrowUpdate(e) {
				updateParallaxPosition(this.x);
				updateIndex(calcNewIndex(this.x));
			},
		})[0];

		function calcBoundariesAndSnapPoints(): BoundariesAndSnapType {
			if (!carouselContainerRef.current) {
				return { snapPoints: [], bounds: { minX: 0, maxX: 0, minY: 0, maxY: 0 } };
			}
			const snapPoints: number[] = [];
			let widthToPush = 0;
			const gap =
				options.gap === 'followGrid'
					? parseInt(getComputedStyle(document.documentElement).getPropertyValue('--gridGutter'))
					: options.gap;

			for (let index = 0; index < carouselContainerRef.current.children.length; index++) {
				if (index === 0) {
					widthToPush += 0;
				} else {
					const currentChild = carouselContainerRef.current.children[index];
					if (!currentChild.getAttribute('data-carousel-ignore')) {
						const previousChild = carouselContainerRef.current.children[index - 1];
						widthToPush += previousChild.clientWidth + gap;
					}
				}

				snapPoints.push(Math.ceil(-widthToPush));
			}

			return { snapPoints: snapPoints, bounds: { minX: 0, maxX: -widthToPush, minY: 0, maxY: 0 } };
		}

		function calcNewIndex(xPos: number) {
			const snapPoints = boundariesRef.current?.snapPoints;
			// Don't calculate new index when scrolling past start (x > 0)
			if (!snapPoints || xPos > 0) return 0;

			if (options.snap) {
				return oldClosestNumber(snapPoints, xPos);
			} else {
				return closestNumber(snapPoints, xPos);
			}
		}

		function handleResize() {
			boundariesRef.current = calcBoundariesAndSnapPoints();
			draggable.applyBounds(boundariesRef.current.bounds);

			if (options.snap) {
				draggable.vars.snap = {
					x: boundariesRef.current.snapPoints,
				};
			}

			draggable.update();
			moveToIndex(activeIndexRef.current, 0);
		}

		function updateIndex(newIndex: number) {
			// Only trigger index update if it has changed
			if (newIndex !== activeIndexRef.current) {
				activeIndexRef.current = newIndex;

				// Send index update to parent
				dragToNewIndex(newIndex);
			}

			return newIndex;
		}

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
			draggable.kill();
		};
	}, []);

	// Create quick setters for parallax children
	React.useEffect(() => {
		const query = gsap.utils.selector(carouselContainerRef);

		query('[data-speed]').forEach(el => {
			const setter = gsap.quickSetter(el, 'x', 'px');
			const ratio = 1 - parseFloat(el.getAttribute('data-speed') || '1');

			parallaxChildrenPositionRef.current.push((x: number) => {
				setter(x * ratio * -1);
			});
		});
	}, []);

	// Scroll to new index when it changes
	React.useEffect(() => {
		activeIndexRef.current = activeIndex;
		moveToIndex(activeIndex, 0.5);
	}, [activeIndex]);

	function moveToIndex(index: number, duration: number) {
		// Don't move if user is dragging
		if (!isDragginRef.current) {
			gsap.to(carouselContainerRef.current, {
				x: boundariesRef.current?.snapPoints[index],
				duration: duration,
				ease: 'back(1.3).out',
				onUpdate() {
					updateParallaxPosition(parseFloat(this._targets[0]._gsap.x));
				},
			});
		}
	}

	return (
		<Container className={className}>
			<Inner ref={carouselContainerRef} gap={options.gap}>
				{children}
			</Inner>
		</Container>
	);
}
export default DraggableCarousel;
