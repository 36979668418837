import React from 'react';
import { useAuthenticatedStatus, useAuthenticatedUser } from '@client/utils/AuthStore';
import styled from 'styled-components';
import { AuthenticatedContainer } from './AuthenticatedContainer';
import { NotAuthenticatedContainer } from './NotAuthenticatedContainer';
import { DefaultButton } from '../shared/DefaultButton';
import { IContentPageState } from '@client/store/ContentPageStore';
import { Colors } from '@client/style/Variables';
import { commonTextStyle } from '@client/common/CommonText';
import { context } from '@client/App';
import PageHandler from '@client/core/PageSwitch/PageHandler';

const Container = styled.div``;

const SignedInArea = styled.div<{ theme?: IContentPageState }>`
	display: inline-flex;
	background-color: ${Colors.lightBackground};
	border-radius: 40px;
	color: ${Colors.preloaderBackground};
	position: relative;

	.info-area {
		min-width: 100px;
		display: flex;
		height: 100%;
		position: relative;

		.info {
			${commonTextStyle}
			height: 100%;
			padding-top: 11px;
			padding-left: 16px;
			padding-right: 16px;
		}
	}
`;

interface IProps {
	className?: string;
	rememberPath?: boolean;
}

export const LoginLogoutButton = (props: IProps) => {
	const user = useAuthenticatedUser();
	const status = useAuthenticatedStatus();

	return (
		<Container className={props.className}>
			<AuthenticatedContainer>
				<SignedInArea>
					<DefaultButton
						title='Log ud'
						backgroundColor={Colors.loginButton}
						textColor={Colors.lightText}
						onClick={() => {
							context.auth.signOut();
						}}
					/>
					<div
						className='info-area'
						onClick={() => {
							PageHandler.getInstance().goto('/min-side');
						}}
					>
						<div className='info'>{user?.userInfo?.given_name}</div>
					</div>
				</SignedInArea>
			</AuthenticatedContainer>

			<NotAuthenticatedContainer>
				<>
					{status === 'authenticating' ? (
						<DefaultButton title='Vent' backgroundColor={Colors.loginButtonIdle} textColor={Colors.lightText} />
					) : (
						<DefaultButton
							title='Log ind'
							backgroundColor={Colors.lightBackground}
							textColor={Colors.darkBackground}
							onClick={() => {
								context.auth.signIn(props.rememberPath !== false);
							}}
						/>
					)}
				</>
			</NotAuthenticatedContainer>
		</Container>
	);
};
