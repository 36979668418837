import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { CommonText, CommonTitle } from '@client/common/CommonText';
import HTML from '@client/core/utils/HTML';
import { Breakpoints, Colors } from '@client/style/Variables';
import Functions from '@client/style/Functions';
import SquaredLinkButton from '@client/modules/shared/SquaredLinkButton';
import VideoComponent, { VideoData, ImperativeVideo } from './VideoComponent';
import WaveContainer from '../Dividers.tsx/WaveContainer';

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	${Functions.breakpoint(Breakpoints.mobile)} {
		height: auto;
		aspect-ratio: 16/9;
	}
`;

const Content = styled.div`
	/* background-color: yellow; */
	pointer-events: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 1;
	width: 100vw;
	height: 100vh;
	${Functions.breakpoint(Breakpoints.mobile)} {
		height: auto;
		aspect-ratio: 16/9;
		position: absolute;
	}
`;

const StyledCommonTitle = styled(CommonTitle)<{ textColor?: string }>`
	color: ${props => props.textColor || Colors.lightText};
	font-size: 55px;
	max-width: 400px;
	position: relative;
	z-index: 1;
	pointer-events: auto;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 96px;
		max-width: 800px;
	}

	${Functions.breakpoint(Breakpoints.desktop)} {
		font-size: 110px;
		max-width: 1000px;
	}
`;

const VideoPlayer = styled(VideoComponent)`
	position: absolute;
	.promo-player {
		aspect-ratio: 16/9;
		width: auto !important;
		height: 100vh !important;
		object-fit: cover;
		pointer-events: none;

		${Functions.breakpoint(Breakpoints.mobile)} {
			height: auto !important;
			width: 100vw !important;
		}
	}
`;

const VideoContainer = styled.div`
	position: relative;
`;

const StyledCommonText = styled(CommonText)<{ textColor?: string }>`
	color: ${props => props.textColor || Colors.lightText};
	max-width: 400px;
	position: relative;
	z-index: 1;
	pointer-events: auto;

	${Functions.breakpoint(Breakpoints.laptop)} {
		max-width: 600px;
	}

	${Functions.breakpoint(Breakpoints.desktop)} {
		max-width: 1000px;
	}
	${Functions.breakpoint(Breakpoints.ultra)} {
		max-width: 1200px;
	}
`;

const StyledLinkButton = styled(SquaredLinkButton)`
	margin-top: 30px;
`;

const StyledBackground = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	height: 50%;
`;

export const VideoCTA = (props: any) => {
	// console.log(props);
	const ctaButtonData = props.data.link[0];
	const videoPlayerRef = useRef<ImperativeVideo>();

	const moduleRef = React.useRef<HTMLDivElement>(null);
	const prevColorBackground = React.useRef<HTMLDivElement>(null);
	const nextColorBackground = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const FindModuleElementColor = (element: Element) => {
			const colorElementClassName = 'colorWrapperAnimatedModule';
			let colorElement: Element | null;

			if (element.classList.contains(colorElementClassName)) {
				colorElement = element;
			} else {
				colorElement = element.querySelector(`div[class*="${colorElementClassName}"]`);
			}

			return colorElement ? window.getComputedStyle(colorElement).backgroundColor : undefined;
		};

		if (moduleRef.current) {
			const prevSibling = moduleRef.current.previousElementSibling;
			const nextSibling = moduleRef.current.nextElementSibling;

			const prevColor = prevSibling ? FindModuleElementColor(prevSibling) : undefined;
			const nextColor = nextSibling ? FindModuleElementColor(nextSibling) : undefined;

			if (prevColor && prevColorBackground.current) {
				prevColorBackground.current.style.backgroundColor = prevColor;
			}
			if (nextColor && nextColorBackground.current) {
				nextColorBackground.current.style.backgroundColor = nextColor;
			}
		}
	}, []);

	return (
		<VideoContainer ref={moduleRef}>
			<StyledBackground ref={prevColorBackground} style={{ top: 0 }} />
			<StyledBackground ref={nextColorBackground} style={{ bottom: 0 }} />
			<WaveContainer side='both'>
				<Container>
					<VideoPlayer
						ref={videoPlayerRef}
						customClass={'promo-player'}
						controls={false}
						autoplay={true}
						muted={true}
						loop={true}
						data={props.data.video}
					/>
					<Content>
						<StyledCommonTitle
							textColor={
								props.data.backgroundColorLink?.contrastColor
									? Functions.hexFromRGB(props.data.backgroundColorLink.contrastColor)
									: undefined
							}
							dangerouslySetInnerHTML={{ __html: HTML.clean(props.data.title) }}
						/>
						<StyledCommonText
							textColor={
								props.data.backgroundColorLink?.contrastColor
									? Functions.hexFromRGB(props.data.backgroundColorLink.contrastColor)
									: undefined
							}
							dangerouslySetInnerHTML={{ __html: HTML.clean(props.data.subtitle) }}
						/>

						{props.data.link.length > 0 && (
							<StyledLinkButton
								backgroundColor={Functions.hexFromRGB(ctaButtonData.buttonColorLink?.color)}
								textColor={Functions.hexFromRGB(ctaButtonData.textColorLink?.color)}
								data={ctaButtonData.link[0]}
								title={ctaButtonData.title}
							/>
						)}
					</Content>
				</Container>
			</WaveContainer>
		</VideoContainer>
	);
};
