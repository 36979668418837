import React from 'react';
import styled from 'styled-components';
import { DefaultPageWrapper, Page, TextStyleBody, TextStyleHeader } from '@style/Shared';
import Menu from '@client/modules/Menu/Menu';
import GotoElement from '@client/core/utils/GotoElement';
import { Colors, presetColors } from '@client/style/Variables';
import MyPageFavourites from '@client/modules/MyPage/MyPageFavourites';
import { AuthenticatedContainer } from '@client/modules/Auth/AuthenticatedContainer';
import { NotAuthenticatedContainer } from '@client/modules/Auth/NotAuthenticatedContainer';
import SimpleFooter from '@client/modules/Footers/SimpleFooter';
import MagicModuleSwitcher from '@client/modules/MagicModuleSwitcher';

const StyledPage = styled(Page)`
	background-color: ${presetColors.nude};
`;

const ContentWrapper = styled.div`
	${DefaultPageWrapper}
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 300px;
	min-height: 90vh;

	h1 {
		${TextStyleHeader}
		color: ${Colors.darkText};
		width: 100%;
		text-align: center;
	}
`;

const NotAuthenticatedText = styled.h2`
	${TextStyleBody}
	padding-top: 100px;
	color: ${Colors.darkText};
	text-align: center;
`;

const ModulWrapper = styled.div`
	width: 100%;
`;

export default function MyPage(props: { content?: any }) {
	return (
		<StyledPage>
			<Menu
				onLogoClick={() => GotoElement.Instance.scrollToElementWithId(`module_${props.content.startId}`)}
				specificColor={Colors.footerDarkBackground}
				currentSlug={props.content?._prefix + props.content?.slug}
			/>

			<ContentWrapper>
				<h1>{props.content.title}</h1>

				<AuthenticatedContainer>
					<MyPageFavourites artists={props.content._artists} />
				</AuthenticatedContainer>

				<NotAuthenticatedContainer>
					<NotAuthenticatedText>Log ind for at se din side</NotAuthenticatedText>
				</NotAuthenticatedContainer>

				<ModulWrapper id='CurrentPageContent'>
					<MagicModuleSwitcher modules={props.content ? props.content.modules : []} />
				</ModulWrapper>
			</ContentWrapper>

			<SimpleFooter />
		</StyledPage>
	);
}
