import Store from '@core/Store';
import { IMadPapir } from './LinkoutPaper';

export interface ILinkoutState {
	url: string | undefined;
	madPapir: IMadPapir | undefined;
}

export const DefaultState = {
	url: undefined,
	madPapir: undefined,
};

export class LinkoutStoreClass extends Store<ILinkoutState> {
	constructor() {
		super(DefaultState);
	}

	public goto(url: string | undefined) {
		this.set({
			url: url,
		});
	}

	public setMadPapir(madPapir: IMadPapir | undefined) {
		this.set({
			madPapir: madPapir,
		});
	}
}

const LinkoutStore = new LinkoutStoreClass();

export default LinkoutStore;
