import React, { DOMElement, forwardRef, useEffect, useImperativeHandle } from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import gsap from 'gsap';
import { Index, Breakpoints, Colors, Fonts } from '../../style/Variables';
import { CommonText, CommonTitle } from '@client/common/CommonText';
import SingleArtist from './SingleArtist';
import HTML from '@client/core/utils/HTML';
import { AnimatedModule } from '../AnimatedModule';

const Container = styled.div`
	height: fit-content;
	width: 100%;
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: center;
	flex-wrap: nowrap;
	justify-content: flex-start;
	z-index: 1;
	position: relative;
	padding-top: 80px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 200px;
	}

	${Functions.breakpoint(Breakpoints.tablet)} {
		padding-top: 200px;
	}
`;

const StyledAnimatedModule = styled(AnimatedModule)`
	height: auto;
`;

const StyledCommonTitle = styled(CommonTitle)<{ textColor?: string }>`
	color: ${props => props.textColor || Colors.lightText};
	max-width: 400px;
	position: relative;
	z-index: 0;
	pointer-events: auto;
	margin: auto;
	padding: 0 60px;
	font-size: 34px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 90px;
		font-family: ${Fonts.GeomanistBold};
		font-size: 48px;
		font-weight: 400;
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		padding-bottom: 90px;
		max-width: 800px;
	}

	${Functions.breakpoint(Breakpoints.desktop)} {
		padding-bottom: 90px;
		max-width: 1000px;
	}
`;

const StyledCommonText = styled(CommonText)<{ textColor?: string }>`
	color: ${props => props.textColor || Colors.lightText};
	max-width: 400px;
	position: relative;
	z-index: 1;
	pointer-events: auto;
	margin: auto;
	padding: 0 60px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		max-width: 600px;
	}

	${Functions.breakpoint(Breakpoints.desktop)} {
		max-width: 1200px;
	}
`;

const Artists = styled.div`
	width: 100%;
	margin: auto;

	${Functions.breakpoint(Breakpoints.mobile)} {
	}

	${Functions.breakpoint(Breakpoints.tablet)} {
		max-width: 800px;
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		max-width: 1000px;
	}

	${Functions.breakpoint(Breakpoints.desktop)} {
		max-width: 1200px;
	}
`;

function HighlightedArtists({ data }: { data: any }) {
	const lerp = (a, b, amount) => (1 - amount) * a + amount * b;
	return (
		<StyledAnimatedModule moduleData={data}>
			<Container>
				<StyledCommonTitle
					textColor={Functions.hexFromRGB(data.backgroundColorLink.contrastColor)}
					dangerouslySetInnerHTML={{ __html: HTML.clean(data.title) }}
				/>
				<StyledCommonText
					textColor={Functions.hexFromRGB(data.backgroundColorLink.contrastColor)}
					dangerouslySetInnerHTML={{ __html: HTML.clean(data.subtitle) }}
				/>
				<Artists>
					{data.artists.map((artist, index) => (
						<SingleArtist
							key={index}
							title={artist.title}
							place={artist.location}
							time={artist.startTime}
							image={artist.images[0]}
							nationality={artist.nationality.nationality}
							link={artist}
							fontSize={lerp(150, 96, (data.artists.length > 5 ? 5 : data.artists.length - 1) / 5)}
						/>
					))}
				</Artists>
			</Container>
		</StyledAnimatedModule>
	);
}
export default HighlightedArtists;
