import Store from '@client/core/Store';

export interface IMapState {
	mapFocus: string;
}

export const DefaultState = {
	mapFocus: '',
};

export class MapStoreClass extends Store<IMapState> {
	constructor() {
		super(DefaultState);
	}

	setMapFocus(focus: string) {
		console.log(focus);
		this.set({ mapFocus: focus });
	}
}

const MapStore = new MapStoreClass();

export default MapStore;
