import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import VideoComponent, { VideoData, VideoProvider } from './VideoPlayer/VideoComponent';

const StyledIframe = styled.iframe`
	object-fit: cover;
	pointer-events: none;
	user-select: none;
	border: none;
	aspect-ratio: 16/9;
	width: auto !important;
	height: 100vh !important;

	${Functions.breakpoint(Breakpoints.tablet)} {
		height: auto !important;
		width: 100vw !important;
	}
`;
const StyledVideoComponent = styled(VideoComponent)`
	pointer-events: none;
	user-select: none;
	.promo-player {
		object-fit: cover;
		pointer-events: none;
		user-select: none;
		border: none;
		aspect-ratio: 16/9;
		width: auto !important;
		height: 100vh !important;

		${Functions.breakpoint(Breakpoints.tablet)} {
			height: auto !important;
			width: 100vw !important;
		}
	}
`;

export default function BackgroundVideoModule(props: {
	data: {
		platform: 'youtube' | 'twentythree';
		url: string;
		className?: string;
	};
}) {
	const url = new URL(props.data.url);
	const params = new URLSearchParams(url.searchParams);

	if (props.data.platform === 'youtube') {
		const youtubeVideoId = url.pathname.split('/').pop();
		url.hostname = 'youtube.com';
		url.pathname = '/embed/' + youtubeVideoId;
		params.set('autoplay', '1');
		params.set('controls', '0');
		params.set('disablekb', '1');
		params.set('fs', '0');
		params.set('iv_load_policy', '3');
		params.set('loop', '1');
		params.set('playlist', youtubeVideoId || '');
		params.set('modestbranding', '1');
		params.set('playsinline', '1');
		url.search = params.toString();

		const videoData: VideoData = {
			height: 0,
			width: 0,
			url: url.href,
			provider: VideoProvider.YOUTUBE,
			thumbnailUrl: '',
			providerUid: '',
			title: '',
		};

		return (
			<StyledVideoComponent
				customClass={'promo-player'}
				controls={false}
				autoplay={true}
				muted={true}
				loop={true}
				data={videoData}
			></StyledVideoComponent>
		);
	} else if (props.data.platform === 'twentythree') {
		params.set('showDescriptions', '0');
		params.set('showLogo', '0');
		params.set('hideBigPlay', '1');
		params.set('socialSharing', '0');
		params.set('showBrowse', '0');
		params.set('loop', '1');
		params.set('showTray', '0');
		params.set('autoPlay', '1');
		params.set('mutedAutoPlay', '1');
		params.set('autoMute', '1');
		params.set('defaultQuality', 'fullhd');
		params.set('ambient', '1');
	}

	url.search = params.toString();

	return <StyledIframe tabIndex={-1} src={url.href} className={props.data.className} allow='autoplay' />;
}
