import React, { ComponentProps } from 'react';

export const PapandSVG = React.forwardRef<SVGSVGElement, ComponentProps<'svg'>>((props, ref) => (
	<svg ref={ref} width='247' height='244' viewBox='0 0 247 244' fill='none' {...props}>
		<g id='papand'>
			<g id='tail'>
				<path
					id='Vector'
					d='M201.962 95.6127C206.907 93.0297 208.687 90.4331 211.079 91.488C214.163 92.8403 215.779 94.3685 217.396 100.373C218.252 103.524 218.497 109.745 222.342 109.88C225.467 109.988 232.45 105.012 237.722 106.743C238.944 107.148 240.901 109.961 241.757 110.949C246.322 116.169 236.893 128.597 238.482 132.884C240.072 137.171 250.737 143.365 245.316 150.911C241.037 156.862 179.504 202.842 179.504 202.842L177.873 196.851L188.226 106.378L201.948 95.6127H201.962Z'
					fill='#736357'
				/>
				<path
					id='Vector_2'
					d='M199.314 92.5022C210.631 86.5924 214.286 94.0439 214.748 97.2626C215.21 100.481 215.848 106.634 219.693 106.77C223.552 106.905 234.557 102.618 239.122 107.838C243.687 113.058 235.141 124.567 234.108 129.016C231.799 139.064 245.589 142.256 241.296 149.356C237.505 155.631 181.081 198.663 181.081 198.663L185.591 103.254L199.314 92.4887V92.5022Z'
					fill='#D1AC92'
				/>
			</g>
			<g id='body'>
				<path
					id='Vector_3'
					d='M169.708 24.6267C169.708 24.6267 139.886 6.81595 117.808 0C89.6025 2.65065 57.8103 14.7273 57.8103 14.7273L71.0842 41.8288L101.11 69.7959C101.11 69.7959 137.957 62.6012 138.459 62.3037C138.962 62.0062 161.515 41.085 162.453 40.7064C163.39 40.3277 169.722 24.6402 169.722 24.6402L169.708 24.6267Z'
					fill='#D4B6A2'
				/>
				<path
					id='Vector_4'
					d='M54.7792 199.475L62.4148 197.608C62.4148 197.608 71.1101 184.937 79.3027 193.727L73.5013 186.208L48.2985 179.933L45.1465 181.934L54.7792 199.475V199.475Z'
					fill='#736357'
				/>
				<path
					id='Vector_5'
					d='M110.091 41.9506C110.091 41.9506 86.3415 23.3961 57.81 14.7274C57.81 14.7274 58.1768 80.7907 58.4621 94.5849C58.7475 108.379 39.5498 185.424 39.5498 185.424L54.7938 199.488C54.7938 199.488 62.2799 187.074 70.8666 193.146C79.4532 199.218 75.1599 214.932 75.1599 214.932L100.784 243.887L130.701 211.903L129.112 139.551L123.854 84.1176L117.183 42.7891L110.104 41.9642L110.091 41.9506Z'
					fill='#D1AC92'
				/>
				<path
					id='Vector_6'
					d='M100.77 243.873L120.24 137.59V125.175L110.104 41.937L169.721 24.6132C177.411 32.0512 186.011 50.4434 193.117 89.0536L197.451 138.239C195.766 167.978 199.258 177.877 194.435 192.578C194.435 192.578 144.845 229.524 100.797 243.873H100.77Z'
					fill='#E8C3A9'
				/>
				<path
					id='Vector_7'
					d='M169.708 24.6267L169.45 25.5057L137.793 23.7747L137.386 22.3276L156.91 22.5034L169.708 24.6267Z'
					fill='#967F6D'
				/>
				<path
					id='Vector_8'
					d='M137.794 23.7748L92.0892 4.46292L91.6273 3.15112L137.386 22.3277L137.794 23.7748Z'
					fill='#B09480'
				/>
				<path
					id='Vector_9'
					d='M91.6268 3.15102L117.808 0C139.899 6.81595 169.708 24.6267 169.708 24.6267L137.399 22.3276L91.6268 3.15102Z'
					fill='#E8C3A9'
				/>
				<path
					id='Vector_10'
					d='M57.8099 14.7272L91.2733 3.50256L135.348 23.3824L110.09 41.9505C110.09 41.9505 86.3414 23.3959 57.8099 14.7272Z'
					fill='#E8C8B1'
				/>
				<path
					id='Vector_11'
					d='M153.866 137.861L153.513 123.783L154.519 124.283L155.347 123.106L156.38 123.999L157.073 122.565L158.024 123.188L158.717 122.106L159.315 123.066L160.171 121.795L160.918 122.538L161.475 121.578L162.439 134.52L153.866 137.861Z'
					fill='#FFDFBA'
				/>
			</g>
			<g id='beak-bottom'>
				<path
					id='Vector_12'
					d='M26.3172 120.226L35.4201 131.207L80.5407 181.894L116.354 132.911L66.4923 97.8848L26.3172 120.226Z'
					fill='#B8957D'
				/>
				<path
					id='Vector_13'
					d='M116.354 132.911L116.49 139.957L80.7444 182.286L79.7797 175.659L116.354 132.911Z'
					fill='#736357'
				/>
				<path
					id='Vector_14'
					d='M79.7801 175.66L26.3175 120.226L24.8366 122.268L80.5273 182.381L79.7801 175.66Z'
					fill='#A68771'
				/>
			</g>
			<g id='eye'>
				<path
					id='Vector_15'
					d='M131.993 79.9116C131.993 70.5532 138.093 63.0881 145.661 63.0881C153.228 63.0881 160.633 69.9987 160.633 79.3572C160.633 88.7156 153.649 95.3692 146.082 95.3692C138.514 95.3692 131.993 89.2565 131.993 79.8981V79.9116Z'
					fill='#D1AC92'
				/>
				<path
					id='Vector_16'
					d='M132.861 78.9784C132.861 70.3503 138.649 63.4802 145.823 63.4802C152.996 63.4802 160.021 69.8499 160.021 78.478C160.021 87.1061 153.404 93.2459 146.217 93.2459C139.029 93.2459 132.848 87.6065 132.848 78.9919L132.861 78.9784Z'
					fill='white'
				/>
				<g id='iris'>
					<path
						id='iris_2'
						d='M143.745 74.2451C143.514 69.9987 146.517 66.1715 150.117 66.5231C153.595 66.8612 156.435 69.8229 156.788 73.5419C157.196 77.7748 154.288 81.3856 150.688 81.575C147.087 81.7643 143.976 78.4781 143.745 74.2451V74.2451Z'
						fill='black'
					/>
				</g>
				<g id='reflection'>
					<path
						id='Vector_17'
						d='M154.898 72.7304L152.765 73.5148C152.765 73.5148 151.611 70.4313 151.678 69.958C151.746 69.4847 152.575 69.3765 152.969 69.5794C153.363 69.7822 154.898 72.7304 154.898 72.7304V72.7304Z'
						fill='white'
					/>
					<path
						id='Vector_18'
						d='M155.13 73.3121L152.984 74.0559C152.984 74.0559 153.147 74.8403 153.486 75.273C153.826 75.7058 155.361 75.1513 155.47 74.4481C155.552 73.9477 155.13 73.2986 155.13 73.2986V73.3121Z'
						fill='white'
					/>
				</g>
			</g>
			<g id='beak-top'>
				<path
					id='Vector_19'
					d='M58.979 167.18L116.354 132.911L116.083 126.42L58.7345 159.228L58.979 167.18Z'
					fill='#736357'
				/>
				<path
					id='Vector_20'
					d='M0.00081807 97.3302L1.52249 102.469L58.9795 167.18L58.7349 156.902L0.00081807 97.3302Z'
					fill='#A68771'
				/>
				<path
					id='Vector_21'
					d='M116.272 126.474L58.9242 159.282L-0.000160503 97.3302L58.9242 90.1824L116.272 126.474Z'
					fill='#E8C3A9'
				/>
				<path
					id='Vector_22'
					d='M56.4512 164.218C54.2909 162.366 56.2474 156.726 53.7746 154.982C51.3019 153.237 51.6144 157.443 49.2368 154.982C46.8591 152.52 49.3319 150.357 47.3754 148.193C45.419 146.029 44.9163 150.357 43.1636 148.301C41.411 146.245 44.169 145.082 42.1175 142.418C40.0659 139.754 39.8485 144.393 38.0144 142.148C36.4655 140.254 38.5443 138.713 36.384 136.36C34.2238 134.006 34.5634 138.645 32.5526 135.792C31.6152 134.466 33.4765 133.655 30.9087 130.747C29.0609 128.638 28.5854 131.505 27.4985 130.463C25.3518 128.394 27.2268 126.771 25.379 124.716C23.5312 122.66 22.553 125.23 21.3167 123.377C20.0803 121.524 21.1401 119.996 19.5776 118.414C17.8114 116.628 17.9065 119.374 16.1538 117.426C14.4012 115.479 15.0397 113.789 13.3007 111.909C11.9964 110.502 12.2273 113.221 10.4747 111.26C8.72204 109.299 9.78178 108.095 8.43673 106.662C7.09167 105.228 6.81995 107.581 5.78738 106.459C4.17059 104.687 5.23034 103.186 3.69508 101.631C2.37719 100.278 1.52124 102.483 1.52124 102.483L56.4512 164.218Z'
					fill='#7A6150'
				/>
			</g>
			<g id='wing'>
				<path
					id='Vector_23'
					d='M178.879 128.665C186.025 147.408 188.838 173.644 184.083 181.231C183.96 181.434 182.928 182.759 182.792 182.935C177.534 189.616 172.67 178.783 169.939 182.475C169.042 183.693 168.798 185.342 168.132 186.857C166.746 189.94 164.912 192.929 161.38 194.011C156.122 195.62 156.013 187.75 153.105 187.29C150.198 186.83 145.117 196.486 139.995 194.593C130.443 191.049 140.443 170.263 135.103 148.585L136.218 145.042L178.893 128.678L178.879 128.665Z'
					fill='#736357'
				/>
				<path
					id='Vector_24'
					d='M180.211 126.893C187.547 146.137 189.382 174.483 184.124 181.164C178.866 187.844 174.002 177.012 171.271 180.704C168.54 184.396 167.956 190.617 162.712 192.226C157.467 193.835 157.345 185.964 154.437 185.505C151.53 185.045 146.449 194.701 141.326 192.808C131.775 189.264 141.544 166.707 136.218 145.028L180.224 126.893H180.211Z'
					fill='#D1AC92'
				/>
				<path
					id='Vector_25'
					d='M162.398 134.48L166.434 144.717L164.871 144.366L164.776 145.799L163.105 145.204L162.697 146.989L161.23 146.638L160.714 148.071L159.586 147.233L158.947 149.085L157.602 148.206L156.774 149.856L153.839 137.861L162.398 134.48Z'
					fill='#FFE5C7'
				/>
			</g>
		</g>
	</svg>
));
