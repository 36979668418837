export class SnailLog {
	public static logEnabled = true;

	/**
	 * Messages
	 * @param value
	 */
	public static log(value: any, ...optionalParams: Array<any>) {
		if (!this.logEnabled || typeof window == 'undefined') return;

		if (optionalParams) {
			console.log(value, ...optionalParams);
		} else {
			console.log(value);
		}
	}

	public static obj(value: any) {
		if (!this.logEnabled || typeof window == 'undefined') return;
		console.dir(value);
	}

	public static error(error: any): void {
		if (!this.logEnabled || typeof window == 'undefined') return;
		console.error(error);
	}

	public static brand(): void {
		if (typeof window == 'undefined') return;
		console.log(
			`%c
                                                                   @@@@@@       
                                                                   @@@@@@       
                                                                  @@@@@@  @@@@  
                                                              @@@@@@@@@@@@@@@@@ 
                                        @@                 @@@@@@@@@@@@@@@@@@   
                                @@@@@@@@@@@@@@@@@         @@@@@@     @@@@@@     
                             @@@@@@@@@@@@@@@@@@@@@@@     @@@@@@       @@@@@@    
                            @@@@@@@            @@@@@@@                 @@@@@    
                           @@@@@@       @        @@@@@@               @@@@@@    
                          @@@@@@       @@@@@      @@@@@@              @@@@@@    
                          @@@@@        @@@@@@      @@@@@              @@@@@@    
                          @@@@@         @@@@@@     @@@@@             @@@@@@     
              @@@@@@       @@@@@        @@@@@@    @@@@@@            @@@@@@      
             @@@@@@@@@     @@@@@@    @@@@@@@     @@@@@@            @@@@@@       
            @@@@@@@@@@@      @@@@@@@@@@@@@@     @@@@@@           @@@@@@@        
          @@@@@@@ @@@@@@@        @@@@@@@      @@@@@@@          @@@@@@@          
        @@@@@@@     @@@@@@@@               @@@@@@@@        @@@@@@@@@            
@@@@@@@@@@@@@         @@@@@@@@@@@@@@@@@@@@@@@@@@@      @@@@@@@@@@               
@@@@@@@@@@                @@@@@@@@@@@@@@@@@@@@         @@@@@@                   


                     Set Snail - Serious Code, Silly Games                      
`,
			'font-family:monospace; font-size: 12px; line-height: 15px;'
		);
	}
}
