import { CommonTitle } from '@client/common/CommonText';
import Functions from '@client/style/Functions';
import { Breakpoints, Colors } from '@client/style/Variables';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImageData } from '../Image';
import CarouselItem, { CarouselLink } from './CarouselItem';
import DraggableCarousel from '@client/core/modules/Carousel/DraggableCarousel';
import Carousel from '@client/core/modules/Carousel/Carousel';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import MagicCarouselSelector from '@client/core/modules/Carousel/MagicCarouselSelector';
import { loadNews } from '@client/core/utils/EntityLoader';

const Container = styled.div<{ bgColor: string }>`
	width: 100%;
	background-color: ${props => props.bgColor};
	position: relative;
	padding: 40px 0;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 80px 0;
	}
`;

const StyledCommonTitle = styled(CommonTitle)`
	color: ${Colors.darkText};
`;

const CarouselContainer = styled.div`
	margin-top: 60px;
	width: 100%;
	position: relative;
`;

export default function NewsCarousel({
	data,
}: {
	data: { title: string; backgroundColorLink: any; addedNews: any[] };
}) {
	const [activeIndex, setActiveIndex] = useState(0);

	const evaluateIsActive = (index: number): boolean => {
		if (index === activeIndex) return true;
		if (index === activeIndex + 1) return true;

		return false;
	};
	return (
		<Container
			bgColor={data.backgroundColorLink && Functions.hexFromRGB(data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<StyledCommonTitle>{data.title}</StyledCommonTitle>
			<CarouselContainer>
				<MagicCarouselSelector onChange={number => setActiveIndex(number)}>
					{data.addedNews &&
						data.addedNews.length > 0 &&
						data.addedNews.map((item, index) => (
							<CarouselItem
								key={index}
								image={item.image}
								date={new Date(item.createdAt)}
								isActive={evaluateIsActive(index)}
								title={item.title}
								link={{ _prefix: item._prefix, slug: item.slug, title: item.title }}
							></CarouselItem>
						))}
				</MagicCarouselSelector>
			</CarouselContainer>
		</Container>
	);
}
