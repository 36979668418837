import { IDatoCMSColor } from '@client/core/utils/datoCMS/IDatoCMSColor';
import Site from '@client/store/Site';
import LinkoutStore from './LinkoutStore';

export interface IThemeColor {
	color: IDatoCMSColor;
	contrastColor: IDatoCMSColor;
}

export interface IMadPapir {
	targetUrl: string;
	filter: 'startsWith' | 'exactMatch';
	backgroundColorLink: IThemeColor;
	infoText: string;
}

export default class LinkoutPaper {
	private static _instance: LinkoutPaper;

	public static get Instance(): LinkoutPaper {
		if (!this._instance) this._instance = new LinkoutPaper();

		return this._instance;
	}

	constructor() {}

	/**
	 * Overrides default link handling
	 */
	public handleExternalLinkDefault = (url: string): boolean => {
		const madPapir: IMadPapir | undefined = this.getMadPapir(url);

		const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
		const isIphone = navigator.userAgent.includes('iPhone');
		const isIpad = navigator.userAgent.includes('iPad');

		if (madPapir && !isSafari && !isIpad && !isIphone) {
			LinkoutStore.setMadPapir(madPapir);
			LinkoutStore.goto(url);
			return true;
		}

		return false;
	};

	private getMadPapir = (url: string): IMadPapir | undefined => {
		const list: IMadPapir[] = Site.get().global.madpapirList;
		for (const madPapir of list) {
			if (madPapir.filter === 'startsWith') {
				if (url.startsWith(madPapir.targetUrl)) {
					return madPapir;
				}
			} else if (madPapir.filter === 'exactMatch') {
				if (madPapir.targetUrl === url) {
					return madPapir;
				}
			}
		}
		return undefined;
	};
}
