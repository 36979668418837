import React from 'react';
import styled from 'styled-components';
import { AnimationContainer } from './AnimationCommon';
import { AnimationElementRainbow } from './AnimationElementRainbow';
import { AnimationElementSparkles } from './AnimationElementSparkles';
import { Leaf } from './AnimationElementLeaves';
import AnimationElementPegefyr from './AnimationElementPegefyr';
import { Breakpoints } from '@client/style/Variables';
import Functions from '@client/style/Functions';

const StyledAnimationContainer = styled(AnimationContainer)`
	height: 300px;
	/* bottom: 0; */
	/* top: unset; */
	width: 100vw;
	position: sticky !important;
	left: 0;
	bottom: 0;
	overflow: hidden;

	${Functions.breakpoint(Breakpoints.laptop)} {
		height: 400px;
	}
`;

const DesktopDiv = styled.div`
	/* width: 100vw; */
	height: 100%;

	display: none;
	${Functions.breakpoint(Breakpoints.laptop)} {
		display: block;
	}
`;

const MobileDiv = styled.div`
	/* width: 100vw; */
	height: 100%;

	${Functions.breakpoint(Breakpoints.laptop)} {
		display: none;
	}
`;

export function AnimationBlockFlowerPointer() {
	return (
		<StyledAnimationContainer>
			<MobileDiv>
				<AnimationElementRainbow position={{ y: '50%' }}></AnimationElementRainbow>
				<Leaf delayOrder={0} variant={2} position={{ angle: -210, x: 4, y: 340, scale: 1.1 }} />
				<Leaf delayOrder={0} variant={2} position={{ angle: -190, x: 5, y: 350, flip: true }} />
				<AnimationElementPegefyr position={{ y: -50, x: 0, scale: 0.8 }} />
				<Leaf delayOrder={0} variant={2} position={{ angle: -150, x: -20, y: 350, scale: 0.9 }} />

				<Leaf delayOrder={0} variant={2} position={{ angle: -120, x: 20, y: 350, scale: 0.8 }} />

				<AnimationElementSparkles style={{ height: '300px' }} />
			</MobileDiv>
			<DesktopDiv>
				<AnimationElementRainbow
					position={{ y: '60%', x: -560, angle: 20, scale: 1.7, anchor: 'left' }}
				></AnimationElementRainbow>
				<AnimationElementRainbow
					position={{ y: '50%', x: 360, angle: -20, scale: 1.7, anchor: 'right', origin: '100% 100%' }}
				></AnimationElementRainbow>

				<Leaf
					delayOrder={0}
					variant={2}
					position={{ angle: -255, x: -280, y: 420, scale: 1.1, anchor: 'center', flip: true }}
				/>
				<Leaf delayOrder={0} variant={2} position={{ angle: -225, x: -230, y: 400, flip: true, anchor: 'center' }} />
				<AnimationElementPegefyr position={{ y: 50, scale: 1, x: '-50%', anchor: 'center' }} />
				<Leaf delayOrder={0} variant={2} position={{ angle: -135, x: -270, y: 440, scale: 0.9, anchor: 'center' }} />

				<Leaf delayOrder={0} variant={2} position={{ angle: -120, x: -225, y: 450, scale: 0.8, anchor: 'center' }} />

				<AnimationElementSparkles style={{ height: '300px' }} />
			</DesktopDiv>
		</StyledAnimationContainer>
	);
}
