import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import React from 'react';
import styled from 'styled-components';

export default function Embed({ embedType, url }: { embedType: 'youtube' | 'spotify' | 'twentythree'; url: string }) {
	const fixSpotifyUrl = (url: string) => {
		const realUrl = new URL(url);
		const pathname = realUrl.pathname;
		return 'https://open.spotify.com/embed' + pathname + '?utm_source=generator';
	};

	const fixYoutubeUrl = (url: string) => {
		const realUrl = new URL(url);
		const pathname = realUrl.pathname;
		return 'https://www.youtube.com/embed' + pathname;
	};
	return embedType === 'spotify' ? (
		<iframe
			src={fixSpotifyUrl(url)}
			width='100%'
			height='100%'
			frameBorder='0'
			allowFullScreen={false}
			allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'
			loading='lazy'
		></iframe>
	) : embedType === 'youtube' ? (
		<iframe
			width='100%'
			height='100%'
			src={fixYoutubeUrl(url)}
			title='YouTube video player'
			frameBorder='0'
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
			allowFullScreen
		></iframe>
	) : (
		<iframe
			src={url + '&ambient=1&defaultQuality=fullhd'}
			width='100%'
			height='100%'
			frameBorder='0'
			scrolling='no'
			allowFullScreen
			allow='autoplay; fullscreen'
		></iframe>
	);
}
