import React, { useEffect } from 'react';
import styled from 'styled-components';
import MagicModuleSwitcher from '@modules/MagicModuleSwitcher';
import { Page } from '@style/Shared';
import Menu from '../modules/Menu/Menu';
import GotoElement from '@client/core/utils/GotoElement';
import Footer from '@client/modules/Footers/Footer';
import { HistoryState } from '@client/core/PageSwitch/PageHandler';
import SimpleFooter from '@client/modules/Footers/SimpleFooter';
import LandingPageHeader from '@client/modules/ContentpageHeader/LandingPageHeader';
import Functions from '@client/style/Functions';
import { Breakpoints, presetColors } from '@client/style/Variables';
import { ColorUtils } from '@client/core/utils/colors/ColorUtils';
import PageOverlay from '@client/modules/overlays/PageOverlays/PageOverlay';

const StyledPage = styled(Page)`
	min-height: 100vh;
`;

const Spacing = styled.div<{ color?: string }>`
	background-color: ${props => props.color};
	height: 38vw;
	width: 100%;
	${Functions.breakpoint(Breakpoints.tablet)} {
		height: 18vw;
	}
`;

const ModulWrapper = styled.div`
	width: 100%;
`;

export default function LandingPage(props: { content?: any; simpleFooter?: boolean }) {
	// start on module with item functionality
	useEffect(() => {
		if (!props.content.startId) {
			return;
		}

		if (
			sessionStorage.getItem('scrollPosition_' + window.location.href) !== null &&
			sessionStorage.getItem('navHistoryState') === HistoryState.REPLACE
		) {
			return;
		}

		GotoElement.Instance.scrollToElementWithId(`module_${props.content.startId}`, window.innerHeight * 0.15);
	}, []);

	return (
		<StyledPage>
			<Menu
				onLogoClick={() => GotoElement.Instance.scrollToElementWithId(`module_${props.content.startId}`)}
				specificColor={
					props.content.header[0] &&
					props.content.header[0].backgroundColor &&
					ColorUtils.findContrastingColor(
						Functions.hexFromRGB(props.content.header[0].backgroundColor?.color),
						presetColors.white,
						presetColors.blackish
					)
				}
				currentSlug={props.content?._prefix + props.content?.slug}
			/>
			<PageOverlay></PageOverlay>

			{props.content && props.content.header[0] && (
				<LandingPageHeader data={props.content.header[0]}></LandingPageHeader>
			)}
			<ModulWrapper id='CurrentPageContent'>
				<MagicModuleSwitcher modules={props.content ? props.content.modules : []} />
			</ModulWrapper>
			<Spacing
				color={
					props.content
						? Functions.hexFromRGB(props.content.modules[props.content.modules.length - 1].backgroundColorLink?.color)
						: '#000000'
				}
				className='colorWrapperAnimatedModule'
			/>
			{props.content.slug === '' ? <Footer /> : <SimpleFooter />}
		</StyledPage>
	);
}
