import Link, { ILinkData } from '@client/core/PageSwitch/Link';
import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import SquaredDefaultButton, { ButtonSizeType } from './SquaredDefaultButton';

export interface ILinkButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	textColor?: string;
	backgroundColor?: string;
	data?: ILinkData;
	className?: string;
	customOnClick?: () => void;
	size?: ButtonSizeType;
}

export default function SquaredLinkButton(props: ILinkButtonProps) {
	return (
		<Link data={props.data} className={props.className} customOnClick={props.customOnClick}>
			<SquaredDefaultButton
				backgroundColor={props.backgroundColor}
				textColor={props.textColor}
				title={props.data?.title && props.data?.title}
				size={props.size}
			></SquaredDefaultButton>
		</Link>
	);
}
