import sanitizeHtml from 'sanitize-html';

export default class HTML {
	public static unescapeHtml(html): string {
		const el = document.createElement('div');
		return html.replace(/\&[#0-9a-z]+;/gi, function (enc) {
			el.innerHTML = enc;
			return el.innerText;
		});
	}

	public static clean = (content: string): string => {
		return sanitizeHtml(content, {
			// allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
			allowedTags: [
				'h1',
				'h2',
				'h3',
				'h4',
				'h5',
				'h6',
				'blockquote',
				'q',
				'cite',
				'ol',
				'ul',
				'li',
				'table',
				'tbody',
				'tr',
				'th',
				'td',
				'a',
				'strong',
				'i',
				'b',
				'div',
				'p',
				'span',
				'br',
				'u',
				'em',
				'hr',
				'strike',
				's',
				'del',
				'ins',
			],
			transformTags: {
				div: 'p',
				span: 'p',
				em: 'i',
				hr: 'br',
				strong: 'b',
				strike: 's',
				del: 's',
				ins: 'u',
			},
		});
	};

	public static simple = (content: string): string => {
		var sanitized = sanitizeHtml(content, {
			allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'p', 'br'],
			transformTags: {
				h1: 'p',
				h2: 'p',
				h3: 'p',
				h4: 'p',
				h5: 'p',
				h6: 'p',
				hr: 'br',
			},
		});

		return sanitized.replace(/<p[^>]*?>/g, '').replace(/<\/p>/g, '<br/><br/>');
	};
}
