import { CommonText, CommonTitle } from '@client/common/CommonText';
import HTML from '@client/core/utils/HTML';
import Functions from '@client/style/Functions';
import { Breakpoints, Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import { DefaultButton } from '../shared/DefaultButton';
import TextSlideInAnimation from '../shared/TextSlideInAnimation'; //@ts-ignore
import Splat from '../../assets/svgs/splat.svg';

const Container = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	/* min-height: 100%; */
	min-height: 100vh;
`;

const SplatBackground = styled.div`
	position: fixed;
	width: 300%;
	height: 150%;
	left: -150%;
	top: -25%;

	${Functions.breakpoint(Breakpoints.laptop)} {
		width: 150%;
		height: 150%;
		left: -25%;
		top: -25%;
	}

	svg {
		width: 100%;
		height: 100%;
	}
`;

const Title = styled(CommonTitle)`
	color: ${Colors.darkText};
	position: relative;
`;

const Text = styled(CommonText)`
	color: ${Colors.darkText};
	text-align: left;
	margin-bottom: 40px;
	${Functions.breakpoint(Breakpoints.laptop)} {
		max-width: 1024px;
		text-align: center;
	}
`;

interface IProps {
	title: string;
	text: string;
	buttonText: string;
	onClick: () => void;
}

export default function IntroModule({ title, text, buttonText: buttonText, onClick }: IProps) {
	return (
		<>
			<SplatBackground>
				<Splat></Splat>
			</SplatBackground>
			<Container>
				<Title>
					<TextSlideInAnimation text={title} delay={0.5} delayBetweenLetters={0.03} />
				</Title>
				<Text dangerouslySetInnerHTML={{ __html: HTML.clean(text) }}></Text>
				<DefaultButton
					backgroundColor={Colors.darkBackground}
					textColor={Colors.lightText}
					onClick={onClick}
					title={buttonText}
				/>
			</Container>
		</>
	);
}
