import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
} from '../AnimationCommon';
import Functions from '@client/style/Functions';
import { AnimationElementTreetop } from '../AnimationElementTreetop';

const StyledAnimationContainer = styled(AnimationContainer)`
	/* position: relative;
	height: 700px;
	*/
	/* align-self: center;
	justify-self: center;
	width: 100%; */
	/* width: 100%; */
	img {
		max-width: 100%;
		height: auto;
	}
`;

const MobileSpan = styled.span`
	display: block;
	${Functions.breakpoint('mobile')} {
		display: none;
	}
`;
const DesktopSpan = styled.span`
	display: none;
	${Functions.breakpoint('mobile')} {
		display: block;
	}
`;

const WaveContainer = styled.div<{ fill?: string }>`
	width: 100vw;
	svg {
		width: 100%;
		height: auto;
	}
	.filler {
		fill: ${props => props.fill || ''};
	}
`;
const BumpContainer = styled.div<{ fill?: string }>`
	width: 80vw;
	svg {
		width: 100%;
		height: auto;
		fill: ${props => props.fill || ''};
	}
	.filler {
		fill: ${props => props.fill || ''};
	}
`;

const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 60%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 40%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const Walter = () => {
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1686663578-waltergraver.png', {
		tiny: 500,
		mobile: 2000,
	});

	return <AnimationImageElement image={image} />;
};

const mobileWave = (
	<svg width='375' height='300' viewBox='0 0 375 300' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M214.786 43.092C140.187 -1.59197 43.5581 -5.65402 0 4.79113V300H375V32.9365C348.965 62.5326 255.185 67.2902 214.786 43.092Z'
			className='filler'
		/>
	</svg>
);
const mobileBump = (
	<svg width='293' height='257' viewBox='0 0 293 257' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M6.49989 127.709C60.4999 12.209 189.5 0.208984 293 0.208984V227.709L256.5 256.209C155.167 251.876 -36.7001 220.109 6.49989 127.709Z'
			className='filler'
		/>
		<path
			d='M6.49989 127.709C60.4999 12.209 189.5 0.208984 293 0.208984V227.709L256.5 256.209C155.167 251.876 -36.7001 220.109 6.49989 127.709Z'
			fill='black'
			fill-opacity='0.2'
		/>
	</svg>
);

export function IllustrationWalterGraver() {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (moduleRef.current) {
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor == null && nextColor == null) {
				setBackgroundColors([undefined, undefined]);
			} else if (prevColor == null) {
				setBackgroundColors([undefined, nextColor]);
			} else if (nextColor == null) {
				setBackgroundColors([prevColor, undefined]);
			} else {
				setBackgroundColors([prevColor, nextColor]);
			}
		}
	}, [moduleRef]);
	return (
		<StyledAnimationContainer
			height={1000}
			width={900}
			ref={moduleRef}
			breakpoints={{ mobile: { height: 1100, width: 2600 }, ultra: { width: 4000, height: 1692 } }}
		>
			<DesktopSpan>
				<AnimationElementTreetop
					variant={3}
					position={{
						x: '12%',
						y: '5%',

						scale: 0.4,
						anchor: 'right',
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={4}
					position={{
						x: '-9%',
						y: '12%',
						flip: true,
						scale: 0.25,
						anchor: 'right',
					}}
				></AnimationElementTreetop>
			</DesktopSpan>
			<AnimationElementTreetop
				variant={2}
				position={{ x: '62%', y: '55%', flip: true, scale: 0.4 }}
			></AnimationElementTreetop>

			<AnimationPositionElement
				position={{ anchor: 'right', y: '72%', breakpoints: { mobile: { y: '26%' }, ultra: { y: '20%' } } }}
			>
				<BumpContainer fill={backgroundColors[1]}>
					<MobileSpan>{mobileBump}</MobileSpan>
					<DesktopSpan>{mobileBump}</DesktopSpan>
				</BumpContainer>
			</AnimationPositionElement>
			<ColorWrapper>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>

			{/* Trees */}
			<DesktopSpan>
				<AnimationElementTreetop
					variant={4}
					position={{
						x: '14%',
						y: '-4%',
						scale: 0.35,
					}}
				></AnimationElementTreetop>
			</DesktopSpan>
			<AnimationElementTreetop variant={2} position={{ x: '-34%', y: '40%', scale: 0.5 }}></AnimationElementTreetop>
			<AnimationElementTreetop
				variant={3}
				position={{
					x: '5%',
					y: '53%',
					flip: true,
					scale: 0.5,
					breakpoints: { mobile: { x: '41%', y: '-2%', scale: 0.4 } },
				}}
			></AnimationElementTreetop>
			<AnimationElementTreetop
				variant={3}
				position={{ x: '67%', y: '68%', scale: 0.5, breakpoints: { mobile: { x: '46.5%', y: '18%', scale: 0.4 } } }}
			></AnimationElementTreetop>

			<AnimationElementTreetop
				variant={3}
				position={{
					x: '39%',
					y: '89%',
					flip: true,
					scale: 0.7,
					breakpoints: { mobile: { x: '78%', y: '26%', scale: 0.4 } },
				}}
			></AnimationElementTreetop>
			<DesktopSpan>
				<AnimationElementTreetop
					variant={3}
					position={{
						x: '49.5%',
						y: '10%',
						flip: true,
						scale: 0.45,
						anchor: 'right',
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={4}
					position={{
						x: '31%',
						y: '30%',
						anchor: 'right',
						scale: 0.45,
					}}
				></AnimationElementTreetop>
			</DesktopSpan>
			{/* Trees end */}

			<AnimationPositionElement position={{ y: '60%', breakpoints: { mobile: { y: '13%' } } }}>
				<WaveContainer fill={backgroundColors[1]}>
					<MobileSpan>{mobileWave}</MobileSpan>
					<DesktopSpan>{mobileWave}</DesktopSpan>
				</WaveContainer>
			</AnimationPositionElement>
			<AnimationPositionElement
				position={{
					width: '89%',
					anchor: 'center',
					x: '-50%',
					y: '56%',
					breakpoints: { mobile: { width: '40%', y: '21%' } },
				}}
			>
				<Walter />
			</AnimationPositionElement>
		</StyledAnimationContainer>
	);
}
