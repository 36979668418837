import React from 'react';
import styled from 'styled-components';
import { Colors, Breakpoints, Fonts, presetColors } from '@style/Variables';
import Link from '@core/PageSwitch/Link';
import Site from '@client/store/Site';
import Functions from '@client/style/Functions';
import SoMeIcon from '../SoMeIcon';
import Breaker from '../Dividers.tsx/Breaker';

const OutsideContainer = styled.div`
	position: relative;
	width: 100vw;
`;

export const FooterContainer = styled.div<{ bgColor?: string; textColor?: string }>`
	background-color: ${props => props.bgColor || Colors.footerDarkBackground};
	color: ${props => props.textColor || Colors.lightText};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	position: relative;
	z-index: 1;
	overflow: hidden;
	font-family: ${Fonts.Geomanist};
`;

const FollowUs = styled.div`
	text-align: center;
	padding-top: 130px;
	font-family: ${Fonts.GeomanistBold};
	font-size: 35px;
	padding-bottom: 70px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 55px;
	}
`;

const AllRights = styled.div`
	text-align: center;
	font-family: ${Fonts.Geomanist};
	font-size: 18px;
	padding-bottom: 50px;
`;

const FooterSoMeBar = styled.div<{ bgColor?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: row;
	padding-bottom: 150px;
	svg {
		path:first-of-type {
			fill: white;
		}
		path {
			fill: ${props => props.bgColor || 'white'};
		}
	}
`;

const LinkContainer = styled.div`
	min-width: 90px;
	width: 90px;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 120px;
		height: 120px;
	}
`;

const StyledLink = styled(Link).withConfig({
	shouldForwardProp: (prop, defaultValidatorFn) => {
		if (prop === 'iconColor') return false;
		if (prop === 'secondaryIconColor') return false;
		return defaultValidatorFn(prop);
	},
})<{ iconColor: string; secondaryIconColor: string }>`
	scale: 0.8;

	${Functions.breakpoint(Breakpoints.mobile)} {
		scale: 1;
	}
	svg > path {
		fill: ${props => props.secondaryIconColor};
	}
	svg > path:first-of-type {
		fill: ${props => props.iconColor};
	}
`;

const StyledAnimatedModule = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	/* min-height: 900px; */
	/* height: 500px; */
`;

const BreakerPlacer = styled.div<{ svgColor?: string }>`
	pointer-events: none;
	position: absolute;
	width: 102%;
	top: 1%;
	left: -1%;

	transform: translateY(-100%);

	svg > path {
		fill: ${props => props.svgColor};
	}
`;

export default function SimpleFooter(data) {
	const footer = Site.use(state => state.footer);
	let SoMeData;

	if (data.optional && data.optional.length > 0) {
		SoMeData = data.optional;
	} else {
		SoMeData = footer.socialMediaBar;
	}

	return (
		<>
			<OutsideContainer>
				<BreakerPlacer svgColor={Functions.hexFromRGB(footer.simpleFooterBgColorLink.color)}>
					<Breaker></Breaker>
				</BreakerPlacer>
				<FooterContainer
					bgColor={Functions.hexFromRGB(footer.simpleFooterBgColorLink.color)}
					textColor={Functions.hexFromRGB(footer.simpleFooterBgColorLink.contrastColor)}
				>
					<FollowUs>Følg med for mere</FollowUs>
					<FooterSoMeBar bgColor={Functions.hexFromRGB(footer.simpleFooterBgColorLink.color)}>
						<>
							{SoMeData.map((entry, index) => {
								return (
									<LinkContainer key={'link_' + index}>
										<StyledLink
											key={index}
											data={{ link: entry.url }}
											iconColor={
												footer.simpleFooterBgColorLink &&
												Functions.hexFromRGB(footer.simpleFooterBgColorLink.contrastColor)
											}
											secondaryIconColor={
												footer.simpleFooterBgColorLink && Functions.hexFromRGB(footer.simpleFooterBgColorLink.color)
											}
										>
											<SoMeIcon icon={entry.socialMediaChannel} />
										</StyledLink>
									</LinkContainer>
								);
							})}
						</>
					</FooterSoMeBar>
					<AllRights>Smukfest - All rights reserved</AllRights>
				</FooterContainer>
			</OutsideContainer>
		</>
	);
}
