import React from 'react';
import styled from 'styled-components';
import { CommonText, CommonTitle } from '@client/common/CommonText';
import HTML from '@client/core/utils/HTML';
import { Breakpoints, Colors } from '@client/style/Variables';
import Functions from '@client/style/Functions';
import SquaredLinkButton from './shared/SquaredLinkButton';
import Image from './Image';
import { Illustration } from './animations/illustrations/Illustration';

const Container = styled.div<{ bgColor?: string }>`
	width: 100%;
	background-color: ${props => props.bgColor || 'white'};

	padding: 40px 0;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 80px 0;
	}
`;

const Content = styled.div`
	pointer-events: none;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	z-index: 1;
	padding: 0 var(--gridMargin);
`;

const StyledCommonTitle = styled(CommonTitle)<{ useBigTitle?: boolean; textColor?: string }>`
	color: ${props => props.textColor || Colors.darkText};
	font-size: ${props => (props.useBigTitle ? '55px' : '44px')};
	width: 100%;
	position: relative;
	z-index: 1;
	pointer-events: auto;
	margin-top: 20px;
	hyphens: auto;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: ${props => (props.useBigTitle ? '110px' : '86px')};
		width: ${Functions.col(16)};
		margin-top: 40px;
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		width: ${Functions.col(12)};
	}

	${Functions.breakpoint(Breakpoints.ultra)} {
		width: ${Functions.col(8)};
	}
`;

const StyledCommonText = styled(CommonText)<{ textColor?: string }>`
	color: ${props => props.textColor || Colors.darkText};
	width: 100%;
	position: relative;
	z-index: 1;
	pointer-events: auto;
	font-size: 18px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		width: ${Functions.col(8)};
		margin-top: 20px;
		font-size: 28px;
	}
	p + p {
		margin-top: 1.3em;
	}
`;

const StyledLinkButton = styled(SquaredLinkButton)<{ $noMargin: boolean }>`
	margin-top: ${props => (props.$noMargin ? '0px' : '28px')};
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: ${props => (props.$noMargin ? '0px' : '40px')};
	}
`;

const ImagesWrapper = styled.div`
	width: ${Functions.colMobile(4)};
	display: flex;
	justify-content: center;

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(8)};
	}
`;

const MediaWrapper = styled.div`
	width: ${Functions.colMobile(4)};
	display: flex;
	justify-content: center;

	${Functions.breakpoint(Breakpoints.mobile)} {
		width: ${Functions.col(8)};
	}
`;

const StyledImage = styled(Image)`
	width: 100%;
	aspect-ratio: 3/2;
	${Functions.breakpoint(Breakpoints.mobile)} {
		aspect-ratio: 16/9;
	}
`;

export const TextCTA = (props: any) => {
	const ctaButtonData = props.data.callToActionButton[0];
	return (
		<Container
			bgColor={props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
			id={'module_' + props.data.moduleId}
		>
			<Content>
				{props.data.image && (
					<ImagesWrapper>
						<StyledImage data={props.data.image} />
					</ImagesWrapper>
				)}
				{props.data.media && props.data.media[0] && (
					<MediaWrapper>
						<Illustration data={props.data.media[0]} />
					</MediaWrapper>
				)}
				{props.data.title && (
					<StyledCommonTitle
						textColor={
							props.data.backgroundColorLink?.contrastColor
								? Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor)
								: undefined
						}
						dangerouslySetInnerHTML={{ __html: HTML.clean(props.data.title) }}
						useBigTitle={props.data.useBigTitle}
					/>
				)}
				{props.data.column && (
					<StyledCommonText
						textColor={
							props.data.backgroundColorLink?.contrastColor
								? Functions.hexFromRGB(props.data.backgroundColorLink.contrastColor)
								: undefined
						}
						dangerouslySetInnerHTML={{ __html: HTML.clean(props.data.column) }}
					/>
				)}

				{props.data.callToActionButton.length > 0 && (
					<StyledLinkButton
						$noMargin={props.data.title === '' ? true : props.data.subtitle === '' ? true : false}
						backgroundColor={Functions.hexFromRGB(ctaButtonData.buttonColorLink?.color)}
						textColor={Functions.hexFromRGB(ctaButtonData.textColorLink?.color)}
						data={ctaButtonData.link[0]}
						title={ctaButtonData.title}
					/>
				)}
			</Content>
		</Container>
	);
};
