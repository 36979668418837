import Store from '@client/core/Store';

export interface IFormInputField {
	fieldType: 'input';
	id: string;
	label: string;
	value: string;
	error?: string | undefined;
	type?: 'text' | 'number' | 'email' | undefined;
	required?: boolean;
	showConditions?: IShowConditionOtherIsSet[];
}

export interface IFormTextareaField {
	fieldType: 'textarea';
	id: string;
	label: string;
	value: string;
	error?: string | undefined;
	required?: boolean;
	showConditions?: IShowConditionOtherIsSet[];
}

export interface IFormSelectField {
	fieldType: 'select';
	id: string;
	label: string;
	value: string;
	options: string[];
	notSelectValue?: string | undefined;
	error?: string | undefined;
	required?: boolean;
	showConditions?: IShowConditionOtherIsSet[];
}

export type IFormField = IFormInputField | IFormTextareaField | IFormSelectField;

export interface IShowConditionOtherIsSet {
	conditionType: 'other-equals';
	otherId: string;
	value: string;
}

export interface IFormState {
	fields: IFormField[];
}

export interface IEncodedFormData {
	formId: string;
	formData: {
		id: string;
		value: string;
	}[];
}

export class FormStoreClass extends Store<IFormState> {
	private _formId: string;

	constructor(state: IFormState, formId: string) {
		super(state);

		this._formId = formId;
	}

	public setValue(id: string, value: string) {
		const state: IFormState = JSON.parse(JSON.stringify(this.get()));

		const target = state.fields.find(x => x.id === id);

		if (!target) {
			console.log(`Field with id ${id} not found`);
			return;
		}

		target.value = value;

		this.set(state);
	}

	public getErrors(): string[] | undefined {
		const state: IFormState = this.get();

		let errors: string[] = [];

		for (const field of state.fields) {
			const fieldErrors: string[] | undefined = this.getErrorsForField(field.id);
			if (!fieldErrors) continue;

			errors = [...errors, ...fieldErrors];
		}

		if (errors.length === 0) return undefined;

		return errors;
	}

	public fulfillsShowCondition(id: string): boolean {
		const field: IFormField | undefined = this.get().fields.find(x => x.id === id);

		if (!field) return false;
		if (!field.showConditions) return true;

		for (const condition of field.showConditions) {
			if (condition.conditionType === 'other-equals') {
				const otherField: IFormField | undefined = this.get().fields.find(x => x.id === condition.otherId);

				if (!otherField) continue;

				if (otherField.value !== condition.value) return false;
			}
		}

		return true;
	}

	public getErrorsForField(id: string): string[] | undefined {
		if (!this.fulfillsShowCondition(id)) return undefined;

		const field: IFormField | undefined = this.get().fields.find(x => x.id === id);

		if (!field) return undefined;

		const errors: string[] = [];

		if (field.fieldType == 'input') {
			if (field.required === true && !field.value) {
				errors.push(`"${field.label}" skal udfyldes`);
			} else if (field.type === 'email' && !this.isEmail(field.value)) {
				errors.push(`"${field.label}" er ikke en rigtig email`);
			}
		}

		if (field.fieldType == 'select') {
			if (field.required === true && !field.value) {
				errors.push(`"${field.label}" skal udfyldes`);
			} else if (field.required === true && field.value === field.notSelectValue?.toLocaleLowerCase()) {
				errors.push(`"${field.label}" skal udfyldes`);
			}
		}

		if (field.fieldType == 'textarea') {
			if (field.required === true && !field.value) {
				errors.push(`"${field.label}" skal udfyldes`);
			}
		}

		if (errors.length === 0) return undefined;

		return errors;
	}

	private isEmail(str: string): boolean {
		const regexp = new RegExp(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		return regexp.test(str);
	}

	public getEncodedFormData(): IEncodedFormData {
		const cleanData: IEncodedFormData = {
			formId: this._formId,
			formData: [],
		};

		const state = this.get();

		for (const field of state.fields) {
			if (!this.fulfillsShowCondition(field.id)) continue;
			cleanData.formData.push({ id: field.id, value: field.value });
		}

		return cleanData;
	}
}
