import Functions from '@client/style/Functions';
import Variables, { Breakpoints, Colors, Fonts } from '@client/style/Variables';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Image, { ImageData } from '../Image';
import gsap from 'gsap';
import { DateUtils } from '@client/core/utils/DateUtils';
import Link, { IInternalLink, ILinkData } from '@client/core/PageSwitch/Link';

const Container = styled(Link)<{ ratio: string }>`
	position: relative;

	display: flex;
	align-items: center;

	min-width: ${props => (props.ratio === '4/5' ? Functions.col(3, 4) : Functions.col(3.55, 4))};
	${Functions.breakpoint(Breakpoints.mobile)} {
		min-width: ${props => (props.ratio === '4/5' ? Functions.col(5, 16) : Functions.col(7, 16))};
	}
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: 1fr auto 140px;
`;

const FirstRow = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
`;

const ImageWrapper = styled.div`
	overflow: hidden;
	width: 100%;
`;

const StyledImage = styled(Image)<{ ratio: string }>`
	aspect-ratio: ${props => props.ratio};
	width: 100%;
	height: auto;
`;

const Title = styled.h3`
	font-family: ${Fonts.GeomanistBold};
	font-size: 28px;
	line-height: 110%;

	margin-top: 16px;
	margin-bottom: 20px;

	${Functions.limitText(3)}
`;
const Date = styled.p`
	font-family: ${Fonts.Geomanist};
	font-size: 14px;
	line-height: 120%;
	margin-top: 10px;
`;
export interface CarouselLink {
	image: ImageData;
	title: string;
	isActive: boolean;
	date: Date;
	link: IInternalLink;
}

export default function CarouselItem({ image, title, isActive, date, link }: CarouselLink) {
	const containerRef = useRef<HTMLAnchorElement | null>(null);

	useEffect(() => {
		onIsActiveChange();
	}, [isActive]);

	const onIsActiveChange = () => {
		if (!containerRef.current) return;
		gsap.to(containerRef.current, { opacity: isActive ? 1 : 0.2, duration: 0.5 });
	};

	return (
		<Container
			ref={containerRef}
			ratio={Functions.findRatioSmall(image.width, image.height)}
			data={{ internalLink: link }}
		>
			<Wrapper>
				<FirstRow>
					<ImageWrapper>
						<StyledImage data={image} ratio={Functions.findRatioSmall(image.width, image.height)}></StyledImage>
					</ImageWrapper>
				</FirstRow>
				<Date>{DateUtils.getDanishDateMonthYearString(date)}</Date>

				<Title>{title}</Title>
			</Wrapper>
		</Container>
	);
}
