import Bugsnag from '@bugsnag/js';
import { StaticTestData } from '@server/utils/testData/StaticTestData';
import CustomEvents, { Events } from '@core/CustomEvents';
import Loader from '@core/Loader';
import { SnailLog } from '@core/SnailLog';
import GoogleAnalytics from '@core/utils/GoogleAnalytics';
import Meta from '@core/utils/Meta';

export enum HistoryState {
	PUSH = 'push',
	REPLACE = 'replace',
}

export default class PageHandler {
	private _currentLink: string;
	private _uid: string;
	public get currentUrl() {
		return this._currentLink;
	}
	private _isLoadingPage: boolean = false;
	public get isLoadingPage() {
		return this._isLoadingPage;
	}

	private static _instance: PageHandler | undefined = undefined;
	public static getInstance(global?: any, uid?: string): PageHandler {
		if (this._instance === undefined) this._instance = new PageHandler(global, uid);

		return this._instance;
	}

	private global: any;

	constructor(global: any, uid?: string) {
		this.global = global;

		if (!uid) {
			var now = new Date();
			now.setSeconds(0, 0);
			this._uid = now.getTime().toString(36);
		} else {
			this._uid = uid;
		}

		history.scrollRestoration = 'manual';

		this._currentLink = window.location.href;
		history.replaceState({}, this._currentLink, this._currentLink);

		CustomEvents.listen(Events.TRANSITIONOUT, this.transitionOut);
		CustomEvents.listen(Events.TRANSITIONIN, this.transitionIn);

		window.addEventListener('popstate', this.onHashChanged);
	}

	public unmount = () => {
		CustomEvents.remove(Events.TRANSITIONOUT, this.transitionOut);
		CustomEvents.remove(Events.TRANSITIONIN, this.transitionIn);
		window.removeEventListener('popstate', this.onHashChanged);
	};

	private onHashChanged = (e: PopStateEvent) => {
		// console.log(this._currentLink, window.location.href, e.state);
		this.goto(window.location.href, HistoryState.REPLACE);
	};

	public async goto(link: string, historyState: HistoryState = HistoryState.PUSH, historyData: any = {}) {
		// console.log(this._currentLink, link);
		if (this._currentLink === link) {
			CustomEvents.dispatch(Events.SAMEPAGE, link);
			return;
		} else {
			sessionStorage.setItem('scrollPosition_' + this._currentLink, window.scrollY.toString());
			sessionStorage.setItem('navHistoryState', historyState);
			sessionStorage.setItem(
				'pageHeight_' + this._currentLink,
				document && Math.max(document.body.offsetHeight, document.body.scrollHeight).toString()
			);
		}

		this._currentLink = link;
		this._isLoadingPage = true;
		CustomEvents.dispatch(Events.LOADINGPAGE, link);

		if (historyState === HistoryState.PUSH) {
			history.pushState(historyData || {}, link, link);
		} else if (historyState === HistoryState.REPLACE) {
			history.replaceState(historyData || {}, link, link);
		}

		var url = new URL(link);
		var data = await Loader.get(`/api/content?path=${encodeURIComponent(url.pathname)}&simple=1&uid=${this._uid}`);

		try {
			let json = JSON.parse(data);
			// if (historyData == {}) history.replaceState(json.content, link, link);
			CustomEvents.dispatch(Events.PAGELOADED, json);
			this.handlePageLoaded(json, historyState);
			this._isLoadingPage = false;
		} catch (e) {
			Bugsnag.notify(`Failed to parse new page (${url.pathname}) : ` + e);
			SnailLog.error(e);
		}
	}

	private _isTransitioned = false;

	private transitionOut = () => {
		this._isTransitioned = true;
	};

	private transitionIn = () => {
		this._isTransitioned = false;
	};

	private handlePageLoaded({ data }: any, historyState: HistoryState) {
		if (this._isTransitioned) {
			this.insetPage(data, historyState === HistoryState.REPLACE);
		} else {
			CustomEvents.once(Events.TRANSITIONOUT, () => {
				this.insetPage(data, historyState === HistoryState.REPLACE);
			});
		}
	}

	private insetPage(data: any, scroll?: boolean) {
		CustomEvents.dispatch(Events.NEWPAGE, data);
		if (scroll && sessionStorage.getItem('scrollPosition_' + this._currentLink) !== undefined) {
			window.scrollTo(0, Number(sessionStorage.getItem('scrollPosition_' + this._currentLink)));
		} else {
			window.scrollTo(0, 0);
		}
		var meta = new Meta(data.content, this.global);
		meta.setMeta();
		GoogleAnalytics.getInstance().pageView(meta);
	}
}
