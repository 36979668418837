import React from 'react';
import styled from 'styled-components';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import useId from '@client/core/CustomHooks/useId';
import { MorphingWavePath, waves } from './Breaker';

const StyledContainer = styled.div`
	width: 100%;
	position: relative;
`;

const StyledMask = styled.div<{ $background?: string }>`
	position: relative;
	background: ${props => props.$background};
`;

const StyledSVG = styled.svg`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
`;

/**
 * Wrapper that adds a wavy clip-path to the top and/or bottom of content
 */
export default function WaveContainer({
	children,
	className,
	addPadding,
	side = 'bottom',
	background,
}: React.PropsWithChildren<{
	className?: string;
	side?: 'top' | 'bottom' | 'both';
	addPadding?: boolean | 'top' | 'bottom';
	background?: string;
}>) {
	const containerRef = React.useRef<HTMLDivElement>(null);
	const maskedElemRef = React.useRef<HTMLDivElement>(null);

	const waveTopRef = React.useRef<SVGPathElement>(null);
	const waveBottomRef = React.useRef<SVGPathElement>(null);
	const rectRef = React.useRef<SVGRectElement>(null);

	const isMobile = useIsMobile();

	const clipPathId = useId('clip-path-');

	React.useEffect(() => {
		/* 
			There's a browser bug when using svg paths with clip-path that locks the path to the top right corner of the viewport.
			Using transforms is the only way to properly position and scale the paths, which is why we have to do a lot of 
			calculations here.
		*/
		const observer = new ResizeObserver(entries => {
			const container = entries[0];
			const { width, height } = container.contentRect;

			const waveHeight = isMobile ? waves.mobile.height : waves.desktop.height;
			const scale = width / (isMobile ? waves.mobile.width : waves.desktop.width);

			const bodyHeight = Math.round(height - (side === 'both' ? waveHeight * 2 : waveHeight) * scale);
			const bodyOffset = Math.round(side === 'bottom' ? 0 : waveHeight * scale);
			const bottomWaveOffset = side === 'bottom' ? bodyHeight : bodyHeight + bodyOffset;

			let paddingTop = 0;
			let paddingBottom = 0;

			if (addPadding && addPadding !== 'bottom' && side !== 'bottom') {
				paddingTop = Math.round(waveHeight * scale);
			}

			if (addPadding && addPadding !== 'top' && side !== 'top') {
				paddingBottom = Math.round(waveHeight * scale);
			}

			if (maskedElemRef.current !== null) maskedElemRef.current.style.paddingTop = paddingTop + 'px';
			if (maskedElemRef.current !== null) maskedElemRef.current.style.paddingBottom = paddingBottom + 'px';

			if (rectRef.current) {
				rectRef.current.setAttribute('y', (bodyOffset - 1).toString());
				rectRef.current.setAttribute('width', width.toString());
				if (bodyHeight > 0) {
					rectRef.current.removeAttribute('display');
					rectRef.current.setAttribute('height', (bodyHeight + 2).toString());
				} else {
					rectRef.current.setAttribute('display', 'none');
				}
			}

			if (waveTopRef.current) {
				waveTopRef.current.setAttribute('transform', `scale(1 -1) scale(${scale}) translate(0 -${waveHeight})`);
			}
			if (waveBottomRef.current) {
				waveBottomRef.current.setAttribute('transform', `translate(0 ${bottomWaveOffset}) scale(${scale})`);
			}
		});

		if (containerRef.current) {
			observer.observe(containerRef.current);
		}

		return function () {
			observer.disconnect();
		};
	}, [isMobile]);

	return (
		<StyledContainer ref={containerRef}>
			{/* Clip path */}
			<StyledSVG>
				<defs>
					<clipPath id={clipPathId}>
						{/* Main body rect */}
						<rect ref={rectRef} x='0' />
						{/* Top wave path */}
						{side !== 'bottom' && <MorphingWavePath ref={waveTopRef} />}
						{/* Bottom wave path */}
						{side !== 'top' && <MorphingWavePath ref={waveBottomRef} />}
					</clipPath>
				</defs>
			</StyledSVG>

			{/* Masked content */}
			<StyledMask
				ref={maskedElemRef}
				className={className}
				style={{ clipPath: `url(#${clipPathId})` }}
				$background={background}
			>
				{children}
			</StyledMask>
		</StyledContainer>
	);
}
