import Loader from '@client/core/Loader';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Functions from '@client/style/Functions';
import { Fonts, Breakpoints } from '@client/style/Variables';
import ListWithImage from '@client/modules/ListModules/ListWithImage';
import ListWithName from '@client/modules/ListModules/ListWithName'; //@ts-ignore
import ListViewImagesSVG from '@client/assets/svgs/ListViewImages.svg'; //@ts-ignore
import ListViewNamesSVG from '@client/assets/svgs/ListViewNames.svg';
import SingleImageEntity from '@client/modules/ListModules/SingleImageEntity';
import FilterSelector from '@client/modules/ListModules/FilterSelector';
import gsap from 'gsap';
import FilterStore, { ViewType } from '@client/modules/ListModules/FilterStore';
import { loadExperiences } from '@client/core/utils/EntityLoader'; //@ts-ignore
import CloseButton from '../../assets/svgs/plus.svg';
import CollectionInfoStore from './ContentTypeStore';
import { ListView } from './ListView';
import DetailedListEntry from './DetailedListEntry';
import { ImageData } from '../Image';
import { CommonBoldText } from '@client/common/CommonText';
import { IDefaultFilterType } from '@client/modules/ListModules/FilterStore';
import { DateUtils } from '@client/core/utils/DateUtils';
import DetailedListEntryWithTimestamp from './DetailedListEntryWithTimestamp';

const Container = styled.div<{ bgColor?: string }>`
	width: 100%;

	background-color: ${props => props.bgColor || 'white'};
`;

const ListNav = styled.div`
	width: 100%;
	padding: 0px var(--gridMargin);
	padding-top: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;

const SVGWrapper = styled.div`
	margin-left: 10px;

	width: 24px;
	height: 24px;
	display: flex;
`;

const Close = styled(CloseButton)`
	width: 23px;
	height: 23px;
`;

const ListsContainer = styled.div`
	padding: 0px var(--gridMargin);
	padding-bottom: 100px;
	/* max-width: 100%; */
	width: 100%;
`;

const ListShowings = styled.div``;

const FilterContainer = styled.button<{ textColor?: string }>`
	display: flex;
	font-size: 32px;
	font-family: ${Fonts.GeomanistBold};
	align-items: center;
	color: ${props => props.textColor || 'black'};
`;

const FilterInlineText = styled.span``;

const FilterSelectorContainer = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
	z-index: 1;
`;

const FilterSelectorPlacer = styled.div`
	position: absolute;
	height: fit-content;
	width: 100%;
`;

const ImageListButton = styled.button`
	margin-right: 5px;
`;

const NameListButton = styled.button`
	margin-left: 5px;
`;

const DetailedEntryList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin-top: 60px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: grid;
		grid-template-columns: 1fr 1fr;

		gap: var(--gridGutter);
		row-gap: 80px;
	}
`;

const ListViewImagesIcon = styled(ListViewImagesSVG)``;

const ListViewNamesIcon = styled(ListViewNamesSVG)``;

const NoMatchText = styled(CommonBoldText)<{ color?: string }>`
	color: ${props => props.color || 'black'};
`;

export default function FilteredList({
	data,
}: {
	data: {
		addedContent?: Array<any>;
		useLocationFilter: boolean;
		useTagFilter: boolean;
		useDateFilter: boolean;
		contentTypes: { id: string; name: string }[];
		backgroundColorLink: any;
		detailedThumbnail: boolean;
		detailedThumbnailWithTimestamp: boolean;
		noMatchesText: string;
	};
}) {
	const [allowListView, setAllowListView] = useState<boolean>(true);
	const [contents, setContents] = useState<any[]>([]);
	const ImageListIcon = useRef<SVGElement | null>(null);
	const NameListIcon = useRef<SVGElement | null>(null);
	const filterSelectorContainerRef = useRef<HTMLDivElement | null>(null);
	const plusRef = useRef<HTMLDivElement | null>(null);
	const [filtersOpen, setFiltersOpen] = useState(false);

	// Filtering store use
	const locationFilter: string[] | undefined = FilterStore.use(state => state.selectedLocationFilters);
	const tagFilter: string[] | undefined = FilterStore.use(state => state.selectedTagFilters);
	const dateFilter: string | undefined = FilterStore.use(state => state.selectedDateFilters);
	const activeFilterAmount: number = FilterStore.use(state => state.activeFilters);
	const possibleDates: Date[] | undefined = FilterStore.use(state => state.dateFilters);

	const dateFilterPossibilities: Date[] = FilterStore.use(state => state.dateFilters);
	const tagFilterPossibilities: IDefaultFilterType[] = FilterStore.use(state => state.tagFilters);
	const locationFilterPossibilities: IDefaultFilterType[] = FilterStore.use(state => state.locationFilters);

	// view
	const view: ViewType = FilterStore.use(state => state.view);
	// -------------------

	const selectedCollectionInfo = CollectionInfoStore.use(state => state.activeInfo);

	// console.log(dateFilterPossibilities);
	// console.log(dateFilter);
	// console.log(possibleDates);

	useEffect(() => {
		updateListView(view === 'list' ? false : true);
	}, [view]);

	useEffect(() => {
		openFilters();
	}, [dateFilterPossibilities, tagFilterPossibilities, locationFilterPossibilities]);

	useEffect(() => {
		// if (!data.addedContent) return;
		if (
			data.contentTypes &&
			(data.contentTypes[0]?.name.toLowerCase().includes('musik') || data.contentTypes[0]?.name === 'Snakke') &&
			data.addedContent
		) {
			setAllowListView(true);
			setContents(sortContent(data.addedContent));
		} else {
			getNewData();
		}
	}, [locationFilter, tagFilter, selectedCollectionInfo, dateFilter]);

	const getNewData = async () => {
		// console.log('getNewData');
		let content = [];
		setAllowListView(false);
		FilterStore.setView('tile');
		content = await loadExperiencePages();
		setContents(content);
	};

	async function loadExperiencePages() {
		const contentTypeString = data.contentTypes?.map(contentType => contentType.id);

		const contentPages = await loadExperiences(undefined, contentTypeString, locationFilter, tagFilter, undefined);

		if (contentPages.data?.results) {
			return contentPages.data.results;
		}
		return [];
	}

	const updateListView = value => {
		if (!ImageListIcon.current || !NameListIcon.current) return;
		if (value === true) {
			gsap.to(ImageListIcon.current, {
				opacity: 1,
				duration: 0.3,
			});
			gsap.to(NameListIcon.current, {
				opacity: 0.1,
				duration: 0.3,
			});
		} else {
			gsap.to(ImageListIcon.current, {
				opacity: 0.1,
				duration: 0.3,
			});
			gsap.to(NameListIcon.current, {
				opacity: 1,
				duration: 0.3,
			});
		}
	};

	const toggleOpenFilter = () => {
		if (filtersOpen) {
			closeFilters();
		} else {
			openFilters();
		}
	};

	const closeFilters = () => {
		if (!filterSelectorContainerRef.current || !plusRef.current) return;

		setFiltersOpen(false);
		gsap.to(filterSelectorContainerRef.current, {
			height: 0,
			duration: 0.3,
		});
		gsap.to(plusRef.current, {
			duration: 0.1,
			transform: 'rotate(0)',
		});
	};

	const openFilters = () => {
		if (!filterSelectorContainerRef.current || !plusRef.current) return;

		setFiltersOpen(true);
		gsap.to(filterSelectorContainerRef.current, {
			height: filterSelectorContainerRef.current.firstElementChild?.getBoundingClientRect().height + 'px',
			duration: 0.3,
		});
		gsap.to(plusRef.current, {
			duration: 0.1,
			transform: 'rotate(45deg)',
		});
	};

	const sortContent = (input: any[]) => {
		const result = input.filter((e, i) => {
			if (data.contentTypes[0]?.name !== e.contentType?.name) return false;
			if (locationFilter.length > 0 && !locationFilter.includes(e.location?.id)) return false;
			if (tagFilter.length > 0 && !tagFilter.includes(e.tags?.id)) return false;
			if (dateFilter) {
				if (e.startTime === null) return false;
				if (!DateUtils.isOnSameSmukDay(new Date(e.startTime), new Date(dateFilter))) return false;
			}

			return true;
		});

		return result.sort((a, b) => {
			return a.title.localeCompare(b.title);
		});
	};

	return (
		<Container
			bgColor={data.backgroundColorLink && Functions.hexFromRGB(data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<ListNav>
				<FilterContainer
					onClick={toggleOpenFilter}
					textColor={data.backgroundColorLink && Functions.hexFromRGB(data.backgroundColorLink?.contrastColor)}
				>
					Filter&nbsp;{activeFilterAmount > 0 && <FilterInlineText>({activeFilterAmount})</FilterInlineText>}
					<SVGWrapper ref={plusRef}>
						<Close></Close>
					</SVGWrapper>
				</FilterContainer>
				{allowListView && (
					<ListShowings>
						<ImageListButton
							onClick={() => {
								FilterStore.setView('tile');
							}}
						>
							<ListViewImagesIcon ref={ImageListIcon} />
						</ImageListButton>
						<NameListButton
							onClick={() => {
								FilterStore.setView('list');
							}}
						>
							<ListViewNamesIcon ref={NameListIcon} />
						</NameListButton>
					</ListShowings>
				)}
			</ListNav>
			<FilterSelectorContainer ref={filterSelectorContainerRef}>
				<FilterSelectorPlacer>
					<FilterSelector
						data={{
							onButtonClick: closeFilters,
							useDateFilter: data.useDateFilter,
							useTagFilter: data.useTagFilter,
							useLocationFilter: data.useLocationFilter,
							backgroundColor: data.backgroundColorLink,
						}}
					/>
				</FilterSelectorPlacer>
			</FilterSelectorContainer>
			<ListsContainer>
				{view === 'list' && contents.length > 0 ? (
					possibleDates.map((date, i) => {
						const dayContent = contents.filter((content: any) => {
							if (content.startTime && DateUtils.isOnSameSmukDay(new Date(content?.startTime), date)) return true;
							else return false;
						});
						return (
							dayContent.length > 0 && (
								<ListWithName title={DateUtils.getWeekDayName(date)} key={'ListWithName_' + i}>
									<ListView list={dayContent}></ListView>
								</ListWithName>
							)
						);
					})
				) : view === 'tile' && contents.length > 0 && data.detailedThumbnail ? (
					<DetailedEntryList>
						{contents.map(
							(
								artist: { previewImage: ImageData; previewText: string; title: string; slug: string; _prefix: string },
								i
							) => (
								<DetailedListEntry
									image={artist.previewImage}
									backgroundColorLink={data.backgroundColorLink}
									link={[{ internalLink: { slug: artist.slug, _prefix: artist._prefix } }]}
									subtitle={artist.previewText}
									title={artist.title}
									key={'DetailedListEntry_' + i}
								></DetailedListEntry>
							)
						)}
					</DetailedEntryList>
				) : view === 'tile' && contents.length > 0 && data.detailedThumbnailWithTimestamp ? (
					<DetailedEntryList>
						{contents.map(
							(
								artist: {
									previewImage: ImageData;
									previewText: string;
									title: string;
									slug: string;
									_prefix: string;
									startTime: string;
									location: any;
								},
								i
							) => (
								<DetailedListEntryWithTimestamp
									image={artist.previewImage}
									backgroundColorLink={data.backgroundColorLink}
									link={[{ internalLink: { slug: artist.slug, _prefix: artist._prefix } }]}
									subtitle={artist.previewText}
									title={artist.title}
									key={'DetailedListEntry_' + i}
									location={artist.location.name}
									startTime={artist.startTime}
								></DetailedListEntryWithTimestamp>
							)
						)}
					</DetailedEntryList>
				) : (
					<ListWithImage>
						{contents.map((artist, i) => (
							<SingleImageEntity item={artist} index={i} key={'SingleImageEntity_' + i}></SingleImageEntity>
						))}
					</ListWithImage>
				)}
				{contents.length === 0 && (
					<NoMatchText
						color={data.backgroundColorLink && Functions.hexFromRGB(data.backgroundColorLink?.contrastColor)}
					>
						{data.noMatchesText}
					</NoMatchText>
				)}
			</ListsContainer>
		</Container>
	);
}
