import React from 'react';

export default function PageSwitch(props: {
	content?: any;
	children: any;
	fallback: (props?: any) => JSX.Element;
	pageType: string;
}) {
	const children = !Array.isArray(props.children) ? [props.children] : props.children;
	const page = children.find(c => c.props.type === props.pageType && c.props.component);
	if (!page) return <props.fallback />;
	return <page.props.component content={props.content} key={props.content?.id} />;
}
