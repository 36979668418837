
import Functions from '@client/style/Functions';
import { Breakpoints,  Fonts } from '@client/style/Variables';
import React, { useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import HTML from '@client/core/utils/HTML';

const Container = styled.div<{ backgroundColor?: string; color?: string }>`
	position: relative;
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${props => props.backgroundColor};
	width: 100%;
	color: ${props => props.color};
	padding-bottom: 40px;
	padding-top: 40px;
`;

const InsideContainer = styled.div`
	max-width: 740px;
	position: relative;
	margin: auto;
`;

const TitleContainer = styled.h2`
	font-family: ${Fonts.GeomanistBold};
	font-size: 44px;
	text-align: center;
	padding-bottom: 10px;
	margin: 0px;
`;

const SubtitleContainer = styled.div`
	font-family: ${Fonts.Geomanist};
	font-size: 18px;
	padding-bottom: 40px;
	text-align: center;
`;

const AccordianContainer = styled.div``;

const AccordianElement = styled.div<{ color?: string }>`
	border: 2px solid ${props => props.color};
	margin-bottom: 10px;
	padding: 0px 20px;
	:last-of-type {
		margin-bottom: 0px;
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-bottom: 20px;
		:last-of-type {
			margin-bottom: 0px;
		}
		padding: 0px 26px;
	}
`;

const AccordianElementTitle = styled.div`
	padding-top: 17px;
	padding-bottom: 17px;
	font-size: 22px;
	font-family: ${Fonts.GeomanistBold};
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: start;
	gap: 10px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 28px;
		line-height: 1.1;
		padding-bottom: 26px;
		padding-top: 26px;
	}
`;

const FoldIcon = styled.button`
	display: flex;
	align-items: center;
`;

const AccordianElementContent = styled.div`
	font-size: 14px;
	height: 0px;
	overflow: hidden;
	font-family: ${Fonts.Geomanist};

	a {
		font-family: ${Fonts.GeomanistBold};
		text-decoration: underline;
	}
	p {
		padding-top: 10px;
		padding-bottom: 17px;
		${Functions.breakpoint(Breakpoints.mobile)} {
			padding-top: 0px;

			padding-bottom: 26px;
		}
	}
	ul {
		margin-bottom: 17px;
		${Functions.breakpoint(Breakpoints.mobile)} {
			padding-top: 0px;

			margin-bottom: 26px;
		}
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 18px;
		line-height: 1.3;
	}
`;

interface AccordianProps {
	data: {
		title: string;
		subtitle: string;
		accordianElements: any;
		backgroundColorLink: any;
		moduleId: string;
	};
}

export default function Accordian(props: AccordianProps) {
	const AccordianTitleRefs = useRef(props.data.accordianElements.map(() => useRef()));
	const AccordianContentRefs = useRef(props.data.accordianElements.map(() => useRef()));
	const AccordianFoldRefs = useRef(props.data.accordianElements.map(() => useRef()));

	const toggleInfo = index => {
		const content = AccordianContentRefs.current[index].current;
		const fold = AccordianFoldRefs.current[index].current;
		if (content.style.height !== '0px' && window.getComputedStyle(content).height !== '0px') {
			gsap.to(content, { duration: 0.2, height: '0px', ease: 'power2.out' });
			gsap.to(fold, { duration: 0.2, rotation: 0, ease: 'power2.out' });
		} else {
			gsap.to(content, { duration: 0.2, height: 'auto', ease: 'power2.out' });
			gsap.to(fold, { duration: 0.2, rotation: -90, ease: 'power2.out' });
		}
	};

	return (
		<Container
			className='colorWrapperAnimatedModule'
			backgroundColor={Functions.hexFromRGB(props.data.backgroundColorLink?.color)}
			color={Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor)}
			id={'module_' + props.data.moduleId}
		>
			<InsideContainer>
				{props.data.title && <TitleContainer>{props.data.title}</TitleContainer>}
				{props.data.subtitle && <SubtitleContainer>{props.data.subtitle}</SubtitleContainer>}
				<AccordianContainer>
					{props.data.accordianElements.map((element: any, index: number) => (
						<AccordianElement
							color={Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor)}
							key={'accordian-' + index}
						>
							<AccordianElementTitle
								ref={AccordianTitleRefs.current[index]}
								onClick={() => {
									toggleInfo(index);
								}}
							>
								{element.title}
								<FoldIcon ref={AccordianFoldRefs.current[index]}>
									<svg width='31' height='30' viewBox='0 0 31 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M18.8292 24.9698L8.92969 15.0703L18.8292 5.17082'
											stroke={Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor) || '#F1E1D6'}
											strokeWidth='4'
										/>
									</svg>
								</FoldIcon>
							</AccordianElementTitle>
							<AccordianElementContent
								dangerouslySetInnerHTML={{ __html: HTML.clean(element.information) }}
								ref={AccordianContentRefs.current[index]}
							></AccordianElementContent>
						</AccordianElement>
					))}
				</AccordianContainer>
			</InsideContainer>
		</Container>
	);
}
