import React from 'react';
import { AnimationContainer, AnimationElementPosition, AnimationPositionElement } from '../animations/AnimationCommon';
import { AnimationElementTreetop } from '../animations/AnimationElementTreetop';
import styled from 'styled-components';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import { AnimationElementLamps } from '../animations/AnimationElementLamps';
import { AnimationElementRainbow } from '../animations/AnimationElementRainbow';

export const ProgramTreetopHeaderImage = () => {
	return (
		<AnimationContainer>
			{/* Layer 0 */}
			<div data-speed='0.1'>
				<AnimationElementTreetop
					variant={1}
					position={{
						x: '40%',
						y: '-40%',
					}}
				/>
				<AnimationElementTreetop
					variant={2}
					position={{
						x: '90%',
						y: '-50%',
					}}
				/>
			</div>

			{/* Layer 1 */}
			<div data-speed='0.2'>
				<AnimationElementTreetop
					variant={1}
					position={{
						x: '-60%',
						y: '15%',
					}}
				/>
				<AnimationElementTreetop
					variant={2}
					position={{
						x: '90%',
						y: '-40%',
					}}
				/>
			</div>

			{/* Layer 2 */}
			<div data-speed='0.4'>
				{/* Lamps */}
				<AnimationElementLamps
					variant={3}
					position={{
						x: '120%',
						y: '130%',
					}}
				/>
				{/* Treetops */}
				<AnimationElementTreetop
					variant={3}
					position={{
						x: '60%',
						y: '-50%',
						flip: true,
					}}
				/>
				<AnimationElementTreetop
					variant={2}
					position={{
						x: '-80%',
						y: '-45%',
						flip: true,
					}}
				/>
				<AnimationElementTreetop
					variant={2}
					position={{
						x: '0%',
						y: '-35%',
						scale: 0.8,
					}}
				/>
				<AnimationElementTreetop
					variant={1}
					position={{
						x: '180%',
						y: '5%',
						flip: false,
					}}
				/>
				<AnimationElementTreetop
					variant={3}
					position={{
						x: '100%',
						y: '-60%',
					}}
				/>
			</div>

			{/* Layer 3 */}
			<div data-speed='0.6'>
				{/* Lamps */}
				<AnimationElementLamps
					variant={2}
					position={{
						x: '20%',
						y: '130%',
					}}
				/>
				<AnimationElementLamps
					variant={3}
					position={{
						x: '440%',
						y: '110%',
					}}
				/>
				{/* Treetops */}
				<AnimationElementTreetop
					variant={1}
					position={{
						x: '-40%',
						y: '-25%',
						scale: 0.9,
						flip: true,
					}}
				/>
				<AnimationElementTreetop
					variant={1}
					position={{
						x: '50%',
						y: '-30%',
						scale: 1,
						flip: true,
					}}
				/>
				<AnimationElementTreetop
					variant={2}
					position={{
						x: '200%',
						y: '-55%',
						flip: false,
					}}
				/>
			</div>

			{/* Layer 4 */}
			<div data-speed='0.8'>
				{/* Lamps */}
				<AnimationElementLamps
					variant={1}
					position={{
						x: '410%',
						y: '50%',
					}}
				/>
				{/* Treetops */}
				<AnimationElementTreetop
					variant={4}
					position={{
						x: '60%',
						y: '-30%',
						scale: 0.8,
					}}
				/>
				<AnimationElementTreetop
					variant={4}
					position={{
						x: '190%',
						y: '-55%',
						scale: 1,
					}}
				/>
			</div>
		</AnimationContainer>
	);
};
