import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';

const Container = styled.div`
	height: fit-content;
	width: 100%;
	position: relative;
`;
const Inner = styled.div<{ elementGap: number }>`
	position: relative;
	width: fit-content;
	left: 0px;
	display: flex;
	gap: ${props => props.elementGap}px;
`;

export interface ImperativeDraggableContainer {
	triggerHandleResize: () => void;
}

function DraggableContainer(
	{
		children,
		className,
		gap = 10,
	}: {
		children: Array<any>;
		className?: string;
		gap?: number;
	},
	ref: React.ForwardedRef<ImperativeDraggableContainer>
) {
	const containerRef = useRef<HTMLDivElement | null>(null);
	let draggable: globalThis.Draggable[];

	useEffect(() => {
		initDraggable();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useImperativeHandle(ref, () => ({
		triggerHandleResize() {
			handleResize();
		},
	}));

	const initDraggable = () => {
		if (!containerRef.current) return;
		gsap.registerPlugin(Draggable, InertiaPlugin);

		calcMaxBounds();

		draggable = Draggable.create(containerRef.current, {
			bounds: calcMaxBounds(),
			type: 'x',
			throwProps: true,
			throwResistance: 10000,
			edgeResistance: 0.65,
		});
	};

	const calcMaxBounds = () => {
		if (containerRef.current && containerRef.current.parentElement) {
			return {
				minX: 0,
				maxX:
					containerRef.current.parentElement.getBoundingClientRect().width -
					containerRef.current.getBoundingClientRect().width,
				minY: 0,
				maxY: 0,
			};
		} else {
			return {
				minX: 0,
				maxX: window ? window.innerWidth : 0,
				minY: 0,
				maxY: 0,
			};
		}
	};

	function handleResize() {
		if (!draggable) initDraggable();
		draggable[0].applyBounds(calcMaxBounds());
	}

	return (
		<Container className={className}>
			<Inner ref={containerRef} elementGap={gap}>
				{React.Children.map(children, (child, index) => {
					return React.cloneElement(child, {});
				})}
			</Inner>
		</Container>
	);
}

export default forwardRef(DraggableContainer);
