import Functions from '@client/style/Functions';
import { Breakpoints, Colors, Fonts, Index, presetColors } from '@client/style/Variables';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import gsap, { Power0, Power1 } from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';

const SVGIcon = styled.div`
	display: flex;
	align-items: center;

	svg {
		margin-left: 20px;
	}
`;
const IndexNavMenu = styled.div<{ backgroundColor?: string; color?: string }>`
	width: 100%;
	height: 100%;

	color: ${props => props.color};
	background-color: ${props => props.backgroundColor};

	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	font-size: 20px;

	gap: 20px;
	text-align: center;

	${Functions.breakpoint(Breakpoints.tablet)} {
		padding: 30px 80px;
		gap: 20px;
		font-size: 20px;
	}
`;

const CloseButton = styled.button`
	position: absolute;
	top: 22px;
	right: 13px;
`;
const IndexNavButton = styled.div`
	font-size: 35px;
	font-family: ${Fonts.GeomanistBold};
	align-items: center;
	justify-content: center;

	${Functions.breakpoint(Breakpoints.tablet)} {
		width: 100%;
		display: flex;
		font-size: 55px;
	}
`;

const FadedBackground = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: black;
	opacity: 0;
	pointer-events: none;
	left: 0;
	top: 0;
	z-index: ${Index.indexMenu - 1};
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	align-items: center;

	margin-top: 20px;
`;

const Button = styled.button<{ color?: string }>`
	color: ${props => props.color};
	transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), color 0.1s ease-in-out;
	text-align: center;

	/* transition: color 0.1s ease-in-out; */
	&:hover {
		/* color: black; */
		scale: 1.03;
	}

	first-of-type {
		margin-top: 20px;
	}
`;

export default function IndexMenu({
	close,
	indexAnchors,
	backgroundColor,
}: {
	close: () => void;
	indexAnchors: { moduleId: string }[];
	backgroundColor: any;
}) {
	const [linkList, setLinkList] = useState<any>([]);
	const indexMenuRef = useRef<HTMLDivElement | null>(null);
	const transparentBgRef = useRef<HTMLDivElement | null>(null);
	const isMobile = useIsMobile('tablet');

	const gsapVars = {
		mobile: {
			open: { duration: 0.125, y: 0, ease: Power0.easeIn, overwrite: true },
			closed: { duration: 0.125, y: '100%', overwrite: true },
		},
		desktop: {
			open: { duration: 0.25, x: 0, ease: Power1.easeOut, overwrite: true },
			closed: { duration: 0.25, x: '100%', overwrite: true },
		},
	};

	useEffect(() => {
		gsap.registerPlugin(ScrollToPlugin);

		const newItems: any = indexAnchors
			.map(anchor => {
				const element = Functions.findModuleById(anchor.moduleId);
				const name = element?.querySelector('h1, h2')?.textContent;
				return { element: element, name: name };
			})
			.filter(item => item !== null);
		setLinkList(newItems);
	}, []);

	const scrollToElement = element => {
		close();
		if (window) gsap.to(window, { scrollTo: { y: element.offsetTop, offsetY: 0 }, duration: 0.5, ease: 'power2.out' });
	};

	return (
		<IndexNavMenu
			ref={indexMenuRef}
			backgroundColor={Functions.hexFromRGB(backgroundColor?.color)}
			color={Functions.hexFromRGB(backgroundColor?.contrastColor)}
		>
			<CloseButton
				onClick={() => {
					close();
				}}
			>
				<svg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<rect
						x='4.66699'
						y='24.8672'
						width='28'
						height='4'
						transform='rotate(-45 4.66699 24.8672)'
						fill={Functions.hexFromRGB(backgroundColor?.contrastColor)}
					/>
					<rect
						x='7.66699'
						y='4.86719'
						width='28'
						height='4'
						transform='rotate(45 7.66699 4.86719)'
						fill={Functions.hexFromRGB(backgroundColor?.contrastColor)}
					/>
				</svg>
			</CloseButton>
			Hop hurtigt til
			<ButtonContainer>
				{linkList?.map((link, index) => (
					<IndexNavButton key={index}>
						<Button
							onClick={() => scrollToElement(link.element)}
							color={Functions.hexFromRGB(backgroundColor?.contrastColor)}
						>
							{link.name}
						</Button>
					</IndexNavButton>
				))}
			</ButtonContainer>
		</IndexNavMenu>
	);
}
