import React from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';
import { Quad, gsap } from 'gsap';
import { SlowMo } from 'gsap/all';
import { Breakpoints, presetColors } from '@client/style/Variables';
import Functions from '@client/style/Functions';

const StyledAnimationContainer = styled(AnimationContainer)`
	align-self: center;
	justify-self: center;
	img {
		max-width: 100%;
		height: auto;
	}
`;

const BubbleContainer = styled.div`
	width: 40vw;
	svg {
		width: 100%;
		height: auto;
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 15vw;
	}
`;

const bubbleSvg = (
	<svg
		width='355'
		height='361'
		viewBox='0 0 355 361'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className='bubbles'
	>
		<circle cx='209.255' cy='237.683' r='10.1829' fill={presetColors.solgult1} className='bubble' />
		<circle cx='250.646' cy='213.338' r='10.1829' fill={presetColors.solgult1} className='bubble' />
		<circle cx='63.1829' cy='264.463' r='10.1829' fill={presetColors.solgult1} className='bubble' />
		<circle cx='287.161' cy='274.199' r='15.052' fill={presetColors.solgult1} className='bubble' />
		<circle cx='111.095' cy='188.102' r='15.052' fill={presetColors.solgult1} className='bubble' />
		<circle cx='277.423' cy='159.778' r='5.31382' fill={presetColors.solgult1} className='bubble' />
		<circle cx='63.183' cy='78.3138' r='5.31382' fill={presetColors.solgult1} className='bubble' />
	</svg>
);

const Juice = () => {
	const imageRef = React.useRef<HTMLImageElement>(null);

	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1686819912-orangejuice.png', {
		tiny: 500,
		mobile: 1400,
	});

	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const speed: number = 3;
		const force: number = 10;
		const tween = gsap.fromTo(
			[imageRef.current, '.bubbles'],
			{ y: -force },
			{ y: force, duration: speed, ease: Quad.easeInOut, repeat: -1, yoyo: true }
		);
		const tween2 = gsap.fromTo(
			[imageRef.current],
			{ rotateZ: -5 },
			{ rotateZ: 5, duration: speed * 2, repeat: -1, yoyo: true, ease: SlowMo.ease.config(0.1, 0.5, false) }
		);

		containerTimeline.add(tween, 0);
		containerTimeline.add(tween2, 0);

		return () => {
			tween.kill();
			tween2.kill();
		};
	});
	return <AnimationImageElement image={image} ref={imageRef} />;
};

const Bubbles = () => {
	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const query = gsap.utils.toArray('.bubble');

		const animationList: any[] = [];

		query.forEach((el: TweenTarget) => {
			const delay = Math.random() * 5 + 2;
			gsap.set(el, { scale: 0, y: '50px' });
			const tl = gsap.timeline({ repeat: -1, delay: delay - 5, repeatDelay: delay });

			tl.to(el, {
				x: '-20px',
				duration: 3,
				ease: 'elastic.out(2, 0.2)',
			});
			tl.to(
				el,
				{
					transformOrigin: 'center center',
					scale: 1,
					y: '-50px',
					duration: 1,
					ease: 'power1.out',
				},
				'0'
			);
			tl.to(
				el,
				{
					scale: 1.5,

					fill: presetColors.pissyellow,
					duration: 0.1,
					ease: 'none',
				},
				'1'
			);
			tl.set(el, { scale: 0 }, '1.1');
			animationList.push(tl);
		});
		// animationList.forEach(tl => {
		// 	containerTimeline.add(tl, 0);
		// });
		return () => {
			animationList.forEach(tl => {
				tl.kill();
			});
		};
	});
	return <BubbleContainer>{bubbleSvg}</BubbleContainer>;
};

export default function IllustrationOrangeJuice() {
	return (
		<StyledAnimationContainer
			height={815}
			width={715}
			breakpoints={{ mobile: { height: 715, width: 715 }, ultra: { height: 1000, width: 715 } }}
		>
			<AnimationPositionElement
				position={{
					anchorY: 'bottom',
					anchor: 'center',
					x: '-50%',
					width: '85vw',
					y: '-3%',
					breakpoints: { mobile: { width: '33vw' } },
				}}
			>
				<Juice></Juice>
			</AnimationPositionElement>
			<AnimationPositionElement
				position={{
					anchorY: 'bottom',
					anchor: 'center',
					x: '-50%',
					y: '-121%',
					breakpoints: { mobile: { y: '-128%' } },
				}}
			>
				<Bubbles></Bubbles>
			</AnimationPositionElement>
		</StyledAnimationContainer>
	);
}
