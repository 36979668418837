import React from 'react';
import { AnimationElementSparkles } from '../animations/AnimationElementSparkles';
import { AnimationElementLeaves } from '../animations/AnimationElementLeaves';
import { AnimationContainer } from '../animations/AnimationCommon';
import { AnimationElementDuck } from '../animations/AnimationElementDuck';
import { AnimationElementShades } from '../animations/AnimationElementShades';

export function AnimationBlockLeavesHeader() {
	return (
		<AnimationContainer>
			<AnimationElementLeaves>
				<AnimationElementDuck
					position={{
						anchor: 'right',
						y: 50,
						x: -50,
					}}
				/>
				<AnimationElementShades
					position={{
						y: 80,
						x: 20,
					}}
				/>
			</AnimationElementLeaves>
			<AnimationElementSparkles style={{ height: '200px' }} />
		</AnimationContainer>
	);
}
