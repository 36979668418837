import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import { Breakpoints, Index } from '@client/style/Variables';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PageOverlayStore from './PageOverlayStore';
import gsap from 'gsap';
import { Power0, Power1 } from 'gsap/all';
import Functions from '@client/style/Functions';

const FadedBackground = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: black;
	opacity: 0;
	pointer-events: none;
	left: 0;
	top: 0;
	z-index: ${Index.indexMenu - 1};
`;

const OverlayContainer = styled.div`
	/* width: fit-content; */
	/* height: 75vh; */
	display: table;
	margin: auto;
	width: 100vw;
	position: fixed;
	bottom: 0px;
	left: 0px;
	z-index: ${Index.indexMenu};
	transform: translate(0, 100%);

	${Functions.breakpoint(Breakpoints.tablet)} {
		width: 50vw;
		right: 0;
		left: unset;
		height: 100vh;
		transform: translate(100%, 0);
	}
`;
export default function PageOverlay() {
	const [currentInfo, setCurrentInfo] = useState<ReactNode>(undefined);
	const isMobile = useIsMobile('tablet');

	const transparentBgRef = useRef<HTMLDivElement | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);

	const content = PageOverlayStore.use(state => state.content);

	const gsapVars = {
		mobile: {
			open: { duration: 0.125, y: 0, ease: Power1.easeIn, overwrite: true },
			closed: { duration: 0.125, y: '100%', overwrite: true, onComplete: () => setCurrentInfo(content) },
		},
		desktop: {
			open: { duration: 0.25, x: 0, ease: Power1.easeOut, overwrite: true },
			closed: { duration: 0.25, x: '100%', overwrite: true, onComplete: () => setCurrentInfo(content) },
		},
	};

	useEffect(() => {
		gsap.set(containerRef.current, { clearProps: true });

		if (content === undefined) {
			gsap.set(containerRef.current, isMobile ? gsapVars.mobile.closed : gsapVars.desktop.closed);
		} else {
			gsap.set(containerRef.current, isMobile ? gsapVars.mobile.open : gsapVars.desktop.open);
		}
	}, [isMobile]);

	useEffect(() => {
		if (content !== undefined) {
			// show
			setCurrentInfo(content);
			gsap.to(containerRef.current, isMobile ? gsapVars.mobile.open : gsapVars.desktop.open);

			gsap.to(transparentBgRef.current, { duration: 0.25, opacity: 0.7, pointerEvents: 'all' });

			document.documentElement.style.overflow = 'hidden';
			document.body.style.overflow = 'none';
		} else {
			// do not show
			gsap.to(containerRef.current, isMobile ? gsapVars.mobile.closed : gsapVars.desktop.closed);
			gsap.to(transparentBgRef.current, { duration: 0.25, opacity: 0, pointerEvents: 'none' });

			document.documentElement.style.removeProperty('overflow');
			document.body.style.removeProperty('overflow');
			const top = parseInt(document.body.style.top);
			if (top && top < 0) window.scrollTo(0, top * -1);
		}
	}, [content]);

	return (
		<>
			<FadedBackground ref={transparentBgRef} onClick={() => PageOverlayStore.setContent(undefined)}></FadedBackground>
			<OverlayContainer ref={containerRef}>{currentInfo}</OverlayContainer>
		</>
	);
}
