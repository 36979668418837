import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import Embed from './Embed';

const Container = styled.div<{ bgColor?: string }>`
	background-color: ${props => props.bgColor || 'transparent'};
	overflow: hidden;

	padding: 0;
	width: 100%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 0 var(--gridMargin);
	}
	z-index: 1;
`;

const IframeWrapper = styled.div<{ isSpotify: boolean }>`
	width: 100%;
	padding: 0 var(--gridMargin);
	margin-top: 60px;
	position: relative;

	height: ${props => (props.isSpotify ? '400px' : 'auto')};
	iframe{
		aspect-ratio: ${props => (props.isSpotify ? 'unset' : '16 / 9')}};
	}

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 0 ${Functions.col(3, 16, 1)};
		height: ${props => (props.isSpotify ? '527px' : 'auto')};
	}
`;

export default function EmbedModule({
	data,
}: {
	data: { embedType: 'youtube' | 'spotify' | 'twentythree'; url: string; backgroundColorLink: any };
}) {
	return (
		<Container
			bgColor={data.backgroundColorLink && Functions.hexFromRGB(data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<IframeWrapper isSpotify={data.embedType === 'spotify'}>
				<Embed embedType={data.embedType} url={data.url}></Embed>
			</IframeWrapper>
		</Container>
	);
}
