import Functions from '@client/style/Functions';
import React from 'react';
import styled from 'styled-components';
import ListWithImage from '../ListModules/ListWithImage';
import PageOverlayStore from '../overlays/PageOverlays/PageOverlayStore';
import LegekammeratEntry from './LegekammeratEntry';
import LegekammeratOverlayContent from './LegekammeratOverlayContent';

const Container = styled.div<{ bgColor?: string }>`
	background-color: ${props => props.bgColor};

	padding: 0 var(--gridMargin);
`;

export default function Legekammerater(props: any) {
	console.log(props);
	return (
		<Container
			bgColor={props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<ListWithImage>
				{props.data.items.map((item, index) => (
					<LegekammeratEntry
						key={'legekammerat_' + item.title + index}
						image={item.image}
						onClick={() =>
							PageOverlayStore.setContent(
								<LegekammeratOverlayContent
									description={item.description}
									link={item.link[0]}
									title={item.title}
								></LegekammeratOverlayContent>
							)
						}
					></LegekammeratEntry>
				))}
			</ListWithImage>
		</Container>
	);
}
