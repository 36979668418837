import { gsap } from 'gsap/all';
import React from 'react';
import styled from 'styled-components';
import { AnimationImageAsset, AnimationImageElement, useAnimationContainerTimeline } from '../AnimationCommon';
import { heading3TextStyle } from '@client/common/CommonText';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import Link, { ILinkData } from '@client/core/PageSwitch/Link';

const StyledContainer = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
	height: 100vh;
	position: absolute;
	z-index: 100;
	--scale: 1;
	${Functions.breakpoint(Breakpoints.laptop)} {
		--scale: 1.3;
	}
`;

const StyledBanner = styled.div`
	${heading3TextStyle}
	height: calc(65px * var(--scale));
	line-height: calc(65px * var(--scale));
	padding: 0 30px 0 20px;
	white-space: nowrap;
	font-size: calc(16px * var(--scale)) !important;
	background-size: auto calc(65px * var(--scale));
	background-image: url('/assets/svgs/wavy-banner.svg');
	clip-path: polygon(0% 0%, 100% 0%, 97% 50%, 100% 100%, 0% 100%);
	background-repeat: repeat-x;
`;

const StyledPlane = styled.div`
	width: calc(109px * var(--scale));
	height: calc(101px * var(--scale));
	position: relative;
	top: calc(-23px * var(--scale));
	z-index: 2;
	transform-origin: 0% 70%;
`;
const StyledPlaneImage = styled(AnimationImageElement)`
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: contain;
`;
const StyledPropeller = styled.div`
	width: calc(60px * var(--scale));
	height: calc(60px * var(--scale));
	border-radius: 50%;
	position: absolute;
	left: calc(-20px * var(--scale));
	top: calc(43px * var(--scale));
	z-index: 3;
	transform: rotateY(80deg);
	overflow: hidden;
`;
const StyledPropellerTip = styled(AnimationImageElement)`
	z-index: 4;
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: contain;
`;
const StyledPropellerInner = styled.div`
	width: 100%;
	height: 100%;
	background: conic-gradient(
			from 83.81deg at 50% 50%,
			rgba(255, 255, 255, 0) 0deg,
			#ffffff 90deg,
			rgba(255, 255, 255, 0) 178.12deg,
			#ffffff 266.25deg,
			rgba(255, 255, 255, 0) 346.88deg,
			rgba(255, 255, 255, 0) 360deg
		),
		rgba(100, 111, 114, 0.7);
	background: conic-gradient(
		from 83.81deg at 50% 50%,
		rgba(255, 255, 255, 0) 0deg,
		#ffffff 16.88deg,
		#ffffff 56.25deg,
		rgba(255, 255, 255, 0) 172.5deg,
		#ffffff 187.5deg,
		#ffffff 206.25deg,
		rgba(255, 255, 255, 0) 360deg
	);
`;
const StyledRope = styled.svg`
	width: calc(37px * var(--scale));
	height: calc(56px * var(--scale));
	margin-left: calc(-20px * var(--scale));
	position: relative;
	margin-right: calc(-2px * var(--scale));
	z-index: 1;
`;
const StyledPlaneContainer = styled.div`
	position: absolute;
	right: 100%;
	top: 120px;
`;

const StyledLink = styled(Link)`
	display: block;
	display: flex;
	align-items: center;
	pointer-events: all;
`;

export function FlyingCanMessage(props: { link: ILinkData }) {
	const message = props.link.title;
	const containerRef = React.useRef<HTMLDivElement>(null);
	const bannerRef = React.useRef<HTMLDivElement>(null);
	const canRef = React.useRef<HTMLDivElement>(null);
	const ropeRef = React.useRef<SVGSVGElement>(null);
	const planeRef = React.useRef<HTMLDivElement>(null);
	const propellerRef = React.useRef<HTMLDivElement>(null);

	const parentTimeline = useAnimationContainerTimeline();

	const planeImage = new AnimationImageAsset('https://www.datocms-assets.com/80292/1687171483-beer-can-plane.png', {
		width: 150,
	});
	const planeTipImage = new AnimationImageAsset(
		'https://www.datocms-assets.com/80292/1687171497-beer-can-plane-tip.png',
		{
			width: 150,
		}
	);

	React.useEffect(() => {
		const timeline = gsap.timeline();
		// Horizontal movement
		timeline.fromTo(
			planeRef.current,
			{
				x: () => {
					return (containerRef.current?.clientWidth || 0) + (planeRef.current?.clientWidth || 0);
				},
			},
			{
				x: '0px',
				// Base duration on screen width to preserve constant speed
				duration: () => (containerRef.current?.clientWidth || 0) / 60,
				repeatRefresh: true,
				repeat: -1,
				repeatDelay: 3,
				ease: 'linear',
			},
			0
		);

		// Vertical movement
		timeline.fromTo(
			planeRef.current,
			{
				y: -5,
			},
			{
				y: 5,
				yoyo: true,
				duration: 2,
				repeat: -1,
				repeatDelay: 0,
				ease: 'sine.inOut',
			},
			0
		);
		timeline.fromTo(
			canRef.current,
			{
				rotate: -0.5,
			},
			{
				rotate: 0.5,
				duration: 0.1,
				repeat: -1,
				repeatRefresh: true,
				yoyo: true,
				ease: 'linear',
			},
			0
		);
		// Move banner wave background horizontally
		const bannerWaveDuration = 0.75;
		timeline.fromTo(
			bannerRef.current,
			{
				backgroundPositionX: '0px',
			},
			{
				backgroundPositionX: () => {
					return bannerRef.current ? bannerRef.current.clientHeight * (132 / 65) : 0;
				},
				repeatRefresh: true,
				duration: bannerWaveDuration,
				repeat: -1,
				ease: 'linear',
			},
			0
		);
		// Move banner angled clip path to match wave movement
		const notchDepthPixels = 15;
		const notchDepthPercent = bannerRef.current ? (notchDepthPixels / bannerRef.current.clientWidth) * 100 : 3;
		timeline.fromTo(
			bannerRef.current,
			{
				clipPath: `polygon(0% 0%, 100% -4%, ${100 - notchDepthPercent}% 48%, 100% 100%, 0% 100%)`,
			},
			{
				clipPath: `polygon(0% 0%, 100% 0%, ${100 - notchDepthPercent}% 52%, 100% 104%, 0% 100%)`,
				delay: () => {
					// Offset animation to be in phase with wave
					const bannerWaveWidth = bannerRef.current ? bannerRef.current.clientHeight * (132 / 65) : 0;
					const bannerWidth = bannerRef.current?.clientWidth || 0;
					return ((bannerWaveWidth - (bannerWidth % bannerWaveWidth)) / bannerWaveWidth) * bannerWaveDuration;
				},
				duration: bannerWaveDuration / 2,
				repeat: -1,
				yoyo: true,
				ease: 'sine.inOut',
			},
			0
		);
		// Move rope to match wave movement
		timeline.fromTo(
			ropeRef.current,
			{
				y: -2,
				rotate: -1,
			},
			{
				y: 2,
				rotate: 1,
				duration: bannerWaveDuration / 2,
				repeat: -1,
				yoyo: true,
				ease: 'sine.inOut',
			},
			0
		);

		// Spin propeller
		timeline.fromTo(
			propellerRef.current,
			{
				rotate: 0,
			},
			{
				rotate: -360,
				duration: 0.7,
				repeat: -1,
				ease: 'linear',
			},
			0
		);
		parentTimeline.add(timeline, 0);
		return () => {
			timeline.kill();
		};
	}, []);

	return (
		<StyledContainer ref={containerRef}>
			<StyledPlaneContainer ref={planeRef}>
				<StyledLink data={props.link}>
					<StyledPlane ref={canRef}>
						<StyledPlaneImage image={planeImage} />
						<StyledPropeller>
							<StyledPropellerInner ref={propellerRef} />
						</StyledPropeller>
						{/* <StyledPropellerTip /> */}
						<StyledPropellerTip image={planeTipImage} />
					</StyledPlane>
					<StyledRope ref={ropeRef} width='38' height='57' viewBox='0 0 38 57' fill='none'>
						<path
							d='M35.7217 7.46033C33.555 12.502 20.0093 24.2103 0.134277 24.2103'
							stroke='#262C2E'
							strokeWidth='2'
						/>
						<path
							d='M35.7217 48.6141C33.555 43.5724 20.0093 31.8641 0.134277 31.8641'
							stroke='#262C2E'
							strokeWidth='2'
						/>
						<rect x='34' width='3.0874' height='56.5' fill='#A78974' />
					</StyledRope>
					<StyledBanner ref={bannerRef}>{message}</StyledBanner>
				</StyledLink>
			</StyledPlaneContainer>
		</StyledContainer>
	);
}
