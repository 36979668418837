import React, { ReactChild } from 'react';
import { useAuthenticatedUser } from '@client/utils/AuthStore';

export interface IProps {
	children: ReactChild;
}

export const NotAuthenticatedContainer = (props: IProps) => {
	const user = useAuthenticatedUser();

	return <>{!user && props.children}</>;
};
