export default class BrowserDetect {
	public static isTranslite3DSupported() {
		return 'WebKitCSSMatrix' in window && 'm11' in new WebKitCSSMatrix();
	}

	public static isMobile() {
		return /iphone|ipod|kindle|android|blackberry|opera mini|opera mobi|skyfire|maemo|windows phone|palm|iemobile|symbian|symbianos|fennec/i.test(
			navigator.userAgent.toLowerCase()
		);
	}

	public static isTablet() {
		return /ipad|sch-i800|playbook|xoom|gt-p1000|gt-p7510|sgh-t849|nexus 7|nexus 10|shw-m180s|a100|dell streak|silk/i.test(
			navigator.userAgent.toLowerCase()
		);
	}
}
