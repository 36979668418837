import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import { gsap } from 'gsap';
import { AnimationElementTreetop } from '../AnimationElementTreetop';

const StyledAnimationContainer = styled(AnimationContainer)`
	margin-top: -19vw;
	pointer-events: none;
	// opacity: 0;

	${Functions.breakpoint(Breakpoints.mobile)} {
		// display: block;

		margin-top: 2vw;
	}

	img {
		max-width: 100%;
		height: auto;
	}
`;

const MobileContainer = styled.div`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;
const DesktopContainer = styled.div`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;
const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 40%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 60%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const Speaker = () => {
	const imageRef = useRef<HTMLImageElement | null>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670508124-speakers-desktop.png', {});
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		const tween = gsap.to(imageRef.current, {
			scale: 1.02,
			duration: 0.4,
			yoyo: true,
			repeat: -1,
			ease: 'back.in(1, 0.3)',
		});
		containerTimeline.add(tween, 0);
		return () => {
			tween.kill();
		};
	}, []);
	return <AnimationImageElement image={image} ref={imageRef} />;
};

const Guitar = () => {
	const imageRef = useRef<HTMLImageElement | null>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670508071-guitarhero-desktop.png', {});
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		const tl = gsap.timeline({});

		tl.to(imageRef.current, {
			y: '+=5%',
			duration: 0.5,
		});
		tl.to(
			imageRef.current,
			{
				rotate: -6,
				x: '+=3%',
				duration: 0.4,
				yoyo: true,
				repeat: 3,
				ease: 'back.out(1, 0.3)',
				skewX: 5,
			},
			'<75%'
		);
		tl.to(imageRef.current, {
			y: '-=5%',
			duration: 1,
			x: '-=3%',
			rotate: +6,
		});

		containerTimeline.add(tl, 0);
		return () => {
			tl.kill();
		};
	}, []);
	return <AnimationImageElement image={image} ref={imageRef} />;
};

const mobileHaze = (
	<svg width='375' height='371' viewBox='0 0 375 371' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M431.316 349.207C395.816 363.207 319.416 383.807 321.816 358.207C324.816 326.207 372.316 306.207 368.316 282.207C364.316 258.207 304.316 286.707 298.816 264.207C294.661 247.207 334.241 218.651 310.816 201.207C287.316 183.707 237.599 219.876 211.816 210.207C187.816 201.207 177.316 181.707 157.816 175.207C114.485 160.763 162.825 264.207 132.816 264.207C108.316 264.207 97.3164 231.707 70.3164 225.707C43.3164 219.707 124.759 352.436 49.3164 315.707C11.3164 297.207 -10.1836 276.707 -10.1836 276.707C-9.21899 277.594 -10.1836 1 -10.1836 1H440.929C440.975 0.196472 441 0.170807 441 1H440.929C440.099 15.3136 432.263 276.458 431.316 349.207Z'
			fill='#FFB09A'
		/>
	</svg>
);
const HazeContainer = styled.div`
	width: 100vw;
	svg {
		width: 100%;
		height: auto;
	}
`;

const Flower = () => {
	const imageRef = useRef<HTMLImageElement | null>(null);

	const image = new AnimationImageAsset(
		'https://www.datocms-assets.com/80292/1687352767-hellerupblomst1-edited.png',
		{}
	);
	const containerTimeline = useAnimationContainerTimeline();

	useEffect(() => {
		gsap.set(imageRef.current, { transformOrigin: 'bottom center -100px' });
		const tween = gsap.to(imageRef.current, {
			scale: 1.02,
			rotateX: 5,
			skewX: -1,
			duration: 0.4,
			yoyo: true,
			repeat: -1,
			ease: 'back.in(1, 0.3)',
		});
		containerTimeline.add(tween, 0);
		return () => {
			tween.kill();
		};
	}, []);
	return <AnimationImageElement image={image} ref={imageRef} />;
};

export function IllustrationBushGuitar() {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (moduleRef.current) {
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor == null && nextColor == null) {
				setBackgroundColors([undefined, undefined]);
			} else if (prevColor == null) {
				setBackgroundColors([undefined, nextColor]);
			} else if (nextColor == null) {
				setBackgroundColors([prevColor, undefined]);
			} else {
				setBackgroundColors([prevColor, nextColor]);
			}
		}
	}, [moduleRef]);

	const desktopHaze = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670833075-breaker-2-haze.png', {
		width: 2600,
	});

	return (
		<StyledAnimationContainer
			height={910}
			width={690}
			breakpoints={{ mobile: { height: 1100, width: 2600 }, ultra: { width: 4000, height: 1846 } }}
			ref={moduleRef}
		>
			<ColorWrapper>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>
			<DesktopContainer>
				<AnimationPositionElement position={{ y: '20%' }}>
					<AnimationImageElement image={desktopHaze}></AnimationImageElement>
				</AnimationPositionElement>
			</DesktopContainer>
			<MobileContainer>
				<AnimationPositionElement position={{ y: '45%' }}>
					<HazeContainer>{mobileHaze}</HazeContainer>
				</AnimationPositionElement>
			</MobileContainer>
			<AnimationPositionElement
				position={{
					width: '70vw',
					y: '115%',
					x: '24%',
					anchor: 'right',
					breakpoints: { mobile: { width: '39vw', anchor: 'right', y: '75%', x: '0' } },
				}}
			>
				<Speaker />
			</AnimationPositionElement>
			<DesktopContainer>
				<AnimationElementTreetop
					variant={4}
					position={{
						anchor: 'right',
						y: '-60%',
						x: '49%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '27vw' },
							tablet: { width: '27vw' },
							laptop: { width: '27vw' },
							desktop: { width: '27vw' },
							ultra: { width: '27vw' },
							largeDesktop: { width: '27vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={1}
					position={{
						anchor: 'center',
						y: '16%',
						x: '-93%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '42vw' },
							tablet: { width: '42vw' },
							laptop: { width: '42vw' },
							desktop: { width: '42vw' },
							ultra: { width: '42vw' },
							largeDesktop: { width: '42vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationPositionElement
					position={{
						width: '48vw',
						y: '-46%',
						x: '-30%',
						breakpoints: { mobile: { width: '17vw', y: '-80%', x: '10%' } },
					}}
				>
					<Flower></Flower>
				</AnimationPositionElement>
				<AnimationElementTreetop
					variant={3}
					position={{
						anchor: 'right',
						x: '-43%',
						y: '8%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '35vw' },
							tablet: { width: '35vw' },
							laptop: { width: '35vw' },
							desktop: { width: '35vw' },
							ultra: { width: '35vw' },
							largeDesktop: { width: '35vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={1}
					position={{
						anchor: 'center',
						y: '-30%',
						x: '-53%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '42vw' },
							tablet: { width: '42vw' },
							laptop: { width: '42vw' },
							desktop: { width: '42vw' },
							ultra: { width: '42vw' },
							largeDesktop: { width: '42vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={8}
					position={{
						anchor: 'right',
						x: '1%',
						y: '1%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '30vw' },
							tablet: { width: '30vw' },
							laptop: { width: '30vw' },
							desktop: { width: '30vw' },
							ultra: { width: '30vw' },
							largeDesktop: { width: '30vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={3}
					position={{
						flip: true,
						y: '-52%',
						x: '-44%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '36vw' },
							tablet: { width: '36vw' },
							laptop: { width: '36vw' },
							desktop: { width: '36vw' },
							ultra: { width: '36vw' },
							largeDesktop: { width: '36vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{
						x: '-55%',
						y: '35%',
						breakpoints: {
							tiny: { width: '80vw' },
							mobile: { width: '27vw', y: '40%', x: '-44%' },
							tablet: { width: '27vw' },
							laptop: { width: '27vw' },
							desktop: { width: '27vw' },
							ultra: { width: '27vw' },
							largeDesktop: { width: '27vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={6}
					position={{
						anchor: 'center',
						x: '-120%',
						y: '-37%',
						breakpoints: {
							tiny: { width: '32vw' },
							mobile: { width: '32vw' },
							tablet: { width: '32vw' },
							laptop: { width: '32vw' },
							desktop: { width: '32vw' },
							largeDesktop: { width: '32vw' },
							ultra: { width: '32vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<MobileContainer>
				<AnimationElementTreetop
					variant={3}
					position={{ anchor: 'right', x: '50%', y: '-30%', breakpoints: { tiny: { width: '71vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={4}
					position={{ anchor: 'right', x: '47%', y: '57%', breakpoints: { tiny: { width: '64vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={3}
					position={{ flip: true, x: '-38%', y: '-20%', breakpoints: { tiny: { width: '71vw' } } }}
				></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={2}
					position={{
						x: '-55%',
						y: '35%',
						breakpoints: {
							tiny: { width: '80vw' },
							mobile: { width: '27vw', y: '40%', x: '-44%' },
							tablet: { width: '27vw' },
							laptop: { width: '27vw' },
							desktop: { width: '27vw' },
							ultra: { width: '27vw' },
							largeDesktop: { width: '27vw' },
						},
					}}
				></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={2}
					position={{
						anchor: 'center',
						x: '-40%',
						y: '15%',
						breakpoints: {
							tiny: { width: '80vw' },
						},
					}}
				></AnimationElementTreetop>
			</MobileContainer>

			<AnimationPositionElement
				position={{ width: '75vw', y: '43%', x: '-25%', breakpoints: { mobile: { width: '34vw', y: '15%', x: '0%' } } }}
			>
				<Guitar />
			</AnimationPositionElement>

			<DesktopContainer>
				<AnimationElementTreetop
					variant={7}
					position={{
						anchor: 'right',
						x: '1%',
						y: '-40%',
						breakpoints: {
							tiny: { width: '39vw' },
							mobile: { width: '39vw' },
							tablet: { width: '39vw' },
							laptop: { width: '39vw' },
							desktop: { width: '39vw' },
							largeDesktop: { width: '39vw' },
							ultra: { width: '39vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<AnimationElementTreetop
				variant={9}
				position={{
					x: '-8%',
					y: '20%',
					breakpoints: {
						tiny: { width: '58vw' },
						mobile: { width: '27vw', x: '-1%', y: '-3%' },
						tablet: { width: '27vw' },
						laptop: { width: '27vw' },
						desktop: { width: '27vw' },
						largeDesktop: { width: '27vw' },
						ultra: { width: '27vw' },
					},
				}}
			></AnimationElementTreetop>
			<MobileContainer>
				<AnimationPositionElement
					position={{
						width: '28vw',
						y: '-6%',
						x: '-30%',
						breakpoints: { mobile: { width: '17vw', y: '-80%', x: '10%' } },
					}}
				>
					<Flower></Flower>
				</AnimationPositionElement>
				<AnimationElementTreetop
					variant={8}
					position={{ x: '2%', y: '10%', anchor: 'right', breakpoints: { tiny: { width: '55vw' } } }}
				></AnimationElementTreetop>
			</MobileContainer>
		</StyledAnimationContainer>
	);
}
