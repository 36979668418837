import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Page } from '@client/style/Shared';
import Menu from '@client/modules/Menu/Menu';
import { Breakpoints, Fonts, presetColors } from '@client/style/Variables';
import ListWithImage from '@client/modules/ListModules/ListWithImage';
import SingleImageEntity from '@client/modules/ListModules/SingleImageEntity';
import SpilleplanComponent from '@client/modules/Spilleplan/SpilleplanComponent';
import { gsap } from 'gsap';
import Functions from '@client/style/Functions';

const StyledPage = styled(Page)``;

const StyledListWithImage = styled(ListWithImage)`
	padding: 50px var(--gridMargin);
`;

const ListTitle = styled.h2`
	font-family: ${Fonts.GeomanistBold};
	font-size: 28px;

	margin-top: 40px;
	margin-bottom: 0;

	color: ${presetColors.blackish};

	${Functions.breakpoint(Breakpoints.laptop)} {
		margin-top: 80px;
		font-size: 40px;
	}
`;

export default function SpilleplanPage(props: { content?: any }) {
	const pageRef = useRef<HTMLDivElement>(null);
	const onDayChange = (color: string) => {
		gsap.to(pageRef.current, {
			backgroundColor: color,
			duration: 0.5,
		});
	};

	const hasAdditionalEvents = props.content && props.content?.additionalEvents.length > 0;

	return (
		<StyledPage ref={pageRef}>
			<Menu specificColor={presetColors.blackish} currentSlug={props.content?._prefix + props.content?.slug} />
			<SpilleplanComponent artists={props.content?._artists} onDayChange={onDayChange} filter={props.content?.filter} />
			{hasAdditionalEvents && (
				<>
					<ListTitle>Det sker hele tiden</ListTitle>
					<StyledListWithImage>
						{props.content?.additionalEvents.map((artist, i) => (
							<SingleImageEntity item={artist} index={i} key={'SingleImageEntity_' + i}></SingleImageEntity>
						))}
					</StyledListWithImage>
				</>
			)}
		</StyledPage>
	);
}
