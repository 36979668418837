import { CommonText, CommonTitle } from '@client/common/CommonText';
import { OverlayController } from '@client/core/modules/Overlays/Overlays';
import { ILinkData } from '@client/core/PageSwitch/Link';
import HTML from '@client/core/utils/HTML';
import { DefaultOverlayFrame } from '@client/modules/shared/DefaultOverlayFrame';
import LinkButton from '@client/modules/shared/LinkButton';
import Functions from '@client/style/Functions';
import { Breakpoints, Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';

/**
 * Text
 */
const StyledCommonTitle = styled(CommonTitle)`
	color: ${Colors.darkText};
	font-size: 34px;
	word-break: break-word;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 48px;
	}
`;

const StyledCommonText = styled(CommonText)`
	color: ${Colors.darkText};

	img {
		/* margin-top: 20px; */
		width: 100%;
	}

	p {
		margin-top: 20px;
	}
`;

interface IProps {
	controller: OverlayController;
	title: string;
	text: string;
	link: ILinkData;
}

export const OverlayNewsDuckItem = (props: IProps) => {
	const onCloseClick = (): void => {
		props.controller.close();
	};

	const onLinkUse = (): void => {
		props.controller.close();
	};

	return (
		<DefaultOverlayFrame
			backgroundColor={Colors.newsBackground}
			dimColor={Colors.defaultOverlayDim}
			onCloseButtonClick={onCloseClick}
			bottomChildren={
				<>
					{props.link && (
						<LinkButton
							data={{ ...props.link[0], onLinkUse: onLinkUse }}
							backgroundColor={Colors.darkBackground}
							textColor={Colors.lightText}
						/>
					)}
				</>
			}
		>
			<StyledCommonTitle>{props.title}</StyledCommonTitle>
			<StyledCommonText dangerouslySetInnerHTML={{ __html: HTML.unescapeHtml(props.text) }} />
		</DefaultOverlayFrame>
	);
};
