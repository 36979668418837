import React from 'react';
import styled from 'styled-components';
import { CommonSubTitle, boldTextStyle } from '@client/common/CommonText';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
`;

const Background = styled.div<{ backgroundColor: string }>`
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: ${props => props.backgroundColor};
	width: 100%;
	height: 100%;
	opacity: 0.9;
`;

const StyledText = styled(CommonSubTitle)<{ color: string }>`
	${boldTextStyle};
	color: ${props => props.color};
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export interface IProps {
	backgroundColor: string;
	foregroundColor: string;
	text: string;
}

export default function FormPreloader(props: IProps) {
	return (
		<Container>
			<Background backgroundColor={props.backgroundColor} />
			<StyledText color={props.foregroundColor}>{props.text}</StyledText>
		</Container>
	);
}
