import React from 'react';
import gsap from 'gsap/all';
import {
	AnimationElementPosition,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../animations/AnimationCommon';
import styled from 'styled-components';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';

const lampColors = ['#84AEFF', '#92CDDC', '#FFAD98', '#FFAE02', '#FFD9D0', '#FFDF9A', '#F74D38'];
let lampColorCounter = 1;

const Lamps1 = (
	<svg width='212' height='104' viewBox='0 0 212 104' fill='none'>
		<path d='M1 1C6.70863 59.3295 130.734 111.908 169.734 44.9082' stroke='#262C2E' strokeWidth='2' />
		<path
			d='M32.759 51.9146C36.9444 48.598 35.0968 46.9307 34.0943 46.7076C32.7624 46.4109 30.7391 47.2722 30.1299 50.2187'
			stroke='#262C2E'
			strokeWidth='1'
		/>
		<path
			d='M34.5453 52.232L29.3135 48.4468C28.4633 47.8317 27.2746 47.9327 26.5801 48.6798L21.8701 53.3969C26.365 54.3595 29.0633 57.2176 30.8502 61.0372L34.9932 54.9316C35.6383 54.0805 35.4385 52.8778 34.5449 52.2314L34.5453 52.232Z'
			fill='#262C2E'
		/>
		<path
			d='M19.6264 71.2139C14.5155 70.0975 11.234 65.2479 12.2969 60.3819C13.3598 55.516 18.3646 52.4764 23.4755 53.5928C28.5863 54.7092 31.8679 59.5589 30.805 64.4248C29.7421 69.2907 24.7373 72.3303 19.6264 71.2139Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M90.3016 77.8997C92.3536 72.9695 89.9292 72.4074 88.9443 72.6984C87.6356 73.0848 86.2838 74.8192 87.1799 77.6915'
			stroke='#262C2E'
			strokeWidth='1'
		/>
		<path
			d='M92.0172 77.3111L85.6061 76.5379C84.5643 76.4122 83.5737 77.077 83.3286 78.0672L81.497 84.4765C85.8948 83.1386 89.6406 84.3297 93.0556 86.8036L93.718 79.4549C93.8694 78.3977 93.1115 77.4428 92.0165 77.3108L92.0172 77.3111Z'
			fill='#262C2E'
		/>
		<path
			d='M88.1753 101.146C83.1642 102.648 77.9426 99.9984 76.5125 95.2275C75.0824 90.4566 77.9854 85.3713 82.9964 83.8692C88.0075 82.3671 93.2292 85.017 94.6593 89.7879C96.0894 94.5588 93.1864 99.6441 88.1753 101.146Z'
			fill='white'
			className='lamp'
		/>
	</svg>
);

const Lamps2 = (
	<svg width='212' height='69' viewBox='0 0 212 69' fill='none'>
		<path d='M136.047 1C105.616 56.7936 32.6991 51.0854 1 31.4316' stroke='#262C2E' strokeWidth='2' />
		<path
			d='M35.0006 46.4501C38.3818 42.3168 36.221 41.0822 35.194 41.0781C33.8294 41.0726 32.0365 42.3458 32.0702 45.3544'
			stroke='#262C2E'
			strokeWidth='1'
		/>
		<path
			d='M36.813 46.3789L30.8939 43.7974C29.9321 43.3779 28.7924 43.7303 28.2733 44.6084L24.6784 50.2219C29.2752 50.2031 32.5212 52.4195 35.0821 55.7699L37.8267 48.9207C38.2753 47.9516 37.8235 46.8192 36.8125 46.3784L36.813 46.3789Z'
			fill='#262C2E'
		/>
		<path
			d='M26.2885 68.1068C21.0571 68.1068 16.8163 64.0692 16.8163 59.0886C16.8163 54.1079 21.0571 50.0703 26.2885 50.0703C31.5199 50.0703 35.7607 54.1079 35.7607 59.0886C35.7607 64.0692 31.5199 68.1068 26.2885 68.1068Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M109.812 32.4501C106.431 28.3168 108.592 27.0822 109.619 27.0781C110.983 27.0726 112.776 28.3458 112.742 31.3544'
			stroke='#262C2E'
			strokeWidth='1'
		/>
		<path
			d='M107.999 32.3789L113.919 29.7974C114.88 29.3779 116.02 29.7303 116.539 30.6084L120.134 36.2219C115.537 36.2031 112.291 38.4195 109.73 41.7699L106.986 34.9207C106.537 33.9516 106.989 32.8192 108 32.3784L107.999 32.3789Z'
			fill='#262C2E'
		/>
		<path
			d='M118.524 54.1068C123.755 54.1068 127.996 50.0692 127.996 45.0886C127.996 40.1079 123.755 36.0703 118.524 36.0703C113.293 36.0703 109.052 40.1079 109.052 45.0886C109.052 50.0692 113.293 54.1068 118.524 54.1068Z'
			fill='white'
			className='lamp'
		/>
	</svg>
);

const Lamps3 = (
	<svg width='212' height='86' viewBox='0 0 212 86' fill='none'>
		<path d='M210.886 1C150.492 92.0821 40 76.9102 1.00002 9.91017' stroke='#262C2E' strokeWidth='2' />
		<path
			d='M151.166 57.1817C146.98 53.8651 148.828 52.1978 149.83 51.9747C151.162 51.678 153.186 52.5393 153.795 55.4858'
			stroke='#262C2E'
			strokeWidth='1'
		/>
		<path
			d='M149.38 57.4991L154.611 53.7139C155.462 53.0988 156.65 53.1998 157.345 53.9469L162.055 58.664C157.56 59.6266 154.862 62.4847 153.075 66.3043L148.932 60.1987C148.287 59.3476 148.486 58.1449 149.38 57.4984L149.38 57.4991Z'
			fill='#262C2E'
		/>
		<path
			d='M164.298 76.481C169.409 75.3646 172.691 70.5149 171.628 65.649C170.565 60.7831 165.56 57.7435 160.449 58.8599C155.338 59.9763 152.057 64.8259 153.12 69.6919C154.183 74.5578 159.188 77.5973 164.298 76.481Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M51.9039 58.4861C53.4351 53.3702 55.668 54.4692 56.2464 55.3178C57.0152 56.4452 56.9642 58.6436 54.4527 60.3005'
			stroke='#262C2E'
			strokeWidth='1'
		/>
		<path
			d='M50.9482 56.9442L56.4015 60.4027C57.2876 60.9647 57.6339 62.1063 57.197 63.0281L54.5591 69.1499C52.0007 65.3309 48.3466 63.8826 44.1369 63.6369L48.2746 57.5277C48.8263 56.6133 50.0175 56.3536 50.9489 56.9444L50.9482 56.9442Z'
			fill='#262C2E'
		/>
		<path
			d='M38.8396 77.831C41.769 82.1653 47.489 83.418 51.6155 80.629C55.7421 77.84 56.7125 72.0655 53.7831 67.7312C50.8537 63.3969 45.1338 62.1442 41.0072 64.9332C36.8807 67.7222 35.9102 73.4968 38.8396 77.831Z'
			fill='white'
			className='lamp'
		/>
	</svg>
);

const Lamps4 = (
	<svg width='114' height='177' viewBox='0 0 114 177' fill='none'>
		<path d='M4.75036e-06 175.913C53.5438 175.913 99.253 99.2538 99.253 0' stroke='#262C2E' strokeWidth='2' />
		<path
			d='M67.5973 130.843C64.6047 127 66.5172 125.852 67.4262 125.849C68.6339 125.843 70.2208 127.027 70.191 129.824'
			stroke='#262C2E'
			strokeWidth='1.08115'
			strokeMiterlimit='10'
		/>
		<path
			d='M75.3075 150.975C79.9377 150.975 83.6912 147.222 83.6912 142.592C83.6912 137.962 79.9377 134.208 75.3075 134.208C70.6773 134.208 66.9238 137.962 66.9238 142.592C66.9238 147.222 70.6773 150.975 75.3075 150.975Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M65.993 130.777L71.2318 128.377C72.0831 127.987 73.0919 128.314 73.5513 129.131L76.733 134.349C72.6645 134.332 69.7915 136.392 67.525 139.507L65.0958 133.14C64.6987 132.239 65.0986 131.186 65.9934 130.776L65.993 130.777Z'
			fill='#262C2E'
		/>
		<path
			d='M88.6909 86.8181C84.0576 85.3171 84.9965 83.2939 85.7468 82.7806C86.7435 82.0986 88.7211 82.1878 90.266 84.5195'
			stroke='#262C2E'
			strokeWidth='1.08115'
			strokeMiterlimit='10'
		/>
		<path
			d='M106.371 99.1561C110.203 96.5578 111.204 91.3447 108.605 87.5123C106.007 83.6799 100.794 82.6794 96.9616 85.2778C93.1292 87.8761 92.1288 93.0892 94.7271 96.9216C97.3254 100.754 102.539 101.754 106.371 99.1561Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M87.3258 87.6643L90.3153 82.738C90.8011 81.9375 91.8199 81.6426 92.6583 82.0604L98.2202 84.5943C94.8429 86.863 93.6212 90.1807 93.493 94.0305L87.9093 90.1236C87.075 89.6007 86.8153 88.505 87.326 87.6636L87.3258 87.6643Z'
			fill='#262C2E'
		/>
	</svg>
);

const Lamps5 = (
	<svg width='132' height='168' viewBox='0 0 132 168' fill='none'>
		<path d='M14.209 1C15.0328 74.3123 51.8676 166.938 131.734 166.938' stroke='#262C2E' strokeWidth='2' />
		<path
			d='M64.2415 142.152C66.5917 137.887 68.4008 139.191 68.7629 140.025C69.2442 141.133 68.7826 143.058 66.2008 144.134'
			stroke='#262C2E'
			strokeWidth='1.08115'
			strokeMiterlimit='10'
		/>
		<path
			d='M48.7839 157.18C50.6105 161.435 55.5404 163.403 59.795 161.576C64.0496 159.75 66.0179 154.82 64.1912 150.565C62.3646 146.31 57.4347 144.342 53.1801 146.169C48.9255 147.995 46.9572 152.925 48.7839 157.18Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M63.67 140.652L67.942 144.519C68.6362 145.147 68.7331 146.203 68.1643 146.948L64.6242 151.93C63.0352 148.185 60.0084 146.357 56.2523 145.503L61.1447 140.759C61.816 140.039 62.941 139.991 63.6706 140.652L63.67 140.652Z'
			fill='#262C2E'
		/>
		<path
			d='M21.1461 69.2318C25.1768 66.4979 26.1964 68.4816 26.1405 69.3889C26.0664 70.5944 24.7812 72.1001 21.9922 71.8867'
			stroke='#262C2E'
			strokeWidth='1.08115'
			strokeMiterlimit='10'
		/>
		<path
			d='M0.550414 75.6036C0.246416 80.2238 3.74538 84.2156 8.36557 84.5196C12.9858 84.8236 16.9776 81.3246 17.2816 76.7045C17.5856 72.0843 14.0866 68.0924 9.46644 67.7884C4.84625 67.4844 0.854413 70.9834 0.550414 75.6036Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M21.3181 67.6349L23.3689 73.02C23.7021 73.8951 23.309 74.8801 22.4642 75.285L17.0481 78.1172C17.3326 74.0586 15.4652 71.0565 12.5062 68.5904L19.0191 66.5845C19.9442 66.2474 20.9684 66.7156 21.3186 67.6354L21.3181 67.6349Z'
			fill='#262C2E'
		/>
	</svg>
);

const Lamps6 = (
	<svg width='176' height='432' viewBox='0 0 176 432' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M119.909 0.0385742C119.909 212.784 72.9956 399.346 -14.2847 424.439'
			stroke='#262C2E'
			stroke-width='4.36402'
		/>
		<path
			d='M120.509 113.176C110.112 111.255 111.56 106.635 113.03 105.318C114.983 103.568 119.265 103.201 123.25 107.783'
			stroke='#262C2E'
			stroke-width='2.34715'
			stroke-miterlimit='10'
		/>
		<path
			d='M162.054 134.731C169.569 128.054 170.247 116.55 163.571 109.035C156.894 101.521 145.39 100.842 137.875 107.519C130.361 114.196 129.682 125.7 136.359 133.214C143.036 140.729 154.54 141.407 162.054 134.731Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M117.81 115.381L122.851 103.932C123.67 102.072 125.78 101.149 127.702 101.811L140.391 105.692C133.763 111.53 132.072 119.017 132.885 127.34L119.761 120.51C117.818 119.62 116.949 117.335 117.81 115.38L117.81 115.381Z'
			fill='#262C2E'
		/>
		<path
			d='M41.4031 387.788C34.2748 379.978 38.2196 377.17 40.1864 377.008C42.7995 376.792 46.4345 379.085 46.8441 385.144'
			stroke='#262C2E'
			stroke-width='2.34715'
			stroke-miterlimit='10'
		/>
		<path
			d='M61.5036 430.056C71.525 429.271 79.0128 420.511 78.228 410.49C77.4433 400.469 68.6833 392.981 58.6619 393.766C48.6405 394.55 41.1527 403.31 41.9375 413.332C42.7222 423.353 51.4823 430.841 61.5036 430.056Z'
			fill='white'
			className='lamp'
		/>
		<path
			d='M37.9193 387.916L48.8513 381.834C50.6278 380.846 52.8665 381.384 53.9993 383.073L61.7701 393.828C52.9615 394.48 47.0924 399.426 42.7146 406.552L36.3779 393.182C35.3659 391.3 36.053 388.954 37.9202 387.915L37.9193 387.916Z'
			fill='#262C2E'
		/>
	</svg>
);

const Lamp7 = (
	<svg width='119' height='178' viewBox='0 0 119 178' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M111 0.116699C111 85.6167 76.5 170.117 -4 170.117' stroke='#262C2E' stroke-width='2' />
		<path
			d='M93.9944 101.443C89.3399 100.095 90.2104 98.0533 90.9404 97.5194C91.9103 96.81 93.8798 96.837 95.489 99.1075'
			stroke='#262C2E'
			stroke-width='1.07568'
			stroke-miterlimit='10'
		/>
		<path
			d='M111.962 113.159C115.692 110.455 116.524 105.24 113.82 101.51C111.117 97.7798 105.901 96.9478 102.171 99.6515C98.4412 102.355 97.6092 107.571 100.313 111.301C103.017 115.031 108.232 115.863 111.962 113.159Z'
			className='lamp'
			fill='white'
		/>
		<path
			d='M92.6632 102.326L95.4821 97.3334C95.9402 96.5221 96.9441 96.197 97.7909 96.5863L103.401 98.9324C100.114 101.294 99.0023 104.631 98.995 108.464L93.3202 104.753C92.4743 104.259 92.1817 103.178 92.6633 102.325L92.6632 102.326Z'
			fill='#262C2E'
		/>
		<path
			d='M50.5215 157.321C47.2546 153.742 49.0625 152.455 49.9638 152.38C51.1614 152.281 52.8273 153.332 53.015 156.109'
			stroke='#262C2E'
			stroke-width='1.07568'
			stroke-miterlimit='10'
		/>
		<path
			d='M59.7335 176.692C64.3262 176.332 67.7578 172.318 67.3981 167.725C67.0385 163.132 63.0238 159.701 58.4311 160.06C53.8384 160.42 50.4068 164.435 50.7664 169.027C51.126 173.62 55.1407 177.052 59.7335 176.692Z'
			className='lamp'
			fill='white'
		/>
		<path
			d='M48.9248 157.379L53.9349 154.592C54.749 154.139 55.775 154.386 56.2942 155.16L59.8555 160.089C55.8186 160.387 53.1288 162.654 51.1225 165.92L48.2184 159.793C47.7546 158.93 48.0695 157.855 48.9252 157.379L48.9248 157.379Z'
			fill='#262C2E'
		/>
	</svg>
);

const StyledLampsContainer = styled.div`
	width: 40vw;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 30vw;
	}
	${Functions.breakpoint(Breakpoints.desktop)} {
		width: 500px;
	}
	svg {
		width: 100%;
		height: auto;
	}
`;

export const AnimationElementLamps = (props: {
	variant?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
	position: AnimationElementPosition;
}) => {
	const ref = React.useRef<HTMLDivElement>(null);
	const variant = [Lamps1, Lamps2, Lamps3, Lamps4, Lamps5, Lamps6, Lamp7][props.variant ? props.variant - 1 : 0];
	const parentTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const query = gsap.utils.selector(ref);

		const tween = gsap.to(query('.lamp'), {
			fill: () => lampColors[lampColorCounter++ % lampColors.length],
			transformOrigin: 'center center',
			keyframes: {
				scale: [1, 1.1, 1],
			},
			duration: 0.3,
			repeatDelay: 1,
			stagger: 0.2,
			repeatRefresh: true,
			ease: 'power1.inOut',
			repeat: -1,
			onRepeat: () => {
				tween.repeatDelay(gsap.utils.random(0.5, 1, 0.1));
			},
		});

		return () => {
			tween.kill();
		};
	}, []);

	return (
		<AnimationPositionElement ref={ref} position={{ ...props.position }}>
			<StyledLampsContainer>{variant}</StyledLampsContainer>
		</AnimationPositionElement>
	);
};
