import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { PageContent } from '@client/style/Shared';
import { ModuleWrapper } from './ModuleWrapper';
import Functions from '@client/style/Functions';
import AnimationSceneComponent from '@client/animationv2/AnimationSceneComponent';

const StyledModuleWrapper = styled(ModuleWrapper)`
	position: relative;
	width: 100%;
	margin-top: -1px; /* Remove the 1px line - rounding issue */
`;

const StyledAnimation = styled(AnimationSceneComponent)<{ baseHeightOnAnimation?: boolean }>`
	position: ${props => (props.baseHeightOnAnimation ? 'relative' : 'absolute')};
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	pointer-events: none;
`;

const ColorWrapper = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;
const BottomColor = styled.div<{ bgColor?: string }>`
	top: 50%;
	left: 0;
	width: 100%;
	height: 50%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

export interface IProps {
	className?: string;
	children: JSX.Element | JSX.Element[];
	moduleData: any;
	baseHeightOnAnimation?: boolean;
	usePreloader?: boolean;
	sortingIndex?: number;
}

export const AnimatedModule = (props: IProps) => {
	const [hasMounted, setHasMounted] = useState<boolean>();
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setHasMounted(true);
	}, []);

	useEffect(() => {
		if (props.moduleData && props.moduleData.backgroundColorLink) {
			const color = Functions.hexFromRGB(props.moduleData.backgroundColorLink?.color);
			setBackgroundColors([color, color]);
			return;
		}
		if (moduleRef.current) {
			// console.log('moduleRef: ', moduleRef.current);
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor == null && nextColor == null) {
				setBackgroundColors([undefined, undefined]);
			} else if (prevColor == null) {
				setBackgroundColors([nextColor, nextColor]);
			} else if (nextColor == null) {
				setBackgroundColors([prevColor, prevColor]);
			} else {
				setBackgroundColors([prevColor, nextColor]);
			}
		}
	}, [hasMounted]);
	return (
		<StyledModuleWrapper className={props.className} id={props.moduleData.moduleId && props.moduleData.moduleId}>
			<ColorWrapper
				className='colorWrapperAnimatedModule'
				bgColor={
					props.moduleData.backgroundColorLink
						? Functions.hexFromRGB(props.moduleData.backgroundColorLink?.color)
						: undefined
				}
				ref={moduleRef}
			>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>
			<StyledAnimation
				data={props.moduleData.animation}
				baseHeightOnAnimation={props.baseHeightOnAnimation}
				usePreloader={props.usePreloader ? props.usePreloader : false}
			/>
			<PageContent>{props.children}</PageContent>
		</StyledModuleWrapper>
	);
};
