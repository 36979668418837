import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import CustomEvents, { Events } from '@core/CustomEvents';
import { Breakpoints, Colors, Index, presetColors } from '@style/Variables';
import gsap from 'gsap';
import { waves } from './Dividers.tsx/Breaker';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import WaltherLogo from '@client/assets/svgs/waltherlogo.svg';
import TextSlideInAnimation, { ImperativeTextSlideInAnimation } from './shared/TextSlideInAnimation';
import { CommonTitle } from '@client/common/CommonText';
import Functions from '@client/style/Functions';

const StyledSiteLoaderWrapper = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: ${Index.siteLoader};
	pointer-events: none;
	overflow: hidden;
`;

const StyledSwiper = styled.div`
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: absolute;

	--margin: 37%;
	${Functions.breakpoint(Breakpoints.tablet)} {
		--margin: 17%;
	}

	margin-top: calc(var(--margin) * -1);
	margin-bottom: calc(var(--margin) * -1);
	padding-top: var(--margin);
	padding-bottom: var(--margin);
`;

const StyledWaveSvg = styled.svg`
	display: block;
	width: 100%;
	position: absolute;
	bottom: 0;
	path {
		fill: ${presetColors.evergreen};
	}
`;

const StyledBackground = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	background-color: ${presetColors.evergreen};
`;

const StyledTextContainer = styled.div`
	display: flex;
	opacity: 1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	z-index: ${Index.siteLoader};
	pointer-events: none;
`;

const StyledAnimationContainer = styled.div`
	width: 100px;
	height: 100px;
	fill: white;
	margin-bottom: 20px;
`;

const StyledCommonTitle = styled(CommonTitle)`
	color: ${Colors.lightText};
	pointer-events: none;
	font-size: 24px;
	line-height: 2em;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 48px;
	}
`;

export default function SiteLoader() {
	const swiperRef = useRef<HTMLDivElement | null>(null);
	const waveRef = useRef<SVGPathElement>(null);
	const textContainerRef = useRef<HTMLDivElement | null>(null);
	const slideAnimationRef = useRef<ImperativeTextSlideInAnimation>(null);
	const isMobile = useIsMobile();

	const newPageAnimationRef = useRef<gsap.core.Timeline>();
	const loadingPageAnimationRef = useRef<gsap.core.Timeline>();
	const showTextContainerRef = useRef<gsap.core.Tween>();
	const hideTextContainerRef = useRef<gsap.core.Tween>();

	const path = React.useMemo(() => {
		return isMobile ? waves.mobile.variants[4] : waves.desktop.variants[1];
	}, [isMobile]);

	// Create text animations
	useEffect(() => {
		// Create "loading page" animation
		showTextContainerRef.current = gsap.fromTo(
			textContainerRef.current,
			{
				opacity: 0,
				scale: 0.9,
			},
			{
				opacity: 1,
				scale: 1,
				delay: 0.4,
				duration: 0.3,
				ease: 'back.out(1.4)',
			}
		);

		hideTextContainerRef.current = gsap.fromTo(
			textContainerRef.current,
			{
				opacity: 1,
				scale: 1,
			},
			{
				delay: 0.5,
				opacity: 0,
				scale: 0.9,
				duration: 0.3,
				ease: 'back.in(1.4)',
			}
		);
	}, []);

	// Create animations
	useEffect(() => {
		// Create "loading page" animation
		const loadingPageTimeline = gsap.timeline({ paused: true });
		loadingPageTimeline.set(swiperRef.current, {
			scaleY: -1, // Flip vertically so we only need a wave on one side
			display: 'block',
		});
		loadingPageTimeline.fromTo(
			swiperRef.current,
			{
				y: '100%',
			},
			{
				y: '0%',
				duration: 0.8,
				ease: 'linear',
				onComplete: () => {
					CustomEvents.dispatch(Events.TRANSITIONOUT);
				},
			},
			0
		);
		if (showTextContainerRef.current) {
			loadingPageTimeline.add(showTextContainerRef.current, 0);
		}
		loadingPageTimeline.fromTo(
			waveRef.current,
			{
				morphSVG: {
					shape: path,
				},
			},
			{
				morphSVG: {
					// Morph to copy of the same path but with each point index shifted by 2
					shape: path,
					shapeIndex: 2,
					// Use rotational transform around a point that is moved down by 400%
					type: 'rotational',
					origin: '50% 400%',
				},
				ease: 'linear',
				duration: 0.4,
				repeat: 2,
			},
			0
		);
		loadingPageTimeline.call(
			() => {
				slideAnimationRef.current?.triggerAnimation();
			},
			[],
			0.3
		);
		loadingPageAnimationRef.current = loadingPageTimeline;

		// Create "new page" animation
		const newPageTimeline = gsap.timeline({ paused: true });
		newPageTimeline.set(swiperRef.current, {
			scaleY: 1, // Reset vertical flip
		});
		newPageTimeline.fromTo(
			swiperRef.current,
			{
				y: '0%',
			},
			{
				y: '-100%',
				delay: 0.5,
				duration: 0.8,
				ease: 'linear',
				onComplete: () => {
					if (!swiperRef.current) return;

					gsap.set(swiperRef.current, {
						display: 'none',
					});
				},
			}
		);
		if (hideTextContainerRef.current) {
			newPageTimeline.add(hideTextContainerRef.current, 0);
		}
		newPageTimeline.fromTo(
			waveRef.current,
			{
				morphSVG: {
					shape: path,
				},
			},
			{
				morphSVG: {
					// Morph to copy of the same path but with each point index shifted by 2
					shape: path,
					shapeIndex: 2,
					// Use rotational transform around a point that is moved down by 400%
					type: 'rotational',
					origin: '50% 400%',
				},
				ease: 'linear',
				duration: 0.4,
				repeat: 2,
			},
			0
		);

		newPageTimeline.call(
			() => {
				// Call transitionin event 0.3s before animation completes
				CustomEvents.dispatch(Events.TRANSITIONIN);
			},
			[],
			'-=0.3'
		);
		newPageAnimationRef.current = newPageTimeline;
	}, [isMobile]);

	React.useEffect(() => {
		const loadingPage = () => {
			loadingPageAnimationRef.current?.play(0).timeScale(1);
		};
		const newPage = () => {
			newPageAnimationRef.current?.play(0).timeScale(1);
		};

		// Animate siteloader after first render
		// Wrapped in timeout to make sure useIsMobile is correct
		setTimeout(() => {
			newPage();
		}, 100);

		CustomEvents.listen(Events.LOADINGPAGE, loadingPage);
		CustomEvents.listen(Events.NEWPAGE, newPage);

		return function () {
			CustomEvents.remove(Events.LOADINGPAGE, loadingPage);
			CustomEvents.remove(Events.NEWPAGE, newPage);
		};
	}, []);

	return (
		<StyledSiteLoaderWrapper>
			<StyledSwiper ref={swiperRef}>
				<StyledWaveSvg viewBox={isMobile ? waves.mobile.viewBox : waves.desktop.viewBox}>
					<path d={path} ref={waveRef} transform-origin='50% 50%' />
				</StyledWaveSvg>
				<StyledBackground></StyledBackground>
			</StyledSwiper>

			<StyledTextContainer ref={textContainerRef}>
				<StyledAnimationContainer>
					<WaltherLogo />
				</StyledAnimationContainer>
				<StyledCommonTitle>
					<TextSlideInAnimation ref={slideAnimationRef} breakAtWord={false} text={'Henter alt smukt'} />
				</StyledCommonTitle>
			</StyledTextContainer>
		</StyledSiteLoaderWrapper>
	);
}
