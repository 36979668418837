import Store from '@core/Store';
import { defaultLang, Lang } from '@core/utils/Language';
import { ImageData } from '@modules/Image';
import Mode from '@core/interface/Mode';
import { IMadPapir } from '@client/modules/Linkout/LinkoutPaper';

export interface ISiteState {
	server: {
		mode: Mode;
		uid: string;
		bugsnagKey: any;
	};
	language: Lang;
	global: GlobalData;
	news: GlobalData;
	footer: FooterData;
	topMenu: any;
}

export interface GlobalData {
	site: string;
	seo?: any;
	facebook?: string;
	youtube?: string;
	instagram?: string;
	cookieInformation?: any;
	newsletterTitle: string;
	newsletterDescription: string;
	newsletterImage: ImageData;
	madpapirList: IMadPapir[];
	transitions: any[];
	orientationMessage: string;
	topMenuTitle: string;
	topMenuDato: string;
	pageNotFoundText: string;
	pageNotFoundLink: any[];
	loginPromptTitle: string;
	loginPromptBody: string;
}

export interface FooterData {
	address: string;
	backgroundColor: any;
	simpleFooterBgColorLink: any;
	contact: string;
	createdAt: string;
	desktopLinkList: Array<any>;
	facebookLink: string;
	id: string;
	instagramLink: string;
	itemType: string;
	modelId: string;
	updatedAt: string;
	sponsors: Array<any>;
	sponsorLink: any;
	socialMediaBar: Array<any>;
}

export const DefaultState = {
	server: {
		mode: process.env.NODE_ENV === 'production' ? Mode.PROD : Mode.DEV,
		uid: '1234',
		bugsnagKey: null,
	},
	language: defaultLang,
	global: {} as GlobalData,
	news: {} as any,
	footer: {} as FooterData,
	topMenu: {} as any,
};

export class SiteStore extends Store<ISiteState> {
	constructor() {
		super(DefaultState);
	}
}

const Site = new SiteStore();

export default Site;
