import { ILink } from '../utils/LinkTools';
import PageHandler from './PageHandler';

export default class LinkHandling {
	private static _instance: LinkHandling;
	private _handleExternalLink: (url: string) => boolean | undefined = () => undefined;

	public static get Instance(): LinkHandling {
		if (!this._instance) this._instance = new LinkHandling();

		return this._instance;
	}

	constructor() {}

	/**
	 * Override link hanling
	 */
	public set handleExternalLink(value: (url: string) => boolean | undefined) {
		this._handleExternalLink = value;
	}

	/**
	 * Link opening
	 */
	public openLink(link: ILink): void {
		// return;
		if (link.isLocal) {
			PageHandler.getInstance().goto(link.url.href);
		} else {
			if (/^(mailto|tel):/.test(link.url.href)) {
				window.location.href = link.url.href;
			} else {
				this.openExternalLink(link.full);
			}
		}
	}

	private openExternalLink(link: string): void {
		const useDefault: boolean = this._handleExternalLink === undefined || this._handleExternalLink(link) === false;

		if (useDefault) {
			this.handleExternalLinkDefault(link);
		}
	}

	/**
	 * Default handlers
	 */
	public handleExternalLinkDefault(link: string): boolean {
		window.open(link, '_blank');
		return true;
	}
}
