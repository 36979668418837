import React from 'react';
import styled from 'styled-components';
import { Breakpoints, Colors, Fonts } from '@client/style/Variables';
import Functions from '@client/style/Functions';

export const Button = styled.button<{ backgroundcolor: string; textcolor: string; starColor:string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	pointer-events: auto;
	width: auto;
	border-radius: 25px;
	background-color: ${props => props.backgroundcolor || Colors.darkBackground};
	color: ${props => props.textcolor || Colors.lightText};
	position: relative;
	padding: 10px 14px;
	padding-right: 24px;
	min-height: 42px;
	font-family: ${Fonts.Geomanist};
	text-decoration: Capitalize;
	font-size: 15px;
	cursor: pointer;
	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);

	&:hover {
		transform: scale(1.1, 1.1);
	}

	&:active {
		transform: scale(0.95, 0.95);
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 21px;
	}

	svg {
		path {
			fill: ${props => props.starColor};
		}
	}
`;

interface IProps {
	title: string;
	textColor: string;
	backgroundColor: string;
	starColor: string;
	onClick?: () => void;
}

export const FavouriteButton = (props: IProps) => {
	return (
		<Button
			starColor={props.starColor}
			backgroundcolor={props.backgroundColor}
			textcolor={props.textColor}
			onClick={props.onClick}
		>
			<svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M14.5979 1.8541C15.1966 0.0114784 17.8034 0.0114797 18.4021 1.8541L20.878 9.47417C21.1458 10.2982 21.9137 10.8561 22.7801 10.8561H30.7924C32.7298 10.8561 33.5353 13.3354 31.9679 14.4742L25.4859 19.1836C24.7849 19.6929 24.4916 20.5957 24.7594 21.4197L27.2353 29.0398C27.834 30.8824 25.725 32.4146 24.1576 31.2758L17.6756 26.5664C16.9746 26.0571 16.0254 26.0571 15.3244 26.5664L8.84241 31.2758C7.27499 32.4146 5.16603 30.8824 5.76473 29.0398L8.24064 21.4197C8.50839 20.5957 8.21507 19.6929 7.5141 19.1836L1.03208 14.4742C-0.535347 13.3354 0.270204 10.8561 2.20765 10.8561H10.2199C11.0863 10.8561 11.8542 10.2982 12.122 9.47417L14.5979 1.8541Z'
					fill='#FF9900'
				/>
			</svg>

			<div className='title'>{props.title}</div>
		</Button>
	);
};
