import { boldTextStyle, commonTextStyle } from '@client/common/CommonText';
import { Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import { IFormInputField } from './FormStore';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	align-items: center;
`;

const Label = styled.label<{ valid?: boolean | undefined }>`
	${commonTextStyle}
	${boldTextStyle}
	color: ${props => (props.valid === false ? Colors.error : Colors.darkText)};
`;

const StyledInput = styled.input`
	${commonTextStyle}
	flex: 1;
	height: 50px;
	border: none;
	padding: 10px;
`;

interface IProps {
	data: IFormInputField;
	valid?: boolean | undefined;
	onChange: (value: string) => void;
}

export default function FormInput(props: IProps) {
	const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(e.target.value);
	};

	return (
		<Container>
			<Label htmlFor={props.data.id} valid={props.valid}>
				{props.data.label}
			</Label>

			<StyledInput
				type={props.data.type ? props.data.type : 'text'}
				id={props.data.id}
				value={props.data.value}
				required={props.data.required === true}
				onChange={e => onChangeInternal(e)}
			/>
		</Container>
	);
}
