import { context } from '@client/App';
import { ISmukConfig } from './SmukConfig';
import { AzureAuth } from '@client/core/auth/azure/AzureAuth';

export type FavoriteGroup = 'Music';

export class SmukApi {
	private _config: ISmukConfig;
	private _auth: AzureAuth;

	constructor(config: ISmukConfig, auth: AzureAuth) {
		this._config = config;
		this._auth = auth;
	}

	/**
	 * Favorites
	 */
	public async addFavorite(group: FavoriteGroup, id: string) {
		return context.auth.authenticatedCall(async () => {
			const result = await fetch(`${this._config.api}profile/Favorite/${group}/${id}`, this.buildRequest('POST'));
			return result;
		});
	}

	public async removeFavorite(group: FavoriteGroup, id: string) {
		return context.auth.authenticatedCall(async () => {
			const result = await fetch(`${this._config.api}profile/Favorite/${group}/${id}`, this.buildRequest('DELETE'));
			return result;
		});
	}

	public async getFavorites(group: FavoriteGroup) {
		return context.auth.authenticatedCall(async () => {
			const result = await fetch(`${this._config.api}profile/Favorite/${group}`, this.buildRequest('GET'));
			return result;
		});
	}

	/**
	 * Default request
	 */
	private buildRequest(method: 'GET' | 'POST' | 'DELETE', headers?: Headers): RequestInit {
		if (!headers) {
			headers = new Headers();
			headers.append('X-VenueId', '0');
		}

		headers.append('Authorization', `Bearer ${this._auth.user.data.token?.access_token}`);

		const request: RequestInit = {
			method: method,
			headers: headers,
		};

		return request;
	}
}
