import Link, { createLinkObject } from '@client/core/PageSwitch/Link';
import Functions from '@client/style/Functions';
import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Breakpoints, Fonts } from '../../style/Variables';
import ScrollTrigger from 'gsap/ScrollTrigger';

const BoldList = styled.div`
	width: 100%;
	font-size: 44px;
	line-height: 110%;
	font-family: ${Fonts.GeomanistBold};

	/* display: flex;
	justify-content: center; */

	position: relative;
	margin-bottom: 40px;

	${Functions.breakpoint(Breakpoints.tablet)} {
		font-size: 80px;
	}
	${Functions.breakpoint(Breakpoints.desktop)} {
		font-size: 138px;
	}
`;

const OrdinaryList = styled.div`
	width: 100%;
	font-size: 22px;
	line-height: 140%;
	font-family: ${Fonts.Geomanist};

	a:not(:last-child):after {
		content: '·';
		padding: 0 6px;
		display: inline-block;
	}

	${Functions.breakpoint(Breakpoints.tablet)} {
		font-size: 30px;
	}

	${Functions.breakpoint(Breakpoints.desktop)} {
		font-size: 35px;
		width: ${Functions.col(8, 16)};
		max-width: 2000px;
	}
`;

export function ListView({ list }: { list: any[] }) {
	const boldListRef = useRef<HTMLDivElement | null>(null);
	const listRef = useRef<HTMLDivElement | null>(null);

	gsap.registerPlugin(ScrollTrigger);

	useEffect(() => {
		if (!boldListRef.current) return;
		if (boldListRef.current.children.length === 0) return;
		let count = 0;
		gsap.set(boldListRef.current.children, {
			opacity: 0,
			x: () => {
				const returnVal = count % 2 === 0 ? 30 : -30;
				count++;
				return returnVal;
			},
		});

		ScrollTrigger.batch(boldListRef.current.children, {
			onEnter: batch => {
				if (!batch) return;

				gsap.to(batch, {
					opacity: 1,
					x: 0,
					duration: 1,
					stagger: 0.1,
					ease: 'back.out(1)',
				});
			},
			start: '80% 80%',
			once: true,
		});

		return () => {
			if (!boldListRef.current) return;
			if (boldListRef.current.children.length === 0) return;

			gsap.killTweensOf(boldListRef.current.children);
		};
	}, [list]);

	useEffect(() => {
		if (!listRef.current) return;
		if (listRef.current.children.length === 0) return;

		gsap.set(listRef.current.children, {
			opacity: 0,
			y: 100,
		});
		ScrollTrigger.batch(listRef.current.children, {
			onEnter: batch => {
				if (!batch) return;
				gsap.to(batch, {
					opacity: 1,
					duration: 0.7,
					stagger: { from: 'random', amount: 0.5, grid: 'auto' },
				});
			},
			start: '80% 80%',
			once: true,
		});
		return () => {
			if (!listRef.current) return;
			if (listRef.current.children.length === 0) return;

			gsap.killTweensOf(listRef.current.children);
		};
	}, [list]);

	return (
		<>
			<BoldList ref={boldListRef}>
				{list.map((item: any, i: number) => {
					return (
						item.boldTextInPosterView && (
							<div key={'boldListItem_' + i + '_' + item.id}>
								<Link data={createLinkObject(item)}>{item.title}</Link>
							</div>
						)
					);
				})}
			</BoldList>

			<OrdinaryList ref={listRef}>
				{list.map((item: any, i: number) => {
					return (
						!item.boldTextInPosterView && (
							<Link data={createLinkObject(item)} key={'listItem_' + i + '_' + item.id}>
								{item.title}
							</Link>
						)
					);
				})}
			</OrdinaryList>
		</>
	);
}
