import React, { useEffect } from 'react';
import styled from 'styled-components';
import MagicModuleSwitcher from '@modules/MagicModuleSwitcher';
import { Page } from '@style/Shared';
import { Breakpoints, Colors, presetColors } from '@client/style/Variables';
import Functions from '@client/style/Functions';
import ContentPageStore from '@client/store/ContentPageStore';
import Menu from '@client/modules/Menu/Menu';
import GotoElement from '@client/core/utils/GotoElement';
import SimpleFooter from '@client/modules/Footers/SimpleFooter';
import ContentpageHeader from '@client/modules/ContentpageHeader/ContentpageHeader';
import { ColorUtils } from '@client/core/utils/colors/ColorUtils';
import FilterStore from '@client/modules/ListModules/FilterStore';
import { DateUtils } from '@client/core/utils/DateUtils';
import Site from '@client/store/Site';
import PageOverlay from '@client/modules/overlays/PageOverlays/PageOverlay';

const StyledPage = styled(Page)<{ color?: string }>`
	/* background-color: ${props => props.color + ' !important'}; */
	min-height: 100vh;
`;

const Spacing = styled.div<{ color?: string }>`
	background-color: ${props => props.color};
	height: 38vw;
	width: 100%;
	${Functions.breakpoint(Breakpoints.tablet)} {
		height: 18vw;
	}
`;

const ModulWrapper = styled.div`
	width: 100%;
`;

export default function ContentPage(props: { content?: any }) {
	const global = Site.use(state => state.global) as any;

	useEffect(() => {
		FilterStore.setTagFilters(props.content._oddTags);
		FilterStore.setLocationFilters(props.content._locationTags);
		FilterStore.setDateFilters(DateUtils.getDaysListFromTo(new Date(global.startDate), new Date(global.endDate)));

		if (props.content?.backgroundColor === null) ContentPageStore.setBackgroundColor(Colors.lightBackground);
		// else ContentPageStore.setBackgroundColor(Functions.hexFromRGB(props.content?.backgroundColor));
	}, []);

	return (
		<StyledPage>
			<Menu
				onLogoClick={() => GotoElement.Instance.scrollToElementWithId(`module_${props.content.startId}`)}
				specificColor={
					props.content?.backgroundColor &&
					ColorUtils.findContrastingColor(
						Functions.hexFromRGB(props.content.backgroundColor?.color),
						presetColors.white,
						presetColors.blackish
					)
				}
				currentSlug={props.content?._prefix + props.content?.slug}
			/>
			<PageOverlay></PageOverlay>
			<ContentpageHeader
				title={props.content?.title}
				subtitle={props.content?.subtitle}
				parent={props.content?.parentPage}
				bgColor={props.content?.backgroundColor}
				CTALink={props.content?.link}
				video={props.content?.video}
				fullBleed={props.content?.fullBleed}
				image={props.content?.image}
				indexMenu={props.content?.indexMenu}
				hideBreaker={props.content?.modules[0]?.modelId === 'fullBleedMediaModule'}
			></ContentpageHeader>
			<ModulWrapper id='CurrentPageContent'>
				<MagicModuleSwitcher
					modules={props.content ? props.content.modules : []}
					addedNews={props.content ? props.content?._news : undefined}
				/>
			</ModulWrapper>
			<Spacing
				color={Functions.hexFromRGB(
					props.content?.modules[props.content?.modules.length - 1]?.backgroundColorLink?.color ||
						props.content?.modules[props.content?.modules.length - 1]?.backgroundColor?.color
				)}
				className='colorWrapperAnimatedModule'
			></Spacing>
			<SimpleFooter optional={props.content.someFooter} />
		</StyledPage>
	);
}
