import React from 'react';
import ModuleSwitcherCore, { Module } from '@client/core/ModuleSwitcherCore';
import { IllustrationHotdog } from './IllustrationHotdog';
import { IllustrationHandelsbod } from './IllustrationHandelsbod';
import { IllustrationLove } from './IllustrationLove';
import styled from 'styled-components';
import IllustrationWishingWell from './IllustrationWishingWell';
import { IllustrationArmband } from './IllustrationArmband';
import { IllustrationWalterGraver } from './IllustrationWalterGraver';
import { IllustrationArmbandCrew } from './IllustartionArmbandCrew';
import { IllustrationSnakke } from './IllustrationSnakke';
import { IllustrationFinurligheder } from './IllustrationFinurligheder';
import { IllustrationMadOgDrikke } from './IllustrationMadOgDrikke';
import { IllustrationBushGuitar } from './IllustrationBushGuitar';
import { IllustrationBushPedal } from './IllustrationBushPedal';
import { IllustrationCrewWalter } from './IllustrationCrewWalter';
import IllustrationRegnbuescene from './IllustrationRegnbuescene';
import IllustrationOrangeJuice from './IllustrationOrangeJuice';
import IllustrationSovSmukt from './IllustrationSovSmukt';
import IllustrationKabselblomst from './IllustrationKabselblomst';
import { IllustrationBush1 } from './IllustrationBush1';

const StyledIllustrationWrapper = styled.div`
	position: relative;
	width: 100%;
	display: grid;
`;

export function Illustration(props) {
	return (
		<StyledIllustrationWrapper>
			<ModuleSwitcherCore modules={props.data.variant}>
				<Module modelId={'illustrationHotdog'} component={IllustrationHotdog} />
				<Module modelId={'illustrationHandelsbod'} component={IllustrationHandelsbod} />
				<Module modelId={'illustrationLove'} component={IllustrationLove} />
				<Module modelId={'illustrationWishingWell'} component={IllustrationWishingWell} />
				<Module modelId={'illustrationArmband'} component={IllustrationArmband} />
				<Module modelId={'illustrationArmbandCrew'} component={IllustrationArmbandCrew} />
				<Module modelId={'illustrationWalterGraver'} component={IllustrationWalterGraver} />
				<Module modelId={'illustrationSnakke'} component={IllustrationSnakke} />
				<Module modelId={'illustrationFinurligheder'} component={IllustrationFinurligheder} />
				<Module modelId={'illustrationMadOgDrikke'} component={IllustrationMadOgDrikke} />
				<Module modelId={'illustrationBushGuitar'} component={IllustrationBushGuitar} />
				<Module modelId={'illustrationBushPedal'} component={IllustrationBushPedal} />
				<Module modelId={'illustrationCrewWalter'} component={IllustrationCrewWalter} />
				<Module modelId={'illustrationRegnbuescene'} component={IllustrationRegnbuescene} />
				<Module modelId={'illustrationOrangeJuice'} component={IllustrationOrangeJuice} />
				<Module modelId={'illustrationSovSmukt'} component={IllustrationSovSmukt} />
				<Module modelId={'illustrationKabselblomst'} component={IllustrationKabselblomst} />
				<Module modelId={'illustrationBushOne'} component={IllustrationBush1} />
			</ModuleSwitcherCore>
		</StyledIllustrationWrapper>
	);
}
