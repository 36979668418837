import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { AnimationEffect } from './effects/AnimationEffect';
import { HoverEffect } from './effects/types/HoverEffect';
import { WiggleEffect } from './effects/types/WiggleEffect';

const Container = styled.div``;

interface AnimationSettings extends React.HTMLAttributes<HTMLDivElement> {
	effect: string | undefined;
}

export default function AnimationEffectComponent({ ...props }: AnimationSettings) {
	const containerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!props.effect || !containerRef.current) return;

		let effect: AnimationEffect | undefined;
		if (props.effect.toLowerCase() === 'wiggle') {
			effect = new WiggleEffect(containerRef.current);
		} else if (props.effect.toLowerCase() === 'hover') {
			effect = new HoverEffect(containerRef.current);
		} else if (props.effect.toLowerCase() === 'attention') {
			effect = new AnimationEffect(containerRef.current);
		}

		if (effect) effect.activate();

		return () => {
			if (effect) effect.deactivate();
		};
	}, [containerRef]);

	return <Container ref={containerRef}>{props.children}</Container>;
}
