import Functions from '@client/style/Functions';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Image, { ImageData } from '../Image';
import gsap from 'gsap';
import HTML from '@client/core/utils/HTML';
import { Breakpoints, Fonts, presetColors } from '@client/style/Variables';

const Container = styled.div<{ ratio: string }>`
	position: relative;

	display: flex;
	align-items: center;

	min-width: ${props => (props.ratio === '4/5' ? Functions.col(3, 4) : Functions.col(3.55, 4))};
	${Functions.breakpoint(Breakpoints.mobile)} {
		min-width: ${props => (props.ratio === '4/5' ? Functions.col(5, 16) : Functions.col(7, 16))};
	}
`;

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
`;

const ImageText = styled.p`
	align-self: flex-start;
	margin-top: 10px;

	min-height: 1.2em;
	line-height: 1.2em;

	font-size: 14px;
	font-family: ${Fonts.Geomanist};

	color: ${presetColors.blackish};
`;

const StyledImage = styled(Image)<{ ratio: string }>`
	aspect-ratio: ${props => props.ratio};
	width: 100%;
	height: auto;
`;

const ImageWrapper = styled.div`
	overflow: hidden;
	width: 100%;
`;

export default function SimpleImageCarouselEntry({
	image,
	text,
	isActive,
}: {
	image: ImageData;
	text: string;
	isActive: boolean;
}) {
	const containerRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		onIsActiveChange();
	}, [isActive]);

	const onIsActiveChange = () => {
		if (!containerRef.current) return;
		gsap.to(containerRef.current, { opacity: isActive ? 1 : 0.2, duration: 0.5 });
	};
	return (
		<Container ref={containerRef} ratio={Functions.findRatioSmall(image.width, image.height)}>
			<Wrapper>
				<ImageWrapper>
					<StyledImage data={image} ratio={Functions.findRatioSmall(image.width, image.height)}></StyledImage>
				</ImageWrapper>
				<ImageText dangerouslySetInnerHTML={{ __html: HTML.clean(text) }} />
			</Wrapper>
		</Container>
	);
}
