import React, { ReactChild } from 'react';
import styled from 'styled-components';
import { TextStyleBody, TextStyleSubHeader } from '@style/Shared';
import { Colors } from '@client/style/Variables';
import ListWithImage from '../ListModules/ListWithImage';
import SingleImageEntity from '../ListModules/SingleImageEntity';
import { IFavourite, useFavourites } from '../Favourites/FavouriteStore';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 100px;
	position: relative;
	align-items: center;
`;

const Header = styled.h2`
	${TextStyleSubHeader}
	color: ${Colors.darkText};
	text-align: center;
`;

const NoFavourites = styled.span`
	${TextStyleBody}
	color: ${Colors.darkText};
	text-align: center;
	width: 50%;
	padding-top: 20px;
`;

const Grid = styled.div`
	display: flex;
	margin-top: 20px;
`;

export default function MyPageFavourites(props: { artists: any }) {
	const favourites: IFavourite[] = useFavourites();

	const getArtistDataFromFavId = (artistId: string): any => {
		return props.artists.find(x => x.id === artistId);
	};

	const renderFavourite = (favData: IFavourite): ReactChild | undefined => {
		const artistData: any | undefined = getArtistDataFromFavId(favData.id);

		if (!artistData) return undefined;

		return <SingleImageEntity item={artistData} index={0} key={'SingleImageEntity_' + artistData.id} />;
	};

	return (
		<Container>
			<Header>Her er dine favoritter</Header>
			{favourites.length === 0 && (
				<NoFavourites>
					Øv, du har ingen favoritter. Hvis du markerer en kunstner som favorit vil artisten blive fremhævet i f.eks.
					spilleplanen.
				</NoFavourites>
			)}

			{favourites.length > 0 && (
				<Grid>
					<ListWithImage>{favourites.map((data, i) => renderFavourite(data))}</ListWithImage>
				</Grid>
			)}
		</Container>
	);
}
