// import { CookieInformationHelper } from 'ts/cookieInformation/CookieInformationHelper';
// import { v4 as uuidv4 } from 'uuid';

import { CookieInformationHelper, CookieTypes, ICookieEvent } from '@client/modules/CookieInformationMiddleware';
import { useEffect } from 'react';
import Meta from './Meta';

export default class GoogleAnalytics {
	public static ANALYTICS_KEY: string = 'G-D7TMJTKPDT';

	private _gtag: any = undefined;

	private _started: boolean = false;
	private _active: boolean = false;

	private _testMode: boolean = false; // will force the banner to open

	private static _instance: GoogleAnalytics | undefined = undefined;
	public static getInstance(): GoogleAnalytics {
		if (this._instance === undefined) this._instance = new GoogleAnalytics();

		return this._instance;
	}

	public startup(): void {
		this._active = true;

		if (this._started) return;

		console.log('starting up analytics');

		if (typeof gtag === 'undefined') {
			console.warn('gtag was not found');
		} else {
			this._gtag = gtag;
		}

		this.setup();
		this._started = true;
	}

	public shutdown(): void {
		this._active = false;
		console.log('stopping pageview logging of ');
	}

	private setup() {
		if (!this._gtag) return;

		console.log('setting up analytics');

		this._gtag('js', new Date());
		this._gtag('config', GoogleAnalytics.ANALYTICS_KEY, {
			send_page_view: false,
		});
	}

	/**
	 * Tracking
	 */
	public pageView(meta?: Meta): void {
		if (!this._gtag) return;
		if (!this._started) return;
		if (!this._active) false;

		// if (!this.useStatistical()) return;

		let payload: any = {};

		payload.page_title = meta?.getTitle();
		payload.page_location = window.location.href;
		// payload.page_path = window.location.pathname;

		this.applyNeededData(payload);

		// console.log('GoogleAnalytics.pageView();');
		// console.dir(payload);

		this._gtag('event', 'page_view', {
			payload,
		});
	}

	private applyNeededData(payload: any): void {
		if (!payload.send_to) payload.send_to = [GoogleAnalytics.ANALYTICS_KEY];

		if (payload.event) payload.event = payload.event.toLowerCase();
	}

	/**
	 * Helpers
	 */
	// private useStatistical(): boolean {
	// 	return this.hasConsent;
	// }

	/**
	 * Save
	 */
	// public set hasConsent(val: boolean) {
	// 	this.choiceMade = true;
	// 	localStorage.setItem('allow-cookies', val ? 'true' : 'false');
	// }

	// public get hasConsent(): boolean {
	// 	if (this._testMode) return false;

	// 	return localStorage.getItem('allow-cookies') === 'true';
	// }

	// public set choiceMade(val: boolean) {
	// 	localStorage.setItem('allow-cookies-choice-made', val ? 'true' : 'false');
	// }

	// public get choiceMade(): boolean {
	// 	if (this._testMode) return false;

	// 	return localStorage.getItem('allow-cookies-choice-made') === 'true';
	// }
}

export function GoogleAnalyticsToCookieInformation() {
	useEffect(() => {
		const statisticalEventChanged = (e: ICookieEvent) => {
			if (e.given) {
				GoogleAnalytics.getInstance().startup();
			} else {
				GoogleAnalytics.getInstance().shutdown();
			}
		};

		CookieInformationHelper.getInstance().statisticalEvent.on(statisticalEventChanged);

		if (CookieInformationHelper.getInstance().hasConsentFor(CookieTypes.STATISTICAL)) {
			GoogleAnalytics.getInstance().startup();
		} else {
			GoogleAnalytics.getInstance().shutdown();
		}

		return function () {
			CookieInformationHelper.getInstance().statisticalEvent.off(statisticalEventChanged);
		};
	}, []);

	return null;
}
