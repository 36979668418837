import { ColorUtils } from './ColorUtils';

export class Color {
	private readonly _color: number;

	constructor(color: number | string) {
		if (typeof color === 'string') {
			if (color.length === 7) {
				this._color = ColorUtils.CSStoHEX(color);
			} else {
				console.error('Color ' + color + ' is not a valid color');
				this._color = 0xff00fe; //VERY UGLY PINK COLOR
			}
		} else {
			this._color = color;
		}
	}

	public get hex(): number {
		return this._color;
	}

	public get css(): string {
		let str16 = this._color.toString(16);
		while (str16.length < 6) {
			str16 = '0' + str16;
		}
		return '#' + str16;
	}

	// Value used to determine percieved brightness of a color, for contrast / accessibility
	public get yiq(): number {
		let col = this.css.replace('#', '');
		let red = parseInt(col.slice(0, 2), 16);
		let green = parseInt(col.slice(2, 4), 16);
		let blue = parseInt(col.slice(4, 6), 16);
		return (red * 299 + green * 587 + blue * 114) / 1000;
	}

	public alpha(amount: number): string {
		return ColorUtils.hexToRGB(this.css, amount);
	}

	// https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#--version-2-hex--
	// amount between 1 and -1, higher number = bright, lower number = darker
	public shade(amount: number): Color {
		let tint = amount < 0 ? 0 : 255;
		let pct = amount < 0 ? amount * -1 : amount;
		let red = this._color >> 16;
		let green = (this._color >> 8) & 0x00ff;
		let blue = this._color & 0x0000ff;

		return new Color(
			'#' +
				(
					0x1000000 +
					(Math.round((tint - red) * pct) + red) * 0x10000 +
					(Math.round((tint - green) * pct) + green) * 0x100 +
					(Math.round((tint - blue) * pct) + blue)
				)
					.toString(16)
					.slice(1)
		);
	}
}
