import { Breakpoints } from '@client/style/Variables';

type breakpointMatches = { [breakpoint: string]: boolean };
type eventCallback = (breakPoints: breakpointMatches) => any;

class MatchBreakpoint {
	breakpoints: breakpointMatches = {};
	private _onChangeEvents: { [key: number]: eventCallback } = {};
	private _eventIdCounter = 1;
	constructor() {
		for (const breakpoint in Breakpoints) {
			if (typeof window !== 'undefined') {
				const query = window.matchMedia(`(min-width: ${Breakpoints[breakpoint]})`);
				this.breakpoints[breakpoint] = query.matches;
				query.addEventListener('change', e => {
					this.breakpoints[breakpoint] = e.matches;
					this._onChange();
				});
			} else {
				this.breakpoints[breakpoint] = false;
			}
		}
	}
	private _onChange() {
		for (const id in this._onChangeEvents) {
			this._onChangeEvents[id](this.breakpoints);
		}
	}
	addChangeListener(callback: eventCallback) {
		const id = this._eventIdCounter + 1;
		this._onChangeEvents[id] = callback;
		this._eventIdCounter = id;
		return id;
	}
	removeChangeListener(eventId: number) {
		delete this._onChangeEvents[eventId];
	}
}

export const matchBreakpoint = new MatchBreakpoint();
