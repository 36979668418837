import { CommonBoldText, CommonText, CommonTitle } from '@client/common/CommonText';
import HTML from '@client/core/utils/HTML';
import { CommonButton, CommonInput } from '@client/style/Shared';
import { Colors } from '@client/style/Variables';
import React, { useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	/* min-height: 100%; */
	min-height: 100vh;
	max-width: 1024px;
	margin: 0 auto;
`;

const Title = styled(CommonTitle)`
	color: ${Colors.darkText};
	text-align: left;
	width: 100%;
`;

const Text = styled(CommonText)`
	color: ${Colors.darkText};
	text-align: left;
`;

const InputHeader = styled(CommonBoldText)`
	color: ${Colors.darkText};
	margin-top: 40px;
	margin-bottom: 20px;
	padding-left: 12px;
	text-align: left;
	width: 100%;
`;

const StyledButton = styled(CommonButton)`
	margin-top: 30px;
`;

const StyledInput = styled(CommonInput)``;

const EmailInput = styled(StyledInput).attrs({ type: 'email' })``;

const NumberInput = styled(StyledInput).attrs({ type: 'number' })``;

export interface UserProfile {
	profile?: string;
	email?: string;
	age?: string;
}

interface IProps {
	title: string;
	text: string;
	buttonText: string;
	onSubmit: (profile: UserProfile) => void;
}

export default function SubmitMailModule({ title, text, buttonText: buttonText, onSubmit }: IProps) {
	const nameRef = useRef<HTMLInputElement | null>(null);
	const mailRef = useRef<HTMLInputElement | null>(null);
	const ageRef = useRef<HTMLInputElement | null>(null);
	const goNext = () => {
		const isValid: boolean | undefined = mailRef.current?.reportValidity();
		mailRef.current?.setAttribute('aria-invalid', (!isValid).toString());

		const isNameValid: boolean | undefined = nameRef.current?.reportValidity();
		nameRef.current?.setAttribute('aria-invalid', (!isValid).toString());

		const isAgeValid: boolean | undefined = ageRef.current?.reportValidity();
		ageRef.current?.setAttribute('aria-invalid', (!isValid).toString());

		// console.log(isValid, isNameValid, isAgeValid);

		if (isValid && isNameValid && isAgeValid) {
			// saveProfile(nameRef.current.value, mailRef.current.value, ageRef.current.value);
			// console.log('saveprofile not implemented');
			onSubmit({ profile: nameRef.current?.value, email: mailRef.current?.value, age: ageRef.current?.value });
		}
	};

	return (
		<Container>
			<Title>{title}</Title>
			<Text dangerouslySetInnerHTML={{ __html: HTML.clean(text) }}></Text>
			<InputHeader>Navn</InputHeader>
			<StyledInput ref={nameRef} placeholder='Dit fulde navn' required></StyledInput>
			<InputHeader>Email</InputHeader>
			<EmailInput ref={mailRef} placeholder='Indtast email adresse' required></EmailInput>
			<InputHeader>Alder</InputHeader>
			<NumberInput ref={ageRef} placeholder='Indtast alder' required></NumberInput>

			<StyledButton onClick={goNext}>{buttonText}</StyledButton>
		</Container>
	);
}
