import gsap, { ScrollTrigger } from 'gsap/all';
import React, { StyleHTMLAttributes } from 'react';
import styled from 'styled-components';
import { useAnimationContainerTimeline } from './AnimationCommon';

const StyledSparkle = styled.div`
	width: 35px;
	height: 37px;
	background-image: url('/assets/svgs/sparkle.svg');
	position: absolute;
	top: -18.5px; // Half height
	left: -17.5px; // Half width
`;

const StyledSparkleContainer = styled.div`
	pointer-events: none;
	position: absolute;
	width: calc(100% - 20px);
	height: calc (100% - 20px);
	top: 10px;
	left: 10px;
`;

export const Sparkle = (props: { index: number }) => {
	const sparkleRef = React.useRef<HTMLDivElement>(null);

	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const container = sparkleRef.current?.parentElement;
		let delayedCall: gsap.core.Tween;

		const idleTween = gsap.fromTo(
			sparkleRef.current,
			{
				x: i => gsap.utils.random(0, container?.clientWidth || 100),
				y: i => gsap.utils.random(0, container?.clientHeight || 100),
				scale: 0,
			},
			{
				delay: 0.3 + props.index * 0.08,
				keyframes: [
					{ scale: 'random(0.5, 1.5, 0.1)', duration: 0.2, ease: 'power1.out' },
					{ scale: 0, duration: 1, ease: 'power2.in' },
				],
				scrollTrigger: {
					trigger: container,
					toggleActions: 'restart pause restart pause',
				},
				onStart() {
					this.invalidate();
					delayedCall?.kill();
				},
				onComplete() {
					delayedCall = gsap.delayedCall(gsap.utils.random(0.5, 3, 0.1), () => {
						this.invalidate();
						this.restart();
					});
				},
			}
		);

		containerTimeline.eventCallback('onStart', () => {
			delayedCall?.kill();
			idleTween.restart(true);
		});

		return () => {
			delayedCall?.kill();
			idleTween.kill();
		};
	}, []);

	return <StyledSparkle ref={sparkleRef} />;
};

export const AnimationElementSparkles = (props: {
	sparkleCount?: number;
	className?: string;
	style?: React.CSSProperties;
}) => {
	const { sparkleCount, ...rest } = props;
	const sparkles = Array.from(Array(sparkleCount || 4).keys());
	return (
		<StyledSparkleContainer {...rest}>
			{sparkles.map((key, index) => (
				<Sparkle key={key} index={index} />
			))}
		</StyledSparkleContainer>
	);
};
