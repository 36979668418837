import gsap from 'gsap/all';
import React from 'react';
import styled from 'styled-components';
import {
	AnimationElementPosition,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from './AnimationCommon';

const StyledLeavesContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
`;

export const Leaf = (props: { delayOrder?: number; variant?: 1 | 2 | 3; position: AnimationElementPosition }) => {
	const leafRef = React.useRef<HTMLImageElement>(null);

	const variants = [
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1676635614-leaf-1.png', { height: 200 }),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1676635600-leaf-2.png', { height: 200 }),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1676635586-leaf-3.png', { height: 200 }),
	];

	const image = variants[(props.variant || 1) - 1];

	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const direction = (props.position.anchor === 'right' ? 1 : -1) * (props.position.flip ? -1 : 1);

		const timeline = gsap.timeline();

		timeline.set(leafRef.current, {
			transformOrigin: 'top center',
		});

		timeline.fromTo(
			leafRef.current,
			{
				rotate: 50 * direction,
				y: '-25%',
			},
			{
				rotate: 0,
				y: 0,
				duration: 1 + (props.delayOrder || 0) * 0.25,
				ease: 'elastic(0.75, 0.6).inOut',
			}
		);

		timeline.to(leafRef.current, {
			delay: (props.delayOrder || 0) * 0.3,
			rotate: () => gsap.utils.random(1, 2, 0.1) * direction,
			duration: 6,
			ease: 'back.inOut(5)',
			yoyo: true,
			repeat: -1,
			repeatRefresh: true,
		});

		containerTimeline.add(timeline, 0);
	}, []);

	return (
		<AnimationPositionElement position={{ ...props.position, origin: 'top center' }}>
			<AnimationImageElement ref={leafRef} image={image} />
		</AnimationPositionElement>
	);
};

export const AnimationElementLeaves = (props: React.PropsWithChildren<{ className?: string }>) => {
	const { children, ...rest } = props;

	return (
		<StyledLeavesContainer {...rest}>
			<Leaf
				delayOrder={3}
				variant={2}
				position={{
					x: 30,
					y: -100,
					scale: 1,
					angle: -40,
					flip: false,
				}}
			/>
			<Leaf
				delayOrder={2}
				variant={3}
				position={{
					x: -100,
					y: 0,
					scale: 1.1,
					angle: -70,
					flip: true,
				}}
			/>
			<Leaf
				delayOrder={1}
				variant={1}
				position={{
					x: -140,
					y: -50,
					scale: 1.2,
					angle: -45,
					flip: false,
				}}
			/>
			<Leaf
				delayOrder={3}
				variant={2}
				position={{
					anchor: 'right',
					x: 100,
					y: 0,
					scale: 0.9,
					angle: 70,
					flip: true,
				}}
			/>

			{/* Allow child elements to be placed inbetween leaves */}
			{children}

			<Leaf
				delayOrder={0}
				variant={2}
				position={{
					x: -130,
					y: 40,
					scale: 1,
					angle: -30,
					flip: false,
				}}
			/>
			<Leaf
				delayOrder={2}
				variant={1}
				position={{
					anchor: 'right',
					x: 110,
					y: -10,
					scale: 1.3,
					angle: 40,
					flip: true,
				}}
			/>
		</StyledLeavesContainer>
	);
};
