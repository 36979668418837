import Functions from '@client/style/Functions';
import { Colors, Fonts, presetColors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import { useHasAnyFavourites, useIsFavourite } from '../Favourites/FavouriteStore';
import Link, { createLinkObject } from '@client/core/PageSwitch/Link';
import { MathUtils } from 'pixi-spine';
import { FavouritePanelCompact } from '../Favourites/FavouritePanelCompact';

const Event = styled.div<{ leftPos: number; width: number }>`
	position: absolute;
	left: calc(${props => props.leftPos}px + var(--gridMargin));
	bottom: 20px;
	height: 70px;
	width: ${props => props.width}px;
`;

const LinkContainer = styled(Link)<{ color: string }>`
	width: fit-content;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;

	border-left: 4px solid ${props => props.color};
	padding-left: 14px;
`;

const EventTitle = styled.p<{ color: string }>`
	font-family: ${Fonts.GeomanistBold};
	font-size: 22px;
	line-height: 1.1;

	color: ${props => props.color};

	${Functions.limitText(1)};
`;

const EventTime = styled.p<{ color: string }>`
	color: ${props => props.color};

	font-family: ${Fonts.Geomanist};
	font-size: 12px;
	line-height: 1.2;
`;

const FavContainer = styled.div`
	padding-top: 4px;
`;

interface IProps {
	performance: any;
	leftPos: number;
	width: number;
}

export default function SpilleplanEvent(props: IProps) {
	const isFavourite = useIsFavourite(props.performance.id);

	const textColor = isFavourite ? presetColors.white : presetColors.blackish;

	return (
		<Event leftPos={props.leftPos} width={props.width}>
			<LinkContainer data={createLinkObject(props.performance)} color={textColor}>
				<EventTitle color={textColor}>{props.performance.title}</EventTitle>
				<EventTime color={textColor}>
					{new Date(props.performance.startTime).toLocaleTimeString('Da-dk', {
						hour: '2-digit',
						minute: '2-digit',
						timeZone: 'Europe/Copenhagen',
					})}
				</EventTime>
				<FavContainer>
					<FavouritePanelCompact size={22} artistId={props.performance.id} />
				</FavContainer>
			</LinkContainer>
		</Event>
	);
}
