import React from 'react';
import { Colors } from '@client/style/Variables';
import FavouritesStore, { useIsFavourite } from './FavouriteStore';
import { FavouriteButton } from './FavoriteButton';
import { useAuthenticatedUser } from '@client/utils/AuthStore';
import { OverlayController, Overlays } from '@client/core/modules/Overlays/Overlays';
import { OverlayLoginPrompt } from '../overlays/LoginPrompt/OverlayLoginPrompt';

export interface IProps {
	artistId: string;
}

export const FavouritePanel = (props: IProps) => {
	const isFavourite = useIsFavourite(props.artistId);
	const user = useAuthenticatedUser();

	return (
		<>
			{!isFavourite ? (
				<FavouriteButton
					title='Tilføj favorit'
					backgroundColor={Colors.darkBackground}
					starColor={Colors.lightText + '22'}
					textColor={Colors.lightText}
					onClick={() => {
						if (!user) {
							Overlays.Instance.open({
								create: (controller: OverlayController) => {
									return <OverlayLoginPrompt controller={controller} />;
								},
							});
						}

						FavouritesStore.addFavourite({ id: props.artistId });
					}}
				/>
			) : (
				<FavouriteButton
					title='Fjern favorit'
					backgroundColor={Colors.favouriteBackground}
					starColor={Colors.newsBackground}
					textColor={Colors.lightText}
					onClick={() => {
						FavouritesStore.removeFavourite(props.artistId);
					}}
				/>
			)}
		</>
	);
};
