import { useState } from 'react';

export type StorageItem = Record<string, unknown> | string | unknown[];

export default class Storage {
	/**
	 * Get a storage item
	 * @param {string} key - The key of the storage item
	 * @returns Data object
	 */
	public static get<T = StorageItem>(key: string): T | undefined {
		const data = localStorage.getItem(key);
		if (!data) return;
		try {
			return JSON.parse(data) as T;
		} catch (e) {
			console.error(`Failed to parse JSON data for Storage ${key}: `, data);
		}
		return;
	}

	/**
	 * Set a storage item
	 * @param {string} key - The key of the storage item
	 * @param {T} value - The data object to be stored
	 */
	public static set<T = StorageItem>(key: string, value: T): void {
		try {
			localStorage.setItem(key, JSON.stringify(value));
		} catch (e) {
			console.error(`Failed to parse JSON data for Storage ${key}: `, value);
		}
	}

	/**
	 * Remove a storage item
	 * @param {string} key - The key of the storage item
	 */
	public static remove(key: string): void {
		localStorage.removeItem(key);
	}

	/**
	 * Use a storage item as a React hook
	 * @param {string} key - The key of the storage item
	 * @param {T} initialValue - The initial data object if no storage item is found
	 * @returns `[value, setValue]` - The React hook to use and set the data object
	 */
	public static use<T = StorageItem>(key: string, initialValue?: T): [T | undefined, (newValue: T) => void] {
		const [storedValue, internalSetValue] = useState<T | undefined>(Storage.get(key) || initialValue);

		const handleStorageChange = (event: StorageEvent) => {
			if (event.key === key) {
				internalSetValue(Storage.get(key));
			}
		};

		window.addEventListener('storage', handleStorageChange);

		const setValue = (newValue: T) => {
			Storage.set(key, newValue);
			internalSetValue(newValue);
		};

		return [storedValue, setValue];
	}
}
