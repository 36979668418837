import React from 'react';
import styled from 'styled-components';
import { AnimationContainer, AnimationImageAsset, AnimationImageElement } from '../AnimationCommon';
import { AnimationElementSparkles } from '../AnimationElementSparkles';

const StyledAnimationImage = styled(AnimationImageElement)`
	max-width: 80%;
	margin-left: 20%;
	height: auto;
`;

const StyledRainbow = styled.img`
	position: absolute;
	display: block;
	top: 23%;
	right: 35%;
	width: 110%;
	height: auto;
`;

export function IllustrationHandelsbod() {
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1679307281-handelsbod.png', {
		width: 815,
		height: 715,
	});

	return (
		<AnimationContainer width={image.width} height={image.height}>
			<StyledAnimationImage image={image} />
			<StyledRainbow src='https://www.datocms-assets.com/80292/1679475062-handelsbod-rainbow.svg' />
			<AnimationElementSparkles />
		</AnimationContainer>
	);
}
