import React from 'react';
import styled from 'styled-components';

import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import gsap from 'gsap/all';

export const waves = {
	desktop: {
		width: 1000,
		height: 177,
		viewBox: '0 0 1000 177',
		variants: [
			'M1.45703 -0.000205994H-40.9302H-118C-118 -0.000205994 -140 46.6364 -85 38.9315C-30 31.2266 -34 73.193 14 73.193C81.3894 73.193 111.369 29.8304 173.9 38.9315C210.758 44.2903 239.704 76.5356 276.707 80.7833C329.901 86.8886 378.211 33.3991 430.584 44.4986C461.319 51.009 484.965 78.8446 516.18 82.4036C558.755 87.2589 595.289 45.4188 638.095 47.2648C669.172 48.6016 697.829 73.075 728.298 66.796C748.425 62.6467 764.188 43.9775 784.699 42.7047C845.5 38.9315 864.5 82.4036 920 82.4036C972.607 82.4036 978.5 58.796 1025 66.796C1080.33 76.3149 1081.67 97.3539 1132.96 73.193C1169.26 56.0918 1164 -0.000205994 1164 -0.000205994H1082.73H1001.46H1.45703Z',
			'M-57 52.9998C-0.5 57.9998 -12 86.5774 40.5 95.4998C86.6867 103.349 147.794 60.0845 190 70.9998C248 86 253.808 140.496 353 159.5C460 180 491.5 100.533 608 84.9998C683 74.9997 706.5 121 766 121C815.076 121 838.773 46.496 904 52.9998C962.5 58.8329 958.5 126 1031 121C1103.5 116 1113.29 55.7419 1181 70.9998C1245 85.4224 1265 -1.52588e-05 1265 -1.52588e-05H1065.5H1002.07H-3.99963H-35.9996L-109 -3.05176e-05C-109 -3.05176e-05 -243.5 111.5 -171.5 116C-119.102 119.275 -97.4627 49.4191 -57 52.9998Z',
			'M198.657 78.2777C240.165 78.2777 264.195 139.082 305.335 133.573C336.597 129.383 348.258 87.9074 376.887 74.684C415.671 56.7558 458.136 98.868 500.654 94.4756C534.56 90.9745 559.844 58.7755 593.594 53.9723C618.333 50.4538 642.512 62.3403 667.373 64.7998C697.344 67.7685 727.199 56.9468 757.286 58.3125C802.413 60.3496 841.441 88.9664 884.277 103.289C922.095 115.934 972.074 124.027 1002.07 106.432C1047.75 79.6429 1091.01 145 1146 125.5C1198.44 106.903 1091.01 7.62939e-05 1091.01 7.62939e-05H1046.54H1002.07H-6.60938H-69.0139H-131.418C-131.418 7.62939e-05 -139.983 72.7355 -117.24 98.019C-93.4149 124.499 -40.9636 126.718 -6.60938 98.019C39.5006 59.5001 68.0395 91.4556 93.5006 94.4756C125.8 98.3068 153.501 78.2778 198.657 78.2777Z',
			'M1002.07 90.9003C981.794 55.1611 946.516 35.0834 893.918 80.593C841.319 126.103 822.407 126.103 796.991 64.638C771.58 3.17345 706.562 64.638 685.289 77.0513C664.01 89.4646 648.645 100.101 614.959 72.3232C581.273 44.5453 544.039 52.9828 519.212 75.3614C494.392 97.7401 457.158 90.0548 438.246 71.7329C419.334 53.4111 368.506 34.4989 309.993 84.7365C251.481 134.974 210.705 153.886 150.421 106.016C90.1377 58.1449 41.1736 56.8839 2.07629 112.514C-22.1946 147.047 -80.4238 142.984 -103.387 112.484C-133.508 72.4783 -78.8788 0 -78.8788 0H-42.5H-6H1002.07H1051.72H1101.37C1101.37 0 1128.1 71.627 1110.42 98.019C1092.4 124.912 1026.92 134.695 1002.07 90.9003Z',
			'M2.07214 136.761C46.1926 128.314 70.8396 79.5795 97.1996 51.5009C123.56 23.4222 165.967 30.8701 182.014 46.9175C198.062 62.965 221.557 57.8029 245.626 42.3342C269.694 26.8597 313.82 48.0634 344.764 94.4811C375.707 140.899 437.038 122.56 476.286 90.4707C515.533 58.3816 559.087 53.2196 583.155 85.8874C607.223 118.549 631.292 89.8978 658.225 69.84C685.157 49.7821 694.903 26.2868 732.721 56.0842C770.545 85.8816 783.149 83.5899 823.265 53.7925C863.381 23.9951 893.179 39.4639 929.284 85.3087C965.395 131.154 996.035 135.011 1020.74 120.052C1067.96 91.4632 1090.85 128.632 1116.46 98.0187C1141.95 67.5581 1101.37 -0.000244141 1101.37 -0.000244141H1052.18H1003H2.07214H-42.627H-87.3262C-87.3262 -0.000244141 -110.451 53.2804 -90.8101 87.4939C-71.6665 120.831 -30.1443 142.927 2.07214 136.761Z',
			'M-20.765 98.019C-0.45249 126.656 21.4052 134.273 36.3705 129.95C64.6402 121.798 82.0543 78.2755 115.346 86.0208C127.32 88.8043 137.146 99.4004 149.426 99.0937C167.048 98.6481 176.122 77.1377 192.187 69.8865C233.991 51.0336 258.497 118.431 296.035 122.392C339.297 126.972 370.274 56.1856 414.484 78.6423C438.877 91.0323 444.149 132.242 471.452 134.07C503.044 136.177 514.953 81.2407 546.579 82.809C562.424 83.596 573.298 98.8622 587.72 105.465C651.987 134.904 685.303 17.2967 749.641 38.5613C797.875 54.5101 797.347 164.778 858.594 144.458C887.619 134.826 896.216 77.1658 932.633 85.3726C963.724 92.3868 979.946 159.956 1016.18 109.972C1037.63 80.3871 1073.7 111.763 1094.46 81.4652C1115.64 50.5503 1081.34 9.15527e-05 1081.34 9.15527e-05H1043.92H1006.5H-3H-41.5H-146.5C-146.5 9.15527e-05 -167.539 110.399 -126.5 122.392C-84 134.813 -49.0116 58.1915 -20.765 98.019Z',
			'M935.313 36.9295C963.191 43.4705 975.114 108.874 1000.67 108.064C1043.63 106.701 1027.05 66.1534 1071.67 65.5052C1109.4 64.9572 1106.34 0 1106.34 0H1056.92H1007.5H-15.5H-58.3521H-101.204C-101.204 0 -137.587 94.3825 -100.735 142.275C-68.3801 184.319 -37.5293 125.619 0.665161 124.429C35.1096 123.356 40.9488 170.263 66.0183 163.758C91.0878 157.259 105.347 90.6388 141.204 90.6388C177.06 90.6388 170.202 138.11 211.065 130.072C247.118 122.977 249.363 40.9051 297.367 40.9051C345.37 40.9051 334.381 116.171 402.627 116.171C470.874 116.171 474.346 36.9352 520.614 36.9352C559.919 36.9352 560.408 83.6873 593 84.281C607.886 84.5521 620.526 74.4893 635.5 75.3761C667.895 77.2948 670.685 125.029 709.161 126.003C754.85 127.161 768.733 30.5811 815.579 31.7385C862.425 32.8959 872.258 88.4108 897.13 88.4108C922.003 88.4108 907.085 30.3056 935.313 36.9295Z',
		],
	},
	mobile: {
		width: 1000,
		height: 377,
		viewBox: '0 0 1000 377',
		variants: [
			'M-49 175C47 141 50.5 203.24 123 212C174 218.162 226.511 103.126 333.5 143.5C435.179 181.86 425.619 285.025 543.5 302C668.5 320 724.5 257 807.649 237.543C934.127 207.946 927.5 326.5 1018 348.5C1108.5 370.5 1115.87 313 1237 313C1388.5 313 1296 0 1296 0H1099.5H1000H9.15527e-05H-84.4999H-217.5C-217.5 0 -312.862 245.619 -255 288C-141 371.5 -145 209 -49 175Z',
			'M792.481 49.3869C672.5 0 662.5 424 488.5 354.5C424.939 329.112 433 228 368.212 260.588C332.994 278.302 314.68 319.922 277 331.602C181.523 361.194 175.5 144.5 62.5833 199.201C-7.66347 233.231 13.9999 327.15 -71.0001 331.602C-156 336.054 -130 189.601 -217 175C-318.188 158.018 -195 0 -195 0H-89H0.0228271H1000H1096.5H1261C1261 0 1386.5 218.343 1308 250.5C1229.5 282.657 1162 112 1106.5 112C1021 112 979 240.5 886.5 186.5C839.497 159.06 840.425 69.1219 792.481 49.3869Z',
			'M-10.0001 172C69.4998 172 60.6384 376.85 187.661 376.85C259.659 376.85 257.067 264.44 323.657 264.44C390.247 264.44 401.911 331.105 462.32 331.105C522.726 331.105 543.622 77.1711 668.183 77.1711C792.742 77.1711 774.25 296.693 858.103 296.693C941.954 296.693 941.002 183.373 1015.5 172C1090 160.627 1163.11 265.448 1220.5 235.5C1295 196.627 1175.5 0 1175.5 0H1077.5H1001H0.999985H-73.5H-154C-154 0 -244.564 178.937 -171 222C-98.5 264.44 -89.5 172 -10.0001 172Z',
			'M0.000427246 0H-82.9995H-230.5C-230.5 0 -406.738 196.685 -298 216C-222 229.5 -234 149.5 -148 155.5C-54.3496 162.034 -141.003 314.651 0.000427246 309.144C141.004 303.638 102.588 53.5476 247.728 58.1905C402.255 63.1809 391.94 369.267 552.451 376.743C646.725 381.129 643.606 230.413 700.916 180.427C766.893 122.753 766.117 259.084 817.687 258.399C863.886 257.792 852.571 180.799 895.578 173.574C967.015 161.545 932.004 298.495 1000 313.997C1068 329.5 1051.52 252.559 1112 231.5C1168 212 1209 264.899 1283.5 238C1365.51 208.389 1237.5 0 1237.5 0H1097.5H1000H0.000427246Z',
			'M716.534 214.315C615.218 206.612 487.426 106.938 389 139.5C273.635 177.665 289.016 338.995 174.747 366.262C14.5 404.5 28.1484 88.2736 -96.5001 50.5C-162.4 30.5297 -187 263.5 -315 252.5C-394.549 245.664 -402 0 -402 0H-127H0.000152588H1000H1077.5H1371.5C1371.5 0 1433.81 136.397 1331.5 159C1245.5 178 1267.5 271.938 1164 277C1032.5 283.432 1061.5 50.5652 973.499 87.5C868.055 131.757 862.315 225.368 716.534 214.315Z',
			'M718.161 300.691C615.091 268.622 535.91 381.501 431.68 365.731C291.472 344.527 156.507 114.142 -0.000213623 189.038C-56.5003 216.075 -86.9293 285.22 -155.5 300.691C-230 317.5 -239.5 121 -337 109C-391.111 102.34 -443.5 0 -443.5 0H-114H-0.000213623H1000H1137H1398.5C1398.5 0 1436.5 206.5 1340 251C1261.51 287.193 1212.5 99.8657 1124 135C1046 165.966 1066.5 270.5 1000 339C924.101 417.181 801.103 326.516 718.161 300.691Z',
			'M63 348.801C118.273 318.113 94.4959 235.597 158.976 223.418C230.264 210.036 245.381 322.306 310.385 329.228C367.983 335.333 396.328 265.297 448.541 254.483C549.965 233.542 582.566 392.384 686.854 374.533C818.5 352 785 132.391 931 180C1028.11 211.666 976 356.5 1088 356.5C1200 356.5 1206 177 1346 161.5C1450.01 149.984 1434 0 1434 0H1135.5H1000.5H0.500153H-132H-437C-437 0 -434.218 306.304 -358 329.228C-257.5 359.456 -177.724 243 -98.5 243C11 243 7.72701 379.489 63 348.801Z',
		],
	},
};

const StyledSVG = styled.svg`
	display: block;
	position: relative;
`;

let waveIdCounter = 0;

const shuffledMobileWavePaths = getShuffledArray(waves.mobile.variants);
const shuffledDesktopWavePaths = getShuffledArray(waves.desktop.variants);

function getShuffledArray(array: string[]) {
	const shuffledArray = [...array];

	for (let i = shuffledArray.length - 1; i > 0; i--) {
		const r = Math.floor(Math.random() * (i + 1));
		const currentItem = shuffledArray[i];
		const randomItem = shuffledArray[r];
		shuffledArray[i] = randomItem;
		shuffledArray[r] = currentItem;
	}

	return shuffledArray;
}

export const MorphingWavePath = React.forwardRef(
	(
		props: {
			looping?: boolean | number;
		} & Omit<React.SVGProps<SVGPathElement>, 'd'>,
		ref
	) => {
		const isMobile = useIsMobile();
		const morphTweenRef = React.useRef<gsap.core.Timeline>();
		const scrubTweenRef = React.useRef<gsap.core.Tween>();

		const path = React.useMemo(() => {
			const waves = isMobile ? shuffledMobileWavePaths : shuffledDesktopWavePaths;
			waveIdCounter = waveIdCounter + 1;
			return waves[waveIdCounter % waves.length];
		}, [isMobile]);

		const refFunction = (element: SVGPathElement) => {
			if (ref) {
				if (typeof ref === 'function') {
					ref(element);
				} else {
					ref.current = element;
				}
			}

			if (element !== null && element !== undefined) {
				morphTweenRef.current = gsap.timeline({ paused: true });

				element.setAttribute('d', path);

				morphTweenRef.current.to(element, {
					morphSVG: {
						// Morph to copy of the same path but with each point index shifted by 2
						shape: path,
						shapeIndex: 2,
						// Use rotational transform around a point that is moved down by 400%
						type: 'rotational',
						origin: '50% 400%',
					},
					ease: 'linear',
				});

				let opts;
				if (props.looping) {
					opts = {
						repeat: -1,
						duration: typeof props.looping === 'number' ? props.looping : 1,
						progress: 1,
					};
				} else {
					opts = {
						progress: 0.5,
						scrollTrigger: {
							trigger: element.parentElement,
							scrub: 1, // Add "lag" to animation
						},
					};
				}

				// Use a second tween to scrub between two defined positions in the morph tween
				scrubTweenRef.current = gsap.fromTo(
					morphTweenRef.current,
					{ progress: 0 },
					{
						...opts,
						ease: 'linear',
					}
				);
			} else {
				morphTweenRef.current?.kill();
				scrubTweenRef.current?.kill();
			}
		};

		return <path key={path} ref={refFunction} transform-origin='50% 50%' {...props} />;
	}
);

export default function Breaker({ number, className, flip }: { number?: number; className?: string; flip?: boolean }) {
	const isMobile = useIsMobile();

	return (
		<StyledSVG viewBox={isMobile ? waves.mobile.viewBox : waves.desktop.viewBox} className={className}>
			<MorphingWavePath transform={!flip ? 'scale(1, -1)' : ''} />
		</StyledSVG>
	);
}
