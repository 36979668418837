import DraggableCarousel from '@client/core/modules/Carousel/DraggableCarousel';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DraggableScrollContainer from '../DraggableScrollContainer';
import { Breakpoints, Fonts, presetColors } from '@client/style/Variables';
import { DateUtils } from '@client/core/utils/DateUtils';
import SpilleplanDayEntry from './SpilleplanDayEntry';
import Site from '@client/store/Site';
import { gsap } from 'gsap';
import Functions from '@client/style/Functions';
import { ScrollTrigger } from 'gsap/all';

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const StyledDraggableCarousel = styled(DraggableCarousel)`
	padding-top: 100px;
	padding-left: var(--gridMargin);
	padding-bottom: 40px;

	color: ${presetColors.blackish};
	width: 100%;

	z-index: 1;
`;

const StyledCalendarDraggableCarousel = styled(DraggableScrollContainer)`
	z-index: 0;
`;

const DateHolder = styled.div`
	font-family: ${Fonts.GeomanistBold};

	opacity: 0.1;
	&.active {
		opacity: 1;
	}
`;

const DateName = styled.p`
	font-size: 48px;
	line-height: 1.1;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 62px;
	}
`;

const DateNumber = styled.p`
	font-size: 22px;
	line-height: 1.1;
`;

interface IProps {
	artists: any[];
	onDayChange: (color: string) => void;
	filter: [{ name: string; id: string }];
}

export default function SpilleplanComponent(props: IProps) {
	const global = Site.use(state => state.global) as any;

	const DayColors = [
		presetColors.sunkiss,
		presetColors.solgult1,
		presetColors.sunkiss,
		presetColors.nude,
		presetColors.himmelblue,
		presetColors.seablue,
		presetColors.kissMeRed,
		presetColors.weirdgreen,
	];

	const [currentTime, setCurrentTime] = useState(() => {
		const temp = new Date();
		return temp;
	});

	const [dateRange, setDateRange] = useState<Date[] | undefined>(undefined);
	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {
		gsap.registerPlugin(ScrollTrigger);

		const calendar = document.getElementsByClassName('calendarCarousel')[0];
		if (!calendar) return;

		const st = ScrollTrigger.create({
			pin: '.dayCarousel',
			pinType: 'fixed',
			start: '65px top',
			end: () => `${calendar.clientHeight + calendar.getBoundingClientRect().top} bottom`,
			pinSpacing: false,
		});

		return () => {
			st && st.kill();
		};
	}, []);

	useEffect(() => {
		const color = DayColors[activeIndex];
		props.onDayChange(color);
		gsap.to('.dayCarousel', { backgroundColor: color, duration: 0.5 });
	}, [activeIndex]);

	const allowIndexChange = useRef(false);

	useEffect(() => {
		const interval = setInterval(() => {
			const temp = new Date();
			setCurrentTime(temp);
		}, 30000);

		setTimeout(() => {
			allowIndexChange.current = true;
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const changeActiveIndex = (index: number) => {
		allowIndexChange.current && setActiveIndex(index);
	};

	/**
	 * Artist filtering
	 */
	const [program, setProgram] = useState<any[] | undefined>(undefined);

	useEffect(() => {
		const filteredArtists = filterArtists(props.artists);
		const sortedArtists = sortIntoDays(filteredArtists);

		setProgram(sortedArtists);

		const startDate: Date = DateUtils.getLowestArtistDate(filteredArtists);
		const endDate: Date = DateUtils.getHighestArtistDate(filteredArtists);
		let dateRange: Date[] = DateUtils.getDaysListFromTo(
			DateUtils.normalizeTime(startDate),
			DateUtils.normalizeTime(endDate)
		);

		// console.log('range');
		// console.log('startDate : ' + startDate);
		// console.log('endDate : ' + endDate);
		// console.dir(dateRange);

		// const lastXDays = 8;
		// dateRange = dateRange.splice(dateRange.length - lastXDays, lastXDays);

		setDateRange(dateRange);

		setActiveIndex(getActiveIndex(dateRange));
	}, []);

	const getActiveIndex = (dates: Date[]): number => {
		const temp = new Date();

		for (let index = 0; index < dates.length; index++) {
			if (temp.getDate() === dates[index].getDate()) return index;
		}

		return 0;
	};

	const sortIntoDays = (artists: any[]) => {
		const eventsByDay: Record<string, any[]> = {};

		for (const artist of artists) {
			const adjustedStartTime = DateUtils.normalizeTime(new Date(artist.startTime));

			// Adjusting the artist dates, to show the day before if playing past midnight
			adjustedStartTime.setHours(adjustedStartTime.getHours() - 6);

			const day = adjustedStartTime.toISOString().substr(0, 10);

			// console.log(artist.previewText + ' ' + day);

			(eventsByDay[day] ||= []).push(artist);
		}

		const sortedArtists = Object.entries(eventsByDay)
			.sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
			.map(([date, events]) => ({ date, events }));

		// console.log('sorted');
		// console.dir(sortedArtists);

		return sortedArtists;
	};

	const filterArtists = (artists: any[]): any[] => {
		const newArtists: any[] = [];
		for (const artist of artists) {
			if (!artist.location) {
				continue;
			}

			if (!isInFilter(artist, props.filter)) continue;

			newArtists.push(artist);
		}

		// console.log('filtered out: ' + (artists.length - newArtists.length));

		return newArtists;
	};

	const isInFilter = (artist: any, filter: [{ name: string; id: string }] | undefined) => {
		if (!filter?.length) return true;

		for (const contentType of filter) {
			if (contentType.id === artist.contentType?.id) return true;
		}

		return false;
	};

	return (
		<Container>
			{dateRange && (
				<StyledDraggableCarousel className='dayCarousel' activeIndex={activeIndex} dragToNewIndex={changeActiveIndex}>
					{dateRange.map((date, index) => {
						return (
							<DateHolder key={'dragDay_' + index} className={index === activeIndex ? 'active' : ''}>
								<DateName>{DateUtils.getWeekDayName(date)}</DateName>
								<DateNumber>{DateUtils.getDanishDateMonthYearString(date, false)}</DateNumber>
							</DateHolder>
						);
					})}
				</StyledDraggableCarousel>
			)}

			{program && dateRange && (
				<StyledCalendarDraggableCarousel
					className='calendarCarousel'
					activeIndex={activeIndex}
					dragToNewIndex={changeActiveIndex}
				>
					{program.map((day, i) => (
						<SpilleplanDayEntry
							dateIndicator={currentTime}
							bgColor={DayColors[activeIndex]}
							key={'spilleplanEntry_' + i + '_' + day.date.toString()}
							events={day.events}
							specialOptions={{ lastDate: i === dateRange.length - 1, pointerIllustration: i < 3 }}
						/>
					))}
				</StyledCalendarDraggableCarousel>
			)}
		</Container>
	);
}
