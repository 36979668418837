import { IAzureVerifier } from './IAzureAuthConfig';

export class AzureAuthHelper {
	public static extractCodeFromLocation(): string | undefined {
		let hash = window.location.search;

		let params = new URLSearchParams(hash.substr(1));
		const code = params.get('code');

		if (!code) return undefined;

		return code;
	}

	public static extractIdTokenFromLocation(): string | undefined {
		let hash = window.location.hash;

		let params = new URLSearchParams(hash.substr(1));
		const idToken = params.get('id_token');

		if (!idToken) return undefined;

		return idToken;
	}

	public static async getCodeVerifier(): Promise<IAzureVerifier> {
		// This function is similar to crypto.randomBytes but uses the Web Crypto API
		// function generateRandomBytes(length: number): Promise<Uint8Array> {
		// 	return window.crypto.subtle.getRandomValues(new Uint8Array(length));
		// }

		function generateSomewhatRandomBytes(length: number): Promise<Uint8Array> {
			const array = new Uint8Array(length);
			for (let i = 0; i < length; i++) {
				array[i] = Math.floor(Math.random() * 256); // Generate values between 0 and 255
			}
			// Combine with browser-specific entropy sources if available
			if (window.crypto && window.crypto.getRandomValues) {
				window.crypto.getRandomValues(new Uint8Array(10)); // Add 10 random bytes for additional entropy
			}
			return Promise.resolve(array);
		}

		async function generateCodeVerifierAndChallenge(): Promise<{ codeVerifier: string; codeChallenge: string }> {
			// Generate a code_verifier with random bytes
			const codeVerifierBytes = await generateSomewhatRandomBytes(32);
			const codeVerifier = btoa(String.fromCharCode(...codeVerifierBytes)) // Use btoa for base64 encoding
				.replace(/\+/g, '-')
				.replace(/\//g, '_')
				.replace(/=/g, '');

			// Create a SHA-256 hash of the code_verifier
			const hashBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));

			// Convert the hash to a Base64-URL-encoded string
			const codeChallenge = btoa(String.fromCharCode(...new Uint8Array(hashBuffer))) // Use btoa for base64 encoding
				.replace(/\+/g, '-')
				.replace(/\//g, '_')
				.replace(/=/g, '');

			return { codeVerifier, codeChallenge };
		}

		const data: IAzureVerifier = await generateCodeVerifierAndChallenge();
		return data;
	}
}
