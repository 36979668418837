import React, { useEffect, useState } from 'react';
import ContentPage from './pages/ContentPage';
import LandingPage from './pages/LandingPage';
import SiteLoader from '@modules/SiteLoader';
import PageSwitch from '@core/PageSwitch/PageSwitch';
import Page from '@core/PageSwitch/Page';
import CustomEvents, { Events } from '@core/CustomEvents';
import ErrorPage from './pages/ErrorPage';
import { SiteContent } from '@style/Shared';
import Linkout from './modules/Linkout/Linkout';
import StepByStepPage from './pages/StepByStepPage';
import ListPage from './pages/ListPage';
import ArtistPage from './pages/ArtistPage';
import MapPage from './pages/MapPage';
import NewsPage from './pages/NewsPage';
import SpilleplanPage from './pages/SpilleplanPage';
import AuthPage from './pages/AuthPage';
import MyPage from './pages/MyPage';

export default function RouterSwitch({ data }: { data?: any }) {
	const [content, setContent] = useState<any>(data ? data : {});

	const newPage = async (e: CustomEvent) => {
		setContent(e.detail.content);
	};

	useEffect(() => {
		CustomEvents.listen(Events.NEWPAGE, newPage);

		return function () {
			CustomEvents.remove(Events.NEWPAGE, newPage);
		};
	}, []);

	return (
		<>
			<SiteContent id={'siteContent'}>
				<PageSwitch content={content} pageType={content ? content.modelId : 'not_found'} fallback={ErrorPage}>
					<Page type={'contentPage'} component={ContentPage} />
					<Page type={'newsPage'} component={NewsPage} />
					<Page type={'landingPage'} component={LandingPage} />
					<Page type={'stepByStepPage'} component={StepByStepPage} />
					<Page type={'artistPage'} component={ArtistPage} />
					<Page type={'collectionPage'} component={ListPage} />
					<Page type={'mapPage'} component={MapPage} />
					<Page type={'spilleplanPage'} component={SpilleplanPage} />
					<Page type={'authPage'} component={AuthPage} />
					<Page type={'myPage'} component={MyPage} />
					{/* todo rename so it matches the CMS naming */}
					<Page type={'collectionPage'} component={ListPage} /> 
					<Page type={'mapPage'} component={MapPage} />
					<Page type={'spilleplanPage'} component={SpilleplanPage} />
					{/* <Page type={'aboutPage'} component={AboutPage} /> */}
				</PageSwitch>
			</SiteContent>
			<Linkout />
			<SiteLoader />
			{/* <Transition /> */}
			{/* <Preloader /> */}
		</>
	);
}
