import Functions from '@client/style/Functions';
import { Fonts } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';

const IndexNav = styled.div<{ color?: string }>`
	font-size: 28px;
	font-family: ${Fonts.GeomanistBold};
	padding-top: 80px;
	text-align: right;
	display: flex;
	align-items: center;
	line-height: initial;
	justify-content: flex-end;
	color: ${props => props.color};
	z-index: 1;
`;

const SVGIcon = styled.div`
	display: flex;
	align-items: center;

	svg {
		margin-left: 20px;
	}
`;

const Clickable = styled.button<{ color?: string }>`
	display: flex;
	align-items: center;
	color: ${props => props.color};
`;

export default function IndexMenuButton({ onClick, bgColor }: { onClick: () => void; bgColor: any }) {
	return (
		<IndexNav color={Functions.hexFromRGB(bgColor?.contrastColor)}>
			<Clickable
				color={Functions.hexFromRGB(bgColor?.contrastColor)}
				onClick={() => {
					onClick();
				}}
			>
				Oversigt
				<SVGIcon>
					<svg width='30' height='25' viewBox='0 0 30 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<rect x='9.33301' y='0.628906' width='20' height='4' fill={Functions.hexFromRGB(bgColor?.contrastColor)} />
						<rect
							x='0.333008'
							y='0.628906'
							width='4.3'
							height='4'
							fill={Functions.hexFromRGB(bgColor?.contrastColor)}
						/>
						<rect x='9.33301' y='20.6289' width='20' height='4' fill={Functions.hexFromRGB(bgColor?.contrastColor)} />
						<rect x='0.333008' y='20.6289' width='4.3' height='4' fill={Functions.hexFromRGB(bgColor?.contrastColor)} />
						<rect x='9.33301' y='10.6289' width='20' height='4' fill={Functions.hexFromRGB(bgColor?.contrastColor)} />
						<rect x='0.333008' y='10.6289' width='4.3' height='4' fill={Functions.hexFromRGB(bgColor?.contrastColor)} />
					</svg>
				</SVGIcon>
			</Clickable>
		</IndexNav>
	);
}
