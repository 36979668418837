import React from 'react';
import styled from 'styled-components';
import { Breakpoints, Colors, Fonts } from '@client/style/Variables';
import Functions from '@client/style/Functions';

export const Button = styled.button<{ backgroundcolor: string; textcolor: string; bordercolor?: string }>`
	display: flex;
	justify-content: center;

	pointer-events: auto;
	width: auto;
	border-radius: 25px;
	background-color: ${props => props.backgroundcolor || Colors.darkBackground};
	color: ${props => props.textcolor || Colors.lightText};
	border: 1px solid ${props => (props.bordercolor ? props.bordercolor : props.backgroundcolor)};
	position: relative;
	padding: 12px 24px;
	min-height: 42px;
	font-family: ${Fonts.Geomanist};
	text-decoration: Capitalize;
	font-size: 15px;
	cursor: pointer;

	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);

	&:hover {
		transform: scale(1.1, 1.1);
	}

	&:active {
		transform: scale(0.95, 0.95);
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 21px;
	}

	:disabled {
		opacity: 0.5;
		pointer-events: none;
	}
`;

interface IProps {
	title: string;
	textColor: string;
	backgroundColor: string;
	borderColor?: string;
	onClick?: () => void;
	disabled?: boolean;
}

export const DefaultButton = (props: IProps) => {
	return (
		<Button
			disabled={props.disabled}
			backgroundcolor={props.backgroundColor}
			textcolor={props.textColor}
			bordercolor={props.borderColor}
			onClick={props.onClick}
		>
			{props.title}
		</Button>
	);
};
