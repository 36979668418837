import gsap from 'gsap/all';
import React from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';

const StyledAnimationContainer = styled(AnimationContainer)`
	img {
		max-width: 100%;
		height: auto;
	}
`;

const Hotdog = () => {
	const imageRef = React.useRef<HTMLImageElement>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1679307294-hotdog.png', {
		width: 815,
		height: 715,
	});
	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const tween = gsap.fromTo(
			imageRef.current,
			{
				scale: 0,
			},
			{
				scale: 1,
				duration: 0.4,
				ease: 'elastic.out(1.5, 0.7)',
			}
		);
		containerTimeline.add(tween, 0);
		return () => {
			tween.kill();
		};
	}, []);

	return <AnimationImageElement image={image} ref={imageRef} />;
};

export function IllustrationHotdog() {
	return (
		<StyledAnimationContainer width={815} height={715}>
			<Hotdog />
		</StyledAnimationContainer>
	);
}
