import gsap from 'gsap/all';
import React from 'react';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';

const Hearts = () => {
	const heart1Ref = React.useRef<HTMLImageElement>(null);
	const heart2Ref = React.useRef<HTMLImageElement>(null);
	const heart3Ref = React.useRef<HTMLImageElement>(null);

	const heart1 = new AnimationImageAsset('https://www.datocms-assets.com/80292/1679307288-hjerte-1.png', {
		width: 97,
		height: 151,
	});
	const heart2 = new AnimationImageAsset('https://www.datocms-assets.com/80292/1679307291-hjerte-2.png', {
		width: 105,
		height: 124,
	});

	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const tween = gsap.fromTo(
			[heart2Ref.current, heart1Ref.current, heart3Ref.current],
			{
				scale: 0,
				y: '100%',
				x: i => ['0%', '-50%', '-50%'][i],
				rotate: i => [0, -45, -45][i],
			},
			{
				scale: 1,
				rotate: 0,
				y: '0%',
				x: '0%',
				duration: 0.8,
				stagger: 0.15,
				ease: 'elastic.out(1, 0.4)',
			}
		);

		containerTimeline.add(tween, 0);
	}, []);

	return (
		<>
			<AnimationPositionElement
				position={{
					anchor: 'center',
					x: '-160%',
					y: '50%',
					flip: true,
				}}
			>
				<AnimationImageElement image={heart1} ref={heart1Ref} />
			</AnimationPositionElement>

			<AnimationPositionElement
				position={{
					anchor: 'center',
					x: '-65%',
					scale: 0.95,
				}}
			>
				<AnimationImageElement image={heart1} ref={heart2Ref} />
			</AnimationPositionElement>

			<AnimationPositionElement
				position={{
					anchor: 'center',
					x: '55%',
					y: '45%',
				}}
			>
				<AnimationImageElement image={heart2} ref={heart3Ref} />
			</AnimationPositionElement>
		</>
	);
};

export function IllustrationLove() {
	return (
		<AnimationContainer width={350} height={300}>
			<Hearts />
		</AnimationContainer>
	);
}
