import gsap from 'gsap/all';
import React from 'react';
import styled from 'styled-components';
import {
	AnimationElementPosition,
	AnimationPositionElement,
	useAnimationContainerTimeline,
	WaveEase,
} from './AnimationCommon';

const StyledRainbowWrapper = styled.div`
	width: 1136px;
	height: calc(123px * 2);
	img {
		display: block;
		height: 100%;
		width: auto;
	}
`;

export const AnimationElementRainbow = (props: { position?: AnimationElementPosition }) => {
	const ref = React.useRef<HTMLImageElement>(null);
	const wrapperRef = React.useRef<HTMLDivElement>(null);
	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const timeline = gsap.timeline();

		containerTimeline.add(timeline, 0);

		timeline.fromTo(
			ref.current,
			{
				x: '-25%',
				scale: 1.45,
				rotate: 10,
			},
			{
				x: '-50%',
				scale: 1,
				rotate: 0,
				duration: 2,
				ease: 'power3.out',
			}
		);

		timeline.fromTo(
			ref.current,
			{
				x: '0%',
			},
			{
				x: '-50%',
				ease: WaveEase(0.7),
				duration: 20,
				repeat: -1,
				scrollTrigger: {
					trigger: ref.current,
					toggleActions: 'play pause play pause',
				},
			}
		);

		return () => {
			timeline.kill();
		};
	}, []);

	return (
		<AnimationPositionElement position={props.position}>
			<StyledRainbowWrapper ref={wrapperRef}>
				<img ref={ref} src='https://www.datocms-assets.com/80292/1678201235-rainbow.svg' />
			</StyledRainbowWrapper>
		</AnimationPositionElement>
	);
};
