import React from 'react';
import styled from 'styled-components';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import WaveContainer from './Dividers.tsx/WaveContainer';
import MagicModuleSwitcher from './MagicModuleSwitcher';

const StyledMediaWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100vh;

	display: flex;
	flex-direction: column;
	align-items: center;

	${Functions.breakpoint(Breakpoints.tablet)} {
		height: auto;
		aspect-ratio: 16/9;
	}
`;

const StyledContainer = styled.div`
	position: relative;
	width: 100%;
`;

const StyledBackground = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	height: 50%;
`;

export default function FullBleedMediaModule(props: {
	data: {
		media: [any];
	};
}) {
	const moduleRef = React.useRef<HTMLDivElement>(null);
	const prevColorBackground = React.useRef<HTMLDivElement>(null);
	const nextColorBackground = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const FindModuleElementColor = (element: Element) => {
			const colorElementClassName = 'colorWrapperAnimatedModule';
			let colorElement: Element | null;

			if (element.classList.contains(colorElementClassName)) {
				colorElement = element;
			} else {
				colorElement = element.querySelector(`div[class*="${colorElementClassName}"]`);
			}

			return colorElement ? window.getComputedStyle(colorElement).backgroundColor : undefined;
		};

		if (moduleRef.current) {
			const prevSibling = moduleRef.current.previousElementSibling;
			const nextSibling = moduleRef.current.nextElementSibling;

			const prevColor = prevSibling ? FindModuleElementColor(prevSibling) : undefined;
			const nextColor = nextSibling ? FindModuleElementColor(nextSibling) : undefined;

			if (prevColor && prevColorBackground.current) {
				prevColorBackground.current.style.backgroundColor = prevColor;
			}
			if (nextColor && nextColorBackground.current) {
				nextColorBackground.current.style.backgroundColor = nextColor;
			}
		}
	}, []);

	return (
		<StyledContainer ref={moduleRef}>
			<StyledBackground ref={prevColorBackground} style={{ top: 0 }} />
			<StyledBackground ref={nextColorBackground} style={{ bottom: 0 }} />
			<WaveContainer side='both'>
				<StyledMediaWrapper>
					<MagicModuleSwitcher modules={props.data.media} />
				</StyledMediaWrapper>
			</WaveContainer>
		</StyledContainer>
	);
}
