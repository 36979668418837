import gsap from 'gsap/all';
import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
	display: block;
	position: absolute;
	pointer-events: none;
	width: max(100vw, 100vh);
	height: max(100vw, 100vh);
	top: 0;
	right: 0;
	overflow: visible;
	path {
		transform-origin: top right;
	}
`;

export const Splash = (props: { animate: boolean; color?: string }) => {
	const pathRef = React.useRef<SVGPathElement>(null);

	React.useEffect(() => {
		if (!props.animate) {
			return;
		}
		const timeline = gsap.timeline();

		if (!pathRef.current) return;

		const duration = 0.8;
		const repeats = 1;

		// wobble
		timeline.fromTo(
			pathRef.current,
			{
				morphSVG: {
					shape: path,
					type: 'rotational',
				},
			},
			{
				morphSVG: {
					shape: path,
					type: 'rotational',
					shapeIndex: 2,
				},
				duration: duration / (repeats + 1),
				ease: 'linear',
				repeat: repeats,
				reversed: true,
			},
			0
		);

		// Move
		timeline.fromTo(
			pathRef.current,
			{
				x: '10%',
				y: '-15%',
			},
			{
				x: '0%',
				y: '0%',
				duration: duration,
				ease: 'power2.in',
			},
			0
		);

		// Scale and rotate
		timeline.fromTo(
			pathRef.current,
			{
				scale: 0.3,
				rotate: 15,
			},
			{
				scale: 1,
				rotate: 0,
				duration: duration,
				ease: 'linear',
			},
			0
		);
	}, [props.animate]);

	const path =
		'M-411.001 239C-387.56 149.984 -599.066 69.1595 -559.501 -12C-520.501 -92 -360.002 22 -309.502 -38C-268.26 -87 -208.002 0 -208.002 0L894.5 1.90735e-05L1006 -56H1080.5V22C1080.5 22 1326 205.5 1390.5 365C1456.96 529.353 1320.5 721.5 1297 924C1283.72 1038.4 1478 1136 1439 1278.5C1411.04 1380.67 1312.5 1350.5 1236.5 1416.5C1130.23 1508.78 1178.5 1619 1063 1642C885.897 1677.27 919.5 1412 841 1453.5C774.796 1488.5 865.492 1621.71 791.5 1665.5C742.5 1694.5 694.5 1616 632 1618.5C557.553 1621.48 480.5 1696 453.5 1633.5C429.081 1576.98 581.5 1455.5 533 1387.5C508.959 1353.79 440.15 1461.63 383 1438.5C320 1413 363.308 1357.64 300 1321C229.123 1279.97 172.21 1531.69 -44.5 1418.5C-123 1377.5 -88.4669 1251.64 -114 1210.5C-177 1109 -283.893 1243.67 -305 1149.5C-324.5 1062.5 -72.0001 995 -116 916C-166.277 825.731 -296.641 937.914 -311 855C-326.5 765.5 -223 699 -297 645.5C-380.475 585.15 -550.485 756.326 -576.5 651.5C-593.5 583 -462 546 -492 472C-529.953 378.381 -765.5 411.5 -722.5 318.5C-687.699 243.231 -450.501 389 -411.001 239Z';

	if (props.animate) {
		return (
			<StyledSvg width='1000' height='1000' viewBox='0 0 1000 1000' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path ref={pathRef} d={path} fill={props.color} />
			</StyledSvg>
		);
	} else {
		return null;
	}
};
