import React, { useState } from 'react';
import styled from 'styled-components';
import FormInput from './FormInput';
import { CommonButton } from '@client/style/Shared';
import { FormStoreClass, IEncodedFormData, IFormField } from './FormStore';
import FormSelect from './FormSelect';
import FormTextArea from './FormTextArea';
import { CommonText } from '@client/common/CommonText';
import { Colors } from '@client/style/Variables';
import Loader from '@client/core/Loader';
import FormPreloader from './FormPreloader';

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: 20px;
	margin-top: 50px;
	position: relative;
`;

const StyledButton = styled(CommonButton)`
	margin-top: 30px;
	display: inline-flex;
`;

const Errors = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	position: relative;
`;

const Error = styled(CommonText)`
	color: ${Colors.error};
	text-align: left;
`;

export interface IProps {
	store: FormStoreClass;
	preloaderColors: {
		background: string;
		foreground: string;
	};
	loadText: string;
	sentText: string;
}

export default function FormComponent(props: IProps) {
	const fields = props.store.use(state => state.fields);

	const [errors, setErrors] = useState<string[] | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	const [doneSending, setDoneSending] = useState<boolean>(false);
	const [submitPressed, setSubmitPressed] = useState<boolean>(false);

	const handleSubmit = async () => {
		// console.log('');
		// console.dir(fields);
		// console.dir(props.store.getErrors());

		setSubmitPressed(true);

		const errorList = props.store.getErrors();
		setErrors(errorList);

		if (errorList && errorList.length > 0) return;

		// sending form
		const formData: IEncodedFormData = props.store.getEncodedFormData();
		// console.dir(formData);

		setLoading(true);

		let reply;
		try {
			reply = await Loader.get(`/api/submit-form?data=${JSON.stringify(formData)}`);
		} catch (e) {
			const error = JSON.parse(e as string);
			console.dir(error);

			setLoading(false);
			setErrors(['Noget gik galt, prøv igen!']);
			return;
		}

		const json = JSON.parse(reply);

		console.dir(json);
		setLoading(false);

		setDoneSending(true);
	};

	const renderField = (data: IFormField): React.ReactElement | undefined => {
		if (!props.store.fulfillsShowCondition(data.id)) return undefined;

		let isValid = true;

		if (submitPressed) {
			isValid = !props.store.getErrorsForField(data.id)?.length;
		}

		if (data.fieldType === 'input') {
			return (
				<FormInput
					key={data.id}
					data={data}
					valid={isValid}
					onChange={(value: string) => {
						props.store.setValue(data.id, value);
					}}
				/>
			);
		}
		if (data.fieldType === 'select') {
			return (
				<FormSelect
					key={data.id}
					data={data}
					valid={isValid}
					onSelect={(value: string) => {
						props.store.setValue(data.id, value);
					}}
				/>
			);
		}
		if (data.fieldType === 'textarea') {
			return (
				<FormTextArea
					key={data.id}
					data={data}
					valid={isValid}
					onChange={(value: string) => {
						props.store.setValue(data.id, value);
					}}
				/>
			);
		}

		return undefined;
	};

	return (
		<StyledForm>
			{fields.map(data => {
				return renderField(data);
			})}

			<StyledButton onClick={handleSubmit}>Send</StyledButton>

			{errors !== undefined && (
				<Errors>
					{errors.map((error, i) => {
						return <Error key={i}>{error}</Error>;
					})}
				</Errors>
			)}

			{loading && (
				<FormPreloader
					backgroundColor={props.preloaderColors.background}
					foregroundColor={props.preloaderColors.foreground}
					text={props.loadText}
				/>
			)}

			{doneSending && (
				<FormPreloader
					backgroundColor={props.preloaderColors.background}
					foregroundColor={props.preloaderColors.foreground}
					text={props.sentText}
				/>
			)}
		</StyledForm>
	);
}
