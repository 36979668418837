import Functions from '@client/style/Functions';
import { Breakpoints, Colors, Fonts, Index } from '@client/style/Variables';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import MagicButtonSwitcher from '../shared/MagicButtonSwitcher';
import StatisticEntry from './StatisticEntry';

const Container = styled.div<{ backgroundColor?: string; color?: string }>`
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${props => props.backgroundColor};
	width: 100%;
	color: ${props => props.color};
	text-align: center;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;

interface StatisticProps {
	data: {
		statistics: Array<any>;
		backgroundColor: any;
		link: any[];
	};
}

export default function Statistics(props: StatisticProps) {
	return (
		<Container
			className='colorWrapperAnimatedModule'
			backgroundColor={Functions.hexFromRGB(props.data.backgroundColor?.color)}
			color={Functions.hexFromRGB(props.data.backgroundColor?.contrastColor)}
		>
			{props.data.statistics.map((stat, index) => (
				<StatisticEntry key={index} stat={stat} index={index} />
			))}
			{props.data.link && props.data.link.length > 0 && (
				<ButtonContainer>
					<MagicButtonSwitcher button={props.data.link[0]}></MagicButtonSwitcher>
				</ButtonContainer>
			)}
		</Container>
	);
}
