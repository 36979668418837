import React from 'react';
import { Colors } from '@client/style/Variables';
import FavouritesStore, { useIsFavourite } from './FavouriteStore';
import { FavouriteButtonCompact } from './FavoriteButtonCompact';
import { useAuthenticatedUser } from '@client/utils/AuthStore';
import { OverlayController, Overlays } from '@client/core/modules/Overlays/Overlays';
import { OverlayLoginPrompt } from '../overlays/LoginPrompt/OverlayLoginPrompt';

export interface IProps {
	artistId: string;
	size?: number;
}

export const FavouritePanelCompact = (props: IProps) => {
	const isFavourite = useIsFavourite(props.artistId);
	const user = useAuthenticatedUser();

	return (
		<>
			{!isFavourite ? (
				<FavouriteButtonCompact
					size={props.size ?? 30}
					backgroundColor={Colors.darkBackground + '55'}
					starColor={Colors.lightText + '55'}
					onClick={() => {
						if (!user) {
							Overlays.Instance.open({
								create: (controller: OverlayController) => {
									return <OverlayLoginPrompt controller={controller} />;
								},
							});
						}

						FavouritesStore.addFavourite({ id: props.artistId });
					}}
				/>
			) : (
				<FavouriteButtonCompact
					size={props.size ?? 30}
					backgroundColor={Colors.favouriteBackground}
					starColor={Colors.newsBackground}
					onClick={() => {
						FavouritesStore.removeFavourite(props.artistId);
					}}
				/>
			)}
		</>
	);
};
