import Loader from '../Loader';

export async function sendWishes(wish: Wishes): Promise<string> {
	return Loader.post('/musicwishes', JSON.stringify(wish));
}

export interface Wishes {
	name: string | undefined;
	age: number | undefined;
	email: string | undefined;
	artists: SimpleArtist[];
}

export interface SimpleArtist {
	artistName: string;
	type: 'DK' | 'International';
	popularity: number | undefined;
}
