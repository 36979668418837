/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';
import { Observable } from '@client/core/utils/Observable';
import Bugsnag from '@bugsnag/js';
import Site from '@client/store/Site';
import Mode from '@client/core/interface/Mode';

export class CookieTypes {
	public static FUNCTIONAL: string = 'cookie_cat_functional';
	public static STATISTICAL: string = 'cookie_cat_statistic';
	public static MARKETING: string = 'cookie_cat_marketing';
}

export interface ICookieEvent {
	given: boolean;
}

export class CookieInformationHelper {
	private static _instance: CookieInformationHelper | undefined = undefined;

	public functionalEvent: Observable<ICookieEvent> = new Observable<ICookieEvent>();
	public statisticalEvent: Observable<ICookieEvent> = new Observable<ICookieEvent>();
	public marketingEvent: Observable<ICookieEvent> = new Observable<ICookieEvent>();

	public static getInstance(): CookieInformationHelper {
		if (this._instance === undefined) this._instance = new CookieInformationHelper();

		return this._instance;
	}

	public hasConsentFor(cookieType: string): boolean {
		//@ts-ignore
		let consent: boolean | undefined =
			typeof window.CookieInformation !== 'undefined'
				? window.CookieInformation?.getConsentGivenFor(cookieType)
				: undefined;

		if (consent == undefined) {
			consent = Site.get().server.mode === Mode.DEV;
		}

		return consent;
	}
}

export default function CookieInformationMiddleware() {
	useEffect(() => {
		try {
			//@ts-ignore
			if (typeof window.CookieInformation !== 'undefined') {
				//@ts-ignore
				window.CookieInformation.loadConsent();
			} else {
				throw new Error('CookieInformation is undefined');
			}
		} catch (e) {
			console.log('not able to run window.CookieInformation.loadConsent');
			Bugsnag.notify('Failed to load CookieInformation consent');
		}

		const consentGiven = (e: any) => {
			CookieInformationHelper.getInstance().statisticalEvent.trigger({
				given: CookieInformationHelper.getInstance().hasConsentFor(CookieTypes.STATISTICAL),
			});

			CookieInformationHelper.getInstance().functionalEvent.trigger({
				given: CookieInformationHelper.getInstance().hasConsentFor(CookieTypes.FUNCTIONAL),
			});

			CookieInformationHelper.getInstance().marketingEvent.trigger({
				given: CookieInformationHelper.getInstance().hasConsentFor(CookieTypes.MARKETING),
			});

			return false;
		};

		window.addEventListener('CookieInformationConsentGiven', consentGiven);

		return function () {
			window.removeEventListener('CookieInformationConsentGiven', consentGiven);
		};
	}, []);

	return null;
}
