import Functions from '@client/style/Functions';
import { Breakpoints, Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import Image, { ImageData } from '../Image';
import { CommonText, CommonTitle } from '@client/common/CommonText';
import Link from '@client/core/PageSwitch/Link';

const Container = styled.div`
	width: 100%;
`;

const ImagesWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	${Functions.breakpoint(Breakpoints.mobile)} {
		/* width: ${Functions.col(8)}; */
	}
`;

const StyledImage = styled(Image)`
	width: 100%;
	// aspect-ratio: 3/2;

	${Functions.breakpoint(Breakpoints.mobile)} {
		// aspect-ratio: 16/9;
	}
`;

const Title = styled(CommonTitle)<{ color: string }>`
	margin-top: 20px;
	font-size: 35px;
	text-align: left;
	color: ${props => props.color || Colors.darkText};
`;

const Text = styled(CommonText)<{ color: string }>`
	color: ${props => props.color || Colors.darkText};
	text-align: left;
`;

export default function DetailedListEntry({
	image,
	title,
	subtitle,
	link,
	backgroundColorLink,
}: {
	image: ImageData;
	title: string;
	subtitle: string;
	link: any;
	backgroundColorLink: any;
}) {
	return (
		<Container>
			<Link data={link[0]}>
				{image && (
					<ImagesWrapper>
						<StyledImage data={image} />
					</ImagesWrapper>
				)}
				<Title color={backgroundColorLink && Functions.hexFromRGB(backgroundColorLink?.contrastColor)}>{title}</Title>
				<Text color={backgroundColorLink && Functions.hexFromRGB(backgroundColorLink?.contrastColor)}>{subtitle}</Text>
			</Link>
		</Container>
	);
}
