import Carousel, { ImperativeCarousel } from '@client/core/modules/Carousel/Carousel';
import Functions from '@client/style/Functions';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Image, { ImageData } from '../Image';
import SimpleImageCarouselEntry from './SimpleImageCarouselEntry';
import gsap from 'gsap';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import DraggableCarousel from '@client/core/modules/Carousel/DraggableCarousel';
import { Breakpoints } from '@client/style/Variables';
import MagicCarouselSelector from '@client/core/modules/Carousel/MagicCarouselSelector';

const Container = styled.div<{ bgColor: string }>`
	width: 100%;
	background-color: ${props => props.bgColor || 'transparent'};
	padding-top: 40px;
	padding-bottom: 40px;
	position: relative;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 120px;
		/* padding-bottom: 60px; */
	}
`;

interface IProps {
	data: {
		images: { text: string; image: ImageData }[];
		backgroundColorLink: any;
	};
}

export default function SimpleImageCarousel({ data }: IProps) {
	const [activeIndex, setActiveIndex] = useState(0);

	const evaluateIsActive = (index: number): boolean => {
		if (index === activeIndex) return true;
		if (index === activeIndex + 1) return true;

		return false;
	};

	return (
		<Container
			bgColor={data.backgroundColorLink && Functions.hexFromRGB(data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<MagicCarouselSelector onChange={number => setActiveIndex(number)}>
				{data.images.map((image, index) => {
					return (
						<SimpleImageCarouselEntry
							key={index}
							image={image.image}
							text={image.text}
							isActive={evaluateIsActive(index)}
						/>
					);
				})}
			</MagicCarouselSelector>
		</Container>
	);
}
