import React from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';
import { Quad, gsap } from 'gsap';

const StyledAnimationContainer = styled(AnimationContainer)`
	/* position: relative;
	height: 700px;
	*/
	align-self: center;
	justify-self: center;
	width: 100%;
	img {
		max-width: 100%;
		height: auto;
	}
`;

const Armband = () => {
	const imageRef = React.useRef<HTMLImageElement>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670844303-armband-desktop.png', {
		width: 815,
		height: 715,
	});
	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const speed: number = 3;
		const force: number = 20;
		const tween = gsap.fromTo(
			imageRef.current,
			{ y: -force },
			{ y: force, duration: speed, ease: Quad.easeInOut, repeat: -1, yoyo: true }
		);

		containerTimeline.add(tween, 0);
		return () => {
			tween.kill();
		};
	});

	return <AnimationImageElement image={image} ref={imageRef} />;
};

const Sol = () => {
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670844482-glow-desktop.png', {});

	return <AnimationImageElement image={image} />;
};

export function IllustrationArmband() {
	return (
		<StyledAnimationContainer width={815} height={715}>
			<AnimationPositionElement position={{ scale: 1.15, x: '7.7%' }}>
				<Sol />
			</AnimationPositionElement>
			<AnimationPositionElement position={{ x: '13%', scale: 0.77 }}>
				<Armband />
			</AnimationPositionElement>
		</StyledAnimationContainer>
	);
}
