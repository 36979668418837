import React, { useRef, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors } from '@client/style/Variables';
import gsap from 'gsap';

export const Button = styled.button<{ size?: number; bgColor?: string }>`
	width: ${$props => ($props.size ? $props.size + 'px' : '56px')};
	height: ${$props => ($props.size ? $props.size + 'px' : '56px')};
	border-radius: 90%;
	background-color: ${$props => ($props.bgColor ? $props.bgColor : Colors.MainMenuButtonBackground)};
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);

	&:hover {
		transform: scale(1.1, 1.1);
	}

	&:active {
		transform: scale(0.95, 0.95);
	}
`;

const Ring = styled.div<{ size?: number; bgColor?: string }>`
	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);
	position: absolute;
	width: ${$props => ($props.size ? $props.size + 'px' : '56px')};
	height: ${$props => ($props.size ? $props.size + 'px' : '56px')};
	background-color: ${$props => ($props.bgColor ? $props.bgColor : Colors.MainMenuButtonBackground)};
	border-radius: 90%;
	transform-origin: center center;
	box-sizing: border-box;
`;

const InnerRing = styled(Ring)<{ bgColor?: string }>`
	z-index: -1;
	background-color: transparent;
	border: 3px solid ${$props => ($props.bgColor ? $props.bgColor : Colors.MainMenuButtonBackground)};
`;

const OuterRing = styled(Ring)<{ bgColor?: string }>`
	z-index: -1;
	opacity: 0;
	background-color: ${$props => ($props.bgColor ? $props.bgColor : Colors.MainMenuButtonBackground)};
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	interaction: () => void;
	size?: number;
	bgColor?: string;
}

export default function RoundButtonWithAnimation({ interaction, size, bgColor, ...props }: ButtonProps) {
	const irRef = useRef<HTMLDivElement | null>(null);
	const orRef = useRef<HTMLDivElement | null>(null);

	const exeAnimation = () => {
		interaction();
		if (!irRef.current) return;
		gsap.set(irRef.current, {
			opacity: 1,
		});
		gsap.to(irRef.current, {
			duration: 0.3,
			scale: 1.4,
			onComplete: () => {
				if (!irRef.current) return;
				gsap.to(irRef.current, {
					duration: 0.3,
					opacity: 0,
					onComplete: () => {
						if (!irRef.current) return;
						gsap.set(irRef.current, {
							opacity: 0,
							scale: 1,
						});
					},
				});
			},
		});
		if (!orRef.current) return;
		gsap.to(orRef.current, {
			duration: 0.3,
			delay: 0.1,
			scale: 1.4,
			opacity: 1,
			onComplete: () => {
				if (!orRef.current) return;
				gsap.to(orRef.current, {
					duration: 0.3,
					opacity: 0,
					onComplete: () => {
						if (!orRef.current) return;
						gsap.set(orRef.current, {
							scale: 1,
						});
					},
				});
			},
		});
	};

	return (
		<Button size={size} bgColor={bgColor} {...props} onClick={exeAnimation}>
			<InnerRing size={size} bgColor={bgColor} ref={irRef}></InnerRing>
			<OuterRing size={size} bgColor={bgColor} ref={orRef}></OuterRing>
			{props.children}
		</Button>
	);
}
