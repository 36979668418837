import Store from '@client/core/Store';
import { ReactNode } from 'react';

export interface IPageOverlayState {
	content: ReactNode;
	show: boolean;
}

export class PageOverlayStoreClass extends Store<IPageOverlayState> {
	constructor() {
		super({ content: undefined, show: false });
	}

	public setContent(content: ReactNode) {
		console.log('content :', content);
		this.set({ content: content });
	}
}

const PageOverlayStore = new PageOverlayStoreClass();
export default PageOverlayStore;
