import Functions from '@client/style/Functions';
import { presetColors } from '@client/style/Variables';
import React from 'react';
import DownloadButton from './DownloadButton';
import SquaredLinkButton from './SquaredLinkButton';

interface IMagicButtonSwitcherProps {
	button: any;
	size?: 'small' | 'medium';
	backgroundColorLink?: string;
}

export default function MagicButtonSwitcher(props: IMagicButtonSwitcherProps) {
	return (
		<>
			{props.button.modelId === 'downloadButton' ? (
				<DownloadButton
					backgroundColor={props.button.buttonColor}
					url={props.button.item.url}
					label={props.button.label}
					size={props.size}
				></DownloadButton>
			) : props.button.buttonColorLink ? (
				<SquaredLinkButton
					textColor={Functions.hexFromRGB(props.button.buttonColorLink.contrastColor)}
					backgroundColor={Functions.hexFromRGB(props.button.buttonColorLink.color)}
					data={props.button.link[0]}
					size={props.size}
				></SquaredLinkButton>
			) : (
				<SquaredLinkButton
					backgroundColor={Functions.hexFromRGB(props.backgroundColorLink)}
					data={props.button}
					size={props.size}
				></SquaredLinkButton>
			)}
		</>
	);
}
