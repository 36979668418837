export class Debug {
	public static isLocal(): boolean {
		let local: boolean = false;
		try {
			local = window.location.host.startsWith('localhost');
		} catch (e) {
			console.log('isLocal() local failed. Default to false');
		}
		return local;
	}
}
