import Link from '@client/core/PageSwitch/Link';
import ContentPageStore, { IContentPageState } from '@client/store/ContentPageStore';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DraggableContainer from './DraggableContainer';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import SoMeIcon from './SoMeIcon';

const Container = styled.div<{ isCarousel?: boolean }>`
	margin-top: 40px;
	display: flex;
	gap: 10px;

	justify-content: ${props => (props.isCarousel ? 'start' : 'center')};

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 50px;
		gap: 20px;
	}
	position: relative;
	width: 100%;
`;

const LinkContainer = styled.div`
	min-width: 90px;
	width: 90px;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 120px;
		height: 120px;
	}
`;

const StyledLink = styled(Link).withConfig({
	shouldForwardProp: (prop, defaultValidatorFn) => {
		if (prop === 'iconColor') return false;
		if (prop === 'secondaryIconColor') return false;
		return defaultValidatorFn(prop);
	},
})<{ iconColor: string; secondaryIconColor: string }>`
	scale: 0.8;

	${Functions.breakpoint(Breakpoints.mobile)} {
		scale: 1;
	}
	svg > path {
		fill: ${props => props.secondaryIconColor};
	}
	svg > path:first-of-type {
		fill: ${props => props.iconColor};
	}
`;

const DraggableWrapper = styled.div`
	position: relative;
	padding: 0 var(--gridMargin);
	width: 100%;
`;
interface SoMeProp {
	socialMediaChannel: string;
	url: string;
}

export default function SoMeBar({ entries, ...props }: { entries: SoMeProp[] }) {
	const isMobile = useIsMobile();
	const themeColors: IContentPageState = ContentPageStore.use(state => state);

	return (
		<Container className={props.className ? props.className : null} isCarousel={entries.length > 3 && isMobile}>
			{!isMobile ? (
				<>
					{entries.map((entry, index) => {
						return (
							<LinkContainer key={'link_' + index}>
								<StyledLink
									key={index}
									data={{ link: entry.url }}
									iconColor={themeColors.contrastColor}
									secondaryIconColor={themeColors.backgroundColor}
								>
									<SoMeIcon icon={entry.socialMediaChannel} />
								</StyledLink>
							</LinkContainer>
						);
					})}
				</>
			) : entries.length > 3 ? (
				<DraggableWrapper>
					<DraggableContainer>
						{entries.map((entry, index) => {
							return (
								<LinkContainer key={'link_' + index}>
									<StyledLink
										key={index}
										data={{ link: entry.url }}
										iconColor={themeColors.contrastColor}
										secondaryIconColor={themeColors.backgroundColor}
									>
										<SoMeIcon icon={entry.socialMediaChannel} />
									</StyledLink>
								</LinkContainer>
							);
						})}
					</DraggableContainer>
				</DraggableWrapper>
			) : (
				<>
					{entries.map((entry, index) => {
						return (
							<LinkContainer key={'link_' + index}>
								<StyledLink
									key={index}
									data={{ link: entry.url }}
									iconColor={themeColors.contrastColor}
									secondaryIconColor={themeColors.backgroundColor}
								>
									<SoMeIcon icon={entry.socialMediaChannel} />
								</StyledLink>
							</LinkContainer>
						);
					})}
				</>
			)}
		</Container>
	);
}
