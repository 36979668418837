import Functions from '@client/style/Functions';
import React, { ButtonHTMLAttributes } from 'react';
import SquaredDefaultButton, { ButtonSizeType } from './SquaredDefaultButton';

export interface IDownloadButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	backgroundColor?: any;
	url?: string;
	className?: string;
	label?: string;
	size?: ButtonSizeType;
}

export default function DownloadButton(props: IDownloadButtonProps) {
	return (
		<a className={props.className} href={props.url} download target='_blank'>
			<SquaredDefaultButton
				backgroundColor={Functions.hexFromRGB(props.backgroundColor?.color)}
				textColor={Functions.hexFromRGB(props.backgroundColor?.contrastColor)}
				title={props.label}
				size={props.size}
			></SquaredDefaultButton>
		</a>
	);
}
