import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import { Breakpoints, Colors } from '../../style/Variables';
import { CommonTitle } from '@client/common/CommonText';

const Container = styled.div`
	color: ${Colors.darkText};

	margin-top: 80px;

	position: relative;
	text-align: center;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 120px;
	}
`;

const Title = styled(CommonTitle)`
	font-size: 21px;
	color: ${Colors.darkText};
	margin-bottom: 30px;
	line-height: 110%;
	text-transform: uppercase;

	${Functions.breakpoint(Breakpoints.tablet)} {
		font-size: 35px;
		margin-bottom: 63px;
	}
`;

interface ListWithNamesProps extends HTMLAttributes<HTMLDivElement> {
	title: string | undefined;
}

function ListWithName({ title, ...props }: ListWithNamesProps) {
	return (
		<Container>
			<Title>{title}</Title>
			{props.children}
		</Container>
	);
}
export default ListWithName;
