import { Observable } from '@client/core/utils/Observable';
import { ReactChild } from 'react';
import OverlaysStore from './OverlaysStore';

interface IOverlayData {
	create: (controller: OverlayController) => ReactChild;
}

export class OverlayController {
	public closeEvent: Observable<OverlayController> = new Observable<OverlayController>();
	private _data: IOverlayData;
	private _id: string;

	constructor(data: IOverlayData) {
		this._data = data;
		this._id = Overlays.getId();
	}

	public get data(): IOverlayData {
		return this._data;
	}
	public get id(): string {
		return this._id;
	}

	public close = (): void => {
		console.log('OverlayController.close();');

		this.closeEvent.trigger(this);
	};
}

export class Overlays {
	private _queue: OverlayController[] = [];
	private _idToController: Map<string, OverlayController> = new Map();
	private _activeOverlay: OverlayController | undefined;

	private static _idIndex: number = 0;
	public static getId(): string {
		this._idIndex += 1;
		return this._idIndex.toString();
	}

	private static _instance;
	public static get Instance(): Overlays {
		if (!this._instance) this._instance = new Overlays();
		return this._instance;
	}

	constructor() {}

	public open(data: IOverlayData): void {
		const controller: OverlayController = new OverlayController(data);

		this._queue.push(controller);
		this._idToController.set(controller.id, controller);

		this.processQueue();
	}

	private processQueue(): void {
		if (this._queue.length <= 0) {
			// queue is empty
			this.unLockScroll();
			return;
		}

		if (this._activeOverlay) {
			// overlay opened
			return;
		}

		const controller: OverlayController | undefined = this._queue.shift();
		if (!controller) {
			return;
		}

		this._activeOverlay = controller;
		this._activeOverlay.closeEvent.on(this.onOverlayClose);
		// console.log("add overlay: " + this._activeOverlay.id);

		// console.log('Remove scroll');

		this.lockScroll();

		// console.log('this._lockedScrollPos : ' + this._lockedScrollPos);

		OverlaysStore.set({ activeId: this._activeOverlay.id });
	}

	public getControllerById(id: string): OverlayController | undefined {
		return this._idToController.get(id);
	}

	private onOverlayClose = (controller: OverlayController): void => {
		this._idToController.delete(controller.id);
		controller.closeEvent.off(this.onOverlayClose);

		OverlaysStore.set({ activeId: undefined });
		this._activeOverlay = undefined;

		this.processQueue();
	};

	/**
	 * Lock and unlock scroll
	 */
	private _isScrollLocked: boolean = false;
	private _lockedScrollPos: number = 0;

	private lockScroll() {
		if (this._isScrollLocked) return;
		this._isScrollLocked = true;

		this._lockedScrollPos = window.scrollY;

		document.body.style.position = 'fixed';
		document.body.style.width = '100%';
		document.body.style.height = '100%';
		document.body.style.overflowY = 'hidden';

		const siteContent: HTMLDivElement | null = document.querySelector('#siteContent');

		siteContent && (siteContent.style.position = 'absolute');
		siteContent && (siteContent.style.top = -this._lockedScrollPos + 'px');
	}

	private unLockScroll() {
		if (!this._isScrollLocked) return;
		this._isScrollLocked = false;

		document.body.style.position = '';
		document.body.style.width = '';
		document.body.style.height = '';
		document.body.style.overflowY = '';

		const siteContent: HTMLDivElement | null = document.querySelector('#siteContent');

		siteContent && (siteContent.style.position = '');
		siteContent && (siteContent.style.top = '');

		window.scrollTo({ top: this._lockedScrollPos });
	}
}
