export class AzureAuthLog {
	private _prefix:string;

	public enabled: boolean = true;
	constructor(prefix?:string) {
		this._prefix = prefix ?? '';
	}

	public log(value: any, ...optionalParams: Array<any>):void {
		if (!this.enabled || typeof window == 'undefined') return;

		if (optionalParams) {
			console.log(this._prefix + ' ' + value, ...optionalParams);
		} else {
			console.log(this._prefix + ' ' + value);
		}
	}

	public dir(value: any):void {
		if (!this.enabled || typeof window == 'undefined') return;
		console.dir(value);
	}

	public error(value: any): void {
		if (!this.enabled || typeof window == 'undefined') return;
		console.error(this._prefix + ' ' + value);
	}

	public warn(value: any): void {
		if (!this.enabled || typeof window == 'undefined') return;
		console.warn(this._prefix + ' ' + value);
	}
}
