import Store from '@core/Store';
import AuthStore from '../../utils/AuthStore';
import { deepClone } from '@client/utils/DeepClone';
import { IAzureAuthData } from '@client/core/auth/azure/IAzureAuthConfig';
import { context } from '@client/App';

export interface IFavourite {
	id: string;
}

export interface IFavevouriteState {
	favourites: IFavourite[];
}

export const DefaultState = {
	favourites: [],
};

export class FavouriteStoreClass extends Store<IFavevouriteState> {
	constructor() {
		super(DefaultState);

		AuthStore.userSignedIn.on(this.onUserSignedIn);
		AuthStore.userSignedOut.on(this.onUserSignedOut);
	}

	private onUserSignedIn = (data: IAzureAuthData) => {
		this.load();
	};

	private onUserSignedOut = () => {
		this.clearState();
	};

	public async load(): Promise<void> {
		// restore the state from server
		const reply: Response | undefined = await context.smukAPI.getFavorites('Music');

		if (!reply) return;

		const data = await reply.json();

		const newState: IFavevouriteState = { favourites: [] };
		for (const entry of data) {
			newState.favourites.push({ id: entry.identifier });
		}
		this.set(newState);
	}

	private clearState(): void {
		this.set(DefaultState);
	}

	public async addFavourite(data: IFavourite) {
		let state = deepClone(this.get());
		state.favourites.push(data);
		this.set(state);

		await context.smukAPI.addFavorite('Music', data.id);
	}

	public async removeFavourite(artistId: string) {
		let state: IFavevouriteState = deepClone(this.get());
		state.favourites = state.favourites.filter(x => x.id !== artistId);
		this.set(state);

		await context.smukAPI.removeFavorite('Music', artistId);
	}
}

const FavouritesStore = new FavouriteStoreClass();

export default FavouritesStore;

export const useFavourites = () => {
	return FavouritesStore.use(state => state.favourites);
};

export const useIsFavourite = (artistId: string) => {
	const favourites = FavouritesStore.use(state => state.favourites);
	return favourites.find(x => x.id === artistId) !== undefined;
};

export const useHasAnyFavourites = () => {
	const favourites = FavouritesStore.use(state => state.favourites);
	return favourites.length > 0;
};
