import React from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from '../AnimationCommon';
import Functions from '@client/style/Functions';
import { Breakpoints, presetColors } from '@client/style/Variables';
import gsap from 'gsap/all';
import { AnimationElementRainbow } from '../AnimationElementRainbow';
import { AnimationElementTreetop } from '../AnimationElementTreetop';

const StyledAnimationContainer = styled(AnimationContainer)`
	img {
		max-width: 100%;
		height: auto;
	}
	margin-bottom: -30%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-bottom: -18%;
	}
	background-color: ${presetColors.kissMeRed};
	overflow: hidden;
`;

const MobileContainer = styled.div`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;
const DesktopContainer = styled.div`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;

const Hotdog = () => {
	const imageRef = React.useRef<HTMLImageElement>(null);
	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1679307294-hotdog.png', {
		width: 815,
		height: 715,
	});
	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const tween = gsap.fromTo(
			imageRef.current,
			{
				scale: 0,
			},
			{
				scale: 1,
				duration: 0.4,
				ease: 'elastic.out(1.5, 0.7)',
			}
		);
		containerTimeline.add(tween, 0);
		return () => {
			tween.kill();
		};
	}, []);

	return <AnimationImageElement image={image} ref={imageRef} />;
};

export function IllustrationMadOgDrikke() {
	const deskTopimage = new AnimationImageAsset(
		'https://www.datocms-assets.com/80292/1670922812-madogdrikke-grafik-desktop.png',
		{ width: 2600 }
	);
	const mobileImage = new AnimationImageAsset(
		'https://www.datocms-assets.com/80292/1670922577-madogdrikke-grafik.png',
		{
			width: 1100,
		}
	);

	const paddy = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670839646-plantebof-desktop.png', {});
	const melon = new AnimationImageAsset('https://www.datocms-assets.com/80292/1670837779-vandmelon-desktop.png', {});
	// y: '120%', x: '-35%',angle: 55
	return (
		<StyledAnimationContainer
			height={1100}
			width={690}
			breakpoints={{
				mobile: { height: 2300, width: 2000 },
				tablet: { height: 2000, width: 2000 },
				laptop: { height: 1500, width: 2000 },
				desktop: { height: 1300, width: 2000 },
				ultra: { height: 2000, width: 4000 },
			}}
			className='colorWrapperAnimatedModule'
		>
			<MobileContainer>
				<AnimationElementRainbow
					position={{ scale: 1.8, anchorY: 'bottom', flipY: true, angle: 55, y: '120%', x: '-10%' }}
				></AnimationElementRainbow>
				<AnimationPositionElement position={{ anchorY: 'bottom', angle: -20, scale: 0.9, y: '-50%' }}>
					<Hotdog />
				</AnimationPositionElement>
				<AnimationPositionElement position={{ anchorY: 'bottom', scale: 0.7, anchor: 'right', x: '45%', y: '10%' }}>
					<AnimationImageElement image={paddy} />
				</AnimationPositionElement>
				<AnimationPositionElement position={{ anchorY: 'bottom', scale: 2, y: '95%', angle: 5, x: '-5%' }}>
					<AnimationImageElement image={melon} />
				</AnimationPositionElement>
			</MobileContainer>
			<DesktopContainer>
				<AnimationElementTreetop
					variant={3}
					position={{ anchorY: 'bottom', scale: 0.8, x: '-50%', angle: 5, anchor: 'center', y: '50%' }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={1}
					position={{ anchorY: 'bottom', scale: 0.7, x: '-10%', angle: -8, anchor: 'center', y: '10%' }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{ anchorY: 'bottom', scale: 0.9, x: '15%' }}
				></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={3}
					position={{ anchorY: 'bottom', scale: 0.9, anchor: 'right', origin: 'top right' }}
				></AnimationElementTreetop>
				<AnimationElementRainbow
					position={{ scale: 4, anchorY: 'bottom', flipY: true, angle: 40, y: '0', x: '40%' }}
				></AnimationElementRainbow>
				<AnimationElementRainbow
					position={{ scale: 4, anchorY: 'bottom', flipY: true, angle: -40, y: '-280%', x: '180%' }}
				></AnimationElementRainbow>
				<AnimationPositionElement
					position={{ anchorY: 'bottom', angle: -20, scale: 1, y: '-50%', anchor: 'center', x: '-50%' }}
				>
					<Hotdog />
				</AnimationPositionElement>
				<AnimationPositionElement position={{ anchorY: 'bottom', scale: 0.7, anchor: 'right', x: '45%', y: '10%' }}>
					<AnimationImageElement image={paddy} />
				</AnimationPositionElement>

				<AnimationElementTreetop position={{ anchorY: 'bottom', scale: 0.8 }}></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={3}
					position={{ anchorY: 'bottom', scale: 0.8, x: '-18%', y: '-5%', angle: 10 }}
				></AnimationElementTreetop>

				<AnimationPositionElement position={{ anchorY: 'bottom', scale: 2, y: '65%', angle: 5, x: '-5%' }}>
					<AnimationImageElement image={melon} />
				</AnimationPositionElement>
				<AnimationElementTreetop
					variant={1}
					position={{ anchorY: 'bottom', scale: 0.7, y: '35%', x: '30%' }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{
						anchorY: 'bottom',
						scale: 0.7,
						anchor: 'right',
						origin: 'top right',
						angle: -10,
						y: '35%',
						x: '-15%',
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
		</StyledAnimationContainer>
	);
}
