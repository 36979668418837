import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

export default class GotoElement {
	private static _instance: GotoElement;

	public static get Instance(): GotoElement {
		if (!this._instance) this._instance = new GotoElement();

		return this._instance;
	}

	constructor() {
		gsap.registerPlugin(ScrollToPlugin);
	}

	public scrollToElementWithId(id: string, offset?: number): void {
		// console.log('GotoElement.scrollToElementWithId(); ' + id + ' offset: ' + offset);
		const target = document.body.querySelector(`#${id}`);

		if (!target) return;

		if (!(target instanceof HTMLElement)) {
			return;
		}
		gsap.to(window, { duration: 0.5, scrollTo: { y: target, offsetY: offset } });
	}
}
