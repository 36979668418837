import { CommonText } from '@client/common/CommonText';
import { Image as SpotifyImage, Item } from '@client/core/utils/SpotifyLoader';
import { Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import Image from '../Image';
//@ts-ignore
import walther from '../../assets/svgs/walther.svg'; //@ts-ignore
import closeBtn from '../../assets/svgs/close-btn.svg';

const Container = styled.div`
	position: relative;
	aspect-ratio: 1;
`;

const ImageContainer = styled(Image)`
	aspect-ratio: 1;
	width: 100%;

	img {
		flex-shrink: 0;
		flex-grow: 1;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

const CloseWrapper = styled.div`
	position: absolute;
	top: 12px;
	right: 12px;
	cursor: pointer;
`;

const Name = styled(CommonText)`
	margin-top: 10px;
	color: ${Colors.darkText};
`;

const Walther = styled(walther)``;

const WaltherContainer = styled.div`
	width: 100%;
	height: auto;

	path {
		fill: ${Colors.darkText};
	}
`;

const CloseIcon = styled(closeBtn)``;

interface ISelectedItem {
	item: Item;
	onClose: (id) => void;
}

export default function SelectedItem({ item, onClose }: ISelectedItem) {
	return (
		<Container>
			{item.images && item.images.length > 0 ? (
				<ImageContainer width={item.images[0].width} src={item.images[0].url}></ImageContainer>
			) : (
				<WaltherContainer>
					<Walther></Walther>
				</WaltherContainer>
			)}
			<Name>{item.name}</Name>
			<CloseWrapper onClick={() => onClose(item.id)}>
				<CloseIcon></CloseIcon>
			</CloseWrapper>
		</Container>
	);
}
