import React from 'react';
import {
	AnimationElementPosition,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
	mergePositions,
} from './AnimationCommon';
import { gsap } from 'gsap/all';

export const AnimationElementTreetop = (props: {
	delayOrder?: number;
	variant?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
	position: AnimationElementPosition;
}) => {
	const treetopRef = React.useRef<HTMLImageElement>(null);
	const parentTimeline = useAnimationContainerTimeline();

	const size = {
		tiny: 375,
		mobile: 960,
		ultra: 1600,
	};

	const width = {
		breakpoints: {
			tiny: {
				width: '80vw',
			},
			mobile: {
				width: '60vw',
			},
			desktop: {
				width: '960px',
			},
			largeDesktop: {
				width: '960px',
			},
			ultra: {
				width: '960px',
			},
		},
	};

	const position = mergePositions(width, props.position);

	const variants = [
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1682504697-treetop-1.png', size),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1682504703-treetop-2.png', size),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1682504708-treetop-3.png', size),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1682504711-treetop-4.png', size),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1686212498-skov-13.png', size),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1686898856-treetop-6.png', size),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1687162269-treetop-7-sideclipped.png', size),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1687261205-treetop-08-sideclipped.png', size),
		new AnimationImageAsset('https://www.datocms-assets.com/80292/1687335458-treetop-07-sideclipped.png', size),
	];

	const image = variants[(props.variant || 1) - 1];

	React.useEffect(() => {
		const tween = gsap.to(treetopRef.current, {
			x: () => gsap.utils.random(-2, 2, 0.1),
			y: () => gsap.utils.random(-2, 2, 0.1),
			rotate: () => gsap.utils.random(-0.5, 0.5, 0.1),
			duration: 0,
			repeatDelay: 0.5,
			repeatRefresh: true,
			repeat: -1,
		});

		parentTimeline.add(tween, 0);

		return () => {
			tween.kill();
		};
	}, []);

	return (
		<AnimationPositionElement position={position}>
			<AnimationImageElement ref={treetopRef} image={image} />
		</AnimationPositionElement>
	);
};
