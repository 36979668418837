import Store from '@core/Store';

export interface IOverlaysState {
	activeId: string | undefined;
}

export const DefaultState = {
	activeId: undefined,
};

export class OverlaysStoreClass extends Store<IOverlaysState> {
	constructor() {
		super(DefaultState);
	}

	// public addItems(amount: number) {
	// 	this.set({
	// 		itemsToLoad: this.get().itemsToLoad + amount,
	// 	});
	// }

	// public addProgress(amount: number) {
	// 	this.set({
	// 		loadedItems: this.get().loadedItems + amount,
	// 	});
	// }
}

const OverlaysStore = new OverlaysStoreClass();

export default OverlaysStore;
