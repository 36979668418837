import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Breaker from './Breaker';

const Container = styled.div<{ svgColor?: string }>`
	position: relative;
	width: 100%;
	svg > path {
		fill: ${props => props.svgColor || ''};
	}
	padding-top: 60px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-top: 0;
		margin-top: -1%;
	}
`;

const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 95%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
	${Functions.breakpoint(Breakpoints.mobile)} {
		height: 98%;
	}
`;

const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;

	${Functions.breakpoint(Breakpoints.mobile)} {
		top: 98%;
		height: 2%;
	}
`;

export default function AutomatedBreakerModule({ data }: { data?: { specifier: number } }) {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);
	const pathRef = useRef<SVGPathElement>(null);

	useEffect(() => {
		if (moduleRef.current) {
			// console.log('moduleRef: ', moduleRef.current);
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor == null && nextColor == null) {
				setBackgroundColors([undefined, undefined]);
			} else if (prevColor == null) {
				setBackgroundColors([undefined, nextColor]);
			} else if (nextColor == null) {
				setBackgroundColors([prevColor, undefined]);
			} else {
				setBackgroundColors([prevColor, nextColor]);
			}
		}
	}, [moduleRef]);

	return (
		<Container svgColor={backgroundColors[1]}>
			<ColorWrapper ref={moduleRef}>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>
			<Breaker number={data && data.specifier} />
		</Container>
	);
}
