import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import AnimationItemComponent, { IAnimationItem } from './AnimationItemComponent';
import { animationItemDataToCSSParser } from './AnimationItemDataParser';

const Container = styled.div<{ height?: string }>`
	user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none;
	/* background-color: yellow; */
	/* overflow: hidden; */
	width: 100%;
	height: ${props =>
		props.height ? `calc(100vw * ${parseFloat(props.height.replace('%', '')) / 100})` : '100%'} !important;
`;

const ItemPlacer = styled.div<{ item: IAnimationItem }>`
	position: absolute;
	${props => animationItemDataToCSSParser(props.item)};
`;

export interface IAnimationSceneData {
	animationName?: string;
	breakpoints?: IBreakpointData[];
	id?: string;
	modelId?: string;
}

export interface IBreakpointData {
	heightRelativeToWidth?: string;
	id?: string;
	itemType?: string;
	items?: IAnimationItem[];
	minWidth?: number;
	modelId?: string;
	type?: string;
}

export interface IProps {
	className?: string;
	data: IAnimationSceneData;
	heightRelativeToWidth?: string;
	usePreloader?: boolean;
}

export default function AnimationSceneComponent(props: IProps) {
	const animContainerRef = useRef<HTMLDivElement | null>(null);
	const [breakpoint, setBreakpoint] = React.useState<IBreakpointData | undefined>(
		props.data.breakpoints && props.data.breakpoints[0]
	);

	const isMobile = useIsMobile('mobile');

	useEffect(() => {
		if (isMobile) setBreakpoint(props.data.breakpoints && props.data.breakpoints[0]);
		else setBreakpoint(props.data.breakpoints && props.data.breakpoints[props.data.breakpoints.length - 1]);
	}, [isMobile]);

	return (
		<Container className={props.className} ref={animContainerRef} height={breakpoint?.heightRelativeToWidth}>
			{breakpoint &&
				breakpoint.items &&
				breakpoint.items.map(
					(item, index) =>
						item.modelId !== 'animationLotte' &&
						item.name !== 'background' &&
						item.name !== 'Background' &&
						item.name !== 'Solid' &&
						item.name !== 'solid' && (
							<ItemPlacer item={item} key={item.id + '_' + index}>
								<AnimationItemComponent item={item} />
							</ItemPlacer>
						)
				)}
		</Container>
	);
}
