import { boldTextStyle, commonTextStyle } from '@client/common/CommonText';
import { Colors } from '@client/style/Variables';
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { IFormSelectField } from './FormStore';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	align-items: center;
`;

const Label = styled.label<{ valid?: boolean | undefined }>`
	${commonTextStyle}
	${boldTextStyle}
	color: ${props => (props.valid === false ? Colors.error : Colors.darkText)};
`;

const StyledSelect = styled.select`
	${commonTextStyle}
	flex: 1;
	height: 50px;
	border: none;
	padding: 10px;
`;

interface IProps {
	data: IFormSelectField;
	valid?: boolean | undefined;
	onSelect: (value: string) => void;
}

export default function FormSelect(props: IProps) {
	const onSelectInternal = (e: SyntheticEvent<HTMLSelectElement, Event>) => {
		props.onSelect(e.currentTarget.value);
	};

	return (
		<Container>
			<Label htmlFor={props.data.id} valid={props.valid}>
				{props.data.label}
			</Label>

			<StyledSelect
				name={props.data.id}
				id={props.data.id}
				value={props.data.value ?? props.data.value}
				onChange={e => onSelectInternal(e)}
			>
				{props.data.notSelectValue && (
					<option key={props.data.notSelectValue} value={props.data.notSelectValue}>
						{props.data.notSelectValue}
					</option>
				)}

				{props.data.options.map((option: string) => {
					return (
						<option key={option} value={option}>
							{option}
						</option>
					);
				})}
			</StyledSelect>
		</Container>
	);
}
