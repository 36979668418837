import { boldTextStyle } from '@client/common/CommonText';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import Functions from '@client/style/Functions';
import { Breakpoints, Fonts } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import ImageCarousel from './Carousel/ImageCarousel';
import WaveContainer from './Dividers.tsx/WaveContainer';
import Image, { ImageData } from './Image';
import { DateUtils } from '@client/core/utils/DateUtils';
import { FavouritePanel } from './Favourites/FavouritePanel';

const Container = styled.div`
	position: relative;
	width: 100%;
`;

const ImageWrapper = styled.div<{ hasCarousel?: boolean }>`
	display: ${props => (props.hasCarousel ? 'block' : 'flex')};
	justify-content: center;
`;

const ImagePlacer = styled(Image)<{ ratio?: string }>`
	height: ${props => (props.ratio === '16/9' ? 'auto' : '90vh')};
	aspect-ratio: 4 / 5;

	${Functions.breakpoint(Breakpoints.tablet)} {
		aspect-ratio: ${props => props.ratio};
	}
`;

const TextContainer = styled.header<{ textColor?: string }>`
	color: ${props => props.textColor};
	z-index: 1;
	width: 100%;
	text-align: center;
	padding-top: 30px;
`;

const Title = styled.h1`
	margin: 0;
	font-family: ${Fonts.GeomanistBold};
	font-size: 54px;
	word-wrap: break-word;
	hyphens: auto;
	${Functions.breakpoint(Breakpoints.tablet)} {
		padding: 0 150px; // Make room for back button
		font-size: 120px;
	}
`;

const ExtraInfoContainer = styled.div<{ textColor?: string }>`
	color: ${props => props.textColor};
	margin: 0;
	margin-top: 20px;

	line-height: 1.2;
	display: flex;
	flex-direction: column;

	gap: 5px;

	${Functions.breakpoint(Breakpoints.tablet)} {
		gap: 10px;
	}

	& > p {
		flex: 1 1 50%;
		text-align: center;
		${boldTextStyle}
		margin: 0;
		font-size: 28px;
		text-transform: capitalize;
		${Functions.breakpoint(Breakpoints.tablet)} {
			font-size: 55px;
		}
	}
`;
const SmallText = styled.p`
	font-size: 18px !important;
	line-height: 1.3 !important;
	font-family: ${Fonts.Geomanist} !important;

	margin-top: 14px !important;
	${Functions.breakpoint(Breakpoints.tablet)} {
		margin-top: 25px;
	}
`;

const FavouritePanelContainer = styled.div`
	padding-top: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
`;

interface HeroProps {
	title: string;
	location: any;
	startTime: Date;
	showTime: boolean;
	images: ImageData[];
	textColor?: string;
	video?: any; //Not yet sure how to video is served
	artistId: string;
}

export default function ArtistHero(props: HeroProps) {
	const isMobile = useIsMobile();

	console.log('props.startTime : ' + props.startTime);

	const startTime: Date | undefined = props.startTime ? DateUtils.normalizeTime(props.startTime) : undefined;
	let dayStartTime: Date | undefined = undefined;

	if (startTime) {
		// shift to the day before
		console.log('New starttime: ' + startTime);

		dayStartTime = new Date(startTime);
		dayStartTime.setHours(dayStartTime.getHours() - 6);
	}

	return (
		<Container>
			{/* Image */}
			<WaveContainer>
				<ImageWrapper hasCarousel={props.images.length > 1}>
					{!isMobile && props.images.length > 1 ? (
						<ImageCarousel images={props.images} />
					) : (
						<ImagePlacer
							data={props.images[0]}
							ratio={Functions.findRatio(props.images[0].width, props.images[0].height)}
							maxWidth={2500}
							minWidth={800}
						></ImagePlacer>
					)}
				</ImageWrapper>
			</WaveContainer>

			<TextContainer textColor={props.textColor}>
				<Title>{props.title}</Title>

				<ExtraInfoContainer role='doc-subtitle' textColor={props.textColor}>
					{props.location && (
						<>
							<SmallText>Scene</SmallText>
							<p>{props.location.name}</p>
						</>
					)}
					{startTime && dayStartTime && (
						<>
							<SmallText>Showstart</SmallText>
							<p>
								{DateUtils.getWeekDayName(dayStartTime)} {props.showTime && DateUtils.formatTime(startTime)}
							</p>
						</>
					)}
				</ExtraInfoContainer>

				<FavouritePanelContainer>
					<FavouritePanel artistId={props.artistId} />
				</FavouritePanelContainer>
			</TextContainer>
		</Container>
	);
}
