import { CommonText, CommonTitle } from '@client/common/CommonText';
import { OverlayController, Overlays } from '@client/core/modules/Overlays/Overlays';
import { DefaultButton } from '@client/modules/shared/DefaultButton';
import { DefaultOverlayFrame } from '@client/modules/shared/DefaultOverlayFrame';
import Site from '@client/store/Site';
import { Breakpoints, Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import Functions from '@client/style/Functions';
import { OverlayNewsDuckItem } from './OverlayNewsDuckItem';
/**
 * Text
 */
const StyledCommonTitle = styled(CommonTitle)`
	color: ${Colors.darkText};
	font-size: 34px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 48px;
	}
`;

const StyledCommonText = styled(CommonText)`
	color: ${Colors.darkText};
	margin-top: 20px;
`;

interface IProps {
	controller: OverlayController;
}

export const OverlayNewsDuck = (props: IProps) => {
	const news = Site.use(state => state.news) as any;

	const onCloseClick = (): void => {
		props.controller.close();
	};

	console.log('OverlayNewsDuck');

	const onOkayClick = (): void => {
		props.controller.close();

		const newsItem = news.collection[0];

		Overlays.Instance.open({
			create: (controller: OverlayController) => {
				return (
					<OverlayNewsDuckItem
						controller={controller}
						title={newsItem.title}
						text={newsItem.text}
						link={newsItem.link}
					/>
				);
			},
		});
	};

	return (
		<DefaultOverlayFrame
			backgroundColor={Colors.newsBackground}
			dimColor={Colors.defaultOverlayDim}
			onCloseButtonClick={onCloseClick}
			bottomChildren={
				<DefaultButton
					onClick={onOkayClick}
					title={'Fedt, fortæl mere'}
					backgroundColor={Colors.darkBackground}
					textColor={Colors.lightText}
				/>
			}
		>
			<StyledCommonTitle>{news.title}</StyledCommonTitle>
			<StyledCommonText>{news.text}</StyledCommonText>
		</DefaultOverlayFrame>
	);
};
