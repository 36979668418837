import Functions from '@client/style/Functions';
import { Breakpoints, Colors, Fonts, Index } from '@client/style/Variables';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

const StatisticContainer = styled.div`
	padding-bottom: 50px;
	:last-of-type {
		padding-bottom: 0px;
	}
`;

const StatNumber = styled.div`
	font-family: ${Fonts.GeomanistBold};
	font-size: 68px;
	display: inline-block;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 110px;
		line-height: 100%;
	}
`;

const StatHeadline = styled.div`
	font-family: ${Fonts.GeomanistBold};
	font-size: 68px;
	display: inline-block;
	white-space: pre-wrap;
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 110px;
		line-height: 100%;
	}
`;

const StatText = styled.div`
	font-family: ${Fonts.GeomanistBold};
	font-size: 20px;
	position: relative;
	margin-top: -5px;
`;

interface StatProps {
	stat: any;
	index: number;
}

export default function StatisticEntry(props: StatProps) {
	const StatNumberRef = useRef<HTMLDivElement | null>(null);
	const [hasRun, setHasRun] = useState(false);
	useEffect(() => {
		checkVisibility();
		window.addEventListener('scroll', checkVisibility);
		return () => {
			window.removeEventListener('scroll', checkVisibility);
		};
	}, []);

	const checkVisibility = () => {
		if (StatNumberRef.current === undefined) return;
		if (StatNumberRef.current === null) return;
		const bounds = StatNumberRef.current.getBoundingClientRect();
		if (bounds.top < window.innerHeight && bounds.bottom > 0 && hasRun === false) {
			setHasRun(true);
			gsap.to(StatNumberRef.current, {
				textContent: props.stat.number,
				roundProps: 'textContent',
				stagger: {
					each: 1.0,
				},
				delay: 0.3,
				duration: 1,
				ease: 'power4.out',
				onUpdate: function () {
					this.targets()[0].innerHTML = numberWithCommas(Math.ceil(this.targets()[0].textContent));
				},
				onComplete: () => {
					window.removeEventListener('scroll', checkVisibility);
				},
			});
		}
	};

	const numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	};

	return (
		<StatisticContainer key={'stat' + props.index}>
			<StatNumber ref={StatNumberRef}>0</StatNumber>
			<StatHeadline>{props.stat.ekstra}</StatHeadline>
			<StatText>{props.stat.explainer}</StatText>
		</StatisticContainer>
	);
}
