import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import VideoComponent, { VideoData, ImperativeVideo } from './VideoComponent';
import gsap from 'gsap';
import Functions from '../../style/Functions';
import { Breakpoints, Colors, Fonts, Index } from '../../style/Variables';
import { AnimatedModule } from '../AnimatedModule';
import { CommonText, CommonTitle } from '@client/common/CommonText';
import RoundButtonWithAnimation from '../shared/RoundButtonWithAnimation';
import { CookieInformationHelper, CookieTypes } from '../CookieInformationMiddleware';
import LinkButton from '../shared/LinkButton';
import SquaredLinkButton from '../shared/SquaredLinkButton';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';

const Container = styled.div<{ addMarginTop?: boolean; vpOpen?: boolean }>`
	/* pointer-events: none; */
	width: 100vw;
	height: 100vh;
	position: relative;
	margin-left: -(var(--gridMargin));
	padding: 0;
	//padding-bottom: 30%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	max-height: 100vh;
	align-items: center;
	z-index: ${props => (props.vpOpen ? Index.FullScreenVideoPlayer : 1)};

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding-bottom: 16%;
	}
`;

const VideoContainer = styled.div<{ bgColor?: string }>`
	width: 100vw;
	height: 70%;
	z-index: ${Index.FullScreenVideoPlayer};
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	pointer-events: none;
	cursor: default;
	${Functions.breakpoint(Breakpoints.tablet)} {
		opacity: 1;
	}
`;

const PromoVideo = styled(VideoComponent)<{ vpOpen?: boolean }>`
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	/* padding-bottom: 30%; */
	z-index: ${Index.FullScreenVideoPlayer};
	top: 0px;
	left: 0px;
	display: flex;
	cursor: default;

	.react-player {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 95% !important;
		height: auto !important;
		transform: translate(-50%, -50%);
		opacity: 0;
		overflow: hidden;
		transition: all 0.2s linear 0.1s;
		aspect-ratio: 1920/1080;

		${Functions.breakpoint(Breakpoints.tablet)} {
			opacity: 1;
			width: ${props => (props.vpOpen ? '80% !important' : '45% !important')};
			height: ${props => (props.vpOpen ? 'auto !important' : 'auto !important')};
			min-width: 700px;
			max-width: 1000px;
			&:before {
				transition: all 0.1s ease-in-out 0s;
				content: '';
				display: block;
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				background-color: rgba(30, 30, 30, 0.7);
				pointer-events: none;
				opacity: ${props => (props.vpOpen ? 0 : 1)};
				mix-blend-mode: multiply;
			}
		}
	}
`;

const PlayIcon = styled(RoundButtonWithAnimation)<{ vpOpen?: boolean }>`
	opacity: ${props => (props.vpOpen ? 0 : 1)};
	z-index: unset;
	${Functions.breakpoint(Breakpoints.tablet)} {
		z-index: ${Index.TextOverVideoPlayer};
	}
`;

const TitleContainer = styled(CommonTitle)<{ vpOpen?: boolean }>`
	font-size: 48px;
	display: block;
	position: relative;
	color: ${Colors.defaultWhite};
	transition: all 0.5s ease-in-out 0s;
	opacity: ${props => (props.vpOpen ? 0 : 1)};
	z-index: unset;
	${Functions.breakpoint(Breakpoints.tablet)} {
		z-index: ${Index.TextOverVideoPlayer};
	}
`;

const SubTitleContainer = styled(CommonText)<{ vpOpen?: boolean }>`
	display: block;
	position: relative;
	padding-bottom: 30px;
	color: ${Colors.defaultWhite};
	transition: all 0.5s ease-in-out 0s;
	opacity: ${props => (props.vpOpen ? 0 : 1)};
	white-space: pre-wrap;
	z-index: unset;
	${Functions.breakpoint(Breakpoints.tablet)} {
		z-index: ${Index.TextOverVideoPlayer};
	}
`;

const VideoLink = styled(SquaredLinkButton)`
	position: absolute;
	bottom: 20%;
	z-index: ${Index.TextOverVideoPlayer};
	${Functions.breakpoint(Breakpoints.tablet)} {
		z-index: unset;
	}
	color: ${Colors.defaultWhite};
`;

export default function VideoPlayer({
	data,
}: {
	data: {
		title?: string;
		subtitle: string;
		externalVideo?: VideoData;
		animation: any;
		backgroundColorLink: any;
		callToAction?: any;
	};
}) {
	const videoRef = useRef<HTMLDivElement | null>(null);
	const videoPlayerRef = useRef<ImperativeVideo | null>(null);
	const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
	const isMobile = useIsMobile();
	const [videoPlayerReady, setVideoPlayerReady] = useState(false);

	useEffect(() => {
		if (videoPlayerReady) {
			if (isMobile) {
				if (videoPlayerOpen) {
					videoPlayerRef.current?.unmutePlayer();
					if (!videoRef.current) return;
					gsap.set(videoRef.current, {
						opacity: 1,
					});
					gsap.set('.react-player', {
						opacity: 1,
					});
				} else {
					if (!videoRef.current) return;

					videoPlayerRef.current?.mutePlayer();
					videoPlayerRef.current?.pause();
					gsap.set(videoRef.current, {
						opacity: 0,
					});
					gsap.set('.react-player', {
						opacity: 0,
					});
				}
			} else {
				if (videoPlayerOpen) {
					videoPlayerRef.current?.unmutePlayer();
					if (!videoRef.current) return;

					gsap.set(videoRef.current, {
						backgroundColor: 'rgba(30, 30, 30, 0.5)',
						opacity: 1,
					});
					gsap.set('.react-player', {
						opacity: 1,
					});
				} else {
					videoPlayerRef.current?.mutePlayer();
					if (!videoRef.current) return;

					gsap.set(videoRef.current, {
						opacity: 1,
						backgroundColor: 'transparent',
					});
					gsap.set('.react-player', {
						opacity: 1,
					});
				}
			}
		}
	}, [isMobile, videoPlayerReady]);

	useEffect(() => {
		document.addEventListener('scroll', playMovieInView);
		return function () {
			document.removeEventListener('scroll', playMovieInView);
		};
	}, [videoPlayerReady]);

	const setVPReady = () => {
		setVideoPlayerReady(true);
	};

	const checkIfVideoIsInView = () => {
		if (!videoPlayerRef.current) return false;
		const element = videoPlayerRef.current.getElement();
		if (!element) return false;
		const bounding = element.getBoundingClientRect();
		return (
			bounding.bottom > 0 &&
			bounding.right > 0 &&
			bounding.left < (window.innerWidth || document.documentElement.clientWidth) &&
			bounding.top < (window.innerHeight || document.documentElement.clientHeight)
		);
	};

	const playMovieInView = () => {
		if (videoPlayerRef.current && videoPlayerReady) {
			if (
				checkIfVideoIsInView() &&
				window.innerWidth > parseInt(Breakpoints.tablet) &&
				!videoPlayerRef.current.isPlaying()
			) {
				videoPlayerRef.current.play();
			} else if (
				!checkIfVideoIsInView() &&
				window.innerWidth > parseInt(Breakpoints.tablet) &&
				videoPlayerRef.current.isPlaying()
			) {
				videoPlayerRef.current.pause();
			}
		}
	};

	const openModalPlayer = () => {
		window.scrollTo(0, 0);
		if (isMobile === false) {
			videoPlayerRef.current?.unmutePlayer();
			if (!videoRef.current) return;

			gsap.to(videoRef.current, {
				pointerEvents: 'auto',
				duration: 0.3,
				//backgroundColor: 'rgba(30, 30, 30, 0.5)',
				onStart: () => {
					gsap.to('.react-player', {
						opacity: 1,
						duration: 0.3,
						delay: 0.2,
					});
				},
			});
		} else {
			videoPlayerRef.current?.unmutePlayer();
			if (!videoRef.current) return;

			gsap.to(videoRef.current, {
				//scaleY: 1,
				opacity: 1,
				pointerEvents: 'auto',
				duration: 0.3,
				//backgroundColor: 'rgba(30, 30, 30, 0.5)',
				onStart: () => {
					gsap.to('.react-player', {
						opacity: 1,
						duration: 0.3,
						delay: 0.2,
					});
				},
				onComplete: () => {
					if (!videoPlayerRef.current) return;
					videoPlayerRef.current.play();
				},
			});
		}
		document.body.style.overflow = 'hidden';
		document.body.style.height = '100vh';
		setVideoPlayerOpen(true);
	};

	const closeModalPlayer = () => {
		setVideoPlayerOpen(false);
		onClose();
	};

	const showVideo = () => {
		return CookieInformationHelper.getInstance().hasConsentFor(CookieTypes.MARKETING);
	};

	const onClose = () => {
		if (isMobile === false) {
			videoPlayerRef.current?.mutePlayer();
			if (!videoRef.current) return;

			gsap.to(videoRef.current, {
				backgroundColor: 'transparent',
				duration: 0.2,
				onComplete: () => {
					if (!videoRef.current) return;

					gsap.to(videoRef.current, {
						pointerEvents: 'none',
						duration: '0.3',
						onComplete: () => {
							const top = parseInt(document.body.style.top);
							if (top && top < 0) window.scrollTo(0, top * -1);
						},
					});
				},
			});
		} else {
			videoPlayerRef.current?.pause();
			gsap.to('.react-player', {
				opacity: 0,
				duration: 0.2,
				onComplete: () => {
					if (!videoRef.current) return;

					gsap.to(videoRef.current, {
						pointerEvents: 'none',
						opacity: 0,
						duration: '0.3',
						onComplete: () => {
							const top = parseInt(document.body.style.top);
							if (top && top < 0) window.scrollTo(0, top * -1);
						},
					});
				},
			});
		}
		document.body.style.removeProperty('height');
		document.body.style.removeProperty('overflow');
	};

	return (
		<AnimatedModule moduleData={data}>
			<Container vpOpen={videoPlayerOpen} className='fullscreenElement' addMarginTop={data.title ? false : true}>
				<TitleContainer vpOpen={videoPlayerOpen}>{data.title}</TitleContainer>
				<SubTitleContainer vpOpen={videoPlayerOpen}>{data.subtitle}</SubTitleContainer>
				<PlayIcon vpOpen={videoPlayerOpen} bgColor={Colors.defaultWhite} interaction={openModalPlayer}>
					<svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M0 15V7.67442V0L16 7.67442L0 15Z' fill='#272627' />
					</svg>
				</PlayIcon>

				<VideoContainer
					bgColor={data.backgroundColorLink?.color ? Functions.hexFromRGB(data.backgroundColorLink?.color) : undefined}
					ref={videoRef}
					onClick={closeModalPlayer}
				>
					<PromoVideo
						ref={videoPlayerRef}
						data={data.externalVideo}
						controls={true}
						muted={true}
						loop={true}
						autoplay={false}
						closeModal={closeModalPlayer}
						vpOpen={videoPlayerOpen}
						whenReady={setVPReady}
					/>
				</VideoContainer>
				{/* {data.callToAction.length > 0 && (
					<VideoLink
						backgroundColor={Colors.darkBackground}
						textColor={Colors.lightText}
						data={data.callToAction[0].link[0]}
					></VideoLink>
				)} */}
			</Container>
		</AnimatedModule>
	);
}
