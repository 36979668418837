import React, { ButtonHTMLAttributes, useRef } from 'react';
import styled from 'styled-components';
import Link, { ILinkData } from '@core/PageSwitch/Link';
import { Breakpoints, Colors, Fonts } from '@client/style/Variables';
import Functions from '@client/style/Functions';
import gsap, { Back, Elastic, Linear, Quart, Sine, Expo } from 'gsap';

export const Button = styled(Link)<{ backgroundcolor?: string; textcolor?: string; bordercolor?: string }>`
	pointer-events: auto;
	width: auto;
	border-radius: 25px;
	background-color: ${props => props.backgroundcolor || Colors.darkBackground};
	color: ${props => props.textcolor || Colors.lightText};
	border: 1px solid ${props => (props.bordercolor ? props.bordercolor : props.backgroundcolor)};
	position: relative;
	padding: 12px 24px;
	min-height: 42px;
	font-family: ${Fonts.Geomanist};
	text-decoration: Capitalize;
	font-size: 15px;
	cursor: pointer;
	line-height: 1em;

	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2, 3, 0.4, 1);

	&:hover {
		transform: scale(1.1, 1.1);
	}

	&:active {
		transform: scale(0.95, 0.95);
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 21px;
	}
`;

export const WipeArea = styled.div<{ backgroundcolor?: string }>`
	width: 100%;
	height: 100%;
	/* background-color: red; */
	position: absolute;
	left: 0px;
	top: 0px;
	overflow: hidden;
	border-radius: 25px;

	.wipe {
		background-color: ${props => props.backgroundcolor || Colors.darkBackground};
	}
`;

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	textColor: string;
	backgroundColor: string;
	borderColor?: string;
	data?: ILinkData;
	className?: string;
}

export default function LinkButton(props: IProps) {
	const wipeAreaRef = useRef<HTMLDivElement | null>(null);

	const onMouseEnter = (): void => {
		wipeEffect();
	};

	const wipeEffect = (): void => {
		const wipe: HTMLDivElement = document.createElement('div');

		wipeAreaRef.current?.appendChild(wipe);

		wipe.style.height = '100%';
		wipe.style.width = 40 + 'px';
		wipe.style.position = 'absolute';
		wipe.style.top = '0px';
		wipe.className = 'wipe';
		wipe.style.backgroundColor = 'white';

		const speed: number = 0.7;

		const timeline = gsap.timeline();
		timeline.set(wipe, { css: { left: '-1%', opacity: 0.5, skewX: -20 } });
		timeline.to(
			wipe,
			{
				duration: speed,
				css: {
					width: '100%',
				},

				ease: Expo.easeOut,
			},
			0
		);
		timeline.to(
			wipe,
			{
				duration: speed,
				delay: 0.2,
				css: {
					left: '120%',
				},
				onComplete: () => {
					wipe.remove();
				},
				ease: Expo.easeOut,
			},
			0
		);
	};

	return (
		<Button
			backgroundcolor={props.backgroundColor}
			textcolor={props.textColor}
			bordercolor={props.borderColor}
			data={props.data}
			className={props.className}
			onMouseEnter={onMouseEnter}
		>
			{props.data?.title && props.data?.title}
			<WipeArea backgroundcolor={props.textColor} ref={wipeAreaRef} />
		</Button>
	);
}
