import { IAnimationItem } from './AnimationItemComponent';

const alignX = (val: string | undefined) => {
	switch (val) {
		case 'left':
			return '0%';
		case 'center':
			return '50%';
		case 'right':
			return '100%';
		default:
			return val;
	}
};

const alignY = (val: string | undefined) => {
	switch (val) {
		case 'top':
			return '0%';
		case 'center':
			return '50%';
		case 'bottom':
			return '100%';
		default:
			return val;
	}
};

const pivotXToCSS = (val: string | undefined) => {
	switch (val) {
		case 'left':
			return '0%';
		case 'center':
			return '-50%';
		case 'right':
			return '-100%';
		default:
			return '-' + val;
	}
};

const pivotYToCSS = (val: string | undefined) => {
	switch (val) {
		case 'top':
			return '0%';
		case 'center':
			return '-50%';
		case 'bottom':
			return '-100%';
		default:
			return '-' + val;
	}
};

const offsetYRelativeToWidthTranslater = (val: string | undefined) => {
	if (val) {
		return parseFloat(val.replace('%', '')) / 100;
	}
	return 0;
};

export const animationItemDataToCSSParser = (item: IAnimationItem) => {
	if (!item) return;
	return `left: ${alignX(item.alignX)}; 
    top: calc( ${alignY(item.alignY)} + 100vw * ${offsetYRelativeToWidthTranslater(item.offsetYRelativeToWidth)});
    width: ${item.scaleX};
    height: ${item.scaleY};
    transform: translate(${pivotXToCSS(item.pivotX)}, ${pivotYToCSS(item.pivotY)});
	z-index: ${item.sortIndex};
    `;
};
