import {
	CommonText,
	commonTextStyle,
	heading1TextStyle,
	heading2TextStyle,
	heading3TextStyle,
} from '@client/common/CommonText';
import HTML from '@client/core/utils/HTML';
import ContentPageStore, { IContentPageState } from '@client/store/ContentPageStore';
import Functions from '@client/style/Functions';
import { Breakpoints, Fonts } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ bgColor?: string }>`
	/* margin-top: 40px; */
	width: 100%;
	padding: 0px var(--gridMargin);
	padding-top: 40px;
	background-color: ${props => props.bgColor};

	display: flex;
	flex-direction: column;
	align-items: center;

	${Functions.breakpoint(Breakpoints.mobile)} {
		/* padding: 0 calc(${Functions.colFixed(4, 1)}); */
		padding-top: 80px;

		/* padding-left: calc(var(--gridMargin) + ${Functions.colFixed(4, 1)});
		padding-right: calc(var(--gridMargin) + ${Functions.colFixed(4, 1)}); */
	}
`;

const Text = styled(CommonText)<{ textColor?: string }>`
	width: 100%;
	text-align: start;
	color: ${props => props.textColor};
	margin-top: 0px;

	${Functions.breakpoint(Breakpoints.tablet)} {
		width: ${Functions.col(8)};
		max-width: 740px;
	}

	p {
		${commonTextStyle}
		margin: 0;
	}
	p + p {
		margin-top: 1.3em;
	}

	h1 {
		${heading1TextStyle};
		margin-top: 40px;
		margin-bottom: 10px;
		${Functions.breakpoint(Breakpoints.laptop)} {
			margin-bottom: 15px;
		}
	}

	h2 {
		${heading2TextStyle};
		margin-top: 40px;
		margin-bottom: 10px;
	}

	h3,
	h4,
	h5,
	h6 {
		${heading3TextStyle};
		margin-top: 26px;
		margin-bottom: 5px;
		${Functions.breakpoint(Breakpoints.laptop)} {
			width: ${Functions.col(6, 8)};
			margin-top: 28px;
		}
	}

	a {
		text-decoration: underline;
		font-family: ${Fonts.GeomanistBold};
	}
`;

export default function TextModule({ data }: { data: { text: string; backgroundColor: any } }) {
	const themeColors: IContentPageState = ContentPageStore.use(state => state);

	return (
		<Container
			className='colorWrapperAnimatedModule'
			bgColor={data.backgroundColor && Functions.hexFromRGB(data.backgroundColor?.color)}
		>
			<Text
				textColor={
					data.backgroundColor ? Functions.hexFromRGB(data.backgroundColor?.contrastColor) : themeColors.contrastColor
				}
				dangerouslySetInnerHTML={{ __html: HTML.clean(data.text) }}
			/>
		</Container>
	);
}
