import Functions from '@client/style/Functions';
import Variables, { Breakpoints, Fonts } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import DownloadButton from '../shared/DownloadButton';
import MagicButtonSwitcher from '../shared/MagicButtonSwitcher';
import SquaredLinkButton from '../shared/SquaredLinkButton';

const Container = styled.div<{ color: string }>`
	width: 100%;

	color: ${props => props.color};

	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 35px;
`;

const LabelContainer = styled.div`
	/* max-width: 60%; */
	gap: 2px;
`;

const Title = styled.div`
	font-family: ${Fonts.GeomanistBold};
	font-size: 22px;
	${Functions.hyphen()}

	${Functions.breakpoint(Breakpoints.tablet)} {
		font-size: 30px;
	}
`;
const InfoText = styled.div`
	font-family: ${Fonts.Geomanist};

	font-size: 16px;
	${Functions.breakpoint(Breakpoints.tablet)} {
		font-size: 22px;
	}
`;

export interface IDownloadEntries {
	button: any;
	title: string;
	extraText: string;
}

export default function DownloadEntry({ entry, textColor }: { entry: IDownloadEntries; textColor: string }) {
	return (
		<Container color={textColor}>
			<LabelContainer>
				<Title>{entry.title}</Title>
				<InfoText>{entry.extraText}</InfoText>
			</LabelContainer>
			<MagicButtonSwitcher button={entry?.button[0]} size={'small'} />
		</Container>
	);
}
