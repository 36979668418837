import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimationContainer, AnimationImageAsset, AnimationImageElement } from '../AnimationCommon';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import WaveContainer from '@client/modules/Dividers.tsx/WaveContainer';

const StyledAnimationContainer = styled(AnimationContainer)`
	margin-top: 60px;
	img {
		max-width: 100%;
		height: auto;
	}
	margin-bottom: -50%;
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-bottom: -15%;
	}
`;

const MobileContainer = styled.div`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;
const DesktopContainer = styled.div`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;

export function IllustrationFinurligheder() {
	const deskTopimage = new AnimationImageAsset(
		'https://www.datocms-assets.com/80292/1686050525-finurligheder-grafik-desktop.png',
		{ width: 2600 }
	);
	const mobileImage = new AnimationImageAsset(
		'https://www.datocms-assets.com/80292/1686050520-finurligheder-grafik.png',
		{
			width: 1100,
		}
	);

	return (
		<StyledAnimationContainer height={1200} width={690} breakpoints={{ mobile: { height: 2250, width: 4000 } }}>
			<WaveContainer side='top' addPadding='bottom'>
				<MobileContainer>
					<AnimationImageElement image={mobileImage} />
				</MobileContainer>
				<DesktopContainer>
					<AnimationImageElement image={deskTopimage} />
				</DesktopContainer>
			</WaveContainer>
		</StyledAnimationContainer>
	);
}
