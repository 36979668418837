import { CommonText, CommonTitle } from '@client/common/CommonText';
import { OverlayController } from '@client/core/modules/Overlays/Overlays';
import { DefaultButton } from '@client/modules/shared/DefaultButton';
import { Breakpoints, Colors } from '@client/style/Variables';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Functions from '@client/style/Functions';
import { LoginPromptOverlayFrame } from '@client/modules/shared/LoginPromptOverlayFrame';
import { context } from '@client/App';
import { useAuthenticatedUser } from '@client/utils/AuthStore';
import Site from '@client/store/Site';

/**
 * Text
 */
const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
`;

const StyledCommonTitle = styled(CommonTitle)`
	color: ${Colors.darkText};
	font-size: 34px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: 48px;
	}
`;

const StyledCommonText = styled(CommonText)`
	color: ${Colors.darkText};
`;

const ButtonContainer = styled.div`
	padding-top: 20px;
`;

interface IProps {
	controller: OverlayController;
}

export const OverlayLoginPrompt = (props: IProps) => {
	const user = useAuthenticatedUser();
	const global = Site.use(state => state.global);

	// close when signed in
	useEffect(() => {
		if (user) {
			props.controller.close();
		}
	}, [user]);

	const onCloseClick = (): void => {
		props.controller.close();
	};

	const onLoginClick = (): void => {
		context.auth.signIn();
		props.controller.close();
	};

	return (
		<LoginPromptOverlayFrame
			backgroundColor={Colors.defaultLightGrey}
			dimColor={Colors.defaultOverlayDim}
			onCloseButtonClick={onCloseClick}
		>
			<Content>
				<StyledCommonTitle>{global.loginPromptTitle}</StyledCommonTitle>
				<StyledCommonText>{global.loginPromptBody}</StyledCommonText>
				<ButtonContainer>
					<DefaultButton
						onClick={onLoginClick}
						title={'Log ind'}
						backgroundColor={Colors.darkBackground}
						textColor={Colors.lightText}
					/>
				</ButtonContainer>
			</Content>
		</LoginPromptOverlayFrame>
	);
};
