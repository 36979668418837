export class AnimationEffect {
	protected _container: HTMLDivElement;

	constructor(item: HTMLDivElement) {
		this._container = item;
	}

	public activate(): void {}
	public deactivate(): void {}
	public kill(): void {}
}
