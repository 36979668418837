import React from 'react';
import styled from 'styled-components';
import { Page, PageContent } from '@style/Shared';
import Site from '@client/store/Site';
import { Breakpoints, Colors } from '@client/style/Variables';
import Functions from '@client/style/Functions';
import { CommonBoldText, CommonTitle } from '@client/common/CommonText';
import LinkButton from '@client/modules/shared/LinkButton';
import SquaredLinkButton from '@client/modules/shared/SquaredLinkButton';

const StyledPage = styled(Page)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const StyledCommonTitle = styled(CommonTitle)`
	color: ${Colors.darkText};
	font-size: '48px';
	max-width: 400px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		font-size: '96px';
		max-width: 800px;
	}
`;

const StyledCommonText = styled(CommonBoldText)`
	color: ${Colors.darkText};
	max-width: 400px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		max-width: 600px;
	}
`;

const StyledLinkButton = styled(SquaredLinkButton)`
	margin-top: 30px;
`;

export default function ErrorPage() {
	const language = Site.use(state => state.language);
	const global = Site.use(state => state.global);

	return (
		<StyledPage>
			<PageContent>
				<StyledCommonTitle>404</StyledCommonTitle>
				<StyledCommonText>{global.pageNotFoundText}</StyledCommonText>

				<StyledLinkButton
					backgroundColor={Functions.hexFromRGB(global.pageNotFoundLink[0].buttonColorLink.color)}
					textColor={Functions.hexFromRGB(global.pageNotFoundLink[0].buttonColorLink.contrastColor)}
					data={global.pageNotFoundLink[0].link[0]}
				/>
			</PageContent>
		</StyledPage>
	);
}
