import { Item } from '@client/core/utils/SpotifyLoader';
import { sendWishes, SimpleArtist, Wishes } from '@client/core/utils/WishingWellLoader';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import IntroModule from './IntroModule';
import SelectArtistModule from './SelectArtistModule';
import SubmitMailModule, { UserProfile } from './SubmitMailModule';
import ThankYouModule from './ThankYouModule';

const Container = styled.div`
	min-height: 100vh;
	/* height: 100%; */
	width: 100%;
	/* max-width: 1024px; */
`;

export default function WishingWellHandler(data: {
	data: {
		introText: string;
		introTitle: string;
		introNextButtonText: string;
		lastStepTitle: string;
		lastStepText: string;
		lastStepButtonText: string;
		thankYouTitle: string;
		thankYouText: string;
		thankYouButtonText: string;
	};
}) {
	const [stage, setStage] = useState<ReactNode>();
	const [currentStage, setCurrentStage] = useState<number>(0);
	const [artists, setArtists] = useState<{ local: Item[]; international: Item[] }>();

	const stages: JSX.Element[] = [
		<IntroModule
			title={data.data.introTitle}
			text={data.data.introText}
			buttonText={data.data.introNextButtonText}
			onClick={() => goNext()}
			key={0}
		></IntroModule>,
		<SelectArtistModule
			onClick={() => goNext()}
			saveArtists={(local: Item[], international: Item[]) => saveArtists(local, international)}
			key={1}
		></SelectArtistModule>,
		<SubmitMailModule
			title={data.data.lastStepTitle}
			text={data.data.lastStepText}
			buttonText={data.data.lastStepButtonText}
			onSubmit={profile => submitAndGoNext(profile)}
			key={2}
		></SubmitMailModule>,
		<ThankYouModule
			title={data.data.thankYouTitle}
			text={data.data.thankYouText}
			buttonText={data.data.thankYouButtonText}
			onClick={() => returnToLanding()}
			key={3}
		></ThankYouModule>,
	];

	useEffect(() => {
		setStage(stages[currentStage]);
	}, [currentStage]);

	const submitAndGoNext = (profile: UserProfile) => {
		submit(profile);
		goNext();
	};

	const returnToLanding = () => {
		window.location.href = '/';
	};

	const goNext = () => {
		setCurrentStage(currentStage + 1);
	};

	const submit = (profile: UserProfile) => {
		const dkArtists: SimpleArtist[] = artists
			? artists.local.map(artist => {
					return { artistName: artist.name, popularity: artist.popularity, type: 'DK' };
			  })
			: [];
		const intArtists: SimpleArtist[] = artists
			? artists.international.map(artist => {
					return { artistName: artist.name, popularity: artist.popularity, type: 'International' };
			  })
			: [];

		const objectToSend: Wishes = {
			age: profile.age ? parseInt(profile.age) : undefined,
			name: profile.profile,
			email: profile.email,
			artists: dkArtists.concat(intArtists),
		};

		sendWishes(objectToSend);
	};

	const saveArtists = (localArtists: Item[], internationalArtists: Item[]) => {
		setArtists({ local: localArtists, international: internationalArtists });
	};

	return <Container>{stage}</Container>;
}
