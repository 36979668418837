import Store from '@core/Store';

export interface IPreloaderState {
	itemsToLoad: number;
	loadedItems: number;
}

export const DefaultState = {
	itemsToLoad: 1,
	loadedItems: 1,
};

export class PreloaderStoreClass extends Store<IPreloaderState> {
	constructor() {
		super(DefaultState);
	}

	public addItems(amount: number) {
		this.set({
			itemsToLoad: this.get().itemsToLoad + amount,
		});
	}

	public addProgress(amount: number) {
		this.set({
			loadedItems: this.get().loadedItems + amount,
		});
	}
}

const PreloaderStore = new PreloaderStoreClass();

export default PreloaderStore;

export class PreloaderDebug {
	private static _loaderList: string[] = [];

	public static add(id: string): void {
		this._loaderList.push(id);

		// console.log(this._loaderList);
	}

	public static remove(id: string): void {
		const index: number = this._loaderList.indexOf(id);
		if (index !== -1) {
			this._loaderList.splice(index, 1);
		}

		// console.log(this._loaderList);
	}
}
