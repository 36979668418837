import React from 'react';
import styled from 'styled-components';
import {
	AnimationElementPosition,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from './AnimationCommon';
import { gsap } from 'gsap';
import { Power1 } from 'gsap';

export default function AnimationElementPegefyr(props: { position: AnimationElementPosition }) {
	const ref = React.useRef<HTMLImageElement>(null);

	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1684229112-pegefyr-1.png', {
		height: 300,
	});

	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		const timeline = gsap.timeline({ repeat: -1, repeatDelay: 1 });
		timeline.set(ref.current, { transformOrigin: 'center left' });

		timeline.fromTo(
			ref.current,
			{
				scaleX: 1,
			},
			{ scaleX: 1.05, duration: 0.5, ease: 'elastic.out(1.5, 0.4)' }
		);
		timeline.to(ref.current, { scale: 1, duration: 0.75, ease: Power1.easeOut });

		containerTimeline.add(timeline, 0);

		return () => {
			timeline.kill();
		};
	}, []);

	return (
		<AnimationPositionElement position={{ ...props.position, origin: '50% 100%' }}>
			<AnimationImageElement ref={ref} image={image}></AnimationImageElement>
		</AnimationPositionElement>
	);
}
