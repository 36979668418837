import { CommonText } from '@client/common/CommonText';
import Functions from '@client/style/Functions';
import React from 'react';
import styled from 'styled-components';
import { FormStoreClass } from './FormStore';
import FormComponent from './FormComponent';
import { Breakpoints } from '@client/style/Variables';
import { FormStoreDataParse } from './FormStoreDataParse';

const Container = styled.div<{ backgroundColor?: string; color?: string }>`
	position: relative;
	padding-left: var(--gridMargin);
	padding-right: var(--gridMargin);
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${props => props.backgroundColor};
	width: 100%;
	color: ${props => props.color};
	padding-bottom: 100px;
	padding-top: 50px;
`;

const InsideContainer = styled.div`
	position: relative;
	margin: auto;

	${Functions.breakpoint(Breakpoints.tablet)} {
		width: ${Functions.col(10)};
		max-width: 740px;
	}
`;

const StyleCommonText = styled(CommonText)``;

interface IProps {
	data: {
		text: string;
		loadText: string;
		sentText: string;
		backgroundColorLink: any;
		moduleId: string;
		form: {
			id: string;
			formFields: any[];
		};
	};
}
export default function FormModule(props: IProps) {
	const newStore = new FormStoreClass(
		FormStoreDataParse.CMSDataToState(props.data.form.formFields),
		props.data.form.id
	);

	return (
		<Container
			className='colorWrapperAnimatedModule'
			backgroundColor={Functions.hexFromRGB(props.data.backgroundColorLink?.color)}
			color={Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor)}
			id={'module_' + props.data.moduleId}
		>
			<InsideContainer>
				<StyleCommonText>{props.data.text}</StyleCommonText>

				<FormComponent
					loadText={props.data.loadText}
					sentText={props.data.sentText}
					store={newStore}
					preloaderColors={{
						background: Functions.hexFromRGB(props.data.backgroundColorLink?.color) ?? '#ffffff',
						foreground: Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor) ?? '#000000',
					}}
				/>
			</InsideContainer>
		</Container>
	);
}
