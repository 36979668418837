import Loader from '../Loader';

export async function loadArtists(name: string): Promise<Item[]> {
	if (encodeURIComponent(name.trim()).length > 0) {
		const artists = await Loader.get(`/spotify/artists?name=${encodeURIComponent(name.trim())}&limit=6&market=DK`);
		const json = JSON.parse(artists);
		const data: SpotifyArtistRootObject = JSON.parse(json.data);
		// console.log('SpotifyLoader().loadArtists(); result = ', data);
		return data.artists.items;
	} else {
		return [];
	}
}

export interface ExternalUrls {
	spotify: string;
}

export interface Followers {
	href?: any;
	total: number;
}

export interface Image {
	height: number;
	url: string;
	width: number;
}

export interface Item {
	external_urls?: ExternalUrls;
	followers?: Followers;
	genres?: string[];
	href?: string;
	id?: string;
	images?: Image[];
	name: string;
	popularity?: number;
	type?: string;
	uri?: string;
}

export interface Artists {
	href: string;
	items: Item[];
	limit: number;
	next: string;
	offset: number;
	previous?: any;
	total: number;
}

export interface SpotifyArtistRootObject {
	artists: Artists;
}
