import { ImageData } from '@client/modules/Image';
import InfoContainer from '@client/modules/Map/InfoContainer';
import MapModule from '@client/modules/Map/MapModule';
import MapStore from '@client/modules/Map/MapStore';
import Functions from '@client/style/Functions';
import { Breakpoints, presetColors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	height: 100%;
	width: 100vw;

	overflow: hidden;

	position: absolute;
`;

const BackArrowContainer = styled.div`
	top: 30px;
	left: 20px;
	position: absolute;

	${Functions.breakpoint(Breakpoints.tablet)} {
		top: 40px;
		left: 75px;
	}
`;

const BackArrow = styled.button<{ color?: string }>`
	width: 68px;
	height: 68px;
	border-radius: 50%;

	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${presetColors.evergreen};
	svg {
		path {
			stroke: ${props => props.color};
		}
	}
`;

export interface ILocation {
	backgroundColorLink: any;
	image: ImageData;
	info: { label: string; text: string }[];
	lydNiveauer: 'no_info' | 'low' | 'mid' | 'high';
	title: string;
	uid: string;
	xPosition: number;
	yPosition: number;
}

export default function MapPage(props: {
	content: {
		locations: ILocation[];
		lavLyd: string;
		midLyd: string;
		highLyd: string;
		highLydColor: any;
		midLydColor: any;
		lavLydColor: any;
		ingenLydinfoColor: any;
	};
}) {
	const [isInfoOpen, setIsInfoOpen] = React.useState(false);
	const [currentLocation, setCurrentLocation] = React.useState<ILocation | undefined>(undefined);
	const startFocus = MapStore.use(state => state.mapFocus);

	const [mapOffsetX, setMapOffsetX] = React.useState(0);
	const [mapOffsetY, setMapOffsetY] = React.useState(0);

	const changeLocation = (locationId: string) => {
		const location = props.content.locations.find(location => location.uid === locationId);
		if (location) {
			setCurrentLocation(location);
			setIsInfoOpen(true);
		} else {
			setIsInfoOpen(false);
		}
	};

	const offsetChange = (offset: { x: number; y: number }) => {
		setMapOffsetX(offset.x);
		setMapOffsetY(offset.y);
	}

	const findStartFocus = (locationId: string): { x: number; y: number } => {
		const location = props.content.locations.find(location => location.uid === locationId);
		if (!location) return { x: -1975, y: -809 };
		return { x: location.xPosition, y: location.yPosition };
	};

	return (
		<Container>
			<MapModule
				onLocationSelect={changeLocation}
				startFocus={findStartFocus(startFocus)}
				offsetX={mapOffsetX}
				offsetY={mapOffsetY}
			></MapModule>
			<BackArrowContainer>
				<BackArrow color={presetColors.white} onClick={() => history.back()}>
					<svg width='15' height='24' viewBox='0 0 15 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path d='M12.7569 21.9679L2.85742 12.0684L12.7569 2.16886' stroke='#262C2E' strokeWidth='4' />
					</svg>
				</BackArrow>
			</BackArrowContainer>

			<InfoContainer
				show={isInfoOpen}
				currentLocation={currentLocation}
				onOffsetChange={offsetChange}
				soundColors={{
					noSoundColor: props.content?.ingenLydinfoColor,
					lowSoundColor: props.content?.lavLydColor,
					midSoundColor: props.content?.midLydColor,
					highSoundColor: props.content?.highLydColor,
				}}
				soundTexts={{
					lowSoundText: props.content?.lavLyd,
					midSoundText: props.content?.midLyd,
					highSoundText: props.content?.highLyd,
				}}
				onClose={() => setIsInfoOpen(false)}
			></InfoContainer>
		</Container>
	);
}
