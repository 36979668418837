import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import Image, { ImageData } from '../Image';

const Container = styled.button`
	flex: 0 0 ${Functions.col(2, 4, 0)};
	height: auto;
	margin-bottom: 60px;
	box-sizing: border-box;
	${Functions.breakpoint(Breakpoints.laptop)} {
		flex: 0 0 ${Functions.col(1, 4, 0)};
	}
`;

const StyledImage = styled(Image)`
	height: auto;
	img {
		object-fit: cover;
		aspect-ratio: 1/1;
	}
`;

export default function LegekammeratEntry({ image, onClick }: { image: ImageData; onClick: () => void }) {
	return (
		<Container onClick={onClick}>
			<StyledImage data={image}></StyledImage>
		</Container>
	);
}
