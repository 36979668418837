import gsap from 'gsap/all';
import React from 'react';
import {
	AnimationElementPosition,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
	useAnimationContainerTimeline,
} from './AnimationCommon';

export const AnimationElementShades = (props: { position: AnimationElementPosition }) => {
	const shadesRef = React.useRef<HTMLImageElement>(null);

	const image = new AnimationImageAsset('https://www.datocms-assets.com/80292/1676885936-shades.png', { height: 63 });

	const containerTimeline = useAnimationContainerTimeline();

	React.useEffect(() => {
		gsap.set(shadesRef.current, {
			transformOrigin: '0% 100%',
		});
		const enterTween = gsap.fromTo(
			shadesRef.current,
			{
				scale: 1,
				y: '-30%',
				x: '-10%',
				rotate: -30,
			},
			{
				scale: 1,
				y: '0%',
				x: '0%',
				rotate: 0,
				duration: 0.5,
				ease: 'power3.inOut',
			}
		);

		containerTimeline.add(enterTween, 0);

		return () => {
			enterTween.kill();
		};
	}, []);

	return (
		<AnimationPositionElement position={{ ...props.position, origin: '50% 100%' }}>
			<AnimationImageElement ref={shadesRef} image={image} />
		</AnimationPositionElement>
	);
};
