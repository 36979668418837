import Store from '@client/core/Store';

export interface ICollectionInfoState {
	collectionInfo: ICollectionInfo[];
	activeInfo: ICollectionInfo | undefined;
}

export interface ICollectionInfo {
	id: string | undefined;
	itemType: string | undefined;
	modelId: string | undefined;
	name: string | undefined;
	modules: any[] | undefined;
	subtitle: string | undefined;
	sortingValue: number | undefined;
	backgroundColor: { red: number; green: number; blue: number } | undefined;
	headerColor: { red: number; green: number; blue: number } | undefined;
}

export const DefaultState = {
	collectionInfo: [],
	activeInfo: {
		id: undefined,
		itemType: undefined,
		modelId: undefined,
		name: undefined,
		modules: [],
		subtitle: undefined,
		sortingValue: undefined,
		backgroundColor: undefined,
		headerColor: undefined,
	},
};

export class CollectionInfoStoreClass extends Store<ICollectionInfoState> {
	constructor() {
		super(DefaultState);
	}

	public setCollectionInfo(types: ICollectionInfo[]) {
		this.set({ collectionInfo: types });
	}

	public setSelectedCollectionInfo(activeInfo: ICollectionInfo) {
		this.set({ activeInfo: activeInfo });
	}
}

const CollectionInfoStore = new CollectionInfoStoreClass();

export default CollectionInfoStore;
