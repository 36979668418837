import Carousel, { ImperativeCarousel } from '@client/core/modules/Carousel/Carousel';
import Functions from '@client/style/Functions';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Image, { ImageData } from '../Image';
import CarouselItem from './CarouselItem';

const Container = styled.div`
	position: relative;
`;
const StyledCarousel = styled(Carousel)``;
const StyledImages = styled(Image)<{ ratio: string; placementComparedToActive: '100%' | '50%' | '0%' }>`
	aspect-ratio: ${props => props.ratio};
	height: ${props => (props.ratio === '16/9' ? 'auto' : '100%')};
	max-height: 100%;
	width: ${props => (props.ratio === '16/9' ? `${Functions.col(14, 16)}` : 'auto')};
	position: absolute;
	transform: translate(-${props => props.placementComparedToActive}, 0%);
	left: ${props => props.placementComparedToActive};

	transition: all 0.5s;
`;

const CarouselEntry = styled.div`
	height: 850px;
	flex: 1 0 ${Functions.col(16, 16)};
	position: relative;

	display: flex;
	align-items: center;
`;
const PointerCatcher = styled.div`
	position: absolute;
	width: 100vw;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0.5;

	display: flex;
	margin-left: calc(-1 * var(--gridMargin));
`;

const PointerCatcherItem = styled.div`
	cursor: pointer;
	flex: 1 0;
	pointer-events: all;
`;

export default function ImageCarousel({ images }: { images: ImageData[] }) {
	const carouselRef = useRef<ImperativeCarousel | null>(null);
	const leftTriggerRef = useRef<HTMLDivElement | null>(null);
	const rightTriggerRef = useRef<HTMLDivElement | null>(null);

	const [activeIndex, setActiveIndex] = useState(0);

	const incrementActiveIndex = () => {
		if (activeIndex < images.length - 1) {
			setActiveIndex(activeIndex + 1);
		} else {
			setActiveIndex(0);
		}
	};

	const decrementActiveIndex = () => {
		if (activeIndex > 0) {
			setActiveIndex(activeIndex - 1);
		} else {
			setActiveIndex(images.length - 1);
		}
	};

	return (
		<Container>
			<StyledCarousel ref={carouselRef} activeIndex={activeIndex} isArtistCarousel={true}>
				{images.map((image, index) => {
					return (
						<CarouselEntry key={index}>
							<StyledImages
								data={image}
								ratio={Functions.findRatio(image.width, image.height)}
								placementComparedToActive={index - activeIndex > 0 ? '0%' : index - activeIndex === 0 ? '50%' : '100%'}
							></StyledImages>
						</CarouselEntry>
					);
				})}
			</StyledCarousel>
			<PointerCatcher>
				<PointerCatcherItem ref={leftTriggerRef} onClick={decrementActiveIndex} />
				<PointerCatcherItem ref={rightTriggerRef} onClick={incrementActiveIndex} />
			</PointerCatcher>
		</Container>
	);
}
