import { Observable } from '@client/core/utils/Observable';
import Store from '@core/Store';
import {} from '../core/auth/azure/AzureAuthHelper';
import { IAzureAuthData } from '../core/auth/azure/IAzureAuthConfig';
import { AzureAuthStatus } from '@client/core/auth/azure/AzureAuth';

export interface IAuthState {
	user: IAzureAuthData | undefined;
	status: AzureAuthStatus;
}

export const DefaultState = {
	user: undefined,
	status: '' as AzureAuthStatus,
};

export class AuthStoreClass extends Store<IAuthState> {
	public userSignedIn: Observable<IAzureAuthData> = new Observable<IAzureAuthData>();
	public userSignedOut: Observable<{}> = new Observable<{}>();

	constructor() {
		super(DefaultState);
	}

	public setUser(user: IAzureAuthData | undefined): void {
		this.set({
			user: user,
		});

		if (user) {
			this.userSignedIn.trigger(user);
		} else {
			this.userSignedOut.trigger({});
		}
	}

	public setStatus(status: AzureAuthStatus): void {
		this.set({
			status: status,
		});
	}
}

const AuthStore = new AuthStoreClass();

export default AuthStore;

export const useAuthenticatedUser = () => {
	return AuthStore.use(state => state.user);
};

export const useAuthenticatedStatus = () => {
	return AuthStore.use(state => state.status);
};
