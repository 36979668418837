import Storage from './Storage';
import create, { UseBoundStore, StoreApi } from 'zustand';

export interface IPersistConfig {
	id: string | null;
	flush: boolean;
}

export const DefaultPersist: IPersistConfig = {
	id: null,
	flush: false,
};

export default class Store<T extends object> {
	private _zustand: UseBoundStore<StoreApi<T>>;
	public get zustand() {
		return this._zustand;
	}

	private _id: string | null;

	/**
	 * Create Zustand store wrapper
	 * @param state - The initial state
	 * @param [id] - Optional store ID to make the store persistent
	 */
	constructor(state: T, id: string | null = null) {
		this._id = id;

		if (this._id) {
			state = Storage.get<T>(this._id) ?? state;
		}

		this._zustand = create<T>(() => state);
	}

	/**
	 * Hook to use a store value
	 * @param selector - Selector of the specific value to listen on
	 * @param equals - Optional state equality checker
	 */
	public use = <U>(selector: (state: T) => U, equals?: (a: U, b: U) => boolean): U => {
		return this._zustand(selector, equals);
	};

	/**
	 * Get the full store state
	 */
	public get = (): T => {
		return this._zustand.getState();
	};

	/**
	 * Set the full or partial store state
	 * @param partial - Partial state, or function that returns partial state
	 * @param [replace] - Boolean to replace
	 */
	public set = (partial: T | Partial<T> | ((state: T) => T | Partial<T>), replace?: boolean) => {
		this._zustand.setState(partial, replace);

		if (this._id) {
			Storage.set<T>(this._id, this._zustand.getState());
		}
	};

	/**
	 * Event listener to check state updates
	 * @param listener - Function that gets new state and previous state objects
	 * @returns Function to remove listener
	 */
	public sub = (listener: (state: T, prevState: T) => void): (() => void) => {
		return this._zustand.subscribe(listener);
	};

	/**
	 * If the store is persistent, flush the stored value
	 */
	public flush = () => {
		if (this._id) {
			Storage.remove(this._id);
		}
	};
}
