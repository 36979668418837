import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export interface ImageData {
	height: number;
	width: number;
	smartTags: Array<string>;
	tags: Array<string>;
	isImage: boolean;
	mimeType: string;
	format: string;
	url: string;
	defaultFieldMetadata: {
		alt: string;
		focalPoint: { x: number; y: number };
		title: string;
	};
}

export interface ImageProps {
	data?: ImageData;
	alt?: string;
	title?: string;
	src?: string;
	cache?: string;
	width?: number | string;
	height?: number | string;
	minWidth?: number;
	maxWidth?: number | null;
	className?: string;
	lossless?: boolean;
}

export const ImgWrapper = styled.div<{
	height?: number | string;
	width?: number | string;
	focalPoint?: { x: number; y: number };
}>`
	position: relative;
	height: ${props => (props.height ? props.height : '100%')};
	width: ${props => (props.width ? props.width : '100%')};
	display: flex;

	img {
		height: ${props => (props.height ? props.height : '100%')};
		width: ${props => (props.width ? props.width : '100%')};
		object-fit: cover;
		object-position: ${props =>
			props.focalPoint ? props.focalPoint.x * 100 + '% ' + props.focalPoint.y * 100 + '%' : 'center'};
	}
`;

export default function Image(props: ImageProps) {
	let src = props.src ? props.src : props.data ? props.data.url : null;
	let srcSet: string | undefined = undefined;

	const [width, setWidth] = useState<number | null>(null);
	const [hasBeenInView, setHasBeenInView] = useState(false);
	const imageRef = useRef<HTMLDivElement | null>(null);

	const onResize = () => {
		setWidth(imageRef.current?.clientWidth ?? 0);
	};

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
		return function () {
			window.removeEventListener('resize', onResize);
		};
	}, [imageRef]);

	useEffect(() => {
		const intersectionObserver = new IntersectionObserver(
			entries => {
				if (entries.length === 0) return;
				if (entries[0].isIntersecting) {
					setHasBeenInView(true);
					if (imageRef.current) {
						intersectionObserver.unobserve(imageRef.current as Element);
					}
				}
			},
			{ rootMargin: '100px' }
		);
		intersectionObserver.observe(imageRef.current as Element);

		return function () {
			if (imageRef.current) {
				intersectionObserver.unobserve(imageRef.current as Element);
			}
			window.removeEventListener('resize', onResize);
		};
	}, [imageRef]);

	if (!src)
		return (
			<ImgWrapper
				height={props.height}
				width={props.width}
				className={props.className}
				focalPoint={props.data && props.data.defaultFieldMetadata.focalPoint}
			></ImgWrapper>
		);

	if (src.includes('datocms-assets')) {
		const url = new URL(src);
		const search = url.searchParams;

		const aspectRatio =
			(imageRef.current &&
				imageRef.current?.getBoundingClientRect().width / imageRef.current?.getBoundingClientRect().height) ||
			-1;

		search.set('q', '70');
		search.set('auto', 'format');

		if (props.cache) search.set('cache', props.cache);
		if (props.width && props.height) {
			search.set('h', `${parseInt(props.height as string)}`);
			search.set('w', `${parseInt(props.width as string)}`);
		} else if (props.minWidth) {
			search.set('w', `${props.minWidth}`);
		} else {
			search.set('w', `150`);
		}

		search.set('fit', 'crop');
		search.set('crop', 'center');
		src = url.toString();

		let maxWidth = props.maxWidth ?? props.data?.width ?? (props.width ? parseInt(props.width as string) : 1200);
		if (props.maxWidth && props.data?.width && props.maxWidth > props.data?.width) {
			maxWidth = props.data?.width;
		}
		if (!props.maxWidth) maxWidth = Math.min(maxWidth, 1200);
		const minWidth = props.minWidth || 150;

		if (minWidth < maxWidth) {
			search.delete('h');
			search.delete('w');

			const loops = 6;
			const jump = (maxWidth - minWidth) / loops;
			const base = url.toString();
			srcSet = '';
			for (let i = 0; i <= loops; i++) {
				const width = Math.round(minWidth + jump * i);

				if (aspectRatio > 0.9 && aspectRatio < 1.1) {
					srcSet += `${base}&w=${width}&h=${width} ${width}w${i < loops ? ', ' : ''}`;
				} else if (aspectRatio !== -1) srcSet += `${base}&w=${width} ${width}w${i < loops ? ', ' : ''}`;
			}
		}
	}

	const readyToLoad = hasBeenInView && width;

	return (
		<ImgWrapper
			height={props.height ?? undefined}
			width={props.width ?? undefined}
			className={props.className}
			focalPoint={props.data?.defaultFieldMetadata.focalPoint}
			ref={imageRef}
		>
			<img
				height={props.height ?? undefined}
				width={props.width ?? undefined}
				alt={props.alt ?? props.data?.defaultFieldMetadata.alt}
				title={props.title ?? props.data?.defaultFieldMetadata.title}
				src={readyToLoad ? src : undefined}
				srcSet={readyToLoad ? srcSet : undefined}
				sizes={readyToLoad ? `${width}px` : undefined}
			/>
		</ImgWrapper>
	);
}
