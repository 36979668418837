import { Color } from './Color';

export class ColorUtils {
	public static getRandomHex(addHex: boolean = true): string {
		const preFix = addHex ? '#' : '';
		return preFix + this.getRandom().toString(16);
	}

	public static getRandom() {
		return Math.floor(Math.random() * 16777215);
	}

	public static hexToRGB(hex: string, alpha: number) {
		const r = parseInt(hex.slice(1, 3), 16),
			g = parseInt(hex.slice(3, 5), 16),
			b = parseInt(hex.slice(5, 7), 16);

		if (alpha) {
			return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
		} else {
			return 'rgb(' + r + ', ' + g + ', ' + b + ')';
		}
	}

	public static hexNumToRGB(hex: number, alpha: number) {
		const r = hex >> 16;
		const g = (hex >> 8) & 0xff;
		const b = hex & 0xff;
		return { r: r / 255, g: g / 255, b: b / 255, a: alpha };
	}

	public static cssColorToPIXIColor(cssColor: string) {
		return parseInt(cssColor.substring(1, cssColor.length - 1), 8);
	}

	public static rgbToHex(r: number, g: number, b: number, a?: number): string {
		let hex: string = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

		if (a !== undefined) {
			const alpha = Math.round(0o1 * 255);
			const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
			hex += hexAlpha;
		}

		return hex;
	}

	public static CSStoHEX(color: string) {
		return parseInt(color.replace('#', '0x'));
	}

	public static lerp(a: string, b: string, amount: number) {
		const ah: number = ColorUtils.CSStoHEX(a);
		const bh = ColorUtils.CSStoHEX(b);

		const ar = ah >> 16,
			ag = (ah >> 8) & 0xff,
			ab = ah & 0xff,
			br = bh >> 16,
			bg = (bh >> 8) & 0xff,
			bb = bh & 0xff,
			rr = ar + amount * (br - ar),
			rg = ag + amount * (bg - ag),
			rb = ab + amount * (bb - ab);
		return '#' + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1);
	}

	public static findContrastingColor(color: string | undefined, lightColor: string, darkColor: string) {
		if (color === undefined || color === null) return darkColor;

		let newColor = new Color(color);
		// console.log(newColor.yiq);
		if (newColor.yiq > 127) {
			return darkColor;
		} else {
			return lightColor;
		}
	}
}
