import { Colors, Fonts } from '@client/style/Variables';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ObjectSlideInAnimation from '../shared/ObjectSlideInAnimation';
import TextSlideInAnimation from '../shared/TextSlideInAnimation';
import Site from '@client/store/Site';
import PreloaderStore from '../Preloader/PreloaderStore'; //@ts-ignore
import WaltherLogo from '../../assets/svgs/waltherlogo.svg';

const Container = styled.div`
	display: flex;
`;
interface IProps {
	mode?: 'light' | 'dark';
	color: string;
}

const TextContainer = styled.div<IProps>`
	margin-left: 10px;
	margin-top: 3px;
	color: ${props => props.color};
`;

const StaticWaltherLogoContainer = styled.div<IProps>`
	height: 42px;
	width: 43px;
	fill: ${props => props.color};
`;

const Title = styled.div`
	font-size: 18px;
	font-family: ${Fonts.GeomanistBold};
`;

const DateContainer = styled.div`
	font-size: 16px;
	font-family: ${Fonts.Geomanist};
	margin-top: -3px;
	letter-spacing: -0.6px;
`;

export default function LogoWithText({ mode, color }: IProps) {
	const topMenuInformation = Site.use(state => state.global);
	const loadedItems = PreloaderStore.use(state => state.loadedItems);
	const itemsToLoad = PreloaderStore.use(state => state.itemsToLoad);
	const [animReady, setAnimReady] = useState(false);

	useEffect(() => {
		if (loadedItems === itemsToLoad) setAnimReady(true);
	}, [loadedItems]);

	const stopWaltherWalking = () => {};

	return (
		<Container>
			{animReady && (
				<>
					{/* <ObjectSlideInAnimation direction='fromLeft' duration={1.5} onCompleteExecution={stopWaltherWalking}> */}
					<StaticWaltherLogoContainer mode={mode} color={color}>
						<WaltherLogo />
					</StaticWaltherLogoContainer>
					{/* </ObjectSlideInAnimation> */}

					<TextContainer mode={mode} color={color}>
						<Title>
							{/* <TextSlideInAnimation text={topMenuInformation.topMenuTitle} delay={0.3} breakAtWord={true} /> */}
							{topMenuInformation.topMenuTitle}
						</Title>

						<DateContainer>
							{/* <TextSlideInAnimation
								text={topMenuInformation.topMenuDato}
								breakAtWord={true}
								direction='fromLeft'
								delay={0.6}
								/> */}
							{topMenuInformation.topMenuDato}
						</DateContainer>
					</TextContainer>
				</>
			)}
		</Container>
	);
}
