import { CommonTitle } from '@client/common/CommonText';
import HTML from '@client/core/utils/HTML';
import Functions from '@client/style/Functions';
import { Breakpoints, Colors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ bgColor?: string }>`
	z-index: 0;
	position: relative;
	flex-direction: column;
	gap: 100px;
	display: flex;
	background-color: ${props => props.bgColor || 'white'};
	height: 100%;
	padding-top: 200px;
	padding-bottom: 100px;
	pointer-events: none;
	align-items: center;
`;

const IFrameContainer = styled.div<{
	width: number;
	height: number;
}>`
	width: 100%;
	aspect-ratio: 1920 / 1080;
	height: auto;
	pointer-events: all;
	display: flex;
`;

const StyledCommonTitle = styled(CommonTitle)<{ textColor?: string }>`
	color: ${props => props.textColor || Colors.darkText};
	font-size: 44px;
	width: 100%;
	text-align: center;
	pointer-events: none;
	hyphens: auto;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 86px;
		width: ${Functions.col(16)};
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		width: ${Functions.col(12)};
	}

	${Functions.breakpoint(Breakpoints.ultra)} {
		width: ${Functions.col(8)};
	}
`;

export default function FullBleedEmbed({
	data,
}: {
	data: { src: string; size: string; title: string; backgroundColorLink: any };
}) {
	const split: string[] = data.size.split('x');
	const width: number = parseInt(split[0]);
	const height: number = parseInt(split[1]);

	// const isSmallScreen = useIsMobile('mobile');

	// console.log('isSmallScreen : ' + isSmallScreen);
	// const ratio = width / height;
	// let space = isSmallScreen ? (ratio > 1.5 ? 200 : 100) : 100;
	// space = 100;
	// console.log('ratio : ' + ratio);

	return (
		<Container
			bgColor={data.backgroundColorLink && Functions.hexFromRGB(data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			{data.title && (
				<StyledCommonTitle
					textColor={
						data.backgroundColorLink?.contrastColor
							? Functions.hexFromRGB(data.backgroundColorLink?.contrastColor)
							: undefined
					}
					dangerouslySetInnerHTML={{ __html: HTML.clean(data.title) }}
				/>
			)}

			<IFrameContainer width={width} height={height}>
				<iframe
					src={data.src}
					width='100%'
					height='100%'
					frameBorder='0'
					scrolling='no'
					allowFullScreen
					allow='autoplay; fullscreen'
				/>
			</IFrameContainer>
		</Container>
	);
}
