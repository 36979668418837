import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AnimationContainer } from '../AnimationCommon';
import Functions from '@client/style/Functions';
import { Breakpoints } from '@client/style/Variables';
import { AnimationElementTreetop } from '../AnimationElementTreetop';

const StyledAnimationContainer = styled(AnimationContainer)`
	height: 42vw;
	pointer-events: none;
	z-index: 1;

	${Functions.breakpoint(Breakpoints.mobile)} {
		height: 18vw;
	}

	${Functions.breakpoint(Breakpoints.desktop)} {
		height: 15vw;
	}

	img {
		max-width: 100%;
		height: auto;
	}
`;

const MobileContainer = styled.div`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;
const DesktopContainer = styled.div`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;
const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;

const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 40%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 60%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
`;

export function IllustrationBush1() {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (moduleRef.current) {
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor == null && nextColor == null) {
				setBackgroundColors([undefined, undefined]);
			} else if (prevColor == null) {
				setBackgroundColors([undefined, nextColor]);
			} else if (nextColor == null) {
				setBackgroundColors([prevColor, undefined]);
			} else {
				setBackgroundColors([prevColor, nextColor]);
			}
		}
	}, [moduleRef]);

	return (
		<StyledAnimationContainer
			height={910}
			width={690}
			breakpoints={{ mobile: { height: 1100, width: 2600 }, ultra: { width: 4000, height: 1846 } }}
			ref={moduleRef}
		>
			<ColorWrapper>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>

			<DesktopContainer>
				<AnimationElementTreetop
					variant={4}
					position={{
						anchor: 'right',
						y: '-60%',

						x: '49%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '27vw' },
							tablet: { width: '27vw' },
							laptop: { width: '27vw' },
							desktop: { width: '27vw' },
							ultra: { width: '27vw' },
							largeDesktop: { width: '27vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={1}
					position={{
						anchor: 'center',
						y: '16%',
						x: '-93%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '42vw' },
							tablet: { width: '42vw' },
							laptop: { width: '42vw' },
							desktop: { width: '42vw' },
							ultra: { width: '42vw' },
							largeDesktop: { width: '42vw' },
						},
					}}
				></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={3}
					position={{
						anchor: 'right',
						x: '-43%',
						y: '-20%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '35vw' },
							tablet: { width: '45vw' },
							laptop: { width: '35vw' },
							desktop: { width: '35vw' },
							ultra: { width: '35vw' },
							largeDesktop: { width: '35vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={1}
					position={{
						anchor: 'center',
						y: '-20%',
						x: '-53%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '42vw' },
							tablet: { width: '52vw' },
							laptop: { width: '42vw' },
							desktop: { width: '42vw' },
							ultra: { width: '42vw' },
							largeDesktop: { width: '42vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={8}
					position={{
						anchor: 'right',
						x: '1%',
						y: '-41%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '30vw' },
							tablet: { width: '30vw' },
							laptop: { width: '30vw' },
							desktop: { width: '30vw' },
							ultra: { width: '30vw' },
							largeDesktop: { width: '30vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={3}
					position={{
						flip: true,
						y: '-42%',
						x: '-44%',
						breakpoints: {
							tiny: { width: '0vw' },
							mobile: { width: '36vw' },
							tablet: { width: '36vw' },
							laptop: { width: '36vw' },
							desktop: { width: '36vw' },
							ultra: { width: '36vw' },
							largeDesktop: { width: '36vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={2}
					position={{
						x: '-55%',
						y: '35%',
						breakpoints: {
							tiny: { width: '80vw' },
							mobile: { width: '27vw', y: '40%', x: '-44%' },
							tablet: { width: '27vw' },
							laptop: { width: '27vw' },
							desktop: { width: '27vw' },
							ultra: { width: '27vw' },
							largeDesktop: { width: '27vw' },
						},
					}}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={6}
					position={{
						anchor: 'center',
						x: '-120%',
						y: '-17%',
						breakpoints: {
							tiny: { width: '32vw' },
							mobile: { width: '32vw' },
							tablet: { width: '32vw' },
							laptop: { width: '32vw' },
							desktop: { width: '32vw' },
							largeDesktop: { width: '32vw' },
							ultra: { width: '32vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<MobileContainer>
				<AnimationElementTreetop
					variant={3}
					position={{ anchor: 'right', x: '50%', y: '-30%', breakpoints: { tiny: { width: '71vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={4}
					position={{ anchor: 'right', x: '47%', y: '57%', breakpoints: { tiny: { width: '64vw' } } }}
				></AnimationElementTreetop>
				<AnimationElementTreetop
					variant={3}
					position={{ flip: true, x: '-38%', y: '-20%', breakpoints: { tiny: { width: '71vw' } } }}
				></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={2}
					position={{
						x: '-55%',
						y: '35%',
						breakpoints: {
							tiny: { width: '80vw' },
							mobile: { width: '27vw', y: '40%', x: '-44%' },
							tablet: { width: '27vw' },
							laptop: { width: '27vw' },
							desktop: { width: '27vw' },
							ultra: { width: '27vw' },
							largeDesktop: { width: '27vw' },
						},
					}}
				></AnimationElementTreetop>

				<AnimationElementTreetop
					variant={2}
					position={{
						anchor: 'center',
						x: '-40%',
						y: '15%',
						breakpoints: {
							tiny: { width: '80vw' },
						},
					}}
				></AnimationElementTreetop>
			</MobileContainer>

			<DesktopContainer>
				<AnimationElementTreetop
					variant={7}
					position={{
						anchor: 'right',
						x: '1%',
						y: '-10%',
						breakpoints: {
							tiny: { width: '39vw' },
							mobile: { width: '39vw' },
							tablet: { width: '39vw' },
							laptop: { width: '39vw' },
							desktop: { width: '39vw' },
							largeDesktop: { width: '39vw' },
							ultra: { width: '39vw' },
						},
					}}
				></AnimationElementTreetop>
			</DesktopContainer>
			<AnimationElementTreetop
				variant={9}
				position={{
					x: '-8%',
					y: '20%',
					breakpoints: {
						tiny: { width: '58vw' },
						mobile: { width: '27vw', x: '-1%', y: '-3%' },
						tablet: { width: '27vw' },
						laptop: { width: '27vw' },
						desktop: { width: '27vw' },
						largeDesktop: { width: '27vw' },
						ultra: { width: '27vw' },
					},
				}}
			></AnimationElementTreetop>
			<MobileContainer>
				<AnimationElementTreetop
					variant={8}
					position={{ x: '2%', y: '10%', anchor: 'right', breakpoints: { tiny: { width: '55vw' } } }}
				></AnimationElementTreetop>
			</MobileContainer>
		</StyledAnimationContainer>
	);
}
