import React from 'react';
import { ReactChild } from 'react';
import styled from 'styled-components';
import { OverlayController, Overlays } from './Overlays';
import OverlaysStore from './OverlaysStore';

const Container = styled.div`
	width: 100%;
	height: 100%;
	z-index: 9999;
	position: fixed;
	top: 0px;
	left: 0px;
	pointer-events: none;
`;

export const OverlaysComponent = () => {
	const activeId: string | undefined = OverlaysStore.use(state => state.activeId);

	const renderOverlay = (): ReactChild | undefined => {
		if (!activeId) {
			return;
		}

		const controller: OverlayController | undefined = Overlays.Instance.getControllerById(activeId);

		if (!controller) {
			return;
		}

		return controller.data.create(controller);
	};

	return <Container>{renderOverlay()}</Container>;
};
