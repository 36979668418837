import gsap, { Quad } from 'gsap';
import { AnimationEffect as AnimationEffect } from '../AnimationEffect';

export class HoverEffect extends AnimationEffect {
	private _timeline;

	private _speed: number = 3;
	private _force: number = 20;

	constructor(item: HTMLDivElement) {
		super(item);
	}

	public activate(): void {
		this._timeline = gsap.timeline({ repeat: -1 });

		this._timeline.set(this._container, {
			y: -this._force,
		});
		this._timeline.to(this._container, {
			duration: this._speed,
			y: +this._force,
			ease: Quad.easeInOut,
		});
		this._timeline.to(this._container, {
			duration: this._speed,
			y: -this._force,
			ease: Quad.easeInOut,
		});
	}

	public deactivate(): void {
		if (this._timeline) {
			this._timeline.kill();
		}
	}
}
