import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	AnimationContainer,
	AnimationImageAsset,
	AnimationImageElement,
	AnimationPositionElement,
} from '../AnimationCommon';
import { Breakpoints } from '@client/style/Variables';
import Functions from '@client/style/Functions';
import AutomatedBreakerModule from '@client/modules/Dividers.tsx/AutomatedBreakerModule';
import Breaker from '@client/modules/Dividers.tsx/Breaker';

const StyledAnimationContainer = styled(AnimationContainer)<{ $svgColor?: string }>`
	svg > path {
		fill: ${props => props.$svgColor || ''};
	}
	img {
		max-width: 100%;
		height: auto;
	}
`;
const MobileContainer = styled.div`
	display: block;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: none;
	}
`;
const DesktopContainer = styled.div`
	display: none;
	${Functions.breakpoint(Breakpoints.mobile)} {
		display: block;
	}
`;
const ColorWrapper = styled.div`
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	position: absolute;
`;
const TopColor = styled.div<{ bgColor?: string }>`
	top: 0;
	left: 0;
	width: 100%;
	height: 95%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;
	${Functions.breakpoint(Breakpoints.tablet)} {
		height: 98%;
	}
`;
const BottomColor = styled.div<{ bgColor?: string }>`
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5%;
	z-index: -1;
	background-color: ${props => props.bgColor || ''};
	position: absolute;

	${Functions.breakpoint(Breakpoints.tablet)} {
		top: 98%;
		height: 2%;
	}
`;

const StyledBreaker = styled(Breaker)`
	position: absolute;
	bottom: 10px;
`;

export function IllustrationCrewWalter() {
	const [backgroundColors, setBackgroundColors] = useState<[string | undefined, string | undefined]>([
		undefined,
		undefined,
	]);
	const moduleRef = useRef<HTMLDivElement | null>(null);

	const desktopWalter = new AnimationImageAsset(
		'https://www.datocms-assets.com/80292/1673617789-smukcrew-walther-desktop.png',
		{ width: 2600 }
	);

	const mobileWalter = new AnimationImageAsset('https://www.datocms-assets.com/80292/1673601893-smukcrew-walther.png', {
		width: 690,
	});

	useEffect(() => {
		if (moduleRef.current) {
			// console.log('moduleRef: ', moduleRef.current);
			const prevSibling = moduleRef.current.parentElement?.previousSibling;
			const nextSibling = moduleRef.current.parentElement?.nextSibling;

			let prevColorElement;
			let nextColorElement;
			if (prevSibling && (prevSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				prevColorElement = prevSibling;
			} else {
				prevColorElement =
					prevSibling && (prevSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			if (nextSibling && (nextSibling as HTMLElement).classList.contains('colorWrapperAnimatedModule')) {
				nextColorElement = nextSibling;
			} else {
				nextColorElement =
					nextSibling && (nextSibling as HTMLElement).querySelector('div[class*="colorWrapperAnimatedModule"]');
			}

			const prevColor = prevColorElement && window.getComputedStyle(prevColorElement as HTMLElement).backgroundColor;
			const nextColor = nextColorElement && window.getComputedStyle(nextColorElement as HTMLElement).backgroundColor;

			if (prevColor == null && nextColor == null) {
				setBackgroundColors([undefined, undefined]);
			} else if (prevColor == null) {
				setBackgroundColors([undefined, nextColor]);
			} else if (nextColor == null) {
				setBackgroundColors([prevColor, undefined]);
			} else {
				setBackgroundColors([prevColor, nextColor]);
			}
		}
	}, [moduleRef]);

	return (
		<StyledAnimationContainer
			ref={moduleRef}
			height={4155}
			width={2600}
			$svgColor={backgroundColors[1]}
			breakpoints={{ mobile: { height: 1550, width: 2600 } }}
		>
			<ColorWrapper>
				<TopColor bgColor={backgroundColors[0]}></TopColor>
				<BottomColor bgColor={backgroundColors[1]}></BottomColor>
			</ColorWrapper>
			<DesktopContainer>
				<AnimationPositionElement position={{ width: '80vw', anchor: 'center', x: '-50%' }}>
					<AnimationImageElement image={desktopWalter} />
				</AnimationPositionElement>
			</DesktopContainer>
			<MobileContainer>
				<AnimationPositionElement position={{ y: '-2%' }}>
					<AnimationImageElement image={mobileWalter} />
				</AnimationPositionElement>
			</MobileContainer>
			<StyledBreaker />
		</StyledAnimationContainer>
	);
}
