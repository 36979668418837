import { CommonSubTitle, CommonText, CommonTitle } from '@client/common/CommonText';
import { ILinkData } from '@client/core/PageSwitch/Link';
import React from 'react';
import styled from 'styled-components';
import closeIcon from '@client/assets/svgs/closeIcon.svg'; //@ts-ignore
import { Breakpoints, presetColors } from '@client/style/Variables';
import SquaredLinkButton from '../shared/SquaredLinkButton';
import PageOverlayStore from '../overlays/PageOverlays/PageOverlayStore';
import Functions from '@client/style/Functions';

interface ILegekammeratOverlayContentProps {
	title: string;
	description: string;
	link: any;
}

const Container = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${presetColors.white};

	padding: 30px var(--gridMargin);

	${Functions.breakpoint(Breakpoints.laptop)} {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

const UpperContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const CloseButton = styled(closeIcon)``;

const CloseButtonContainer = styled.button`
	svg rect {
		fill: ${presetColors.blackish};
	}

	${Functions.breakpoint(Breakpoints.laptop)} {
		position: absolute;
		top: 80px;
		right: var(--gridMargin);
		padding: 10px;
		margin-right: -10px;
	}
`;

const Title = styled(CommonTitle)`
	color: ${presetColors.blackish};
`;

const Text = styled(CommonText)`
	font-size: 16px;
	margin-top: 20px;
	margin-bottom: 40px;
	text-align: left;
`;

const StyledLiknButton = styled(SquaredLinkButton)`
	${Functions.breakpoint(Breakpoints.laptop)} {
		justify-self: flex-end;
		justify-self: end;
	}
`;

export default function LegekammeratOverlayContent(props: ILegekammeratOverlayContentProps) {
	return (
		<Container>
			<UpperContainer>
				<Title>{props.title}</Title>
				<CloseButtonContainer onClick={() => PageOverlayStore.setContent(undefined)}>
					<CloseButton></CloseButton>
				</CloseButtonContainer>
			</UpperContainer>
			<Text>{props.description}</Text>

			<StyledLiknButton
				data={props.link.link[0]}
				backgroundColor={presetColors.blackish}
				textColor={presetColors.white}
			></StyledLiknButton>
		</Container>
	);
}
