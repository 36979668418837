import React from 'react';
import styled from 'styled-components';
import Functions from '@client/style/Functions';
import { Fonts, Breakpoints, Colors, presetColors } from '@client/style/Variables';
import useIsMobile from '@client/core/CustomHooks/useIsMobile';
import CollectionInfoStore, { ICollectionInfo } from './ContentTypeStore';
import { gsap } from 'gsap/all';
import { waves } from '../Dividers.tsx/Breaker';
import { commonTextStyle } from '@client/common/CommonText';

import DraggableCarousel from '@client/core/modules/Carousel/DraggableCarousel';
import { ProgramTreetopHeaderImage } from './ProgramPageHeaderImage';
import { ColorUtils } from '@client/core/utils/colors/ColorUtils';

const ListPageNav = styled.div<{ $color?: string }>`
	width: 100%;
	margin: 0px var(--gridMargin);
	--color: ${props => props.$color};
	--text-color: ${props => {
		return ColorUtils.findContrastingColor(props.$color, presetColors.white, presetColors.blackish);
	}};
`;
const StyledSubtitle = styled.p`
	${commonTextStyle}
	margin: 0px var(--gridMargin);
	padding-top: 20px;
	color: var(--text-color);
	text-align: left;
	background-color: var(--color);
	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 28px;
		text-align: center;
		margin: auto;
		width: ${Functions.col(12, 16)};
	}
`;

const StyledDraggableCarousel = styled(DraggableCarousel)`
	width: 100%;
	z-index: 1;
	height: 80vw;
	${Functions.breakpoint(Breakpoints.mobile)} {
		height: 400px;
	}
`;
const StyledDesktopHeader = styled.div`
	width: 100%;
	z-index: 1;
	display: flex;
	align-items: flex-end;
	align-content: flex-end;
	justify-content: center;
	flex-wrap: wrap;
	margin: auto;
	/* gap: var(--gridGutter); */
	column-gap: var(--gridGutter);
	padding: 0 var(--gridGutter);
	& > * {
		cursor: pointer;
	}
	padding-top: 300px;
	/* ${Functions.breakpoint(Breakpoints.mobile)} {
		height: 400px;
	} */
`;

const StyledCategoryButton = styled.div`
	display: block;
	appearance: none;
	text-align: left;
	z-index: 1;
	margin: 0;
	font-size: 48px;
	font-family: ${Fonts.GeomanistBold};
	color: var(--text-color);
	white-space: nowrap;
	transition: opacity 0.2s ease;
	background: transparent;

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 86px;
	}

	&:last-child {
		min-width: 100vw;
	}
	&:first-child {
		margin-left: var(--gridMargin);
		${Functions.breakpoint(Breakpoints.tablet)} {
			margin-left: 0;
		}
	}

	&:not(.active) {
		opacity: 0.1;
	}
`;

const StyledScrollContainer = styled.div`
	margin: 0;
	position: relative;
	z-index: 1;
	background-color: var(--color);
	${Functions.breakpoint(Breakpoints.tablet)} {
	}
`;

const StyledHeaderContainer = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 0;
	pointer-events: none;
	top: -20px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		top: -10vw;
	}
	${Functions.breakpoint(Breakpoints.desktop)} {
		top: -250px;
	}
`;

export default function ProgramPageCategories(props: { subtitle?: string }) {
	const activeCategory = CollectionInfoStore.use(state => state.activeInfo);
	let categories = CollectionInfoStore.use(state => state.collectionInfo);
	const isMobile = useIsMobile();

	categories = categories.sort(function (a, b) {
		return b.sortingValue - a.sortingValue;
	});

	const setActiveCategory = (item: ICollectionInfo) => {
		CollectionInfoStore.setSelectedCollectionInfo(item);
		if (item === undefined || item === null) return;
		history.replaceState(null, '', '#' + item?.name?.toLowerCase().replaceAll(' ', ''));
	};

	React.useEffect(() => {
		// When categories change, set active category to be the first in the list
		const url: URL | undefined = typeof window !== 'undefined' ? new URL(window.location.href) : undefined;

		if (url !== undefined && url.hash) {
			const hash = url.hash.replace('#', '');
			const category = categories.find(({ name }) => name?.toLowerCase().replaceAll(' ', '') === hash);
			if (category) {
				setActiveCategory(category);
			}
		} else {
			setActiveCategory(categories[0]);
		}
	}, [categories]);

	const setActiveIndex = i => {
		setActiveCategory(categories[i]);
	};

	const activeIndex = activeCategory ? categories.findIndex(({ id }) => id === activeCategory.id) : 0;

	return (
		<>
			<ListPageNav $color={Functions.hexFromRGB(activeCategory?.headerColor)}>
				{!!categories.length && (
					<StyledScrollContainer>
						{isMobile && (
							<StyledDraggableCarousel
								className='dayCarousel'
								activeIndex={activeIndex}
								dragToNewIndex={i => {
									setActiveIndex(i);
								}}
							>
								{categories.map((category, i) => (
									<StyledCategoryButton className={i === activeIndex ? 'active' : ''} key={'catergory_' + i}>
										{category.name}
									</StyledCategoryButton>
								))}
								<StyledHeaderContainer data-carousel-ignore>
									<ProgramTreetopHeaderImage />
								</StyledHeaderContainer>
							</StyledDraggableCarousel>
						)}
						{!isMobile && (
							<StyledDesktopHeader>
								{categories.map((category, i) => (
									<StyledCategoryButton
										onClick={() => setActiveCategory(categories[i])}
										className={i === activeIndex ? 'active' : ''}
										key={'catergory_' + i}
									>
										{category.name}
									</StyledCategoryButton>
								))}
								<StyledHeaderContainer data-carousel-ignore>
									<ProgramTreetopHeaderImage />
								</StyledHeaderContainer>
							</StyledDesktopHeader>
						)}
						<StyledSubtitle>{props.subtitle}</StyledSubtitle>
					</StyledScrollContainer>
				)}

				<WaveDivider />
			</ListPageNav>
		</>
	);
}

const StyledSVG = styled.svg`
	display: block;
	position: relative;
	width: 100%;
	z-index: 1;
	top: -1px;
	path {
		fill: var(--color);
	}
`;

export const WaveDivider = () => {
	const isMobile = useIsMobile();
	const morphTweenRef = React.useRef<gsap.core.Timeline>();
	const scrubTweenRef = React.useRef<gsap.core.Tween>();

	const path = React.useMemo(() => {
		return isMobile ? waves.mobile.variants[0] : waves.desktop.variants[2];
	}, [isMobile]);

	const refFunction = (element: SVGPathElement) => {
		if (element !== null) {
			morphTweenRef.current = gsap.timeline({ paused: false });

			element.setAttribute('d', path);

			morphTweenRef.current.to(element, {
				morphSVG: {
					// Morph to copy of the same path but with each point index shifted by 2
					shape: path,
					shapeIndex: 2,
					// Use rotational transform around a point that is moved down by 400%
					type: 'rotational',
					origin: '50% 400%',
				},
				duration: 1,
				ease: 'power2.out',
			});
		} else {
			morphTweenRef.current?.kill();
		}
	};

	return (
		<StyledSVG viewBox={isMobile ? waves.mobile.viewBox : waves.desktop.viewBox}>
			<path key={path} ref={refFunction} transform-origin='50% 50%' />
		</StyledSVG>
	);
};
