import React from 'react';
import Image, { ImageData } from './Image';

export default function BackgroundImageModule(props: {
	data: {
		image: ImageData;
	};
}) {
	return <Image data={props.data.image} maxWidth={1920} />;
}
