import { Breakpoints, Fonts, presetColors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import ContentPageStore, { IContentPageState } from '@client/store/ContentPageStore';
import Functions from '@client/style/Functions';

const Label = styled.label<{ disabled?: boolean; checked?: boolean; theme?: IContentPageState; backgroundColor?: any }>`
	position: relative;
	display: inline-block;

	font-size: 21px;
	font-family: ${Fonts.GeomanistBold};
	line-height: 100%;

	border: 2px solid ${props => props.theme.contrastColor};
	border-radius: 100px;
	padding: 8px 12px 6px;
	:first-letter {
		text-transform: uppercase;
	}

	background-color: ${props => (props.checked ? presetColors.blackish : props.backgroundColor?.color)};
	color: ${props => (props.checked ? presetColors.dust : props.backgroundColor?.contrastColor)};

	cursor: pointer;

	${Functions.breakpoint(Breakpoints.mobile)} {
		padding: 14px 19px 14px;
		font-size: 32px;
		line-height: 106%;
	}
`;

const Input = styled.input.attrs({ type: 'checkbox' })`
	height: 0;
	width: 0;
	opacity: 0;
	z-index: -1;
	box-sizing: border-box;
`;

export default function Checkbox(props: {
	checked: boolean;
	onChange: (value: boolean) => void;
	name?: string;
	id?: string;
	label: string;
	disabled?: boolean;
	backgroundColor?: any;
}) {
	const theme: IContentPageState = ContentPageStore.use(state => state);

	return (
		<Label
			htmlFor={props.id}
			disabled={props.disabled}
			checked={props.checked}
			theme={theme}
			backgroundColor={props.backgroundColor}
		>
			{props.label}
			<Input
				id={props.id}
				name={props.name}
				disabled={props.disabled}
				checked={props.checked}
				onChange={event => props.onChange(event.target.checked)}
			/>
		</Label>
	);
}
