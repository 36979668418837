import { CommonTitle } from '@client/common/CommonText';
import Functions from '@client/style/Functions';
import { Breakpoints, Colors, presetColors } from '@client/style/Variables';
import React from 'react';
import styled from 'styled-components';
import DetailedListEntry from './DetailedListEntry';

const Container = styled.div<{ bgColor?: string }>`
	width: 100%;
	background-color: ${props => props.bgColor || 'white'};
	padding: 20px var(--gridMargin);
`;

const Title = styled(CommonTitle)<{ color?: string }>`
	font-size: 44px;
	text-align: left;
	color: ${props => props.color || Colors.darkText};

	${Functions.breakpoint(Breakpoints.mobile)} {
		font-size: 68px;
	}
`;

const LinkListContainer = styled.div`
	margin-top: 50px;
	width: 100%;

	display: grid;
	grid-template-columns: 1fr;
	gap: var(--gridGutter);
	row-gap: 80px;

	${Functions.breakpoint(Breakpoints.laptop)} {
		grid-template-columns: 1fr 1fr;
	}
`;

export default function FeaturedLinksList(props: {
	data: { title: string; backgroundColorLink: { color: any; contrastColor: any }; links: any[] };
}) {
	return (
		<Container
			bgColor={props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.color)}
			className='colorWrapperAnimatedModule'
		>
			<Title
				color={props.data.backgroundColorLink && Functions.hexFromRGB(props.data.backgroundColorLink?.contrastColor)}
			>
				{props.data.title}
			</Title>

			<LinkListContainer>
				{props.data.links.map((item, i) => (
					<DetailedListEntry
						backgroundColorLink={props.data.backgroundColorLink?.color}
						image={item.image}
						title={item.title}
						subtitle={item.subtitle}
						link={item.link}
						key={'featuredLink' + i}
					/>
				))}
			</LinkListContainer>
		</Container>
	);
}
