import { boldTextStyle, commonTextStyle } from '@client/common/CommonText';
import { Colors } from '@client/style/Variables';
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { IFormTextareaField } from './FormStore';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin-top: 20px;
`;

const Label = styled.label<{ valid?: boolean | undefined }>`
	${commonTextStyle}
	${boldTextStyle}
	color: ${props => (props.valid === false ? Colors.error : Colors.darkText)};
`;

const StyledTextarea = styled.textarea`
	${commonTextStyle}
	flex: 1;
	height: 400px;
	border: none;
	padding: 10px;
`;

interface IProps {
	data: IFormTextareaField;
	valid?: boolean | undefined;
	onChange: (value: string) => void;
}

export default function FormTextArea(props: IProps) {
	const onChangeInternal = (e: SyntheticEvent<HTMLTextAreaElement, Event>) => {
		props.onChange(e.currentTarget.value);
	};

	return (
		<Container>
			<Label htmlFor={props.data.id} valid={props.valid}>
				{props.data.label}
			</Label>

			<StyledTextarea
				id={props.data.id}
				value={props.data.value}
				required={props.data.required === true}
				rows={7}
				onChange={e => onChangeInternal(e)}
			/>
		</Container>
	);
}
