import React from 'react';
import styled from 'styled-components';

export interface IProps {
	className?: string;
	children: JSX.Element | JSX.Element[];
	id?: string;
}

const Container = styled.div`
	position: relative;
	width: 100%;
`;

export const ModuleWrapper = (props: IProps) => {
	return (
		<Container className={props.className} id={'module_' + props.id}>
			{props.children}
		</Container>
	);
};
